import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import { GetGroupIconColor, GetGroupIconName, GetGroupTextColor, GetGroupIconSize } from '../../../utils/tools';
import ColorPickerMenu from '../ColorPicker/ColorPickerMenu';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import translateObj from '../../../utils/translate';
class MainStylePanel extends React.Component {
  constructor(props) {
    super(props);
    this.toggleGroupStyling = this.toggleGroupStyling.bind(this);
    this.getCurrentGroupStyling = this.getCurrentGroupStyling.bind(this);
    this.toggleCustomLabel = this.toggleCustomLabel.bind(this);
    this.getCurrentCustomLabel = this.getCurrentCustomLabel.bind(this);
    this.getCurrentGroup = this.getCurrentGroup.bind(this);
  }

  toggleGroupStyling(currentGroup, e) {
    //update current group's useGroupStyle property
    const groupsArrayClone = Array.from(this.props.groups);
    for (let group of groupsArrayClone) {
      if (group.id === currentGroup) {
        group.useGroupStyle = e.target.checked;
      }
    }

    //update icon color of points in group if necessary
    const pointsArrayClone = Array.from(this.props.points).filter(point => point.content.group === currentGroup);
    const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== currentGroup);
    const styledArray = this.props.updateOrderAndIcon(groupsArrayClone, currentGroup, pointsArrayClone);
    const combinedArray = styledArray.concat(leftOverArray);

    this.props.updateMapObj({
      mapGroups: groupsArrayClone,
      mapPoints: combinedArray
    });
  }

  getCurrentGroupStyling() {
    if (this.props.isGroup) {
      const myGroup = this.props.groups.filter(group => group.id === this.props.currentGroup);
      return myGroup[0].useGroupStyle;
    } else {
      const myGroup = this.props.groups.filter(group => group.id === this.props.currentPoint.group);
      return myGroup[0].useGroupStyle;
    }
  }

  toggleCustomLabel(currentGroup, e) {
    //update current group's useCustomLabel property
    const groupsArrayClone = Array.from(this.props.groups);
    const pointsArrayClone = Array.from(this.props.points).filter(point => point.content.group === currentGroup);
    const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== currentGroup);

    if (this.props.isGroup) {
      for (let group of groupsArrayClone) {
        if (group.id === currentGroup) {
          group.useCustomLabel = e.target.checked;
        }
      }

      for (const point of pointsArrayClone) {
        point.content.useCustomLabel = e.target.checked;
      }
    } else {
      for (let group of groupsArrayClone) {
        if (group.id === currentGroup) {
          group.useCustomLabel = false;
        }
      }

      for (const point of pointsArrayClone) {
        if (point.content.id === this.props.currentPoint.id) {
          point.content.useCustomLabel = e.target.checked;
        }
      }
    }
  
    //update icon color of points in group if necessary
    const styledArray = this.props.updateOrderAndIcon(groupsArrayClone, currentGroup, pointsArrayClone);
    const combinedArray = styledArray.concat(leftOverArray);

    this.props.updateMapObj({
      mapGroups: groupsArrayClone,
      mapPoints: combinedArray
    });
  }

  getCurrentCustomLabel() {
    if (this.props.isGroup) {
      const myGroup = this.props.groups.filter(group => group.id === this.props.currentGroup);
      return myGroup[0].useCustomLabel;
    } else {
      return this.props.currentPoint.useCustomLabel;
    }
  }

  getCurrentGroup(){
    const cgroup = this.props.groups.filter(group => group.id === this.props.currentGroup);
    return cgroup[0];
  }

  hasLeaderLine(currentPoint){
    if(!currentPoint.useLeaderline)
      return false;
    return currentPoint.useLeaderline;
  }

  render() {
    const isGroup = this.props.isGroup;
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="styleIconGroups" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 88.62 94.1">
              <line id="Line_20" stroke="#0c9ed9" fill="none" strokeWidth="4" y1="92.85" x2="88.62" y2="92.85" />
              <path id="Path_4362" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M24.45-22.9,7.23-35A4.68,4.68,0,0,0,.72-33.89a4.48,4.48,0,0,0-.58,1.13L-3.51-22.3-30.89,5.46a4.65,4.65,0,0,0-1.34,3.23V56.11" transform="translate(34.31 37.14)" />
              <path id="Path_4363" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M-28.37,55.58,13.52,40.67a4.6,4.6,0,0,0,2.57-2.37L32.84,3l8.58-7a4.68,4.68,0,0,0,.66-6.59,4.62,4.62,0,0,0-.92-.85" transform="translate(34.31 37.14)" />
              <circle id="Ellipse_2" fill="none" stroke="#0c9ed9" strokeWidth="4" cx="33.22" cy="52.86" r="5.67" />
              <line id="Line_21" fill="none" stroke="#0c9ed9" strokeWidth="4" x1="3.37" y1="92.08" x2="30.5" y2="56.12" />
            </svg>
          </span>
          <span>{isGroup ? translateObj.styleLayersTitle[this.props.translate] : translateObj.stylePointsTitle[this.props.translate]}</span>
          <span className="panel-close closeStyle"><CloseIcon onClick={this.props.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div className="panel-body-small-text">
              <span>{translateObj.currentIcon[this.props.translate]}</span>
              <SVGIconComponent
                color={isGroup ? GetGroupIconColor(this.props.groups, this.props.currentGroup) : this.props.currentPoint.pointColor}
                name={isGroup ? GetGroupIconName(this.props.groups, this.props.currentGroup) : this.props.currentPoint.pointIconName}
                label="0"
                textFill={isGroup ? GetGroupTextColor(this.props.groups, this.props.currentGroup) : this.props.currentPoint.pointTextColor}
                size={isGroup ? GetGroupIconSize(this.props.groups, this.props.currentGroup) : this.props.currentPoint.pointIconSize}
              ></SVGIconComponent>
            </div>
            <div className="useGroupStyleCheckBox2">
              {translateObj.useLayerStyle[this.props.translate]}
              <Checkbox
                color="default"
                value="default"
                inputProps={{ 'aria-label': 'use layer style' }}
                onChange={(event) => this.toggleGroupStyling(this.props.currentGroup, event)}
                checked={this.getCurrentGroupStyling()}
                disableRipple
              />
            </div>
            <div className="useGroupStyleCheckBox2">
              {translateObj.useCustomLabel[this.props.translate]}
              <Checkbox
                color="default"
                value="default"
                inputProps={{ 'aria-label': 'use custom label' }}
                onChange={(event) => this.toggleCustomLabel(this.props.currentGroup, event)}
                checked={this.getCurrentCustomLabel()}
                disableRipple
              />
            </div>
          </div>
          <div>
            <span>{translateObj.chooseIconColor[this.props.translate]}</span>
            <ColorPickerMenu
              squareColor={isGroup ? GetGroupIconColor(this.props.groups, this.props.currentGroup) : this.props.currentPoint.pointColor}
              setFillColor={this.props.changeColorOrIcon.bind(this, 'color')}
              addTransparent={true}
            />
          </div>
          <div style={{ paddingTop: '20px' }}>
            <span>{translateObj.chooseIcon[this.props.translate]}</span>
            <div className="colorPickerDiv">
              <div className="colorPickerRow">
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'teardropIcon')}>
                  <svg height="20px" viewBox="0 0 11.29 19.61" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#9ea2a2" d="M10.23,18.59s5.14-10,5.14-12.86a5.15,5.15,0,1,0-10.29,0c0,2.84,5.15,12.86,5.15,12.86" transform="translate(-4.58 -0.08)" />
                    <path fill="none" stroke="#ffffff" d="M10.23,18.59s5.14-10,5.14-12.86a5.15,5.15,0,1,0-10.29,0C5.08,8.57,10.23,18.59,10.23,18.59Z" transform="translate(-4.58 -0.08)" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'starIcon')}>
                  <svg height="20px" viewBox="0 0 20 19.02" xmlns="http://www.w3.org/2000/svg">
                    <polygon fill="#9ea2a2" stroke="#ffffff" points="10 0 12.42 7.18 20 7.26 13.92 11.79 16.18 19.02 10 14.63 3.82 19.02 6.08 11.79 0 7.26 7.58 7.18 10 0" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'circleIcon')}>
                  <svg height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="10" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'diamondIcon')}>
                  <svg height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <rect fill="#9ea2a2" stroke="#ffffff" width="65" height="65" rx="5" transform="rotate(45, 15, 55)" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'squareIcon')}>
                  <svg height="16px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <rect fill="#9ea2a2" stroke="#ffffff" width="100" height="100" />
                  </svg>
                </div>
              </div>
              <div className="colorPickerRow">
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'airportIcon')}>
                  <svg id="airportIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path id="Path_4000" d="M9.36,15.27l-2.14.85c-.79.31-1.33-.1-3.22-.81l-.43.18,1.56,1.45-.38.15c-.83.33,2.88.17,4-.08l6.18-2.44s2.13-.84,1.39-1.24-2.76.3-2.76.3l-2.38.94-3.5-1.85-.24.09,2,2.46Z" transform="translate(-1.5 -6.5)" fill="#ffffff" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'bankIcon')}>
                  <svg id="bankIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <g transform="scale(1.5)">
                      <path id="Path_4297" d="M8.74,16.72a2.53,2.53,0,0,0,1.45.6,1.21,1.21,0,0,0,1.28-1.12c0-.62-.61-.92-1.28-1.12S8.91,14.58,8.91,14a1.21,1.21,0,0,1,1.28-1.12,1.33,1.33,0,0,1,1.12.57" transform="translate(-4.25 -9.25)" fill="none" stroke="#ffffff" />
                      <path id="Path_4298" d="M10.2,11.8v.61" transform="translate(-4.25 -9.25)" fill="none" stroke="#ffffff" />
                      <path id="Path_4299" d="M10.2,17.75v.61" transform="translate(-4.25 -9.25)" fill="none" stroke="#ffffff" />
                    </g>
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'dockIcon')}>
                  <svg id="bankIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <g transform="translate(0,0)">
                      <line x1="8.57" y1="2.37" x2="8.57" y2="4.02" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                      <polyline points="6.49 5.44 6.49 4.02 10.64 4.02 10.64 5.44" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                      <polyline points="5.39 11.44 4.32 8.41 8.5 7.16 12.73 8.41 11.87 11.44" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                      <line x1="8.5" y1="11.44" x2="8.5" y2="7.16" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                      <polyline points="5.78 7.97 5.78 5.33 11.36 5.33 11.36 7.97" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                      <path d="M4.12,13.56c.43-.62,1-1.28,1.55-1.2S6.4,13.49,7,13.51s.92-1.31,1.55-1.29.79,1.24,1.39,1.25.81-1.19,1.44-1.2.91,1.21,1.55,1.2" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                    </g>
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'educationIcon')}>
                  <svg id="educationIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path id="Path_3943" d="M13,13.37l-.05,2.18-1.28.2a8,8,0,0,1-1.54.12A7.92,7.92,0,0,1,7,15.28v-2l3.21.86L13,13.37" transform="translate(-1.5 -6.5)" fill="#ffffff" />
                    <path id="Path_3944" d="M5.23,16.32V12.8" transform="translate(-1.5 -6.5)" fill="none" stroke="#ffffff" strokeWidth="0.5" />
                    <path id="Path_3945" d="M5.76,18.63c0,.35-.12.63-.26.63H5c-.14,0-.26-.28-.26-.63l.12-1.7c0-.35.12-.63.27-.63h.27c.15,0,.26.28.26.63Z" transform="translate(-1.5 -6.5)" fill="#ffffff" />
                    <path id="Path_3946" d="M4.48,12.59l5.68-1.26,5.65,1.26-5.65,1.53Z" transform="translate(-1.5 -6.5)" fill="#ffffff" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'golfIcon')}>
                  <svg id="golfIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path id="Path_3965" d="M9.33,18.42c-.86.11-1.47.41-1.47.76s1,.81,2.28.81,2.29-.36,2.29-.81-.6-.64-1.45-.76" transform="translate(-1.5 -6.5)" fill="none" stroke="#ffffff" strokeWidth="0.5" />
                    <path id="Path_3966" d="M10.14,19.18V12.89" transform="translate(-1.5 -6.5)" fill="none" stroke="#ffffff" strokeWidth="0.5" />
                    <path id="Path_3967" d="M10.14,10v2.88L13,11.45Z" transform="translate(-1.5 -6.5)" fill="#ffffff" stroke="#ffffff" strokeMiterlimit="10" strokeWidth="0.5" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'hospitalIcon')}>
                  <svg id="hospitalIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path id="Path_3857" d="M6.82,19.23V10.77" transform="translate(-1.5 -6.5)" fill="none" strokeWidth="2" stroke="#ffffff" strokeMiterlimit="10" />
                    <path id="Path_3858" d="M13.18,19.23V10.77" transform="translate(-1.5 -6.5)" fill="none" strokeWidth="2" stroke="#ffffff" strokeMiterlimit="10" />
                    <path id="Path_3859" d="M6.82,15h6.36" transform="translate(-1.5 -6.5)" fill="none" strokeWidth="2" stroke="#ffffff" strokeMiterlimit="10" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'hotelIcon')}>
                  <svg id="hotelIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path id="Path_4338" d="M5.15,18h9.7" transform="translate(-1.5 -6.5)" fill="none" stroke="#ffffff" />
                    <path id="Path_4339" d="M14.36,16.67c0-3-2-5-4.39-5s-4.38,2-4.38,5h8.77Z" transform="translate(-1.5 -6.5)" fill="#ffffff" />
                    <path id="Path_4340" d="M10.49,11.21c0-1.65-1-1.66-1,0Z" transform="translate(-1.5 -6.5)" fill="#ffffff" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'parkIcon')}>
                  <svg id="parkIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path id="Path_3825" d="M11.35,19.4V13.88" transform="translate(-1.5 -6.5)" fill="none" stroke="#ffffff" strokeWidth="0.75" />
                    <path id="Path_3828" d="M11.83,16.68a1.68,1.68,0,0,0,1,.34,1.87,1.87,0,0,0,1.79-1.94,1.91,1.91,0,0,0-1.34-1.88,2,2,0,0,0-1.95-1.81,2.13,2.13,0,0,0-1.9,2.77,1.42,1.42,0,0,0-.66,1.23,1.3,1.3,0,0,0,2.05,1.15Z" transform="translate(-1.5 -6.5)" fill="#ffffff" />
                    <path d="M7.05,10.81a.84.84,0,0,0-1.38.55A.61.61,0,0,0,6,12.49H7.86a.63.63,0,0,0,.64-.61.64.64,0,0,0-.71-.61A.65.65,0,0,0,7.05,10.81Z" transform="translate(-1.5 -6.5)" fill="#ffffff" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'parkingIcon')}>
                  <svg id="parkingIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path d="M13.64,8a.85.85,0,0,0-.83-.83h-.17L12.06,5a1,1,0,0,0-1-.75H5.91a1,1,0,0,0-1,.75L4.36,7.18H4.19A.85.85,0,0,0,3.36,8v3.16h1v1a.61.61,0,0,0,.61.6.6.6,0,0,0,.6-.6v-1h5.88v1a.61.61,0,0,0,1.21,0v-1h1ZM5,10.13A.91.91,0,1,1,5,8.32a.91.91,0,0,1,0,1.81Zm.49-3.28.27-1.38a.75.75,0,0,1,.74-.54h4.1a.76.76,0,0,1,.74.53l.27,1.39Zm6.62,3.28a.91.91,0,0,1,0-1.81.9.9,0,0,1,.92.89h0A.91.91,0,0,1,12.06,10.13Z" fill="#ffffff" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'restaurantIcon')}>
                  <svg id="restaurantIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path d="M5.5,4.1V7.4s0,.8,1.2.8S8,7.7,8,7.4V4.1" fill="none" stroke="#ffffff" strokeMiterlimit="10" />
                    <line x1="6.8" y1="12.9" x2="6.8" y2="4.1" fill="none" stroke="#ffffff" strokeMiterlimit="10" />
                    <path d="M9.8,12.9V4.1a3.82,3.82,0,0,1,1.6,2.8V9.2H9.8" fill="none" stroke="#ffffff" strokeMiterlimit="10" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'retailIcon')}>
                  <svg id="retailIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path id="Path_3820" d="M13.16,19.12v.72H6.84V12.25h6.32v6.87Z" transform="translate(-1.5 -6.5)" fill="#ffffff" />
                    <path id="Path_3821" d="M11.48,12.28v-.56A1.51,1.51,0,0,0,10,10.16h0a1.56,1.56,0,0,0-1.49,1.56v.56" transform="translate(-1.5 -6.5)" fill="none" stroke="#ffffff" strokeWidth="0.75" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'schoolIcon')}>
                  <svg id="schoolIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <rect x="4.81" y="8.26" width="7.38" height="4.72" fill="#ffffff" />
                    <rect x="7.41" y="4.02" width="0.48" height="4.24" fill="#ffffff" />
                    <rect x="7.9" y="4.33" width="2" height="1.39" fill="#ffffff" />
                  </svg>
                </div>


                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'trainIcon')}>
                  <svg id="trainIcon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 17 17">
                    <circle cx="8.5" cy="8.5" r="8" fill="#9ea2a2" stroke="#ffffff" />
                    <path d="M5.47,5.61S5.51,4,6.9,4h3.62a1.46,1.46,0,0,1,1.19,1.57v4.85H5.49Z" fill="none" stroke="#fff" strokeWidth="0.75" />
                    <line x1="5.49" y1="5.7" x2="11.64" y2="5.7" fill="none" stroke="#ffffff" strokeWidth="0.75" />
                    <line x1="5.5" y1="7.89" x2="11.67" y2="7.89" fill="none" stroke="#ffffff" strokeWidth="0.75" />
                    <line x1="10.5" y1="9.18" x2="11.62" y2="9.18" fill="none" stroke="#ffffff" strokeWidth="0.75" />
                    <line x1="5.51" y1="9.18" x2="6.68" y2="9.18" fill="none" stroke="#ffffff" strokeWidth="0.75" />
                    <line x1="5.47" y1="11.81" x2="11.55" y2="11.81" fill="none" stroke="#ffffff" strokeWidth="0.75" />
                    <line x1="6.8" y1="10.52" x2="4.36" y2="12.96" fill="none" stroke="#ffffff" strokeWidth="0.75" />
                    <line x1="10.2" y1="10.51" x2="12.64" y2="12.95" fill="none" stroke="#ffffff" strokeWidth="0.75" />
                  </svg>
                </div>

                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'icon', 'truckIcon')}>
                  <svg version="1.1" id="truckIcon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 17 20">
                    <circle fill="#9EA2A2" stroke="#FFFFFF" cx="8.5" cy="10" r="8"/>
                    <circle fill="#FFFFFF" cx="5.4" cy="12.5" r="0.9"/>
                    <path fill="#FFFFFF" d="M5.4,13c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5C5.9,12.8,5.7,13,5.4,13z"/>
                    <circle fill="#FFFFFF" cx="5.4" cy="12.5" r="0.3"/>
                    <circle fill="#FFFFFF" cx="12.1" cy="12.5" r="0.9"/>
                    <path fill="#FFFFFF" d="M12.1,13c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5C12.6,12.8,12.3,13,12.1,13z"
                      />
                    <circle fill="#FFFFFF" cx="12.1" cy="12.5" r="0.3"/>
                    <path fill="none" d="M12.2,8.5c-0.1-0.2-0.3-0.3-0.5-0.3H11c-0.1,0-0.1,0.1-0.1,0.1v1.4c0,0.1,0.1,0.1,0.1,0.1h1.7
                      c0.1,0,0.2-0.1,0.1-0.2L12.2,8.5z"/>
                    <path fill="#FFFFFF" d="M3.5,11.9c-0.1,0-0.1,0-0.1,0.1v0.4c0,0.1,0,0.1,0.1,0.1h0.7c0-0.2,0.1-0.4,0.2-0.6H3.5z"/>
                    <path fill="#FFFFFF" d="M14.3,10.4l-0.8-0.3l-1-1.7C12.3,8.1,12,8,11.7,8h-0.9c-0.1,0-0.2,0.1-0.2,0.2V10v0.1v1.6
                      c0,0.1-0.1,0.3-0.3,0.3h-4c0.1,0.2,0.2,0.4,0.2,0.6h4.1h0.1h0.2c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1h1
                      c0.2,0,0.3-0.1,0.3-0.3v-1.5C14.5,10.5,14.4,10.4,14.3,10.4z"/>
                    <path fill="#FFFFFF" d="M12.8,9.8H11c-0.1,0-0.1-0.1-0.1-0.1V8.4c0-0.1,0.1-0.1,0.1-0.1h0.7c0.2,0,0.4,0.1,0.5,0.3l0.7,1.1
                      C12.9,9.7,12.9,9.8,12.8,9.8z"/>
                    <path fill="#FFFFFF" d="M5.4,11.4c0.3,0,0.6,0.1,0.8,0.3h4.1c0.1,0,0.2-0.1,0.2-0.2V7.4c0-0.1-0.1-0.2-0.2-0.2H2.7
                      c-0.1,0-0.2,0.1-0.2,0.2v4.1c0,0.1,0.1,0.2,0.2,0.2h1.9C4.8,11.5,5.1,11.4,5.4,11.4z"/>
                  </svg>
                </div>


              </div>
            </div>
          </div>
          <div style={{ paddingTop: '20px' }}>
            <span>{translateObj.chooseTextColor[this.props.translate]}</span>
            <ColorPickerMenu
              squareColor={isGroup ? GetGroupTextColor(this.props.groups, this.props.currentGroup) : this.props.currentPoint.pointTextColor}
              setFillColor={this.props.changeColorOrIcon.bind(this, 'text')}
              addTransparent={true}
            />
          </div>
          <div style={{ paddingTop: '20px' }}>
            <span>{translateObj.chooseSize[this.props.translate]}</span>
            <div className="colorPickerDiv">
              <div className="colorPickerRow">
                <div className="colorPickerCellTiny" onClick={this.props.changeColorOrIcon.bind(this, 'size', 'tiny')}>
                  <svg height="13px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                  </svg>
                </div>

                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'size', 'smaller')}>
                  <svg height="18px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                  </svg>
                </div>

                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'size', 'small')}>
                  <svg height="22px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'size', 'default')}>
                  <svg height="30px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                  </svg>
                </div>
                <div className="colorPickerCell" onClick={this.props.changeColorOrIcon.bind(this, 'size', 'large')}>
                  <svg height="45px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: '10px' }}>
            <span>Add Leader Line on Move:</span>
            <Checkbox
              color="default"
              onChange={this.props.changeColorOrIcon.bind(this, 'hasLeader')}
              checked={isGroup ? this.getCurrentGroup().groupLeaderLine : this.props.currentPoint.useLeaderline}
            />
            </div>
            {/* {(isGroup ? this.getCurrentGroup().groupLeaderLine : this.props.currentPoint.useLeaderline) ? */}
            <div>
              <div style={{ paddingTop: '10px' }}>
              <span>Choose Line Color:</span>
              <ColorPickerMenu
                      squareColor={isGroup ? this.getCurrentGroup().groupLeaderColor : this.props.currentPoint.leaderline?.color || this.props.pointLeaderColor || '#ffffff'}
                      setFillColor={this.props.changeColorOrIcon.bind(this, 'leaderColor')}
                      //setFillColor={this.toggleColor(this)}
                      addTransparent={false}
                    />
            </div>
            <div style={{ paddingTop: '10px' }}>
            <span>Add Leader Line Anchor:</span>
            <Checkbox
              color="default"
              onChange={this.props.changeColorOrIcon.bind(this, 'hasLeaderAnchor')}
              checked={isGroup ? this.getCurrentGroup().groupLeaderAnchor : this.props.currentPoint.leaderline?.anchor || this.props.pointLeaderAnchor}
            />
            </div>

            <div style={{ paddingTop: '20px' }}>
                  <TextField
                    label="Width"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={isGroup ? this.getCurrentGroup().groupLeaderWidth : this.props.currentPoint.leaderline?.width || this.props.pointLeaderWidth || 3}
                    //value={3}
                    InputProps={{ inputProps: { min: 0, max: 5, step: 1 } }}
                    //onChange={(e) => { this.setWidth(e.target.value) }}
                    onChange={this.props.changeColorOrIcon.bind(this, 'leaderWidth')}
                  />
                  &nbsp; &nbsp;
                <TextField
                    label="Opacity"      
                    variant="outlined"
                    size="small"
                    type="number"
                    value={isGroup ? this.getCurrentGroup().groupLeaderOpacity : this.props.currentPoint.leaderline?.opacity || this.props.pointLeaderOpacity || 1}
                    //value={3}
                    InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                    //onChange={(e) => { this.setWidth(e.target.value) }}
                    onChange={this.props.changeColorOrIcon.bind(this, 'leaderOpacity')}
                  />

              </div>

            </div>
           {/* : null} */}
          </div>
        <div className="geocodeDiv flexRight">
          <button
            className="geocodeBtn"
            onClick={this.props.closePanel}
          >
            {translateObj.doneBtn[this.props.translate]}
          </button>
        </div>
      </div>
    );
  }
}

export default MainStylePanel;