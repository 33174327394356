import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import translateObj from '../../../utils/translate';

class LegendOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      top_leftStatus: false,
      bottom_leftStatus: false,
      bottom_rightStatus: false,
      top_rightStatus: false,
      showOptions: false,
      anchorEl: null,
      largeSize: false,
      defaultSize: false,
      smallSize: false,
      top_leftStatusNorth: false,
      bottom_leftStatusNorth: false,
      bottom_rightStatusNorth: false,
      top_rightStatusNorth: false,
      scaleLocation: this.props.scaleLocation || 'bottom_right'
    }

    this._setLegendPosition = this._setLegendPosition.bind(this);
    this._setNorthArrowPosition = this._setNorthArrowPosition.bind(this);
    this._setScalePosition = this._setScalePosition.bind(this);
    this._setLegendSize = this._setLegendSize.bind(this);
    this.toggleViewBtn = this.toggleViewBtn.bind(this);
    this.toggleLegendVisible = this.toggleLegendVisible.bind(this);
    this.toggleTwoColumnLegend = this.toggleTwoColumnLegend.bind(this);
    this.toggleLegendScale = this.toggleLegendScale.bind(this);
    //this.toggleScaleVisible = this.toggleScaleVisible.bind(this);
   // this.scaleCheck
  }


    // onChange={(event) => this.toggleScaleVisible(event)}
  // checked={this.state.scaleCheck()}
  componentDidMount() {
    this._setLegendPosition();
    this._setNorthArrowPosition();
    this._setLegendSize();
  }

  // componentDidUpdate() {
  //   if (this.state.scaleLocation != this.props.scaleLocation){
  //     this.setState({scaleLocation: this.props.scaleLocation});
  //   }
  // }

  _setLegendPosition(vertical, horizontal) {
    let positionArray = [
      { name: 'top_left', bool: false },
      { name: 'bottom_left', bool: false },
      { name: 'bottom_right', bool: false },
      { name: 'top_right', bool: false }
    ];

    const vPosition = vertical || this.props.legend[0].position[1];
    const hPosition = horizontal || this.props.legend[0].position[0];
    const positionName = vPosition + "_" + hPosition;

    for (const position of positionArray) {
      if (position.name === positionName) {
        position.bool = true;
      }
    }

    this.setState({
      top_leftStatus: positionArray[0].bool,
      bottom_leftStatus: positionArray[1].bool,
      bottom_rightStatus: positionArray[2].bool,
      top_rightStatus: positionArray[3].bool
    });

    if (vertical && horizontal) {
      const legendClone = Array.from(this.props.legend);
      legendClone[0].position = [horizontal, vertical];
      this.props.updateMapObj({ legend: legendClone });
    }
  }

  _setScalePosition(vertical, horizontal) {
    const vPosition = vertical;// || this.props.legend[0].northArrowPosition[1];
    const hPosition = horizontal;// || this.props.legend[0].northArrowPosition[0];
    const positionName = vPosition + "_" + hPosition;
    this.props.updateMapObj({ scaleLocation: positionName });
    this.setState({scaleLocation: positionName});

  }

  _setNorthArrowPosition(vertical, horizontal) {
    let positionArray = [
      { name: 'top_left', bool: false },
      { name: 'bottom_left', bool: false },
      { name: 'bottom_right', bool: false },
      { name: 'top_right', bool: false }
    ];

    const vPosition = vertical || this.props.legend[0].northArrowPosition[1];
    const hPosition = horizontal || this.props.legend[0].northArrowPosition[0];
    const positionName = vPosition + "_" + hPosition;

    for (const position of positionArray) {
      if (position.name === positionName) {
        position.bool = true;
      }
    }

    this.setState({
      top_leftStatusNorth: positionArray[0].bool,
      bottom_leftStatusNorth: positionArray[1].bool,
      bottom_rightStatusNorth: positionArray[2].bool,
      top_rightStatusNorth: positionArray[3].bool
    });

    if (vertical && horizontal) {
      const legendClone = Array.from(this.props.legend);
      legendClone[0].northArrowPosition = [horizontal, vertical];
      this.props.updateMapObj({ legend: legendClone });
    }
  }

  _setLegendSize(size) {
    let sizeArray = [
      { name: 'smallSize', bool: false },
      { name: 'defaultSize', bool: false },
      { name: 'largeSize', bool: false }
    ];

    let currentSize = 1;

    if (size) {
      if (size === 'small') {
        currentSize = 1.2;
        sizeArray[0].bool = true;
      } else if (size === 'default') {
        currentSize = 1;
        sizeArray[1].bool = true;
      } else {
        currentSize = 0.8;
        sizeArray[2].bool = true;
      }
    } else {
      currentSize = this.props.legend[0].scale;
      if (currentSize === 1) {
        sizeArray[1].bool = true;
      } else if (currentSize > 1) {
        sizeArray[0].bool = true;
      } else {
        sizeArray[2].bool = true;
      }
    }

    this.setState({
      smallSize: sizeArray[0].bool,
      defaultSize: sizeArray[1].bool,
      largeSize: sizeArray[2].bool
    });

    if (size) {
      const legendClone = Array.from(this.props.legend);
      legendClone[0].scale = currentSize;
      this.props.updateMapObj({ legend: legendClone });
    }
  }

  toggleOptionsPanel(e, bool) {
    this.setState({
      anchorEl: e.currentTarget,
      showOptions: bool
    });
  }

  toggleViewBtn() {
    let updateObj = {
      legendView: 'printView',
      viewButtonText: 'Format View'
    };

    if (this.props.legendView === 'printView') {
      updateObj.legendView = 'mapView';
      updateObj.viewButtonText = 'Print View';
    }

    this.props.updateLegendObj(updateObj);
  }

  toggleLegendVisible(event) {
    const legendClone = Array.from(this.props.legend);
    legendClone[0].visible = event.target.checked;
    this.props.updateMapObj({ legend: legendClone });
  }

  toggleTwoColumnLegend(event) {
    let columnNum = 1;
    if (event.target.checked === true) {
      columnNum = 2;
    }
    const legendClone = Array.from(this.props.legend);
    legendClone[0].columns = columnNum;
    this.props.updateMapObj({ legend: legendClone });
  }

  toggleLegendScale(event) {
    let scale = 1;
    if (event.target.checked === true) {
      scale = 1.2;
    }
    const legendClone = Array.from(this.props.legend);
    legendClone[0].scale = scale;
    this.props.updateMapObj({ legend: legendClone });
  }

  toggleNorthArrowVisible(event) {
    const legendClone = Array.from(this.props.legend);
    legendClone[0].northArrowVisible = event.target.checked;
    this.props.updateMapObj({ legend: legendClone });
  }

  toggleScaleVisible(event) {

    const location = this.state.scaleLocation || 'bottom_right';
    if(this.props.scaleLocation===null){
      this.props.updateMapObj({ scaleLocation: location });
    }
    else {
      this.props.updateMapObj({ scaleLocation: null });
    }
  }

  scaleCheck() {
    if(!this.props.scaleLocation){
      return false;
    }
    return true;
  }




  render() {
    return (
      <div className="legendView-divRight">
        <div>
          <button
            className="geocodeBtnSmall"
            onClick={this.toggleViewBtn}
          >
            Change to&nbsp;
            <span>{this.props.viewButtonText}</span>
          </button>
        </div>
        <div>
          <button
            className="geocodeBtnSmall"
            onClick={(e) => this.toggleOptionsPanel(e, true)}
          >
            {translateObj.legendOptionsBtn[this.props.translate]}
          </button>
        </div>
        {this.state.showOptions ?
          <Menu
            id="legendOpts-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.showOptions}
            onClose={this.toggleOptionsPanel.bind(this, { currentTarget: null }, false)}
          >
            <div className="legendOptsMenuDiv">
              <div className="legendOptsCheckBoxLeft">
                {translateObj.showLegendOnMap[this.props.translate]}
                <Checkbox
                  color="default"
                  value="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={(event) => this.toggleLegendVisible(event)}
                  checked={this.props.legend[0].visible}
                  disableRipple
                />
              </div>
              <div className="legendOptsCheckBoxLeft">
                {translateObj.twoColumnLegend[this.props.translate]}
                <Checkbox
                  color="default"
                  value="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={(event) => this.toggleTwoColumnLegend(event)}
                  checked={this.props.legend[0].columns > 1}
                  disableRipple
                />
              </div>
              {/* <div className="legendOptsCheckBoxLeft">
                Smaller Legend Text
                <Checkbox
                  color="default"
                  value="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={(event) => this.toggleLegendScale(event)}
                  checked={this.props.legend[0].scale > 1}
                  disableRipple
                />
              </div> */}
              <div className="legendPositionOptsDiv">
                <div className="padding-6b paddingT8">{translateObj.legendSize[this.props.translate]}</div>
                <div className="groupNameCompDiv">
                  <Tooltip title="Small" placement="bottom">
                    <span onClick={this._setLegendSize.bind(this, 'small')} className={this.state.smallSize ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="legendSmallIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Default" placement="bottom">
                    <span onClick={this._setLegendSize.bind(this, 'default')} className={this.state.defaultSize ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="legendDefaultIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Large" placement="bottom">
                    <span onClick={this._setLegendSize.bind(this, 'large')} className={this.state.largeSize ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="legendLargeIcon" />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="legendPositionOptsDiv">
                <div className="padding-6b paddingT8">{translateObj.legendPosition[this.props.translate]}</div>
                <div className="groupNameCompDiv">
                  <Tooltip title="Top left" placement="bottom">
                    <span onClick={this._setLegendPosition.bind(this, 'top', 'left')} className={this.state.top_leftStatus ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="topLeftIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Bottom left" placement="bottom">
                    <span onClick={this._setLegendPosition.bind(this, 'bottom', 'left')} className={this.state.bottom_leftStatus ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="bottomLeftIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Bottom right" placement="bottom">
                    <span onClick={this._setLegendPosition.bind(this, 'bottom', 'right')} className={this.state.bottom_rightStatus ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="bottomRightIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Top right" placement="bottom">
                    <span onClick={this._setLegendPosition.bind(this, 'top', 'right')} className={this.state.top_rightStatus ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="topRightIcon" />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="legendOptsCheckBoxLeft">
                {translateObj.showNorthArrowOnMap[this.props.translate]}
                <Checkbox
                  color="default"
                  value="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={(event) => this.toggleNorthArrowVisible(event)}
                  checked={this.props.legend[0].northArrowVisible}
                  disableRipple
                />
              </div>
              <div className="legendPositionOptsDiv">
                <div className="padding-6b paddingT8">{translateObj.northArrowPosition[this.props.translate]}</div>
                <div className="groupNameCompDiv">
                  <Tooltip title="Top left" placement="bottom">
                    <span onClick={this._setNorthArrowPosition.bind(this, 'top', 'left')} className={this.state.top_leftStatusNorth ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="topLeftIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Bottom left" placement="bottom">
                    <span onClick={this._setNorthArrowPosition.bind(this, 'bottom', 'left')} className={this.state.bottom_leftStatusNorth ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="bottomLeftIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Bottom right" placement="bottom">
                    <span onClick={this._setNorthArrowPosition.bind(this, 'bottom', 'right')} className={this.state.bottom_rightStatusNorth ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="bottomRightIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Top right" placement="bottom">
                    <span onClick={this._setNorthArrowPosition.bind(this, 'top', 'right')} className={this.state.top_rightStatusNorth ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="topRightIcon" />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="legendOptsCheckBoxLeft">
                Show Scale on Map
                <Checkbox
                  color="default"
                  value="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={(event) => this.toggleScaleVisible(event)}
                  checked={this.scaleCheck()}
                  disableRipple
                />
              </div>
              <div className="legendPositionOptsDiv">
                <div className="padding-6b paddingT8">Scale Position</div>
                <div className="groupNameCompDiv">
                  <Tooltip title="Top left" placement="bottom">
                    <span onClick={this._setScalePosition.bind(this, 'top', 'left')} className={this.state.scaleLocation === 'top_left' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="topLeftIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Bottom left" placement="bottom">
                    <span onClick={this._setScalePosition.bind(this, 'bottom', 'left')} className={this.state.scaleLocation === 'bottom_left' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="bottomLeftIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Bottom right" placement="bottom">
                    <span onClick={this._setScalePosition.bind(this, 'bottom', 'right')} className={this.state.scaleLocation === 'bottom_right' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="bottomRightIcon" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Top right" placement="bottom">
                    <span onClick={this._setScalePosition.bind(this, 'top', 'right')} className={this.state.scaleLocation === 'top_right' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                      <SVGIconComponent name="topRightIcon" />
                    </span>
                  </Tooltip>
                </div>
              </div>

            </div>
          </Menu>
          : null
        }
      </div>
    );
  }
}

export default LegendOptions;