import React, { Component } from 'react';
import SVGIconComponent from '../../MainPage/SVGIconComponent/SVGIconComponent';

export default class ReleaseNotes2Pt0 extends Component {
  render() {
    return (
      <div>
        <div className="updateMenuBody">
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Retail logos</div>
          </div>
          <div className="updateMenuFeatureDesc">Ability to add retail logos to the map.
                  Select <span className="updateHighlightSpan">Add Data</span> from the sidebar and click <span className="updateHighlightSpan">Add Retail Logos</span>.
                  Choose to search by area, category, or retailer.  Right click a map logo to hide it on the map.  Customize the logo sizes and request missing logos.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Enhanced drawing tools</div>
          </div>
          <div className="updateMenuFeatureDesc">You can now snap lines to rectangles, polygons, or circles.  Shapes can be dragged, edited, deleted, or re-styled.
          Hold shift to create 90 or 45 degree angles.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Additional routing options</div>
          </div>
          <div className="updateMenuFeatureDesc">In the <span className="updateHighlightSpan">Add Rings</span> panel, by selecting
                  <span className="updateHighlightSpan"> Add Routes</span>, there is now an option to add drive time/distance, truck time/distance, or walk time/distance.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">New basemap options</div>
          </div>
          <div className="updateMenuFeatureDesc">There are several new basemap options, including options with fewer labels or no labels, as well as a brand new style - Colliers Bayview.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Sort history table</div>
          </div>
          <div className="updateMenuFeatureDesc"> You can now sort the history table by project name, last saved date, or map id.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Overlapping points warning</div>
          </div>
          <div className="updateMenuFeatureDesc"> After you successfully geocode points, a small warning at the bottom of the screen will alert you if you may have overlapping points.
                </div>
        </div>
      </div>
    );
  }
}