import get from 'lodash/get';
import L from 'leaflet';

let shiftUtils = {
    _enableKeyListener(){
        L.DomEvent.on(this.map.getContainer(),'keydown', this._keyDownFunction,this);
        L.DomEvent.on(this.map.getContainer(),'keyup', this._keyDownFunction,this);
        L.DomEvent.on(this.map.getContainer(), 'mouseover', this._keyDownFunction, this);
        this.map.boxZoom.disable();
    },
    _disableKeyListener(){
        L.DomEvent.off(this.map.getContainer(),'keydown', this._keyDownFunction,this);
        L.DomEvent.off(this.map.getContainer(),'keyup', this._keyDownFunction,this);
        L.DomEvent.off(this.map.getContainer(), 'mouseover', this._keyDownFunction, this);

        //Reset to default boxZoom
        if(this.map.pm.pmOrtho._defaultBox) {
            this.map.boxZoom.enable();
        }
    },
    _keyDownFunction(e) {
        if(e.type === "keyup"){
            this.map.pm.pmOrtho._shiftpressed = false;
            return;
        }
        if(this.map.pm.pmOrtho.options.customKey && this.map.pm.pmOrtho.options.customKey !== "shift"){
            let customKey = this.map.pm.pmOrtho.options.customKey;
            if(e.key === customKey){
                this.map.pm.pmOrtho._shiftpressed = true;
            }else if(e.code === customKey){
                this.map.pm.pmOrtho._shiftpressed = true;
            }else if (e.which === customKey){
                this.map.pm.pmOrtho._shiftpressed = true;
            }else if(e.keyCode === customKey){
                this.map.pm.pmOrtho._shiftpressed = true;
            }else if(customKey === "alt" && e.altKey){
                this.map.pm.pmOrtho._shiftpressed = true;
            }else if((customKey === "strg" || customKey === "ctrl") && e.ctrlKey){
                this.map.pm.pmOrtho._shiftpressed = true;
            }else{
                this.map.pm.pmOrtho._shiftpressed = false;
            }
        }else {
            this.map.pm.pmOrtho._shiftpressed = e.shiftKey;
        }
    },
    _getPointofAngle(latlng_p1,latlng_p2,startAngle=0) {
        let p1 = this.map.latLngToContainerPoint(latlng_p1);
        let p2 = this.map.latLngToContainerPoint(latlng_p2);

        let distance = this._getDistance(p1, p2);
        //Get the angle between the two points
        let pointAngle = this._getAngle(p1, p2);

        let snapAngle = this.options.snapAngle || 45;
        let angle = 0;
        let angles = [];
        let current = 0;
        let i = 0;
        while(i < (360/snapAngle)){
            current = ((i*snapAngle)+startAngle)%360;
            angles.push(current);
            i++;
        }
        angles.sort((a, b) => a - b);
        angle = angles.reduce(function(prev, curr) {
            return (Math.abs(curr - pointAngle) < Math.abs(prev - pointAngle) ? curr : prev);
        });

        let point_result2 = this._findDestinationPoint(p1, distance, angle);
        return this.map.containerPointToLatLng(point_result2);
    },

    _findDestinationPoint(point, distance, angle) {
        angle = angle - 90;
        let x = Math.round(Math.cos(angle * Math.PI / 180) * distance + point.x);
        let y = Math.round(Math.sin(angle * Math.PI / 180) * distance + point.y);
        return {x: x, y:y};
    },
    _getDistance(p1,p2){
        let x = p1.x - p2.x;
        let y = p1.y - p2.y;
        return Math.sqrt( x*x + y*y );
    },
    _getAngle(p1,p2){
        let x = p2.x - p1.x;
        let y = p2.y - p1.y;
        let _angle = ((Math.atan2(y, x) * 180 / Math.PI) * (-1) - 90)* (-1);
        return (_angle < 0 ? _angle + 360 : _angle) % 360;
    },
    _getRectanglePoint(A,B){
        let rect = L.rectangle([A,B]);

        let rectangleWidth = this.map.latLngToContainerPoint(A).x - this.map.latLngToContainerPoint(B).x;
        let rectangleHeight = this.map.latLngToContainerPoint(A).y - this.map.latLngToContainerPoint(B).y;
        let w = this.map.pm.pmOrtho._getDistance(this.map.latLngToContainerPoint(rect.getBounds().getNorthEast()), this.map.latLngToContainerPoint(rect.getBounds().getNorthWest()));
        let h = this.map.pm.pmOrtho._getDistance(this.map.latLngToContainerPoint(rect.getBounds().getNorthEast()), this.map.latLngToContainerPoint(rect.getBounds().getSouthEast()));

        let pt_A = this.map.latLngToContainerPoint(A);
        let pt_B = this.map.latLngToContainerPoint(B);

        let d;
        if (w > h) {
            const p = {x: pt_B.x, y: pt_A.y};
            const angle = rectangleHeight < 0 ? 180 : 0;
            d = this.map.pm.pmOrtho._findDestinationPoint(p, w , angle);
        } else {
            const p = {x: pt_A.x, y: pt_B.y};
            const angle = rectangleWidth < 0 ? 90 : -90;
            d = this.map.pm.pmOrtho._findDestinationPoint(p, h, angle);
        }
        return this.map.containerPointToLatLng(d);
    }
    //
};

L.PMOrtho = L.Class.extend({
    includes: [shiftUtils],
    options: {
        allowOrtho: true,
        customKey: null,
        snapAngle: 45,
        baseAngleOfLastSegment: false
    },
    cssadded: false,
    initialize(map, options) {
        this.map = map;
        L.setOptions(this, options);
        if(this.map && this.map.pm){
            this.map.pm.pmOrtho = this;
        }

        this._overwriteFunctions();
    },
    setOptions(options){
        L.setOptions(this, options);
    },
    _overwriteFunctions: function() {
        //let that = this;
        this._extendedEnable();
        this._extendedDisable();
        L.PM.Draw.Line.prototype._syncHintMarker = this._syncHintMarker(this);

        L.PM.Draw.Rectangle.prototype._finishShapeOrg = L.PM.Draw.Rectangle.prototype._finishShape;
        L.PM.Draw.Rectangle.prototype._finishShape = function (e) {
            e.latlng = this._cornerPoint || e.latlng;
            this._hintMarker._snapped = this._cornerPoint ? false : this._hintMarker._snapped;
            this._finishShapeOrg(e);
        }



        //Edit
        this.map.on('pm:globaleditmodetoggled',function (e) {
            if(e.enabled) {
                e.map.pm.pmOrtho._enableShiftListener();
                let layers = e.map.pm.pmOrtho._findLayers(e.map);

                //Rectangle
                let rectLayers = layers.filter(layer => layer instanceof L.Rectangle);
                rectLayers.forEach(function (layer) {
                    if (!(layer.pm.pmOrtho && layer.pm.pmOrtho.fncoverwritten)) {
                        layer.pm.pmOrtho = {fncoverwritten: true};
                        layer.pm._adjustRectangleForMarkerMoveOrg = layer.pm._adjustRectangleForMarkerMove;
                        layer.pm._adjustRectangleForMarkerMove = function (movedMarker) {
                            if (this._map.pm.pmOrtho._shiftpressed && this._map.pm.pmOrtho.options.allowOrtho) {
                                let newlatlng = this._map.pm.pmOrtho._getRectanglePoint(movedMarker._oppositeCornerLatLng, movedMarker.getLatLng());
                                movedMarker.setLatLng(newlatlng);
                            }
                            layer.pm._adjustRectangleForMarkerMoveOrg(movedMarker);
                        }
                    }
                });

                //Line
                let lineLayers = layers.filter(layer => !(layer instanceof L.Rectangle) && (layer instanceof L.Polyline));
                lineLayers.forEach(function (layer) {
                    if (!(layer.pm.pmOrtho && layer.pm.pmOrtho.fncoverwritten)) {
                        layer.pm.pmOrtho = {fncoverwritten: true};
                        layer.pm._onMarkerDragOrg = layer.pm._onMarkerDrag;
                        layer.pm._onMarkerDrag = function (e) {
                            if (this._map.pm.pmOrtho._shiftpressed && this._map.pm.pmOrtho.options.allowOrtho) {
                                const marker = e.target;

                                const { indexPath, index, parentPath } = this.findDeepMarkerIndex(
                                    this._markers,
                                    marker
                                );
                                // only continue if this is NOT a middle marker
                                if (!indexPath) {
                                    return;
                                }
                                // the dragged markers neighbors
                                const markerArr = indexPath.length > 1 ? get(this._markers, parentPath) : this._markers;
                                // find the indizes of next and previous markers
                                const prevMarkerIndex = (index + (markerArr.length - 1)) % markerArr.length;
                                // get latlng of prev and next marker
                                const prevMarkerLatLng = markerArr[prevMarkerIndex].getLatLng();

                                let startAngle = 0;

                                if(this._map.pm.pmOrtho.options.baseAngleOfLastSegment && markerArr.length > 1){
                                    const prevPrevMarkerIndex = (index + (markerArr.length - 2)) % markerArr.length;
                                    const prevPrevMarkerLatLng = markerArr[prevPrevMarkerIndex].getLatLng();
                                    const lastPolygonPoint = this._map.latLngToContainerPoint(prevMarkerLatLng);
                                    const secondLastPolygonPoint = this._map.latLngToContainerPoint(prevPrevMarkerLatLng);
                                    startAngle = this._map.pm.pmOrtho._getAngle(secondLastPolygonPoint,lastPolygonPoint)+90;
                                    startAngle = startAngle > 180 ? startAngle - 180 : startAngle;
                                }

                                let newlatlng = this._map.pm.pmOrtho._getPointofAngle(prevMarkerLatLng, marker.getLatLng(),startAngle);
                                e.target._latlng = newlatlng;
                                e.target.update();
                            }
                            layer.pm._onMarkerDragOrg(e);
                        }
                        layer.pm.disable();
                        layer.pm.enable();
                    }
                });
            }else{
                e.map.pm.pmOrtho._disableShiftListener();
            }
        });


    },
    _findLayers(map) {
        let layers = [];
        map.eachLayer(layer => {
            if (
                layer instanceof L.Polyline ||
                layer instanceof L.Marker ||
                layer instanceof L.Circle ||
                layer instanceof L.CircleMarker
            ) {
                layers.push(layer);
            }
        });

        // filter out layers that don't have the leaflet-geoman instance
        layers = layers.filter(layer => !!layer.pm);

        // filter out everything that's leaflet-geoman specific temporary stuff
        layers = layers.filter(layer => !layer._pmTempLayer);

        return layers;
    },
    _extendedEnable(){
        let that = this;
        L.PM.Draw.Line.prototype.enableOrg = L.PM.Draw.Line.prototype.enable;
        L.PM.Draw.Line.prototype.enable = function (options) {
            this.enableOrg(options);
            that._enableShiftListener();
            this._map.off('click', this._createVertex, this);
            this._map.on('click', that._createVertexNew, this);
        };

        L.PM.Draw.Rectangle.prototype.enableOrg = L.PM.Draw.Rectangle.prototype.enable;
        L.PM.Draw.Rectangle.prototype.enable = function (options) {
            this.enableOrg(options);
            that._enableShiftListener();

            if (this.options.cursorMarker) {
                L.DomUtil.addClass(this._hintMarker._icon, 'visible');
                // Add two more matching style markers, if cursor marker is rendered
                this._styleMarkers = [];
                for (let i = 0; i < 4; i += 1) {
                    const styleMarker = L.marker([0, 0], {
                        icon: L.divIcon({
                            className: 'marker-icon rect-style-marker',
                        }),
                        draggable: false,
                        zIndexOffset: 100,
                    });
                    styleMarker._pmTempLayer = true;
                    this._layerGroup.addLayer(styleMarker);
                    this._styleMarkers.push(styleMarker);
                }
            }
        };
    },
    _extendedDisable(){
        let that = this;
        L.PM.Draw.Line.prototype.disableOrg = L.PM.Draw.Line.prototype.disable;
        L.PM.Draw.Line.prototype.disable = function () {
            this.disableOrg();
            that._disableShiftListener();
            this._map.off('click', that._createVertexNew, this);

        };

        L.PM.Draw.Rectangle.prototype.disableOrg = L.PM.Draw.Rectangle.prototype.disable;
        L.PM.Draw.Rectangle.prototype.disable = function () {
            this.disableOrg();
            that._disableShiftListener();
        };
        L.PM.Draw.Rectangle.include({_syncRectangleSize: this._syncRectangleSize});
    },
    _enableShiftListener(){
        if(this.map.pm.pmOrtho.options.allowOrtho) {
            this.map.pm.pmOrtho._shiftpressed = false;
            this.map.pm.pmOrtho._defaultBox =this.map.boxZoom.enabled();
            this.map.pm.pmOrtho._enableKeyListener();
        }
    },
    _disableShiftListener(){
        if(this.map.pm.pmOrtho.options.allowOrtho) {
            this.map.pm.pmOrtho._disableKeyListener();
        }
    },
    _syncHintMarker(that) {
        return function (e){
            const polyPoints = this._layer.getLatLngs();
            if (polyPoints.length > 0 && this._map.pm.pmOrtho._shiftpressed && this._map.pm.pmOrtho.options.allowOrtho) {
                const lastPolygonLatLng = polyPoints[polyPoints.length - 1];
                let latlng_mouse = e.latlng;

                let startAngle = 0;

                if(this._map.pm.pmOrtho.options.baseAngleOfLastSegment && polyPoints.length > 1){
                    const secondLastPolygonLatLng = polyPoints[polyPoints.length - 2];
                    const lastPolygonPoint = this._map.latLngToContainerPoint(lastPolygonLatLng);
                    const secondLastPolygonPoint = this._map.latLngToContainerPoint(secondLastPolygonLatLng);
                    startAngle = that._getAngle(secondLastPolygonPoint,lastPolygonPoint);
                }

                let pt = that._getPointofAngle(lastPolygonLatLng, latlng_mouse,startAngle);
                this._hintMarker.setLatLng(pt);
                e.latlng = pt; //Because of intersection
            } else {
                // move the cursor marker
                this._hintMarker.setLatLng(e.latlng);
            }

            // if snapping is enabled, do it
            if (this.options.snappable) {
                const fakeDragEvent = e;
                fakeDragEvent.target = this._hintMarker;
                this._handleSnapping(fakeDragEvent);
            }

            // if self-intersection is forbidden, handle it
            if (!this.options.allowSelfIntersection) {
                this._handleSelfIntersection(true, this._hintMarker.getLatLng());
            }
        }
    },
    _createVertexNew(e){
        const polyPoints = this._layer.getLatLngs();
        if (polyPoints.length > 0 &&  this._map.pm.pmOrtho._shiftpressed &&  this._map.pm.pmOrtho.options.allowOrtho) {
            const lastPolygonLatLng = polyPoints[polyPoints.length - 1];
            let latlng_mouse = e.latlng;
            let startAngle = 0;

            if(this._map.pm.pmOrtho.options.baseAngleOfLastSegment && polyPoints.length > 1){
                const secondLastPolygonLatLng = polyPoints[polyPoints.length - 2];
                const lastPolygonPoint = this._map.latLngToContainerPoint(lastPolygonLatLng);
                const secondLastPolygonPoint = this._map.latLngToContainerPoint(secondLastPolygonLatLng);
                startAngle = this._map.pm.pmOrtho._getAngle(secondLastPolygonPoint,lastPolygonPoint)+90;
                startAngle = startAngle > 180 ? startAngle - 180 : startAngle;
            }

            let pt = this._map.pm.pmOrtho._getPointofAngle(lastPolygonLatLng,latlng_mouse,startAngle);
            e.latlng = pt; //Because of intersection
        }
        this._createVertex(e);
    },
    _syncRectangleSize() {
        // Create a box using corners A & B (A = Starting Position, B = Current Mouse Position)
        const A = this._startMarker.getLatLng();
        const B = this._hintMarker.getLatLng();

        this._layer.setBounds([A, B]);

        if(this._map.pm.pmOrtho.options.allowOrtho && this._map.pm.pmOrtho._shiftpressed) {
            this._cornerPoint = this._map.pm.pmOrtho._getRectanglePoint(A,B);
            this._layer.setBounds([A, this._cornerPoint]);
        }else{
            this._cornerPoint = null;
        }

        // Add matching style markers, if cursor marker is shown
        if (this.options.cursorMarker && this._styleMarkers) {
            const corners = this._findCorners();
            // Reposition style markers
            corners.forEach((unmarkedCorner, index) => {
                this._styleMarkers[index].setLatLng(unmarkedCorner);
            });
        }
    },
});




