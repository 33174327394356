import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import LabelStyleOnly from './LabelStyleOnly';
import translateObj from '../../utils/translate';

class CustomLabelStyle extends React.Component {
  constructor(props) {
    super(props);

    this.deleteLabel = this.deleteLabel.bind(this);
  }

  deleteLabel() {
    let labelsArrayClone = Array.from(this.props.labels);
    labelsArrayClone = labelsArrayClone.filter(lbl => lbl.content.id !== this.props.currentLabel.content.id);
    let updateObject = { labels: labelsArrayClone };

    if(this.props.currentLabel.content.customlayer){
      updateObject = {
        layerlabels: labelsArrayClone
      };
    }

    this.props.updateMapObj(updateObject);
    this.props.updateMapState({ selectedLabel: null,selectedLayerLabel: null });
  }

  componentWillUnmount() {
    this.props.updateMapState({ selectedLabel: null, selectedLayerLabel: null });
  }

  render() {
    return (
      <div>
        <div className="customLabelPanel">
          <h2 className="panel-title">
            <span className="panel-icon-span">
              <svg id="styleIconGroups" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 88.62 94.1">
                  <line id="Line_20" stroke="#0c9ed9" fill="none" strokeWidth="4" y1="92.85" x2="88.62" y2="92.85" />
                  <path id="Path_4362" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M24.45-22.9,7.23-35A4.68,4.68,0,0,0,.72-33.89a4.48,4.48,0,0,0-.58,1.13L-3.51-22.3-30.89,5.46a4.65,4.65,0,0,0-1.34,3.23V56.11" transform="translate(34.31 37.14)" />
                  <path id="Path_4363" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M-28.37,55.58,13.52,40.67a4.6,4.6,0,0,0,2.57-2.37L32.84,3l8.58-7a4.68,4.68,0,0,0,.66-6.59,4.62,4.62,0,0,0-.92-.85" transform="translate(34.31 37.14)" />
                  <circle id="Ellipse_2" fill="none" stroke="#0c9ed9" strokeWidth="4" cx="33.22" cy="52.86" r="5.67" />
                  <line id="Line_21" fill="none" stroke="#0c9ed9" strokeWidth="4" x1="3.37" y1="92.08" x2="30.5" y2="56.12" />
                </svg>
            </span>
            {translateObj.styleLabelsTitle[this.props.translate]}
            <span className="panel-close closeStyle"><CloseIcon onClick={this.props.toggleLabelStyle.bind(this, false)} fontSize="small" /></span>
          </h2>
          <div className="panel-body">
            <LabelStyleOnly
              updateMapState={this.props.updateMapState}
              updateMapObj={this.props.updateMapObj}
              text={this.props.currentLabel.content.text}
              color={this.props.currentLabel.content.color}
              stroke={this.props.currentLabel.content.stroke}
              size={this.props.currentLabel.content.fontSize}
              opacity={this.props.currentLabel.content.opacity}
              font={this.props.currentLabel.content.font}
              weight={this.props.currentLabel.content.weight}
              angle={this.props.currentLabel.content.angle}
              offsetPosition={this.props.currentLabel.content.offsetPosition}
              individualStyle={this.props.individualStyle}
              currentLabel={this.props.currentLabel}
              labels={this.props.labels}
              getLabelIcon={this.props.getLabelIcon}
              readOnlyText={this.props.layerLabel}
              translate={this.props.translate}
              getLabelBoxPostions={this.props.getLabelBoxPostions}
            />
            <div className="geocodeDiv flex">
              <button
                className="geocodeBtn"
                onClick={this.deleteLabel}
              >
                {translateObj.deleteBtn[this.props.translate]}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomLabelStyle;