import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SaveInput from './SaveInput';
import translateObj from '../../../utils/translate';

class SavePanel extends React.Component {
  constructor(props) {
    super(props);

    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    this.props.togglePanel('save');
  }

  render() {
    return (
      <div className="sidePanelStyle">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="saveIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.1 90">
              <path id="Path_4084" d="M34.6,21.28,48,13.06l8.37,13.63" transform="translate(36.68 32.45)" fill="none" stroke='#0c9ed9' strokeWidth="4" />
              <path id="Path_4085" d="M47.78,14.39a41.69,41.69,0,1,1-10-31.17" transform="translate(36.68 32.45)" fill="none" stroke='#0c9ed9' strokeWidth="4" />
              <path id="Path_4087" d="M-10.45,13.72,0,26.74l22.42-28" transform="translate(36.68 32.45)" fill="none" stroke='#0c9ed9' strokeWidth="4" />
            </svg>
          </span>
          {translateObj.saveMapTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <div className={this.props.mapObj.isSaving ? 'loaderShow' : 'loaderHide'}>
            <div className="panel-loader"></div>
          </div>
          <div className={this.props.mapObj.isSaving ? 'loaderHide' : 'loaderShow'}>
            <SaveInput
              mapObj={this.props.mapObj}
              updateMapObj={this.props.updateMapObj}
              headers={this.props.headers}
              updateSidebarObj={this.props.updateSidebarObj}
              updateIsSaving={this.props.updateIsSaving}
              closePanel={this.closePanel}
              translate={this.props.translate}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SavePanel;