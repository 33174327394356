import React, { Component } from 'react';

const subHeaderStyle = {
    padding: '20px 40px 10px 40px',
    color: '#6e6e71',
    fontSize: '14px',
    textAlign: 'center'
}

export default class SupportSub extends Component {
  render() {
    return (
      <div style={subHeaderStyle}>
          BOOM is Colliers proprietary self-service web app for all Colliers professionals, including client service specialists and brokers, to create their own maps. BOOM enables you to upload spreadsheets or manually add/move locations to layers on the map. You can customize the style of the points, layers, labels, how locations display in a legend, add radius rings and drive time polygons, customize titles and layouts and print to a branded PDF or export the map only to PNG. You can even save and retrieve previous maps for further modifications.
      </div>
    );
  }
}