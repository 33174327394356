import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import translateObj from '../../../../utils/translate';

const ulStyle = {
  listStyleType: 'none',
  margin: 0,
  padding: '6px 0px 0px 0px',
}

class TypeCoords extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberMessage: null,
      latitude: '',
      longitude: ''
    }

    this.addCoords = this.addCoords.bind(this);
    this.backToAddPts = this.backToAddPts.bind(this);
  }

  addCoords() {
    if(this.state.latitude===''){
        this.setState({numberMessage: 'Missing Latitude'});
    }
    else if(this.state.longitude===''){
        this.setState({numberMessage: 'Missing Longitude'});
    }
    else if(parseFloat(this.state.latitude) > 90 || parseFloat(this.state.latitude) < -90){
        this.setState({numberMessage: 'Point not added Valid Latitude is between -90 and 90'});
    }
    else if(parseFloat(this.state.longitude) > 180 || parseFloat(this.state.longitude) < -180){
        this.setState({numberMessage: 'Point not added Valid Longitude is between -180 and 180'});
    }
    else {
        const pointsArray = [{
            position: { lat: parseFloat(this.state.latitude), lng: parseFloat(this.state.longitude)},
            content: {
              address:  '',
              city: '',
              state: '',
              zip:  '',
              accuracy: {
                match_type: 'Coordinates'
              }
            }
          }];
      
        this.setState({
            numberMessage: null,
        });
      
        const legendClone = Array.from(this.props.legend);
        if (this.props.points.length === 0) {
            legendClone[0].visible = true;
        }
        const newPointsArray = this.props.createNewPoint(pointsArray);
        
        //this.props.updateMapObj({ mapPoints: newPointsArray, legend: legendClone });

        this.props.updateMapObj({ mapPoints: newPointsArray, geocodingSuccess: true, legend: legendClone });
        this.props.toggleTypeCoord(false, true);
    }
  }

  backToAddPts() {
    this.props.toggleTypeCoord(false, true);
  }

  updateLatitude(e) {
    if (e.target.value === '' || e.target.value === '-'){
        this.setState({latitude: e.target.value, numberMessage: null});
    }
    else if(isNaN(e.target.value)){
        this.setState({numberMessage: 'Numeric Values Only (33.1234)'});
    }
    else{
        const value = parseFloat(e.target.value);
        let numberMessage = null;
        if(value < -90 || value > 90){
            numberMessage = 'Valid Latitude is between -90 and 90';
        }
        this.setState({latitude: e.target.value, numberMessage: numberMessage});
    }
  }

  updateLongitude(e) {
    if (e.target.value === '' || e.target.value === '-'){
        this.setState({longitude: e.target.value, numberMessage: null});
    }
    else if(isNaN(e.target.value)){
        this.setState({numberMessage: 'Numeric Values Only (-111.9999)'});
    }
    else{
        const value = parseFloat(e.target.value);
        let numberMessage = null;
        if(value < -180 || value > 180){
            numberMessage = 'Valid Longitude is between -180 and 180';
        }
        this.setState({longitude: e.target.value, numberMessage: numberMessage});
    }
  }

  componentWillUnmount() {
    this.props.updateMapObj({ geocodingSuccess: false });
  }


  render() {
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="globeIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 92.15 92.15">
              <path id="Path_4355" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.65,54.74A44.84,44.84,0,1,1,39.76,43.12" transform="translate(36.42 36.16)" />
              <path id="Path_4356" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M10.22-34.91c12.11,0,21.92,20.07,21.92,44.82S22.33,54.74,10.22,54.74" transform="translate(36.42 36.16)" />
              <path id="Path_4357" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.19-34.91c-12.1,0-21.92,20.07-21.92,44.82S-2.91,54.74,9.19,54.74" transform="translate(36.42 36.16)" />
              <path id="Path_4358" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.65-36.06v92" transform="translate(36.42 36.16)" />
              <path id="Path_4359" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M54.44,11.72H-35.17" transform="translate(36.42 36.16)" />
              <path id="Path_4360" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M50.74-8.21H-31.44" transform="translate(36.42 36.16)" />
              <path id="Path_4361" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M49,31.64H-29.69" transform="translate(36.42 36.16)" />
            </svg>
          </span>
          {translateObj.typeCoordsBtn[this.props.translate]}</h2>
        <div className="panel-body">
          <div className="paddingB20">
            <TextField
                id="latitude"
                label={translateObj.latitudePlaceholder[this.props.translate]}
                variant="outlined"
                value={this.state.latitude}
                size="small"
                fullWidth
                onChange={(e) => this.updateLatitude(e)}
            />
            </div>
            <div className="paddingB20">
            <TextField
                id="longitude"
                label={translateObj.longitudePlaceholder[this.props.translate]}
                variant="outlined"
                value={this.state.longitude}
                size="small"
                fullWidth
                onChange={(e) => this.updateLongitude(e)}
            />
          </div>
          <div className="geocodeDiv flex">
            <button className="geocodeBtn" onClick={this.backToAddPts}>
              {translateObj.backBtn[this.props.translate]}
            </button>
            <button className="geocodeBtn" onClick={this.addCoords}>
              {translateObj.addtoMapBtn[this.props.translate]}
            </button>
          </div>
          {this.state.numberMessage ?
            <div className="errorMsgDiv padding-t10I">
              {this.state.numberMessage}
            </div>
            : null}

          {/* {this.state.showErrorMessage ?
            <div className="errorMsgDiv padding-t10I">
              Oops!  Something went wrong.  Please try again.
            </div>
            : null} */}
        </div>
      </div>
    )
  }
}

export default TypeCoords;