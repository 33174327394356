import React from 'react';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import translateObj from '../../../utils/translate';

class SaveInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevMapCenter: this.props.mapObj.mapCenter,
      prevMapZoom: this.props.mapObj.mapZoom,
      isError: false,
      isCopy: false
    }

    this.saveMap = this.saveMap.bind(this);
    this.copyMap = this.copyMap.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
  }

  componentDidUpdate() {
    if (this.state.prevMapZoom !== this.props.mapObj.mapZoom || this.state.prevMapCenter !== this.props.mapObj.mapCenter) {
      this.sendSaveInfoToAPI();
    }
  }

  sendSaveInfoToAPI() {
    const mapId = this.props.mapObj.mapId;
    let mapObjClone = JSON.parse(JSON.stringify(this.props.mapObj));
    const rps = mapObjClone.retailPoints;
    let saverps = [];
    rps.forEach(rp => {
      if (rp.customlocation === true){
        saverps.push({
          hashid: rp.hashid,
          latitude: rp.latitude,
          longitude: rp.longitude,
          customlocation: true,
          leaderline: rp.leaderline
        })
      }
    });

    mapObjClone.retailPoints = saverps;

    if(this.props.mapObj.demographics.length>0){
      mapObjClone.showDemographics = true;
    }

    mapObjClone.demographics = null;

    let gapiPointCustomLabels = mapObjClone.gapiPointCustomLabels;
    const customLabelsFromAutoLabelOn = mapObjClone.gapiPointLabels.filter(l=>l.content.customLabel===true);
    gapiPointCustomLabels = gapiPointCustomLabels.concat(customLabelsFromAutoLabelOn);

    mapObjClone.gapiPointCustomLabels = gapiPointCustomLabels;
    mapObjClone.gapiPoints = null;
    mapObjClone.gapiPointLabels = null;


    this.props.updateSidebarObj({ mapHistoryData: [] });
    this.setState({
      isError: false
    });
    this.props.updateIsSaving('isSaving', true);
    //console.log(mapObjClone);

    if (mapId === null || this.state.isCopy === true) {
      axios.post(process.env.REACT_APP_APIURL + "/map", mapObjClone, { headers: this.props.headers })
        .then((result) => {
          this.props.updateMapObj({
            mapId: result.data,
            isSaving: false
          });
          this.props.updateIsSaving('isSaving', false);
          this.props.closePanel();
        }).catch((error) => {
          console.log(error);
          this.props.updateMapObj({
            isSaving: false
          });
          this.setState({
            isError: true
          });
          this.props.updateIsSaving('isSaving', false);
        });
    } else {
      axios.put(process.env.REACT_APP_APIURL + "/map/" + mapId + "/null", mapObjClone, { headers: this.props.headers })
        .then((result) => {
          this.props.updateMapObj({
            isSaving: false
          });
          this.props.updateIsSaving('isSaving', false);
          this.props.closePanel();
        }).catch((error) => {
          console.log(error);
          this.props.updateMapObj({
            isSaving: false
          });
          this.setState({
            isError: true
          });
          this.props.updateIsSaving('isSaving', false);
        });
    }

    this.setState({
      prevMapCenter: this.props.mapObj.mapCenter,
      prevMapZoom: this.props.mapObj.mapZoom,
      isCopy: false
    });
  }

  saveMap() {
    this.props.updateMapObj({ isSaving: true });
  }

  copyMap() {
    this.setState({
      isCopy: true
    });
    this.props.updateMapObj({ isSaving: true });
  }

  async handleChange(event) {
    this.props.updateMapObj({ mapOptionsTitle: event.target.value });
  };

  async handleProjectChange(event) {
    this.props.updateMapObj({ mapOptionsProject: event.target.value });
  };

  render() {
    return (
      <div>
        <div className="panel-body-small-text mapStatus-padding">
          {translateObj.mapStatus[this.props.translate]}: <span className="blueText">
            {this.props.mapObj.mapId === null ? translateObj.unsaved[this.props.translate] : translateObj.saved[this.props.translate]}</span>
        </div>
        {this.props.mapObj.mapId !== null ?
          <div className="panel-body-small-text mapId-padding">
            {translateObj.mapId[this.props.translate]}: <span className="blueText">{this.props.mapObj.mapId}</span>
          </div>
          : null
        }
        {this.state.isError ?
          <div className="panel-body-small-text mapId-padding"><span className="errorText">{translateObj.errorSavingMap[this.props.translate]}</span></div>
          : null
        }
        <div className="mapTitle-padding">
          <TextField
            id="mapTitleInput"
            label={translateObj.enterMapTitle[this.props.translate]}
            defaultValue={this.props.mapObj.mapOptionsTitle}
            variant="outlined"
            onChange={this.handleChange}
            size="small"
            fullWidth
          />
        </div>
        <div className="mapTitle-padding">
          <TextField
            id="mapProjectInput"
            label={translateObj.enterProjectName[this.props.translate]}
            defaultValue={this.props.mapObj.mapOptionsProject}
            variant="outlined"
            onChange={this.handleProjectChange}
            size="small"
            fullWidth
            // onKeyPress={(e) => {
            //   if (e.key === 'Enter') {
            //     this.saveMap()
            //   }
            // }}
          />
        </div>
        <div className="geocodeDiv flex">
          <button className="geocodeBtn" onClick={this.props.closePanel}>{translateObj.cancelBtn[this.props.translate]}</button>
          <button className="geocodeBtn pushLeft" onClick={this.saveMap}>
            {this.props.mapObj.mapId !== null ? translateObj.updateBtn[this.props.translate] : translateObj.saveBtnLowerCase[this.props.translate]}
          </button>
          {this.props.mapObj.mapId ?
            <button className="geocodeBtn" onClick={this.copyMap}>{translateObj.copyBtn[this.props.translate]}</button>
            : null
          }
        </div>
      </div>
    );
  }
}

export default SaveInput;