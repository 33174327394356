import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import PaletteIcon from '@material-ui/icons/Palette';
import RetailLineStyle from '../../SideBar/AddDataPanel/RetailLineStyle';

// import Divider from '@material-ui/core/Divider';
// import TextField from '@material-ui/core/TextField';
// import EditIcon from '@material-ui/icons/Edit';
// import TextFieldsIcon from '@material-ui/icons/TextFields';
// import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';


const popupStyle = {
    fontFamily: 'Open Sans, Arial, sans-serif',
    minWidth: '150px'
  }
  
  const popupFooter = {
    display: 'flex',
    paddingTop: '15px',
  }
  
  const popupFooterActions = {
    marginLeft: 'auto',
    color: '#0c9ed9',
    display: 'flex',
    flexDirection: 'row',
  }
  
  const footerIcons = {
    cursor: 'pointer',
    marginLeft: '5px'
  }
  
  const nameDiv = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '3px 0px'
  }
  
  const labelSpan = {
    paddingRight: '8px'
  }

class RetailPopupContent extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
          editing: false
        }
        this.removePoint = this.removePoint.bind(this);
        this.setLeaderLine = this.setLeaderLine.bind(this);
        this._toggleRetailerLeaderLine = this._toggleRetailerLeaderLine.bind(this);
        this._toggleRetailerLeaderLineAnchor = this._toggleRetailerLeaderLineAnchor.bind(this);
        this.changeLeaderLineColor = this.changeLeaderLineColor.bind(this);
        this.changeLeaderLineWidth = this.changeLeaderLineWidth.bind(this);
        this.changeLeaderLineOpacity = this.changeLeaderLineOpacity.bind(this);
    }

    setLeaderLine() {
        //this.props.editRetailPointLeaderLine(this.props.retailPoint.hashid);
        this.setState({editing: true});
    }

    removePoint() {
        this.props.hideRetailPoint(this.props.retailPoint.hashid)
    }

    _toggleRetailerLeaderLineAnchor(chk) {

        let val = chk;
        let rp = this.props.retailPoint;
        if(chk===undefined){
            val = false;
            rp.leaderline = {};
        }

        if(rp.leaderline===null){
            rp.leaderline = {};
        }
        rp.leaderline.anchor = val;
        this.props.updateRetailPoint(this.props.retailPoint.hashid,rp);
    }


    _toggleRetailerLeaderLine(chk) {
        let val = chk;
        let rp = this.props.retailPoint;
        if(chk===undefined){
            val = false;
            rp.leaderline = {};
        }

        if(rp.leaderline===null){
            rp.leaderline = {};
        }
        rp.leaderline.show = val;
        this.props.updateRetailPoint(this.props.retailPoint.hashid,rp);
    }

    changeLeaderLineWidth(value) {

        let rp = this.props.retailPoint;
        if(rp.leaderline===null){
            rp.leaderline={}
        }
        rp.leaderline.width = value;

        this.props.updateRetailPoint(this.props.retailPoint.hashid,rp);

    }

    changeLeaderLineColor(e,style) {
        let rp = this.props.retailPoint;
        if(rp.leaderline===null){
            rp.leaderline={}
        }
        rp.leaderline.color = style;
        this.props.updateRetailPoint(this.props.retailPoint.hashid,rp);
    }

    changeLeaderLineOpacity(value) {
        let rp = this.props.retailPoint;
        if(rp.leaderline===null){
            rp.leaderline={}
        }
        rp.leaderline.opacity = value;

        this.props.updateRetailPoint(this.props.retailPoint.hashid,rp);
    }

    render() {
        const csz = this.props.retailPoint.city + ", " + this.props.retailPoint.state + " " + this.props.retailPoint.postalcode;

        let leaderline = this.props.retailPoint.leaderline?.show;
        if (leaderline === undefined)
            leaderline = this.props.mapRetailerLeaderLine;

        let leaderlineAnchor = this.props.retailPoint.leaderline?.anchor;

        if (leaderlineAnchor === undefined)
            leaderlineAnchor = this.props.mapRetailerLeaderLineAnchor;

        return (
            <div style={popupStyle}>
            {this.state.editing ?
                <RetailLineStyle
                retailerLeaderLine={leaderline}
                toggleRetailerLeaderLine={this._toggleRetailerLeaderLine}
                retailerLeaderLineAnchor={leaderlineAnchor}
                toggleRetailerLeaderLineAnchor={this._toggleRetailerLeaderLineAnchor}
                retailerLeaderColor={this.props.retailPoint.leaderline?.color || this.props.mapRetailerLeaderColor}
                changeLeaderLineColor={this.changeLeaderLineColor}
                retailerLeaderWidth={this.props.retailPoint.leaderline?.width || this.props.mapRetailerLeaderWidth}
                changeLeaderLineWidth={this.changeLeaderLineWidth}
                retailerLeaderOpacity={this.props.retailPoint.leaderline?.opacity || this.props.mapRetailerLeaderOpacity}
                changeLeaderLineOpacity={this.changeLeaderLineOpacity}
                />
                : null
            }
            <div>
                <div>
                    {this.props.retailPoint.chainname}
                </div>
                <div>
                    {this.props.retailPoint.address}
                </div>
                <div>
                    {csz}
                </div>

            <div style={popupFooter}>
            <div style={popupFooterActions}>
            {/* <div style={footerIcons}><RadioButtonCheckedIcon onClick={this.setEditRing} fontSize="small" /></div>
            <div style={footerIcons}><EditIcon onClick={this.toggleEditing} fontSize="small" /></div> */}
            <div style={footerIcons}><PaletteIcon onClick={this.setLeaderLine} fontSize="small" /></div>
            {/* <div style={footerIcons}><TextFieldsIcon onClick={this.setLabelPoint} fontSize="small"/></div> */}
            <div style={footerIcons}><DeleteIcon onClick={this.removePoint} fontSize="small" /></div>
          </div>
        </div>
        </div>
        </div>
        )
    }
}

export default RetailPopupContent;
    