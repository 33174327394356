import React from 'react';
import { TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ColorPickerMenu from '../ColorPicker/ColorPickerMenu';
import translateObj from '../../../utils/translate';

class AddRingForm extends React.Component {
  constructor(props) {
    super(props);

    this.deleteRing = this.deleteRing.bind(this);
    this.setRingName = this.setRingName.bind(this);
    this.setRingRadius = this.setRingRadius.bind(this);
    this.toggleAutoLabel = this.toggleAutoLabel.bind(this);
    this.setFillColor = this.setFillColor.bind(this);
    this.setStrokeColor = this.setStrokeColor.bind(this);
    this.setLabelFillColor = this.setLabelFillColor.bind(this);
    this.setLabelStrokeColor = this.setLabelStrokeColor.bind(this);
    this.handleLabelDirChange = this.handleLabelDirChange.bind(this);
    this.handleRouteTypeChange = this.handleRouteTypeChange.bind(this);
  }

  deleteRing() {
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone.splice(this.props.idx, 1);
    this.props.updateRingState({
      rings: ringArrayClone,
      tabValue: this.props.tabValue === 0 ? 0 : this.props.tabValue - 1
    });
  }

  setRingName(value) {
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[this.props.idx].label = value;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  setRingRadius(value) {
    const index = this.props.idx;
    let userVal = parseFloat(value) || 0;
    if (this.props.isDrive && userVal > parseFloat(process.env.REACT_APP_DTLIMIT)) {
      userVal = parseFloat(process.env.REACT_APP_DTLIMIT);
    } else if (userVal < 0) {
      userVal = 0;
    }

    const num = Math.round((userVal) * 100) / 100;
    // const beforeValue = index === 0 ? -0.001 : this.props.rings[index - 1].radius;
    // const afterValue = this.props.rings[index + 1] ? this.props.rings[index + 1].radius : this.props.isDrive ? process.env.REACT_APP_DTLIMIT : 10000;
    // if (num >= afterValue || num <= beforeValue) {
    //   return;
    // }

    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[index].radius = num;
    ringArrayClone[index].label = this.props._getRingLabel(num, this.props.isDrive, this.props.rings[index].autoLabel, index, this.props.rings[index].type);
    let updateObj = {
      rings: ringArrayClone,
      error: false,
      isDriveError: false,
    }
    this.props.updateRingState(updateObj);
  }

  toggleAutoLabel(e) {
    const bool = e.target.checked;
    const index = this.props.idx;
    const newLabel = this.props._getRingLabel(null, this.props.isDrive, bool, index, this.props.rings[index].type);
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[index].label = newLabel;
    ringArrayClone[index].autoLabel = bool;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  setFillColor(fill) {
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[this.props.idx].fill = fill;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  setStrokeColor(stroke) {
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[this.props.idx].stroke = stroke;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  setRingOpacity(value) {
    let userVal = value || 0;
    if (value > 1) {
      userVal = 1;
    } else if (value < 0) {
      userVal = 0;
    }

    const num = Math.round((userVal) * 100) / 100;
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[this.props.idx].opacity = num;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  setStrokeWidth(value) {
    const num = parseInt(value);
    // let userVal = value || 0;
    // if (value > 1) {
    //   userVal = 1;
    // } else if (value < 0) {
    //   userVal = 0;
    // }

   // const num = Math.round((userVal) * 100) / 100;
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[this.props.idx].strokeWidth = num;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  setStrokeOpacity(value) {
    let userVal = value || 0;
    if (value > 1) {
      userVal = 1;
    } else if (value < 0) {
      userVal = 0;
    }

    const num = Math.round((userVal) * 100) / 100;
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[this.props.idx].strokeOpacity = num;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  setLabelFillColor(fill) {
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[this.props.idx].labelFill = fill;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  setLabelStrokeColor(stroke) {
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[this.props.idx].labelStroke = stroke;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  handleLabelDirChange(e) {
    const val = e.target.value;
    const ringArrayClone = Array.from(this.props.rings);
    ringArrayClone[this.props.idx].labelDirection = val;
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  handleRouteTypeChange(e) {
    const val = e.target.value;
    const ringArrayClone = Array.from(this.props.rings);
    //change all ring types in this current group
    for (const myRing of ringArrayClone) {
      myRing.type = val;
    }

    //change all ring labels in this current group
    for (const [index, myRing] of ringArrayClone.entries()) {
      myRing.label = this.props._getRingLabel(myRing.radius, this.props.isDrive, myRing.autoLabel, index, e.target.value);
    }
    
    this.props.updateRingState({
      rings: ringArrayClone
    });
  }

  render() {
    const ringLabel = this.props.setRingLabel(this.props.isDrive, this.props.ring.type);
    return (
      <div role="tabpanel" className={this.props.tabValue !== this.props.idx ? 'ringTabHide' : 'ringTabShow'}>
        <div>
          <div className="add-ring-header">
            <div className="legendView-div">
              {this.props.isDrive ?
                <svg id="driveTimeIcon" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.73 19.65">
                  <polygon points="13.86 7.76 17.16 1.19 9.8 5.17 6.59 2.11 6.42 6.24 1.1 6.92 4.35 10.05 3.12 16.27 8.15 14.61 8.15 18.28 12.55 13.02 18.47 16.48 13.86 7.76" fill={this.props.ring.fill} fillOpacity={this.props.ring.opacity} stroke={this.props.ring.stroke} strokeOpacity={this.props.ring.strokeOpacity} strokeMiterlimit="10" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 20 20">
                  <circle fill={this.props.ring.fill} fillOpacity={this.props.ring.opacity} stroke={this.props.ring.stroke} strokeOpacity={this.props.ring.strokeOpacity} cx="10" cy="10" r="8" />
                </svg>
              }
              <span style={{ color: this.props.ring.labelFill, textShadow: '-1px -1px 1px ' + this.props.ring.labelStroke + ',1px -1px 1px ' + this.props.ring.labelStroke + ',-1px 1px 1px ' + this.props.ring.labelStroke + ',1px 1px 1px ' + this.props.ring.labelStroke, fontSize: '12px' }}>
                Label on {this.props.ring.labelDirection}</span>
            </div>
            <div style={{ paddingLeft: '20px' }}>
              <button className="geocodeBtnSmall" onClick={this.deleteRing}>{translateObj.deleteRingBtn[this.props.translate]}</button>
            </div>
          </div>
          {this.props.isDrive ?
            <div className="add-ring-row-div2">
              <div className="add-ring-row">
                {translateObj.ringType[this.props.translate]}
                <div style={{ width: '200px', paddingLeft: '5px' }}>
                  <Select
                    labelId="ringRouteType-select"
                    id={"ringRouteType-select" + this.props.idx}
                    value={this.props.ring.type}
                    onChange={this.handleRouteTypeChange}
                    variant="outlined"
                    autoWidth={true}
                  >
                    <MenuItem value={'driving_time'}>{translateObj.drivingTimeOpt[this.props.translate]}</MenuItem>
                    <MenuItem value={'driving_distance'}>{translateObj.drivingDistanceOpt[this.props.translate]}</MenuItem>
                    <MenuItem value={'trucking_time'}>{translateObj.truckingTimeOpt[this.props.translate]}</MenuItem>
                    <MenuItem value={'trucking_distance'}>{translateObj.truckingDistanceOpt[this.props.translate]}</MenuItem>
                    <MenuItem value={'walking_time'}>{translateObj.walkingTimeOpt[this.props.translate]}</MenuItem>
                    <MenuItem value={'walking_distance'}>{translateObj.walkingDistanceOpt[this.props.translate]}</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            : null
          }
          <div className="add-ring-form">
            <div>
              <TextField
                id={"ringName" + this.props.idx}
                value={this.props.ring.label}
                label="Ring label"
                variant="outlined"
                size="small"
                onChange={(e) => { this.setRingName(e.target.value) }}
              />
            </div>
            <div className="legendView-div">
              <div style={{ width: '80px' }}>
                <TextField
                  id={"ringNumber" + this.props.idx}
                  type="number"
                  value={this.props.ring.radius}
                  variant="outlined"
                  size="small"
                  InputProps={{ inputProps: { min: 0, max: 500 } }}
                  onChange={(e) => { this.setRingRadius(e.target.value) }}
                />
              </div>
              <span style={{ paddingLeft: '5px' }}>{this.props.isDrive ? ringLabel : (this.props.country === 'US' ? 'Miles' : 'KMs')}</span>
            </div>
          </div>
          <div className="ringAutoLabelDiv">
            {translateObj.autoLabel[this.props.translate]}
              <Checkbox
              color="default"
              value="default"
              inputProps={{ 'aria-label': 'checkbox with default color' }}
              onChange={(event) => this.toggleAutoLabel(event)}
              checked={this.props.ring.autoLabel}
              disableRipple
            />
          </div>
          <div className="add-ring-row-div">
            <div className="add-ring-row">
              <div>
                <span className="textUnderline">{this.props.isDrive ? 'Route' : 'Ring'}</span>:<span className="paddingL15">{translateObj.fill[this.props.translate]}</span>
              </div>
              <div style={{ paddingLeft: '5px' }}>
                <ColorPickerMenu
                  squareColor={this.props.ring.fill}
                  setFillColor={this.setFillColor}
                  addTransparent={true}
                />
              </div>
            </div>
            <div className="add-ring-row paddingL15">
              {translateObj.fillOpacity[this.props.translate]}
              <div style={{ width: '80px', paddingLeft: '5px' }}>
                <TextField
                  label="Opacity"
                  id={"ringOpacity" + this.props.idx}
                  value={this.props.ring.opacity}
                  variant="outlined"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                  onChange={(e) => { this.setRingOpacity(e.target.value) }}
                />
              </div>
            </div>
          </div>
          <div className="add-ring-row-div paddingTB10">
            <div className="add-ring-row paddingL50">
              <div>{translateObj.outline[this.props.translate]}:</div>
              <div style={{ paddingLeft: '5px' }}>
                <ColorPickerMenu
                  squareColor={this.props.ring.stroke}
                  setFillColor={this.setStrokeColor}
                  addTransparent={true}
                />
              </div>
            </div>
            <div className="add-ring-row paddingL15">
              {translateObj.outlineOpacity[this.props.translate]}
              <div style={{ width: '80px', paddingLeft: '5px' }}>
                <TextField
                  label="Opacity"
                  id={"strokeOpacity" + this.props.idx}
                  value={this.props.ring.strokeOpacity}
                  variant="outlined"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                  onChange={(e) => { this.setStrokeOpacity(e.target.value) }}
                />
              </div>
            </div>

            <div className="add-ring-row paddingL15">
              <div style={{ width: '80px', paddingLeft: '5px' }}>
                <TextField
                  label="Width"
                  id={"strokeWidth" + this.props.idx}
                  value={this.props.ring.strokeWidth}
                  variant="outlined"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 5, step: 1} }}
                  onChange={(e) => { this.setStrokeWidth(e.target.value) }}
                />
              </div>
            </div>

          </div>
          <div className="add-ring-row-div">
            <div className="add-ring-row">
              <div><span className="textUnderline">{translateObj.label[this.props.translate]}</span>:<span className="paddingL15">{translateObj.fill[this.props.translate]}</span></div>
              <div style={{ paddingLeft: '5px' }}>
                <ColorPickerMenu
                  squareColor={this.props.ring.labelFill}
                  setFillColor={this.setLabelFillColor}
                  addTransparent={true}
                />
              </div>
            </div>
            <div className="add-ring-row paddingL15">
              <div>{translateObj.outline[this.props.translate]}:</div>
              <div style={{ paddingLeft: '5px' }}>
                <ColorPickerMenu
                  squareColor={this.props.ring.labelStroke}
                  setFillColor={this.setLabelStrokeColor}
                  addTransparent={true}
                />
              </div>
            </div>
          </div>
          <div className="add-ring-row-div">
            <div className="add-ring-row paddingL15">
              {translateObj.placement[this.props.translate]}
              <div style={{ width: '120px', paddingLeft: '5px' }}>
                <Select
                  labelId="ringlabelDirection-select"
                  id={"ringlabelDirection-select" + this.props.idx}
                  value={this.props.ring.labelDirection}
                  onChange={this.handleLabelDirChange}
                  variant="outlined"
                  autoWidth={true}
                >
                  <MenuItem value={'bottom'}>{translateObj.bottom[this.props.translate]}</MenuItem>
                  <MenuItem value={'top'}>{translateObj.top[this.props.translate]}</MenuItem>
                  <MenuItem value={'left'}>{translateObj.left[this.props.translate]}</MenuItem>
                  <MenuItem value={'right'}>{translateObj.right[this.props.translate]}</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddRingForm;