import React from 'react';
import { GetTextColor, GetIconColor, GetIconName, GetIconSize, GetSVGLabel, getGeoAccColor } from '../../../utils/tools';
import DeleteIcon from '@material-ui/icons/Delete';
import PaletteIcon from '@material-ui/icons/Palette';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EditIcon from '@material-ui/icons/Edit';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import PublicIcon from '@material-ui/icons/Public';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import Tooltip from '@material-ui/core/Tooltip';
import translateObj from '../../../utils/translate';

const listLIStyle = (isDragging, draggableStyle) => ({
    border: '1px solid #d6d7dd',
    borderRadius: '2px',
    padding: '3px 5px',
    marginBottom: '8px',
    marginTop: '4px',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    ...draggableStyle
  });

class LegendPointRow extends React.Component {
  toggleStylePoint(point) {
    this.props.updateMapObj({
      currentPoint: point.content,
      currentGroup: point.content.group
    });
    this.props.updateLegendObj({
      isStylingPoints: true
    });
  }

  togggleRegeo(point) {  
    this.props.updateMapObj({
      currentPoint: point.content,
      isReGeocoding: true,
      isStyling: false,
      isEditingRing: false,
      isLabeling: false
    });
  }

  toggleAddRings(point) {
    this.props.updateMapObj({
      currentPoint: point.content
    });
    this.props.updateLegendObj({
      isEditingRings: true
    });
  }

  togglePointLabel(point) {
    this.props.updateMapObj({
      currentPoint: point.content
    });
    this.props.updateLegendObj({
      isLabeling: true
    });
  }

  deleteMarker(point) {
    this.props.deletePoint(point);
  }

  render() {
    return (
      <li
        ref={this.props.provided.innerRef}
        {...this.props.provided.draggableProps}
        {...this.props.provided.dragHandleProps}
        style={listLIStyle(this.props.snapshot.isDragging, this.props.provided.draggableProps.style)}
      >
        <span>
          <SVGIconComponent
            color={GetIconColor(this.props.groups, this.props.point)}
            name={GetIconName(this.props.groups, this.props.point)}
            label={GetSVGLabel(this.props.groups, this.props.point)}
            textFill={GetTextColor(this.props.groups, this.props.point)}
            size={GetIconSize(this.props.groups, this.props.point)}
            outline={GetIconColor(this.props.groups, this.props.point) === 'transparent' ? 'transparent' : null}
          ></SVGIconComponent>
        </span>
        <span style={{ paddingLeft: '5px' }}>
          {this.props.point.content.name}
        </span>

        <div style={{ color: getGeoAccColor(this.props.point.content), marginLeft: 'auto', cursor: 'pointer', paddingRight: '5px'  }} onClick={this.togggleRegeo.bind(this, this.props.point)}>
          <Tooltip title="Regeocode point" placement="top">
            <PublicIcon fontSize="small" />
          </Tooltip>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={this.toggleAddRings.bind(this, this.props.point)}>
          <Tooltip title={translateObj.addEditPointTooltip[this.props.translate]} placement="top">
            <RadioButtonCheckedIcon fontSize="small" />
          </Tooltip>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={this.props.togglePointAttribute.bind(this, true, this.props.point)}>
          <Tooltip title={translateObj.editPointDataTooltip[this.props.translate]} placement="top">
            <EditIcon fontSize="small" />
          </Tooltip>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={this.toggleStylePoint.bind(this, this.props.point)}>
          <Tooltip title={translateObj.editPointStyleTooltip[this.props.translate]} placement="top">
            <PaletteIcon fontSize="small" />
          </Tooltip>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={this.togglePointLabel.bind(this, this.props.point)}>
          <Tooltip title="Add Label" placement="top">
            <TextFieldsIcon fontSize="small" />
          </Tooltip>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={this.deleteMarker.bind(this, this.props.point.content)}>
          <Tooltip title={translateObj.deletePointTooltip[this.props.translate]} placement="top">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        </div>
      </li>
    );
  }
}

export default LegendPointRow;