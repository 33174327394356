import React from 'react';
import { useAuth0 } from '../../../react-auth0-spa';
import translateObj from '../../../utils/translate';

const userProfileDiv = {
  color: '#25408f',
  fontSize: '12px',
  marginLeft: 'auto',
  marginRight: '15px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold'
}

const userNameSpan = {
  marginRight: '8px'
}

const logoutSpan = {
  marginLeft: '8px',
  color: '#0c9ed9',
  cursor: 'pointer',
  fontWeight: 'normal'
}

function UserProfile(props) {
  const { isAuthenticated, user, logout } = useAuth0();
  const translate = props.translate;

  return (
    <div className="userProfileFlexDiv">
      <div style={userProfileDiv}> {translateObj.welcome[translate]},&nbsp;
      {isAuthenticated ?
          <span style={userNameSpan}>{user.name}</span>
          :
          <span style={userNameSpan}>Jane User</span>
        }
        {isAuthenticated ?
          <svg id="userAvatar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" height="20px">
            <circle fill="#d6d7dd" cx="10" cy="10" r="10" />
            <circle fill="#929294" cx="10" cy="7.78" r="4" />
            <path fill="#929294" d="M10,20a10,10,0,0,0,7.33-3.21,8.21,8.21,0,0,0-7.33-5,8.21,8.21,0,0,0-7.33,5A10,10,0,0,0,10,20Z" transform="translate(0 0)" />
          </svg>
          :
          <svg id="userAvatar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" height="20px">
            <circle fill="#d6d7dd" cx="10" cy="10" r="10" />
            <circle fill="#929294" cx="10" cy="7.78" r="4" />
            <path fill="#929294" d="M10,20a10,10,0,0,0,7.33-3.21,8.21,8.21,0,0,0-7.33-5,8.21,8.21,0,0,0-7.33,5A10,10,0,0,0,10,20Z" transform="translate(0 0)" />
          </svg>
        }
        {isAuthenticated ?
          <span
            style={logoutSpan}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            {translateObj.signOut[translate]}
          </span>
          : null
        }
      </div>
      <div className="feedbackDiv">
        <a className="helpLink" href="mailto:colliers.gis@colliers.com">{translateObj.feedbackAndSupport[translate]}</a>
      </div>
    </div>
  );
}

export default UserProfile;