import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import AddRing from '../StylePoints/AddRing';
import { GetCurrentGroupOrPointRings } from '../../../utils/tools';
import translateObj from '../../../utils/translate';

class GroupRings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddRing: false,
      showRingMain: true,
      isDrive: false
    }

    this.toggleAddRing = this.toggleAddRing.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  toggleAddRing(bool, bool2, bool3) {
    this.setState({
      isDrive: bool3,
      showAddRing: bool,
      showRingMain: bool2
    });
  }

  closePanel() {
    if (!this.props.isLoadingHistory)
      this.props.toggleGroupRingsPanel(false, this.props.currentGroup);
  }

  render() {
    const ringClone = JSON.parse(JSON.stringify(this.props.rings));
    const pointRings = GetCurrentGroupOrPointRings(true, this.props.currentPoint, ringClone, this.props.points, this.props.currentGroup);
    const routeClone = JSON.parse(JSON.stringify(this.props.driveTimes));
    const pointRoutes = GetCurrentGroupOrPointRings(true, this.props.currentPoint, routeClone, this.props.points, this.props.currentGroup);
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="ringsIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
              <path id="Path_4383" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M10.14,56A46,46,0,1,1,41,44" transform="translate(37.11 37.3)" />
              <path id="Path_4383-2" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.72,42a31.58,31.58,0,1,0-21.21-8.18" transform="translate(37.11 37.3)" />
              <path id="Path_4384" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.76-7.31A17.63,17.63,0,1,0,21.6-2.74" transform="translate(37.11 37.3)" />
            </svg>
          </span>
          <span>{translateObj.addEditGroupRingsTitle[this.props.translate]}</span>
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          {this.state.showRingMain ?
            <div>
              <div>
                <div className="geocodeDiv flexRight">
                  <button
                    className="geocodeBtnSmall"
                    onClick={this.toggleAddRing.bind(this, true, false, false)}
                  >
                    {translateObj.addEditRingBtn[this.props.translate]}
                  </button>
                </div>
                {pointRings.length === 0 ?
                  <div>
                    {translateObj.noRingsAppliedToGroup[this.props.translate]}
                  </div>
                  :
                  <div>
                    {pointRings.map((ring, i) =>
                      <div className="edit-ringRow" key={ring.id}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 20 20">
                          <circle fill={ring.fill} fillOpacity={ring.opacity} stroke={ring.stroke} strokeOpacity={ring.strokeOpacity} cx="10" cy="10" r="8" />
                        </svg>
                        <span style={{ paddingLeft: '5px' }}>
                          {ring.radius} {this.props.country === 'US' ? 'mile' : 'km'} {translateObj.ringSpan[this.props.translate]}
                          </span>
                      </div>
                    )}
                  </div>
                }
              </div>
              {this.props.country === 'OT' ? null :
                <div className="padding-t20">
                  <div className="geocodeDiv flexRight">
                    <button
                      className="geocodeBtnSmall"
                      onClick={this.toggleAddRing.bind(this, true, false, true)}
                    >
                      {translateObj.addEditRouteBtn[this.props.translate]}
                    </button>
                  </div>
                  {pointRoutes.length === 0 ?
                    <div>
                      {translateObj.noRoutesAppliedToGroup[this.props.translate]}
                    </div>
                    :
                    <div>
                      {pointRoutes.map((ring, i) =>
                        <div className="edit-ringRow" key={ring.id}>
                          <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 20 20">
                            <circle fill={ring.fill} fillOpacity={ring.opacity} stroke={ring.stroke} strokeOpacity={ring.strokeOpacity} cx="10" cy="10" r="8" />
                          </svg>
                          <span style={{ paddingLeft: '5px' }}>
                            {ring.radius} {translateObj.minuteRingSpan[this.props.translate]}
                            </span>
                        </div>
                      )}
                    </div>
                  }
                </div>
              }
              <div className="geocodeDiv flex">
                <button
                  className="geocodeBtn"
                  onClick={this.closePanel}
                >
                  {translateObj.cancelBtn[this.props.translate]}
                </button>
              </div>
            </div>
            : null
          }
          {
            this.state.showAddRing ?
              <AddRing
                toggleAddRing={this.toggleAddRing}
                updateMapObj={this.props.updateMapObj}
                currentPoint={this.props.currentPoint}
                rings={this.props.rings}
                driveTimes={this.props.driveTimes}
                headers={this.props.headers}
                isDrive={this.state.isDrive}
                updateStatus={this.props.updateStatus}
                isGroup={true}
                currentGroup={this.props.currentGroup}
                points={this.props.points}
                country={this.props.country}
                translate={this.props.translate}
                retriveGCAtlasToken={this.props.retriveGCAtlasToken}
              />
              : null
          }
        </div>
      </div>
    );
  }
}

export default GroupRings;