import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { geoJsonToNewPoints } from '../../../utils/geoJsonTools';
import axios from 'axios';

class ImportPtsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mainImportMessage: 'Import Successful!',
      subImportMessage: "Let's match the incoming fields.",
      resultsImportMessage: '',
      uploadColumns: [],
      showCols: false,
      processedData: null,
      selectedColumns: {
        name: 'None',
        layer: 'None',
        legend: 'None',
        label: 'None'
      }
    };

    this.closePanel = this.closePanel.bind(this);
    this.createUploadedPoints = this.createUploadedPoints.bind(this);
    this.processData = this.processData.bind(this);
    this.selectChange = this.selectChange.bind(this);
  }

  componentDidMount() {
    this.processData();
  }

  processData() {
    window.history.pushState({},null,window.location.origin);
    const upString = window.sessionStorage.getItem("upload_points");
    const upObj = JSON.parse(upString);
    window.sessionStorage.removeItem("upload_points");

    if(upObj.source==='discover')
      this.setState({mainImportMessage: 'Import From Discover Successful!'});

    axios.get(process.env.REACT_APP_APIURL + '/importPoints?uploadpoints=' + upString, { headers: this.props.headers })
    .then(result => {
      const msg = result.data.features.length + (result.data.features.length == 1 ? ' record matched' : ' records matched');

      let updateObj = {
        uploadColumns: result.data.columns,
        showCols: true,
        processedData: {
          "type": "FeatureCollection",
          "features" : result.data.features
        },
        resultsImportMessage: msg
      };
      if(result.data.columns.includes("Name")){
        updateObj.selectedColumns={
          name: 'Name',
          layer: 'None',
          legend: 'None',
          label: 'None'
        }
      }

      this.setState(updateObj);
    }).catch(error => {
      console.log('ImportPoints ProcessData Error');
      console.log(error);
      this.closePanel();
    });
  }

  closePanel() {
    this.props.toggleImportPoints(false,false);
  }

  createUploadedPoints(){
    var res = geoJsonToNewPoints(this.state.processedData,this.state.selectedColumns);

    const newLegend =  [{
      height: 42,
      width: 85,
      visible: false,
      scale: 1,
      columns: 1,
      position: ['right', 'top'],
      northArrowVisible: true,
      northArrowPosition: ['left', 'bottom']
    }];

    if (res.newPoints.length > 0) {
      newLegend[0].visible = true;
    }

    ///////Check this out, not sure if there are race conditions with orignal state???/
    this.props.updateMapObj({
       mapGroups: res.newGroups,
       currentGroup:  res.newGroups[res.newGroups.length - 1].id,
       mapPoints: res.newPoints,
       geocodingSuccess: true,
       legend: newLegend,
       checkOverlap: true
      });
      this.closePanel();
  }

  selectChange(colName, event) {
    let colObj = this.state.selectedColumns;
    colObj[colName] = event.target.value;
    this.setState(colObj);
  }

  render() {
    return (
      <div className="sidePanelStyle">
      <div>
      <h2 className="panel-title">
        <span className="panel-icon-span">
            <svg id="starIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.63 94.63">
              <path id="Path_4164" d="M16.51,3.68c-7.1-20.22-5.4-19.73-12.33,0-21.68.43-20.6-1-3.82,11.63-6.27,20.5-7.32,19.1,10,7.18,17.77,12.23,16.1,12.8,10-7.18C37.61,2.35,37.56,4.1,16.51,3.68Z" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4165" d="M9.83-36A46.07,46.07,0,1,1-25-19.93" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
            </svg>
          </span>
          {this.state.mainImportMessage} 
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
         </h2>
        <div className="panel-body">
        {
        this.state.processedData === null ?
          <div className="panel-loader"></div>
        :
          <div>
            <div className="textAreaDiv">
              {this.state.subImportMessage}
            </div>
          <div className="margin-t15 margin-b5">
              <div className="targetLayerOuterDiv">
                <div className="targetLayerLabel">Name:</div>
                <div className="targetLayerSelectDiv">
                <Select
                    labelId="name-select"
                    id="name-select"
                    key={"nameKey"}
                    value={this.state.selectedColumns.name}
                    onChange={(event) => this.selectChange('name', event)}
                    inputRef={ref => { this.nameRef = ref; }}
                    variant="outlined"
                  ><MenuItem
                    value={'None'}
                    key={'none'}
                  >None</MenuItem>
                    {
                      this.state.uploadColumns.map((col, i) => (
                        <MenuItem
                          value={col}
                          key={col + "_" + i}
                        >{col}</MenuItem>
                      ))
                    }
                  </Select>

                </div>
              </div>

              <div className="targetLayerOuterDiv">
                <div className="targetLayerLabel">Layer:</div>
                <div className="targetLayerSelectDiv">
                <Select
                    labelId="name-select"
                    id="name-select"
                    key={"layerKey"}
                    value={this.state.selectedColumns.layer}
                    onChange={(event) => this.selectChange('layer', event)}
                    inputRef={ref => { this.nameRef = ref; }}
                    variant="outlined"
                  ><MenuItem
                    value={'None'}
                    key={'none'}
                  >None</MenuItem>
                    {
                      this.state.uploadColumns.map((col, i) => (
                        <MenuItem
                          value={col}
                          key={col + "_" + i}
                        >{col}</MenuItem>
                      ))
                    }
                  </Select>
                </div>
              </div>

              <div className="targetLayerOuterDiv">
                <div className="targetLayerLabel">Legend Data:</div>
                <div className="targetLayerSelectDiv">
                <Select
                    labelId="name-select"
                    id="name-select"
                    key={"legendKey"}
                    value={this.state.selectedColumns.legend}
                    onChange={(event) => this.selectChange('legend', event)}
                    inputRef={ref => { this.nameRef = ref; }}
                    variant="outlined"
                  ><MenuItem
                    value={'None'}
                    key={'none'}
                  >None</MenuItem>
                    {
                      this.state.uploadColumns.map((col, i) => (
                        <MenuItem
                          value={col}
                          key={col + "_" + i}
                        >{col}</MenuItem>
                      ))
                    }
                  </Select>
                </div>
              </div>

              <div className="targetLayerOuterDiv">
                <div className="targetLayerLabel">Icon Label:</div>
                <div className="targetLayerSelectDiv">
                <Select
                    labelId="name-select"
                    id="name-select"
                    key={"labelKey"}
                    value={this.state.selectedColumns.label}
                    onChange={(event) => this.selectChange('label', event)}
                    inputRef={ref => { this.nameRef = ref; }}
                    variant="outlined"
                  ><MenuItem
                    value={'None'}
                    key={'none'}
                  >None</MenuItem>
                    {
                      this.state.uploadColumns.map((col, i) => (
                        <MenuItem
                          value={col}
                          key={col + "_" + i}
                        >{col}</MenuItem>
                      ))
                    }
                  </Select>
                </div>
              </div>
              <div className="textAreaDiv">
                {this.state.resultsImportMessage}
              </div>
              <div className="geocodeDiv flex">
                  <button className="geocodeBtn" onClick={this.createUploadedPoints}>Go</button>
              </div>

            </div>
          </div>
        }
        </div>
      </div>
      </div>
    );
  }
}

export default ImportPtsPanel;