import React, { useState } from 'react'
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import Clear from "@material-ui/icons/Clear";
import {getGeoAccColorFromResult} from '../../../../../src/utils/tools';
import translateObj from '../../../../utils/translate';

const ulStyle = {
  listStyleType: 'none',
  margin: 0,
  padding: '6px 0px 0px 0px',
}

//https://developers.arcgis.com/documentation/mapping-apis-and-services/geocoding/autosuggest/
//searchExtent, category, and countryCode

class TypeAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestData: [],
      searchVal: '',
      magicKeyVal: '',
      showSearchBtn: false,
      showErrorMessage: false,
      newPoint: false,
      score: false,
      match_type: false,
      match_address: false,
      label: false,
      name: false,
      id: false,
      verifyPoints: [],
      verifyCount: false,
      undoPointfromMap: false
    }

    this.geocode = this.geocode.bind(this);
    this.backToAddPts = this.backToAddPts.bind(this);
    this.addPoint = this.addPoint.bind(this);
    this.textEnter = this.textEnter.bind(this);
    this.undoPoint = this.undoPoint.bind(this);
    this.prevVerifyPoint = this.prevVerifyPoint.bind(this);
  }

  componentDidUpdate() {
    if(this.props.undoMoveLocation?.id === this.state.id){
      if(this.state.score){
        this.setState({
          score: false,
          match_type: 'UserMove',
          match_address: false,
          //undoPointfromMap: this.state.verifyPoints[this.state.verifyCount],
          undoPointfromMap: this.props.undoMoveLocation,
          newPoint: 'Moved'
        });
      }
    }
    else if(this.props.undoMoveLocation && this.props.currentPoint){
      if(this.props.undoMoveLocation?.id===this.props.currentPoint?.id){
        if(!this.state.undoPointfromMap){
          this.setState({
            undoPointfromMap: this.props.undoMoveLocation,
            newPoint: 'Moved'
          });
        }
      }
    }
  }

  componentDidMount() {
    const qScore = process.env.REACT_APP_QUESTIONABLE_SCORE;
    const gScore = process.env.REACT_APP_GOOD_SCORE;

    if(this.props.currentPoint){
      let adr = this.props.currentPoint.address + " " + this.props.currentPoint.city;
      
      if(this.props.currentPoint.state !== "" || this.props.currentPoint.zip !== "")
        adr += ", " + this.props.currentPoint.state + " " + this.props.currentPoint.zip;

      this.setState({
        searchVal: adr
      });

      this.callSuggestions(adr);
    }
    else if(this.props.verifyMode){
      const gpts = this.props.points.filter(p=>p.content.group === this.props.currentGroup);

      //const goodTypes =  ['PointAddress','StreetAddress','UserClick','UserMove','Coordinates'];
      const goodTypes = process.env.REACT_APP_GOOD_TYPES.split(',');

      const vpts = gpts.filter(p=>p.content.accuracy.score < gScore || !goodTypes.includes(p.content.accuracy.match_type));

      if(vpts.length>0){
        //this._setPoint(vpts[0],0);
        let adr = vpts[0].content.address + " " + vpts[0].content.city;

        if(vpts[0].content.state !== "" || vpts[0].content.zip !== "")
          adr += ", " + vpts[0].content.state + " " + vpts[0].content.zip;

        this.setState({
          verifyPoints: vpts,
          verifyCount: 0,
          searchVal: adr,
          score: vpts[0].content.accuracy.score || '',
          match_type: vpts[0].content.accuracy.match_type || '',
          match_address: vpts[0].content.accuracy.match_address || '',
          label: vpts[0].content.order || '',
          name: vpts[0].content.name || '',
          id: vpts[0].content.id || '',
          showSearchBtn: true,
          verifyMapLat: vpts[0].position.lat,
          verifyMapLng: vpts[0].position.lng
        });
      
        this.props.updateMapObj({newExtent:{
          north: vpts[0].position.lat + .01,
          south: vpts[0].position.lat - .01,
          west:  vpts[0].position.lng - .01,
          east: vpts[0].position.lng + .01
        }});
      }
      else {
        this.props.updateMapObj({infoBar:true,infoMessage:'No Points to verify in this layer'})
        this.props.toggleTypeAddress(false,true);
      }
    }
  }

  nextVerifyPoint() {
    const newVP = this.state.verifyCount + 1;
    if(newVP === this.state.verifyPoints.length){
      this.props.updateMapObj({infoBar:true, infoMessage: "Verifying Geocodes Complete"})
      this.backToAddPts();
    }
    else {
      const thisVP = this.state.verifyPoints[newVP];
      this._setPoint(thisVP,newVP);
    }
  }

  prevVerifyPoint() {
    const newVP = this.state.verifyCount - 1;
    const thisVP = this.state.verifyPoints[newVP];
    this._setPoint(thisVP,newVP);
  }

  _setPoint(point,count){
    let adr = point.content.address + " " + point.content.city;
    if(point.content.state !== "" || point.content.zip !== "")
      adr += ", " + point.content.state + " " + point.content.zip;

      this.setState({
        verifyCount: count,
        searchVal: adr,
        score: point.content.accuracy.score || '',
        match_type: point.content.accuracy.match_type || '',
        match_address: point.content.accuracy.match_address || '',
        label: point.content.order || '',
        name: point.content.name || '',
        id: point.content.id || '',
        verifyMapLat: point.position.lat,
        verifyMapLng: point.position.lng,
        newPoint: false//,
        //showSearchBtn: true
      });
  
      this.props.updateMapObj({newExtent:{
        north: point.position.lat + .01,
        south: point.position.lat - .01,
        west: point.position.lng - .01,
        east: point.position.lng + .01
      },
      verifyPoint: null
    });
  }

  undoPoint() {
    if(this.state.undoPointfromMap){
      const clonePts = this.props.points;
      const ptid = this.state.undoPointfromMap.id;
      const cloneIx = clonePts.findIndex(point => point.content.id === ptid);

      const newPt = clonePts[cloneIx];
      newPt.position = {
              lat: this.state.undoPointfromMap.lat,
              lng: this.state.undoPointfromMap.lng
      }
      newPt.content.accuracy = this.state.undoPointfromMap.accuracy;

      clonePts[cloneIx] = newPt;
      this.props.updateMapObj({undoMoveLocation: null, mapPoints: clonePts,verifyPoint: false, isReGeocoding: false});   
    
      this.setState({
        undoPointfromMap: false,
        score: newPt.content.accuracy.score || '',
        match_type: newPt.content.accuracy.match_type || '',
        match_address: newPt.content.accuracy.match_address || '',
        newPoint: false
      });
    }
    else if(this.props.verifyMode){
      this._setPoint(this.state.verifyPoints[this.state.verifyCount],this.state.verifyCount);
    }
    else {
      let adr = this.props.currentPoint.address + " " + this.props.currentPoint.city;
      
      if(this.props.currentPoint.state !== "" || this.props.currentPoint.zip !== "")
        adr += ", " + this.props.currentPoint.state + " " + this.props.currentPoint.zip;

      this.setState({
        magicKeyVal: '',
        newPoint: false,
        score: false,
        match_type: false,
        match_address: false,
        suggestData: [],
        searchVal: adr
      });

      this.props.updateMapObj({
        newExtent:{
          north: this.props.currentPoint.lat + .01,
          south: this.props.currentPoint.lat - .01,
          west: this.props.currentPoint.lng - .01,
          east: this.props.currentPoint.lng + .01
        },
        verifyPoint: null
      });
    }
  }

  async callSuggestions(suggestVal) {
    const searchX = this.props.mapCenter ? this.props.mapCenter[1] : this.state.verifyPoints[this.state.verifyCount].position.lng;
    const searchY = this.props.mapCenter ? this.props.mapCenter[0] : this.state.verifyPoints[this.state.verifyCount].position.lat;

    // const extent = (parseFloat(this.props.mapCenter[1]) - 2) + ',' + (parseFloat(this.props.mapCenter[0]) + 2)
    //   + ',' + (parseFloat(this.props.mapCenter[1]) + 2)+ ',' + (parseFloat(this.props.mapCenter[0]) - 2);

    // const url = process.env.REACT_APP_GEOCODE_SUGGEST_URL + '?text=' + suggestVal + '&f=pjson&'
    //   + '&category=Address&searchExtent=' + extent;

    const url2 = process.env.REACT_APP_GEOCODE_SUGGEST_URL + '?text=' + suggestVal + '&f=pjson&'
        + '&category=Address&location=' + searchX + ',' + searchY;
        //+ '&sourceCountry=' + this.props.country;

    const url3 = process.env.REACT_APP_GEOCODE_SUGGEST_URL + '?text=' + suggestVal + '&f=pjson';

    let data = await axios.get(url2).then(result => {
      if (result && result.data) {
        return result.data;
      }
    });

    if(data.suggestions.length > 0){
      return data.suggestions;
    }
  }

  async getSuggestions(e) {
    const suggestVal = e.target.value;    
    this.setState({
      searchVal: suggestVal,
      showSearchBtn: false
    });

    if(suggestVal.length > 4){
      const results = await this.callSuggestions(suggestVal);
      this.setState({
        suggestData: results
      });
    }
  }

  async setSearchValue(e, suggestion) {
    const newPointData = await this.geocode(suggestion.text,suggestion.magicKey);
    this.setState({
      searchVal: suggestion.text,
      magicKeyVal: suggestion.magicKey,
      suggestData: [],
      showSearchBtn: true
    });

    if(newPointData){
      this.setVerifyPoint(newPointData);
    }
  }

  matchQuality(score,match_type){
    const qScore = process.env.REACT_APP_QUESTIONABLE_SCORE;
    const gScore = process.env.REACT_APP_GOOD_SCORE;
    const goodTypes = process.env.REACT_APP_GOOD_TYPES.split(',');

    if(!goodTypes.includes((match_type || '')))
      return 'Doubtful Match';
    else if(score === 100)
      return 'Perfect Match';
    else if(score > gScore)
      return 'Probable Match';
    else if(score > qScore)
      return 'Questionable Match';
    else if(score > 0)
      return 'Doubtful Match';

    return 'N/A';
  }


  englishType(match_type){
    if(match_type==='PointAddress')
      return 'Building or Parcel';
    if(match_type.startsWith('StreetAddress') || match_type === 'Subaddress')
      return 'Street Address';
    if(match_type==='StreetName')
      return 'Street Only';
    if(match_type==='UserClick' || match_type == 'UserMove' || match_type == 'Coordinates')
      return 'Manually Placed';
    if(match_type.startsWith('Postal'))
      return 'Postal Code Center';
    if(match_type==='StreetInt')
      return 'Street Intersection';
    if(match_type==='Locality')
      return 'City Center';
    return match_type;
    
  }

  englishScore(score) {
    const qScore = process.env.REACT_APP_QUESTIONABLE_SCORE;
    const gScore = process.env.REACT_APP_GOOD_SCORE;

    let text = '';
    if(score === 100)
      text = 'Perfect'
    if(score > gScore)
      text = 'Probable';
    else if(score > qScore)
      text = 'Questionable';
    else
      text = 'Doubtful';

    return text;
    
  }

  setVerifyPoint(newPointData) {
    const _color = getGeoAccColorFromResult(newPointData.attributes);

    const verifyPoint = {
      position: {
        lat: newPointData.location.y,
        lng: newPointData.location.x,
      },
      color: _color
    }

    this.props.updateMapObj({
      verifyPoint: verifyPoint
    });

    this.setState({
      newPoint: newPointData,
      score: newPointData.attributes.Score,
      match_type: newPointData.attributes.Addr_type || '',
      match_address: newPointData.attributes.Match_addr,
      suggestData: [],
      showSearchBtn: true,
    });

    setTimeout(() => {
      this.props.updateMapObj({
        newExtent:{
          north: newPointData.location.y + .01,
          south: newPointData.location.y - .01,
          west: newPointData.location.x - .01,
          east: newPointData.location.x + .01
        }
      });
    }, 300);
  }

  async textEnter(){
    const newPointData = await this.geocode(this.state.searchVal);

    if(newPointData){
      this.setVerifyPoint(newPointData);
    }
  }

  async geocode(inputAddress,magicKey) {
    //add point to map
    inputAddress = inputAddress.replace('&', 'and');
    let url = process.env.REACT_APP_GEOCODEURL + '?SingleLine=' + inputAddress + '&f=pjson&outfields=*';
    if(magicKey){
      url += '&magicKey=' + magicKey;
    }

    //await axios.get(process.env.REACT_APP_GEOCODEURL + '?SingleLine=' + inputAddress + '&f=pjson', { headers: this.props.headers })
    const results = await axios.get(url)
      .then(result => {
        if (result) {
          if (result.data) {
            if (result.data.candidates) {
              if (result.data.candidates.length > 0) {
                return result.data.candidates[0];
              }
            } else if (result.data.message) {
              if (result.data.message === 'No results') {
                this.setState({
                  showErrorMessage: true
                });
                return;
              }
            }
          }
        }
      }).catch(error => {
        if (error) {
          this.setState({
            showErrorMessage: true,
          });
        }
      });
    return results;
  }

  async addPoint() {
    let newPointData = this.state.newPoint;
    if(newPointData === undefined){
      newPointData = await this.geocode(this.state.searchVal);
    }

    if(newPointData === 'Moved'){
      console.log('in add point, point moved');
    }
    else if(this.props.currentPoint || this.props.currentGroup){
      console.log('this.props.currentPoint')
      if(newPointData){
        const clonePts = this.props.points;
        const ptid = this.props.verifyMode ? this.state.verifyPoints[this.state.verifyCount].content.id : this.props.currentPoint.id;
        const cloneIx = clonePts.findIndex(point => point.content.id === ptid);
        clonePts[cloneIx].position.lat = newPointData.attributes.DisplayY;
        clonePts[cloneIx].position.lng = newPointData.attributes.DisplayX;
  
        if(clonePts[cloneIx].content.address === clonePts[cloneIx].content.name)
          clonePts[cloneIx].content.name = newPointData.attributes.StAddr || '';
  
        clonePts[cloneIx].content.address = newPointData.attributes.StAddr || '';
        clonePts[cloneIx].content.city = newPointData.attributes.City || '';
        clonePts[cloneIx].content.state = newPointData.attributes.RegionAbbr || '';
        clonePts[cloneIx].content.zip = newPointData.attributes.Postal || '';
  
        clonePts[cloneIx].content.accuracy = {
            score: newPointData.attributes.Score || '',
            match_type: newPointData.attributes.Addr_type || '',
            match_address: newPointData.attributes.Match_addr
          }
        this.props.updateMapObj({mapPoints: clonePts, isReGeocoding: false, verifyPoint: false});
        this.setState({newPoint: false });
      }
    }
    else {
      const pointsArray = [{
        position: { lat: newPointData.attributes.DisplayY, lng: newPointData.attributes.DisplayX },
        content: {
          address: newPointData.attributes.StAddr || '',
          city: newPointData.attributes.City || '',
          state: newPointData.attributes.RegionAbbr || '',
          zip: newPointData.attributes.Postal || '',
          accuracy: {
            score: newPointData.attributes.Score || '',
            match_type: newPointData.attributes.Addr_type || '',
            match_address: newPointData.attributes.Match_addr
          }
        }
      }];

      this.setState({
        showErrorMessage: false,
      });

      const legendClone = Array.from(this.props.legend);
      if (this.props.points.length === 0) {
        legendClone[0].visible = true;
      }
      const newPointsArray = this.props.createNewPoint(pointsArray);
      this.props.updateMapObj({ mapPoints: newPointsArray, legend: legendClone });
      //this.props.updateMapObj({ mapPoints: newPointsArray, geocodingSuccess: true, legend: legendClone });
    }

    this.props.updateMapObj({
      verifyPoint: null
    });

    if(this.props.verifyMode){
      this.nextVerifyPoint();
    }
    else {
      this.setState({
        searchVal: '',
        score: null,
        match_type: null,
        match_address: null,
        label: null,
        name: null
      });
    }
  }

  backToAddPts() {
    this.props.updateMapObj({verifyPoint: false});
    this.props.toggleTypeAddress(false,true);
  }

  componentWillUnmount() {
    this.props.updateMapObj({ geocodingSuccess: false });
  }

  render() {
    const sidePanelStyle = this.props.currentPoint ? "sidePanelStyle" : "";
    const title =  this.props.verifyMode ? "Verify Points" : this.props.currentPoint ? "Correct Point" : "Type Address";
    const addText =  this.props.verifyMode ? (this.state.newPoint ? "Move Point" : "Next Point") : this.props.currentPoint ? "Use Moved Point" : translateObj.addtoMapBtn[this.props.translate];
    const backText =  this.props.verifyMode ? "Cancel Verify Points" : translateObj.backBtn[this.props.translate];
    const faorpText = this.props.verifyMode ? "Type Corrected Address" : translateObj.findAddressOrPlace[this.props.translate]
    const score = this.state.score || this.props.currentPoint?.accuracy?.score || null; //'N/A';
    const match_type = this.state.match_type || this.props.currentPoint?.accuracy?.match_type || null; //'N/A';
    const address = this.state.match_address || this.props.currentPoint?.accuracy?.match_address || null; //'None';
    const label = this.state.label || this.props.currentPoint?.order || null; //'N/A';
    const name = this.state.name || this.props.currentPoint?.name || null; //|| 'N/A';

    const qScore = process.env.REACT_APP_QUESTIONABLE_SCORE;
    const gScore = process.env.REACT_APP_GOOD_SCORE;

    let scoreColor = '#ed1b34';
    if(score===null) //'N/A')
      scoreColor = '#333';
    else if(parseInt(score) > gScore)
      scoreColor = '#339088'
    else if(parseInt(score) > qScore)
      scoreColor = '#ffd400'

    const scoreStyle = {
      fontSize: '12px',
      color: scoreColor,
      fontFamily: "'Open Sans', Arial, sans-serif",
      fontStyle: 'italic'
    }

    const goodTypes = process.env.REACT_APP_GOOD_TYPES.split(',');
    const userTypes = ['UserClick','UserMove','Coordinates','N/A'];

    let typeColor = '#ed1b34';
    if(userTypes.includes(match_type))
      typeColor = '#333';
    else if(goodTypes.includes(match_type))
      typeColor = '#339088';

    const typeStyle = {
      fontSize: '12px',
      color: typeColor,
      fontFamily: "'Open Sans', Arial, sans-serif",
      fontStyle: 'italic'
    }

    const overallStyle = {
        fontSize: '18px',
        color: goodTypes.includes(match_type) ? scoreColor : typeColor,
        fontFamily: "'Open Sans', Arial, sans-serif"
    }

    return (
      <div className={sidePanelStyle}>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="globeIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 92.15 92.15">
              <path id="Path_4355" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.65,54.74A44.84,44.84,0,1,1,39.76,43.12" transform="translate(36.42 36.16)" />
              <path id="Path_4356" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M10.22-34.91c12.11,0,21.92,20.07,21.92,44.82S22.33,54.74,10.22,54.74" transform="translate(36.42 36.16)" />
              <path id="Path_4357" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.19-34.91c-12.1,0-21.92,20.07-21.92,44.82S-2.91,54.74,9.19,54.74" transform="translate(36.42 36.16)" />
              <path id="Path_4358" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.65-36.06v92" transform="translate(36.42 36.16)" />
              <path id="Path_4359" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M54.44,11.72H-35.17" transform="translate(36.42 36.16)" />
              <path id="Path_4360" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M50.74-8.21H-31.44" transform="translate(36.42 36.16)" />
              <path id="Path_4361" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M49,31.64H-29.69" transform="translate(36.42 36.16)" />
            </svg>
          </span>
          {title}</h2>
        <div className="panel-body">
       {/* // {this.props.currentPoint ? */}
        <div className="paddingB20">
        {name ? <div><span className="smallText">Name: {name}</span></div> : name}
        {label ? <div><span className="smallText">Label: {label}</span></div> : null}
        {match_type ? <div><span style={overallStyle}>{this.matchQuality(score,match_type)}</span></div>:null }
        {score ? <div><span className="paddingTB1" style={scoreStyle}>Match Accuracy: {this.englishScore(score)} </span></div> : null}
        {match_type ? <div><span className="paddingTB1" style={typeStyle}>Location Percision: {this.englishType(match_type)}</span></div> : null}
        {address ? <div><span className="smallText">Matched Location: {address}</span></div> : null}
        </div>
       {/* // : null } */}

          <TextField
            id="search-input"
            label={faorpText}
            variant="outlined"
            value={this.state.searchVal}
            size="small"
            fullWidth
            onChange={(e) => { this.getSuggestions(e) }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.textEnter()
              }
            }}
          />
          {this.state.suggestData ?
            <ul style={ulStyle}>
              {this.state.suggestData.map(suggestion => (
                <li
                  className="search"
                  id={suggestion.magicKey}
                  key={suggestion.magicKey}
                  value={suggestion.text}
                  onClick={(e) => { this.setSearchValue(e, suggestion) }}
                >
                  {suggestion.text}
                </li>
              ))}
            </ul>
            : null}

          <div className="geocodeDiv flex">
            <button className="geocodeBtn paddingR10 " onClick={this.backToAddPts}>{backText}</button>
            {this.state.newPoint && this.props.verifyMode ?
            <button className="geocodeBtn" onClick={this.undoPoint}>Undo</button>
            : null}
            {this.props.verifyMode && this.state.verifyCount && !this.state.newPoint > 0 ?
            <button className="geocodeBtn paddingR10 " onClick={this.prevVerifyPoint}>Previous Point</button>
            : null
            }
            {this.state.showSearchBtn ?
              <button className="geocodeBtn" onClick={this.addPoint}>{addText}</button>
              : null
            }
          </div>
          {this.state.showErrorMessage ?
            <div className="errorMsgDiv padding-t10I">
              {translateObj.oopsWrongTryAgain[this.props.translate]}
            </div>
            : null}
        </div>
      </div>
    )
  }
}

export default TypeAddress;

export const FindAddressOrPlace = (props) => {
  //search box
  const [searchVal, setSearchVal] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [suggestData, setSuggestData] = useState([]);
  const [currentVal, setCurrentVal] = useState('');

  const geocode = async (e, suggestion) => {
    setSearchVal('Searching...');
    setSuggestData([]);

    let inputAddress = suggestion.text.replace('&', 'and');
    await axios.get(process.env.REACT_APP_GEOCODEURL + '?SingleLine=' + inputAddress + '&f=pjson', { headers: props.headers })
      .then(result => {
        setSearchVal('');
        setSuggestData([]);
        if (result && result.data && result.data.candidates.length) {

          const locationPoint = result.data.candidates[0].location;


          props.updateMapObj({
            moveCenterandZoom:{
              changeZoomUnder: 10,
              zoom: 14,
              center: [locationPoint.y, locationPoint.x]
            }
          });
        }
      })
  };

  const onEnter = async () => {
    if(currentVal.includes(',')){
      let potCoords = currentVal.split(',');
      if(potCoords.length === 2){
        if(!isNaN(potCoords[0])&&!isNaN(potCoords[1])){
          if(potCoords[0] < -60){
            potCoords = [potCoords[1],potCoords[0]];
          }
          const mapRef = props.refs.map.leafletElement;
          let mapZoom = mapRef.getZoom();
          if (mapZoom < 10)
            mapZoom = 14;
          
          mapRef.setView(potCoords, mapZoom);
        }
        else {
          geocode(null, {text: currentVal})
        }
      }
      else {
        geocode(null, {text: currentVal})
      }
    }
    else{
      geocode(null, {text: currentVal})
    }
    
  };

  const getSuggestions = async (e) => {

    const suggestVal = e.target.value;
    setSearchVal(suggestVal);
    setCurrentVal(suggestVal);

    if(suggestVal!==''){
      await axios.get(process.env.REACT_APP_GEOCODE_SUGGEST_URL + '?text=' + suggestVal + '&f=pjson')
      .then(result => {
        if (result && result.data) {
          setSuggestData(result.data.suggestions);
        }
      });
    }
  };//end get suggestions

  const clearAddress = () => {
    setSearchVal('');
    setSuggestData([]);
  };

  return <div className="panel-body-width">
    <TextField
      id="search-input"
      label={translateObj.zoomToAddressOrPlace[props.translate]}
      variant="outlined"
      value={searchVal}
      size="small"
      fullWidth
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onEnter()
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Clear onClick={clearAddress}/>
          </InputAdornment>
        )
      }}
      onChange={getSuggestions}
    />
    {suggestData.length ?
      <ul style={ulStyle}>
        {suggestData.map(suggestion => (
          <li
            className="search"
            id={suggestion.magicKey}
            key={suggestion.magicKey}
            value={suggestion.text}
            onClick={(e) => { geocode(e, suggestion) }}
          >
            {suggestion.text}
          </li>
        ))}
      </ul>
      : null}
    {showErrorMessage ?
      <div className="errorMsgDiv padding-t10I">
        {translateObj.oopsWrongTryAgain[this.props.translate]}
      </div>
      : null}
  </div>
}
