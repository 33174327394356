import React from 'react';
import RetailLineStyle from './RetailLineStyle';
import ColorPickerMenu from '../ColorPicker/ColorPickerMenu';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField } from '@material-ui/core';

class RetailFilter extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    toggleRetailerLeaderLineAnchor(chk) {
        let _chk = chk;
        if(chk === undefined)
            _chk = !this.props.retailerLeaderLineAnchor;
        this.props.toggleRetailerLeaderLineAnchor(_chk);
    }

    toggleRetailerLeaderLine(chk) {
        let _chk = chk;
        if(chk === undefined)
            _chk = !this.props.retailerLeaderLine;
        this.props.toggleRetailerLeaderLine(_chk);
    }

    changeLeaderLineColor(type, newStyle) {
        this.props.changeLeaderLineColor(type,newStyle);
    }

    changeLeaderLineWidth(value){
        this.props.changeLeaderLineWidth(value);
    }

    changeLeaderLineOpacity(value){
        this.props.changeLeaderLineOpacity(value);
    }

    render() {
        return (
            <div>
                <div className="retailerOuterDiv">
                <div className="retailerLabel">Add Leader Line on Move:</div>
                <Checkbox
                    color="default"
                    onClick={(event) => this.toggleRetailerLeaderLine(event.target.checked)}
                    checked={this.props.retailerLeaderLine}
                />
                </div>
                <div>
                    <div className="retailerOuterDiv">
                    <div className="retailerLabel">Choose Line Color:</div>
                    <ColorPickerMenu
                        squareColor={this.props.retailerLeaderColor}
                        setFillColor={this.changeLeaderLineColor.bind(this, 'color')}
                        addTransparent={false}
                    />
                    </div>

                    <div>
                        <span>Add Leader Line Anchor:</span>
                        <Checkbox
                        color="default"
                        onClick={(event) => this.toggleRetailerLeaderLineAnchor(event.target.checked)}
                        checked={this.props.retailerLeaderLineAnchor}
                    />
                    </div>

                    <div className="retailerOuterDiv paddingB10">
                            <div style={{ width: '80px', paddingLeft: '5px' }}>
                            <TextField
                            label="Width"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={this.props.retailerLeaderWidth}
                            InputProps={{ inputProps: { min: 0, max: 5, step: 1 } }}
                            onChange={(e) => { this.changeLeaderLineWidth(e.target.value) }}
                            />
                        </div>
                    {/* </div>
                    <div className="retailerOuterDiv"> */}
                        {/* <div className="retailerLabel">Line Opacity:</div> */}
                            <div style={{ width: '80px', paddingLeft: '5px' }}>
                            <TextField
                            label="Opacity"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={this.props.retailerLeaderOpacity}
                            InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                            onChange={(e) => { this.changeLeaderLineOpacity(e.target.value) }}
                            />
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RetailFilter;