import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import { GenerateRandomId, GetNewGroupColor, IsNumeric, isLongitude, isLatitude } from '../../../../utils/tools';
import translateObj from '../../../../utils/translate';

const buttonRow = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '15px',
  marginBottom: '5px'
}

class MapColumns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      isLoading: false
    };

    this.geocode = this.geocode.bind(this);
    this.mapDataToPointsArray = this.mapDataToPointsArray.bind(this);
    this.addResultsToPointsArray = this.addResultsToPointsArray.bind(this);
    this.getResultObjForLatLng = this.getResultObjForLatLng.bind(this);
    this.setGroups = this.setGroups.bind(this);
    this.sortPointsByGroup = this.sortPointsByGroup.bind(this);
    this.selectChange = this.selectChange.bind(this);
  }

  async geocode() {
    this.setState({
      showError: false,
      isLoading: true
    });

      const addresses = this.mapDataToPointsArray();
      this.props.updateStatus('isLoadingHistory', true);
  
      if (!this.props.allColumns || (this.latRef.value !== 'None' && this.lngRef.value !== 'None')) {
        const result = this.getResultObjForLatLng(addresses);
        this.addResultsToPointsArray(result.data.addresses, true);
      } else {
        await axios.post(process.env.REACT_APP_BATCH_GEOCODE_URL , addresses, { headers: this.props.headers }) 
          .then((result) => {
            if (result.data && result.data.addresses) {
              this.addResultsToPointsArray(result.data.addresses, false);
            } else {
              this.setState({
                showError: true,
                isLoading: false
              });
              this.props.updateStatus('isLoadingHistory', false);
            }
          }).catch((error) => {
            this.setState({
              showError: true,
              isLoading: false
            });
            this.props.updateStatus('isLoadingHistory', false);
          });
      }
  }

  _noValue(obj) {
    if(obj===undefined)
      return true;
    if(obj===null)
      return true;
    if(obj.trim()==='')
      return true;

    return false;
  }

  _isIntl(obj) {
    if(this._noValue(obj))
      return false;
  
    const notIntl = ['US','USA','United States','Canada','CA'];
    if(notIntl.includes(obj.toString().toUpperCase())){
      return false;
    }
    return true;
  }

  mapDataToPointsArray() {
    const geocodeArray = [];
    const data = this.props.data;
    //data.shift(); //remove first row of headers
    const newArray = Array.from(data);

    let intl = false;
    if(this.props.country==='AUS' || this.props.country === 'JPN'){
      intl = true;
    }

    for (const [index, row] of newArray.entries()) {
      let push_obj = {
        OBJECTID: index,
        //country: this.props.country
      }
      if (this.props.allColumns){
        push_obj.streetAddress = encodeURIComponent(row[this.addressRef.value]);
        push_obj.city = row[this.cityRef.value];
        push_obj.state = row[this.stateRef.value];
        push_obj.postalCode = row[this.postalRef.value];
      }

      if(this.props.supergeo && this.props.countryColName !=='None'){
        if(this._noValue(row[this.countryRef.value])){
          push_obj.country = this.props.country;
        }
        else {
          push_obj.country = row[this.countryRef.value];
          if(this._isIntl(row[this.countryRef.value])){
            intl = true;
          }
        }
      }
      else {
        push_obj.country = this.props.country;
      }
      geocodeArray.push(push_obj);
    }

    const addresses = {
      addresses: geocodeArray,
      intl: intl,
      country: this.props.country
    }

    return addresses;
  };

  getResultObjForLatLng(addresses) {
    let results = {
      data: {
        addresses: {}
      }
    }

    const newArray = [];
    if (addresses && addresses.addresses) {
      for (const address of addresses.addresses) {
        newArray.push({
          output: [
            {
              attributes: {
                ResultID: address.OBJECTID,
                StAddr: address.Address || '',
                City: address.City || '',
                RegionAbbr: address.State || '',
                Postal: address.Postal || '',
                accuracy: {
                  //score: result.output[0].attributes.Score || '',
                  match_type: 'Coordinates'//,
                  //match_address: result.output[0].attributes.Match_addr
                }
              },
              location: {}
            }
          ]
        });
      }
    }

    let lat_ref_value = 'calculated_latitude';
    let lng_ref_value = 'calculated_longitude';
    if (this.props.allColumns){
      lat_ref_value = this.latRef.value;
      lng_ref_value = this.lngRef.value;
    }

    newArray.forEach((item) => {
      const locationY = parseFloat(this.props.data[item.output[0].attributes.ResultID][lat_ref_value]);
      const locationX = parseFloat(this.props.data[item.output[0].attributes.ResultID][lng_ref_value]);
      item.output[0].location.y = isLatitude(locationY) ? locationY : 0;
      item.output[0].location.x = isLongitude(locationX) ? locationX : 0;
      item.output[0].attributes.DisplayY = isLatitude(locationY) ? locationY : 0;
      item.output[0].attributes.DisplayX = isLongitude(locationX) ? locationX : 0;
    });

    results.data.addresses = newArray;
    return results;
  }

  async addResultsToPointsArray(resultsArray, isCoords) {
    const qScore = process.env.REACT_APP_QUESTIONABLE_SCORE;
    const gScore = process.env.REACT_APP_GOOD_SCORE;

    resultsArray.sort((a, b) => (a.output[0].attributes.ResultID > b.output[0].attributes.ResultID) ? 1 : ((b.output[0].attributes.ResultID > a.output[0].attributes.ResultID) ? -1 : 0));
    const newArray = [];
    let goodTypes = process.env.REACT_APP_GOOD_TYPES.split(',');
    if(this.props.addressColName){
      goodTypes.push('Postal');
      goodTypes.push('PostalExt');
    }
    
    let red = 0;
    let green = 0;
    let yellow = 0;

    for (const result of resultsArray) {
      if (result.output[0].location) {
        newArray.push({
          //position: { lat: result.output[0].location.y || 0, lng: result.output[0].location.x || 0 },
          position: { lat: result.output[0].attributes.DisplayY || 0, lng: result.output[0].attributes.DisplayX || 0 },
          content: {
            name: result.output[0].attributes.ResultID,
            address: result.output[0].attributes.StAddr || '',
            city: result.output[0].attributes.City || '',
            state: result.output[0].attributes.RegionAbbr || '',
            zip: result.output[0].attributes.Postal || '',
            accuracy: {
              score: result.output[0].attributes.Score || '',
              match_type: isCoords ? 'Coordinates' : result.output[0].attributes.Addr_type || '',
              match_address: result.output[0].attributes.Match_addr
            }
          }
        });
        
        //if(!isCoords){
        if(isCoords)
          green++;
        else if(!goodTypes.includes((result.output[0].attributes.Addr_type || '')))
          red++;
        else if((result.output[0].attributes.Score || 0) > gScore)
          green++
        else if((result.output[0].attributes.Score || 0) > qScore)
          yellow++
        else if((result.output[0].attributes.Score || 0) > 0)
          red++
        //}
      }
    }

    const addNamesArray = this.addNamesAndGroupsToResults(newArray);
    const addGroupsArray = await this.setGroups(addNamesArray);
    const sortGroupsArray = this.sortPointsByGroup(addGroupsArray);
    const pointsArrayClone = this.props.createNewPoint(sortGroupsArray);
    const legendClone = Array.from(this.props.legend);

    if (this.props.points.length === 0) {
      legendClone[0].visible = true;
    }

    let badGeo = false;
    
    if(red>0 || yellow>0){
      badGeo = {
        green: green,
        yellow: yellow,
        red: red
      }
    }

    this.props.updateMapObj({
      mapPoints: pointsArrayClone,
      geocodingSuccess: true,
      legend: legendClone,
      checkOverlap: true,
      badGeocodes: badGeo
    });
    this.props.updateStatus('isLoadingHistory', false);
    this.props.toggleUploadPanel(false, true);
  }

  addNamesAndGroupsToResults(resultsArray) {
    resultsArray.forEach((result) => {
      const newName = this.props.data[result.content.name] ? (this.props.data[result.content.name][this.nameRef.value] ? (this.props.data[result.content.name][this.nameRef.value]).toString() : 'None') : 'None';
      const newGroup = this.props.data[result.content.name] ? (this.props.data[result.content.name][this.groupRef.value] ? (this.props.data[result.content.name][this.groupRef.value]).toString() : 'None') : 'None';
      let oldAddress = 'None';
      if (this.props.allColumns){
        oldAddress = this.props.data[result.content.name] ? (this.props.data[result.content.name][this.addressRef.value] ? (this.props.data[result.content.name][this.addressRef.value]).toString() : 'None') : 'None';
      }

      const newLegendData = this.props.data[result.content.name] ? (this.props.data[result.content.name][this.legenddataRef.value] ? (this.props.data[result.content.name][this.legenddataRef.value]).toString() : '') : '';
      const newLabel = this.props.data[result.content.name] ? (this.props.data[result.content.name][this.labelRef.value] ? (this.props.data[result.content.name][this.labelRef.value]).toString().substring(0, 3) : '') : '';

      result.content.name = newName.trim();
      result.content.group = newGroup.trim();
      result.content.address = oldAddress.trim();
      result.content.legenddata = newLegendData.trim();
      result.content.label = newLabel.trim();
    });
    return resultsArray;
  }

  setGroups(resultsArray) {
    let groupsArrayClone = Array.from(this.props.groups);
    const targetGroup = groupsArrayClone.filter(group => group.id === this.props.currentGroup);
    const targetGroupPoints = this.props.points.filter(point => point.content.group === targetGroup[0].id);
    let newGroupAdded = false;
    const maxLegendPoints = process.env.REACT_APP_MAX_LEGEND_POINTS || 30;

    const _pointsInLegend = resultsArray.length > maxLegendPoints ? false : true;

    resultsArray.forEach((result) => {
      if (result.content.group === 'None') { //if upload does not specify groups
        if (targetGroupPoints.length === 0) { //if current target group is empty, add new points to target group
          result.content.group = targetGroup[0].id;
          if(!_pointsInLegend){
            const targetIx = groupsArrayClone.findIndex(g => g.id === targetGroup[0].id);
            groupsArrayClone[targetIx].pointsInLegend = _pointsInLegend;
          }
        } else { //else create new group
          if (newGroupAdded === false) { //only create new group one time
            groupsArrayClone.push({
              id: GenerateRandomId(),
              order: groupsArrayClone.length + 1,
              name: 'Layer ' + (groupsArrayClone.length + 1),
              useGroupStyle: false,
              groupTextColor: '#ffffff',
              groupIconName: 'circleIcon',
              groupIconColor: GetNewGroupColor(groupsArrayClone.length + 1),
              groupIconSize: 'default',
              startNumber: 1,
              visible: true,
              nameInLegend: true,
              pointsInLegend: _pointsInLegend,
              groupInLegend: true,
              icon: '',
              showLegendData: false,
              useCustomLabel: false,
              groupTextLabel: false,
              groupTextLabelStroke: '#4a4a4d',
              groupTextLabelColor: '#ffffff',
              groupTextLabelFontSize: 14,
              groupTextLabelOpacity: 1,
              groupTextLabelFont: 'Open Sans',
              groupTextLabelWeight: true,
              groupTextLabelPosition: 'upper-right',
              groupTextLabelField: 'name'
            });

            result.content.group = groupsArrayClone[groupsArrayClone.length - 1].id;
            newGroupAdded = true;
          } else { // then add subsequent points to newly created group
            result.content.group = groupsArrayClone[groupsArrayClone.length - 1].id;
          }
        }
      } else {
        const matchedGroup = groupsArrayClone.filter(group => group.name === result.content.group.toString());
        if (matchedGroup.length > 0) { //group name already exists, add points to existing group ID
          result.content.group = matchedGroup[0].id;
          if(!_pointsInLegend){
            const targetIx = groupsArrayClone.findIndex(g => g.id === matchedGroup[0].id);
            groupsArrayClone[targetIx].pointsInLegend = _pointsInLegend;
          }
        } else { //group does not exist, create new group
          //remove target group if empty and set current group
          if (targetGroupPoints.length === 0) {
            groupsArrayClone = groupsArrayClone.filter(group => group.id !== this.props.currentGroup);
          }

          groupsArrayClone.push({
            id: GenerateRandomId(),
            order: groupsArrayClone.length + 1,
            name: result.content.group.toString(),
            useGroupStyle: false,
            useCustomLabel: false,
            groupTextColor: '#ffffff',
            groupIconName: 'circleIcon',
            groupIconColor: GetNewGroupColor(groupsArrayClone.length + 1),
            groupIconSize: 'default',
            startNumber: 1,
            visible: true,
            nameInLegend: true,
            pointsInLegend: _pointsInLegend,
            groupInLegend: true,
            icon: '',
            showLegendData: false
          });

          result.content.group = groupsArrayClone[groupsArrayClone.length - 1].id;
        }
      }
    });

    //if (this.props.groups.length !== groupsArrayClone.length)
      this.props.updateMapObj({ mapGroups: groupsArrayClone, currentGroup:  groupsArrayClone[groupsArrayClone.length - 1].id, noPointsInLegend: !_pointsInLegend });

    return resultsArray;
  }

  sortPointsByGroup(pointsArray) {
    pointsArray.sort((a, b) => (a.content.group > b.content.group) ? 1 : ((b.content.group > a.content.group) ? -1 : 0));
    return pointsArray;
  }

  selectChange(colName, event) {
    this.props.mapColumnNames(colName, event.target.value);
  }

  render() {
    var loadingClass = this.state.isLoading ? 'loaderShow' : 'loaderHide';
    var mainClass = this.state.isLoading ? 'loaderHide' : 'loaderShow';
    return (
      <div>
        <div className={loadingClass}>
          <div className="panel-loader"></div>
        </div>
        <div className={mainClass}>
          <div className="panel-body-small-text">
            {translateObj.mapColumnsDesc[this.props.translate]}
        </div>
          <div className="margin-t15 margin-b5">
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Name:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="name-select"
                  id="name-select"
                  key={"nameKey"}
                  value={this.props.nameColName}
                  onChange={(event) => this.selectChange('nameColName', event)}
                  inputRef={ref => { this.nameRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>

            {this.props.allColumns ?
            <div>
            
            {this.props.country !== 'OT' ? 
            <div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Address:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="address-select"
                  id="address-select"
                  key={"addressKey"}
                  value={this.props.addressColName}
                  onChange={(event) => this.selectChange('addressColName', event)}
                  inputRef={ref => { this.addressRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">City:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="city-select"
                  id="city-select"
                  value={this.props.cityColName}
                  onChange={(event) => this.selectChange('cityColName', event)}
                  key={"cityKey"}
                  inputRef={ref => { this.cityRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">{this.props.secyGeoName + ':'}</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="state-select"
                  id="state-select"
                  value={this.props.stateColName}
                  onChange={(event) => this.selectChange('stateColName', event)}
                  key={"stateKey"}
                  inputRef={ref => { this.stateRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Postal Code:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="postal-select"
                  id="postal-select"
                  value={this.props.postalColName}
                  onChange={(event) => this.selectChange('postalColName', event)}
                  key={"postalKey"}
                  inputRef={ref => { this.postalRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            {
              this.props.supergeo ? 
              <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Country:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="country-select"
                  id="country-select"
                  value={this.props.countryColName}
                  onChange={(event) => this.selectChange('countryColName', event)}
                  key={"countryKey"}
                  inputRef={ref => { this.countryRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            : null
            }
            </div>
            : null }
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Latitude:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="lat-select"
                  id="lat-select"
                  value={this.props.latColName}
                  onChange={(event) => this.selectChange('latColName', event)}
                  key={"latKey"}
                  inputRef={ref => { this.latRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Longitude:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="lng-select"
                  id="lng-select"
                  value={this.props.lngColName}
                  onChange={(event) => this.selectChange('lngColName', event)}
                  key={"lngKey"}
                  inputRef={ref => { this.lngRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>

            </div> : null }

            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Layer:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="group-select"
                  id="group-select"
                  value={this.props.groupColName}
                  onChange={(event) => this.selectChange('groupColName', event)}
                  key={"groupKey"}
                  inputRef={ref => { this.groupRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Legend Data:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="legenddata-select"
                  id="legenddata-select"
                  value={this.props.legenddataColName}
                  onChange={(event) => this.selectChange('legenddataColName', event)}
                  key={"legenddataKey"}
                  inputRef={ref => { this.legenddataRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Icon Label:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="label-select"
                  id="label-select"
                  value={this.props.labelColName}
                  onChange={(event) => this.selectChange('labelColName', event)}
                  key={"labelKey"}
                  inputRef={ref => { this.labelRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
          </div>
          <div style={buttonRow}>
            <button
              className="geocodeBtn"
              onClick={this.props.togglePanels.bind(this, true, false)}
            >
              {translateObj.backBtn[this.props.translate]}
            </button>
            <button
              className="geocodeBtn"
              onClick={this.geocode}
            >
              {translateObj.geocodeBtn[this.props.translate]}
            </button>
          </div>
          {this.state.showError ?
            <div className="errorMsgDiv">
              {translateObj.oopsWrongTryAgain[this.props.translate]}
          </div>
            : null}
        </div>
      </div>
    );
  }
}

export default MapColumns;