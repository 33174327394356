export function GenerateRandomId() {
  const newID = Math.random().toString(36).substring(3, 14) + Math.random().toString(36).substring(3, 14);
  return newID;

  // TODO: check for randomness among existing IDs?
}

export function getBoundingBoxAndCenterFromPosition(position) {
  const maxy = (position.reduce((prev,current) =>(prev.lat > current.lat) ? prev : current)).lat;
  const miny = (position.reduce((prev,current) =>(prev.lat < current.lat) ? prev : current)).lat;

  const maxx = (position.reduce((prev,current) =>(prev.lng > current.lng) ? prev : current)).lng;
  const minx = (position.reduce((prev,current) =>(prev.lng < current.lng) ? prev : current)).lng;

  const centx = (maxx + minx)/2;
  const centy = (maxy + miny)/2;

  return {
    maxy: maxy,
    miny: miny,
    maxx: maxx,
    minx: minx,
    centx: centx,
    centy: centy
  }

}

export function GetNewGroupColor(order) {
  switch (order) {
    case 1:
      return '#0c9ed9'; //medium blue
    case 2:
      return '#fa6609'; //orange
    case 3:
      return '#9c45ae'; //purple
    case 4:
      return '#25408f'; //dark blue
    case 5:
      return '#ed1b34'; //red
    case 6:
      return '#ffd400'; //yellow
    case 7:
      return '#c3e6ff'; //light blue
    case 8:
      return '#2ab6a9'; //teal
    case 9:
      return '#4a4a4d'; //dark gray
    case 10:
      return '#cccdd5'; //light gray
    default:
      return '#0c9ed9'; //medium blue
  }
}

export function getLabelSize(lbl) {
  let element = document.createElement('span');
  const fontWeight = lbl.content.weight ? 'bold' : 'normal';
  element.style.fontSize = lbl.content.fontSize + "px";
  element.style.fontWeight = fontWeight;
  element.style.fontFamily = '"' + lbl.content.font + '"';
  element.style.textShadow = '-1px -1px 0px ' + lbl.content.stroke + ',1px -1px 0px ' + lbl.content.stroke + ',-1px 1px 0px ' + lbl.content.stroke + ',1px 1px 0px ' + lbl.content.stroke;
  element.style.whiteSpace = 'nowrap';
  element.style.height = 'auto';
  element.style.width = 'auto';
  element.style.position = 'absolute';
  element.style.visibility = 'hidden';
  element.innerHTML = lbl.content.text;
  document.body.appendChild(element);
  let labelwidth = element.clientWidth;
  let labelheight = element.clientHeight;
  document.body.removeChild(element);
  return [labelwidth,labelheight];
}


export function getGeoAccColor(content) {
  const acc = content?.accuracy || null;
  if(acc===null){
    return '#cccdd5'
  }
  return getGeoAccColorFromResult(acc);
}

export function getGeoAccColorFromResult(acc) {
  const qScore = process.env.REACT_APP_QUESTIONABLE_SCORE;
  const gScore = process.env.REACT_APP_GOOD_SCORE;

  const goodTypes = process.env.REACT_APP_GOOD_TYPES.split(',');
  const userTypes = ['UserClick','UserMove','Coordinates'];

  const score = (acc.score || acc.Score || 0);
  const type = (acc.match_type || acc.Addr_type || '');

  if(userTypes.includes(type))
    return '#cccdd5';
  else if(!goodTypes.includes(type))
    return '#ed1b34';
  else if(score > gScore)
    return '#339088';
  else if(score > qScore)
    return '#ffd400';
  else
    return '#ed1b34';
}

export function getPointTextLabelOffset(point) {
  const offset = GetIconOffset(point.content.icon.options.html,point.content.pointIconName);
  const lbloffset = getLabelPopupIconOffset({
    content:{
      fontSize: point.content.pointTextLabelFontSize,
      font: point.content.pointTextLabelFont,
      stroke: point.content.pointTextLabelStroke,
      offsetAnchor: offset,
      offsetPosition: point.content.pointTextLabelPosition,
      text: point.content[point.content.pointTextLabelField]
    }
  });

  return lbloffset;
}

export function getLabelPopupIconOffset(label) {
  const labelsize = getLabelSize(label);
  let labelwidth = labelsize[0];
  let labelheight = labelsize[1];

  let iconAnchor = [labelwidth/2, labelheight/2];

  if(label.content.offsetAnchor){
    iconAnchor = label.content.offsetAnchor;
  }
  const position = label.content.offsetPosition || null;

  if(position === 'center'){
    iconAnchor = [0,0];
  }
  else if(position === 'center-left') {
    iconAnchor = [(iconAnchor[0] + labelwidth/2) * - 1.1, 0]
  }
  else if(position === 'center-right') {
    iconAnchor = [(iconAnchor[0] + labelwidth/2) * 1.1, 0]
  }
  else if(position === 'upper-right') {
    iconAnchor = [(iconAnchor[0] + labelwidth/2) * 1.1, iconAnchor[1] * -1.3]
  }
  else if(position === 'upper-center'){
    iconAnchor = [0, iconAnchor[1] * -1.3]
  }
  else if(position === 'upper-left' ){
    iconAnchor = [(iconAnchor[0] + labelwidth/2) * - 1.1, iconAnchor[1] * -1.3];
  }
  else if(position === 'lower-right' ){
    iconAnchor = [(iconAnchor[0] + labelwidth/2) * 1.1, iconAnchor[1] * 1.3]; 
  }
  else if(position === 'lower-center'){
    iconAnchor = [0, iconAnchor[1] * 1.3]; 
  }
  else if(position === 'lower-left'){
    iconAnchor = [(iconAnchor[0] + labelwidth/2) * - 1.1, iconAnchor[1] * 1.3]
  }
  return iconAnchor;
}

export function getLabelIconOffset(label) {
  const labelsize = getLabelSize(label);
  let labelwidth = labelsize[0];
  let labelheight = labelsize[1];

  let iconAnchor = [labelwidth/2, labelheight/2];

  if(label.content.offsetAnchor){
    iconAnchor = label.content.offsetAnchor;
  }
  const position = label.content.offsetPosition || null;

  if(position === 'center'){
    iconAnchor = [labelwidth/2, labelheight/2]; //[0,0]
  }
  else if(position === 'center-left') {
    iconAnchor = [(labelwidth) + iconAnchor[0], labelheight/2]
  }
  else if(position === 'center-right') {
    iconAnchor = [iconAnchor[0] * -1, labelheight/2]
  }
  else if(position === 'upper-right') {
    iconAnchor = [iconAnchor[0] * -1, iconAnchor[1] * 2]
  }
  else if(position === 'upper-center'){
    iconAnchor = [labelwidth/2, iconAnchor[1] * 2]
  }
  else if(position === 'upper-left' ){
    iconAnchor = [(labelwidth) + iconAnchor[0], iconAnchor[1] * 2];
  }
  else if(position === 'lower-right' ){
    iconAnchor = [iconAnchor[0] * -1, (labelheight/2) * -1]; 
  }
  else if(position === 'lower-center'){
    iconAnchor = [labelwidth/2, (labelheight/2) * -1]; 
  }
  else if(position === 'lower-left'){
    iconAnchor = [(labelwidth) + iconAnchor[0], (labelheight/2) * -1]
  }
  return iconAnchor
}

export function GetIconOffset(html, iconName) {
  const htmlHeight = _getHtmlHeight(html);
  const htmlViewBox = _getHtmlViewBox(html);
  const aspectRatio = _getAspectRatio(htmlViewBox);

  const htmlWidth = Math.round(htmlHeight * aspectRatio);
  let heightFinal = htmlHeight / 2;

  if (iconName === 'teardropIcon') {
    heightFinal = htmlHeight;
  }

  return [Math.round(htmlWidth / 2), Math.round(heightFinal)];
}

export function ResizeLogo(html, size) {
  const htmlHeight = _getHtmlHeight(html);
  const newSize = size || 100;
  const resizeHeight = htmlHeight * (newSize/100);
  const newFullColorSvg = html.replace(/height=".*?"/, 'height="' + resizeHeight + 'px"');
  return newFullColorSvg;
}

export function GetIconWidth(html) {
  const htmlHeight = _getHtmlHeight(html);
  const htmlViewBox = _getHtmlViewBox(html);
  const aspectRatio = _getAspectRatio(htmlViewBox);

  const htmlWidth = Math.round(htmlHeight * aspectRatio);
  return htmlWidth;
}

export function GetLegendIconOffset(point, maxWidth) {
  const pointWidth = GetIconWidth(point.content.icon.options.html);
  const difference = maxWidth - pointWidth;
  const offset = (difference / 2).toString() + 'px';
  return offset;
}

function _getHtmlHeight(html) {
  let htmlHeight = html.match(/height=".*?"/);
  htmlHeight = htmlHeight[0].toLowerCase();
  htmlHeight = htmlHeight.split('"');
  htmlHeight = htmlHeight[1].replace('px', '');
  htmlHeight = parseInt(htmlHeight);

  return htmlHeight;
}

function _getHtmlViewBox(html) {
  let htmlViewBox = html.match(/viewBox=".*?"/);
  htmlViewBox = htmlViewBox[0].split('"');
  htmlViewBox = htmlViewBox[1].split(' ');

  return htmlViewBox;
}

function _getAspectRatio(viewBox) {
  //viewbox width divided by height
  const aspectRatio = parseInt(viewBox[2]) / parseInt(viewBox[3]);
  return aspectRatio;
}

export function GetIconColor(groups, point, legend) {
  const group = legend ? point.id : groups.filter(group => group.id === point.content.group);
  const useGroupStyle = legend ? false : group[0].useGroupStyle;
  if (useGroupStyle === true) {
    return group[0].groupIconColor;
  } else {
    const pointColor = legend ? point.groupIconColor : point.content.pointColor;
    return pointColor;
  }
}

export function GetIconName(groups, point, legend) {
  const group = legend ? point.id : groups.filter(group => group.id === point.content.group);
  const useGroupStyle = legend ? false : group[0].useGroupStyle;
  if (useGroupStyle === true) {
    return group[0].groupIconName;
  } else {
    const pointName = legend ? point.groupIconName : point.content.pointIconName;
    return pointName;
  }
}

export function GetTextColor(groups, point, layerNameLegend) {
  const group = groups.filter(group => group.id === (layerNameLegend ? point.id : point.content.group));
  const useGroupStyle = layerNameLegend ? true : group[0].useGroupStyle;
  if (useGroupStyle === true) {
    if (layerNameLegend) {
      if (group[0].groupIconName === 'teardropIcon' || group[0].groupIconName === 'circleIcon' || group[0].groupIconName === 'starIcon' || group[0].groupIconName === 'diamondIcon' || group[0].groupIconName === 'squareIcon') {
        return 'transparent';
      } else {
        return group[0].groupTextColor;
      }
    } else {
      return group[0].groupTextColor;
    }
  } else if (useGroupStyle === false) {
    const pointTextFill = point.content.pointTextColor;
    return pointTextFill;
  } else {
    return '#ffffff';
  }
}

export function GetIconSize(groups, point, legend) {
  const group = legend ? point.id : groups.filter(group => group.id === point.content.group);
  const useGroupStyle = legend ? false : group[0].useGroupStyle;
  if (useGroupStyle === true) {
    return group[0].groupIconSize;
  } else {
    const pointTextFill = legend ? point.groupIconSize : point.content.pointIconSize;
    return pointTextFill;
  }
}

export function GetSVGLabel(groups, point, layerNameLegend) {
  const group = groups.filter(group => group.id === (layerNameLegend ? point.id : point.content.group));
  const useCustomLabel = layerNameLegend ? true : group[0].useCustomLabel;
  if (useCustomLabel === true) {
    if (layerNameLegend) {
      //not sure yet
    } else {
      return point.content.label;
    }
  } else {
    if (point.content.useCustomLabel) {
      return point.content.label;
    } else {
      return point.content.order;
    }
  }
}

export function GetGroupIconColor(groups, currentGroup) {
  const group = groups.filter(group => group.id === currentGroup);
  return group[0].groupIconColor;
}

export function GetGroupIconName(groups, currentGroup) {
  const group = groups.filter(group => group.id === currentGroup);
  return group[0].groupIconName;
}

export function GetGroupTextColor(groups, currentGroup) {
  const group = groups.filter(group => group.id === currentGroup);
  return group[0].groupTextColor;
}

export function GetGroupIconSize(groups, currentGroup) {
  const group = groups.filter(group => group.id === currentGroup);
  return group[0].groupIconSize;
}

export function GetGroupName(groups, groupId) {
  const group = groups.filter(group => group.id === groupId);
  return group[0].name;
}

export function getRGB(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
    255
  ]
}

export function toRadians (degrees) {
  return degrees * Math.PI / 180;
};

export function toDegrees (radians) {
  return radians * 180 / Math.PI;
}


export function bearing (startLat, startLng, destLat, destLng) {
  startLat = toRadians(startLat);
  startLng = toRadians(startLng);
  destLat = toRadians(destLat);
  destLng = toRadians(destLng);

  let y = Math.sin(destLng - startLng) * Math.cos(destLat);
  let x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  let brng = Math.atan2(y, x);
  brng = toDegrees(brng);
  return (brng + 360) % 360;
}

export function cogo(x,y,angle,dist) {
  const xw = dist * Math.cos(angle * Math.PI / 180);
  const yw = dist * Math.sin(angle * Math.PI / 180);
  return [x + xw,y - yw]
}

export function distanceInKM(lat1, lon1, lat2, lon2, unit) {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") { dist = dist * 1.609344 }
    if (unit === "N") { dist = dist * 0.8684 }
    return dist;
  }
}

export function GetMeterDistanceForDT(polygonArray) {
  const minMaxObj = _getMaxMinLatLng(polygonArray);

  const distNSInKM = distanceInKM(minMaxObj.maxY, minMaxObj.minX, minMaxObj.minY, minMaxObj.minX, "K");
  const distEWInKM = distanceInKM(minMaxObj.maxY, minMaxObj.minX, minMaxObj.maxY, minMaxObj.maxX, "K");

  const distanceArray = [(distNSInKM * 1000), (distEWInKM * 1000)];

  //return distance in meters
  return distanceArray;
}

export function CheckBoundsToRadius(center, radius, minX, minY, maxX, maxY) {
  const swDist = distanceInKM(center.lat || center[0], center.lng || center[1], minY, minX, "K");
  const nwDist = distanceInKM(center.lat || center[0], center.lng || center[1], maxY, minX, "K");
  const seDist = distanceInKM(center.lat || center[0], center.lng || center[1], minY, maxX, "K");
  const neDist = distanceInKM(center.lat || center[0], center.lng || center[1], maxY, maxX, "K");
  let allInside = true;

  if ((swDist * 1000) > radius) {
    allInside = false;
  }

  if ((nwDist * 1000) > radius) {
    allInside = false;
  }

  if ((seDist * 1000) > radius) {
    allInside = false;
  }

  if ((neDist * 1000) > radius) {
    allInside = false;
  }

  return allInside;
}

export function GetGeoCenter(polygonArray) {
  const minMaxObj = _getMaxMinLatLng(polygonArray);

  return [(minMaxObj.minY + minMaxObj.maxY) / 2, (minMaxObj.minX + minMaxObj.maxX) / 2];
}

function _getMaxMinLatLng(polygonArray) {
  let latArray = [];
  let lngArray = [];

  for (const polygon of polygonArray) {
    for (const latLngPair of polygon) {
      latArray.push(latLngPair[0]);
      lngArray.push(latLngPair[1]);
    }
  }

  const maxY = Math.max(...latArray);
  const minY = Math.min(...latArray);
  const maxX = Math.max(...lngArray);
  const minX = Math.min(...lngArray);

  const minMaxObj = {
    maxY: maxY,
    minY: minY,
    maxX: maxX,
    minX: minX
  };

  return minMaxObj;
}

export function IsObjectEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }

  return true;
}

export function isNullorEmptyArray(obj) {
  if(obj===null)
    return true;
  if(obj===undefined)
    return true;
  if(Array.isArray(obj)){
    if(obj.length===0)
      return true;
  }
  return false;
}

export function IsNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isLongitude (n) {
  if(IsNumeric(n)){
    if(parseFloat(n)>-180 && parseFloat(n)<180)
      return true;
    else
      return false;
  }
  return false;
}

export function isLatitude (n) {
  if(IsNumeric(n)){
    if(parseFloat(n)>-90 && parseFloat(n)<90)
      return true;
    else
      return false;
  }
  return false;
}

export function GetRingRadius(ring) {
  let radius = ring.meterDistance[0] / 2;
  if (ring.labelDirection === 'left' || ring.labelDirection === 'right') {
    radius = ring.meterDistance[1] / 2;
  }

  return radius;
}

export function GetCurrentGroupOrPointRings(isGroup, currentPoint, ringClone, points, currentGroup) {
  let currentPointRings = isGroup ? [] : ringClone.filter(ring => ring.pointId === currentPoint.id);
  if (isGroup) { //if group, find any rings that belong to each point in the group
    const groupPoints = points.filter(point => point.content.group === currentGroup);
    let ringsInGroup = [];
    for (const ring of ringClone) { //get rings belonging to current group
      for (const point of points) {
        if (ring.pointId === point.content.id && point.content.group === currentGroup) {
          ringsInGroup.push(ring);
        }
      }
    }

    const distinctRadii = [...new Set(ringsInGroup.map(ring => ring.radius))]; //get unique radii
    let groupRings = [];
    for (const rad of distinctRadii) {
      const ringsInGroupWithRadius = ringsInGroup.filter(ring => ring.radius === rad);
      if (ringsInGroupWithRadius.length === groupPoints.length) {
        groupRings.push(ringsInGroupWithRadius[0]);
      }
    }
    currentPointRings = groupRings;
  }

  currentPointRings.forEach((ring) => {
    ring.autoLabel = true;
  });
  currentPointRings.sort((a, b) => (b.radius < a.radius) ? 1 : ((a.radius < b.radius) ? -1 : 0));
  return currentPointRings;
}

export function GetLongestStringLength(str) {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext("2d");
  ctx.font = '14px "Open Sans"';
  return ctx.measureText(str).width;
}

export function GetDataColumnPadding(point, longestStringPixels) {
  const currentStringLength = GetLongestStringLength(point.content.name);
  const paddingPixels = longestStringPixels - currentStringLength;
  return paddingPixels + 8;
}

export function ReplaceKeys(object) {
  Object.keys(object).forEach(function (key) {
    var newKey = key.trim();
    if (object[key] && typeof object[key] === 'object') {
      ReplaceKeys(object[key]);
    }
    if (key !== newKey) {
      object[newKey] = object[key];
      delete object[key];
    }
  });
}

export function GetMapPadding(mapRef, maskBounds) {
  let xPadding = 0;
  let yPadding = 0;
  const pixelBounds = mapRef.getPixelBounds();
  const pixelBoundsArray = [Math.round(pixelBounds.max.x - pixelBounds.min.x), Math.round(pixelBounds.max.y - pixelBounds.min.y)];

  if (maskBounds) {
    xPadding = (pixelBoundsArray[0] - maskBounds[0]) / 2;
    yPadding = (pixelBoundsArray[1] - maskBounds[1]) / 2;
  }

  const paddingArray = [xPadding, yPadding];
  return paddingArray;
}

export function GetUpdatedZIndex(groups, points) {
  const pointClone = Array.from (points);
  for (const point of pointClone) {
    const pointGroup = groups.filter(group => group.id === point.content.group);
    point.content.zIndex = (pointGroup[0].order * -5000) + (point.content.order * 5);
  }
  return pointClone;
}


export function GetDriveTimeModeInteger(ring) {
  if (ring.type === 'driving_time') {
    return 1;
  }else if (ring.type === 'driving_distance') {
    return 2;
  } else if (ring.type === 'trucking_time') {
    return 3;
  } else if (ring.type === 'trucking_distance') {
    return 4;
  } else if (ring.type === 'walking_time') {
    return 5;
  } else if (ring.type === 'walking_distance') {
    return 6;
  } else {
    return 1;
  }
}

export function GetDriveTimeMode(ring,isIntl) {
  if (ring.type === 'driving_time') {
    return 'DriveTimeBufferMinutes';
  } else if (ring.type === 'driving_distance') {
    if(isIntl){
      return 'DriveTimeBufferKms';
    }
    return 'DriveTimeBufferMiles';
  } else if (ring.type === 'trucking_time') {
    return 'TruckingMinutes';
  } else if (ring.type === 'trucking_distance') {
    if(isIntl){
      return 'TruckingKms';
    }
    return 'TruckingMiles';
  } else if (ring.type === 'walking_time') {
    return 'WalkingMinutes';
  } else if (ring.type === 'walking_distance') {
    if(isIntl){
      return 'WalkingKms';
    }
    return 'WalkingMiles';
  } else {
    return 'DriveTimeBufferMinutes';
  }
}

export function CountDecimals(value) {
  let text = value ? value.toString() : '0';
  // verify if number 0.000005 is represented as "5e-6"
  if (text.indexOf('e-') > -1) {
    let [base, trail] = text.split('e-');
    let deg = parseInt(trail, 10);
    return deg;
  }
  // count decimals for number in representation like "0.123456"
  if (Math.floor(value) !== value) {
    return value.toString().split(".")[1].length || 0;
  }
  return 0;
}

export function pixelXYToLatLong(pixelX, pixelY, zoom) {  
  const mapSize = MapSize(zoom);  
  const x = (parseInt(pixelX) / mapSize) - 0.5;
  const y = 0.5 - (parseInt(pixelY) / mapSize);  
  const latitude = 90 - 360 * Math.atan(Math.exp(-y * 2 * Math.PI)) / Math.PI;  
  const longitude = 360 * x;  
  return [latitude,longitude];
}  


export function LatLongToPixelXY(latitude, longitude, zoom) {
  const x = (parseFloat(longitude) + 180) /360;
  const sinLatitude = Math.sin(parseFloat(latitude) * 3.14156 /180);
  const y = 0.5 - Math.log((1 + sinLatitude) / (1 - sinLatitude)) / (4 * 3.14156);
  const mapSize = MapSize(zoom);
  const pixelX = (x * mapSize + 0);//, 0, mapSize - 1);  
  const pixelY = (y * mapSize + 0); //, 0, mapSize - 1);  
  return [parseInt(pixelX), parseInt(pixelY)];
}

function MapSize(zoom) {
   const fZoom = parseFloat(zoom);
   const ret = 256 * Math.pow(2, fZoom);
   return ret;
}

export function GetFourCornerBounds(position) {
  const minLat = Math.min(...position.map(item => item.lat));
  const maxLat = Math.max(...position.map(item => item.lat));
  const minLng = Math.min(...position.map(item => item.lng));
  const maxLng = Math.max(...position.map(item => item.lng));

  const boundsObj = {
    ne: {lat: maxLat, lng: maxLng},
    nw: {lat: maxLat, lng: minLng},
    se: {lat: minLat, lng: maxLng},
    sw: {lat: minLat, lng: minLng}
  };

  return boundsObj;
}

export function GetCornerDirection(markerCoord, boundsObj) {
  let corner = 'ne';
  if (markerCoord.lat === boundsObj.nw.lat && markerCoord.lng === boundsObj.nw.lng) {
    corner = 'nw';
  } else if (markerCoord.lat === boundsObj.se.lat && markerCoord.lng === boundsObj.se.lng) {
    corner = 'se';
  } else if (markerCoord.lat === boundsObj.sw.lat && markerCoord.lng === boundsObj.sw.lng) {
    corner = 'sw';
  }

  return corner;
}

export function GetNewPositionArray(boundsObj, corner, dragCoord) {
  //replace max lat/lng based on new corner
  let minLat = boundsObj.sw.lat;
    let minLng = boundsObj.sw.lng;
    let maxLat = boundsObj.ne.lat;
    let maxLng = boundsObj.ne.lng;
    if (corner === 'ne') {
      maxLat = dragCoord.lat;
      maxLng = dragCoord.lng;
    } else if (corner === 'nw') {
      maxLat = dragCoord.lat;
      minLng = dragCoord.lng;
    } else if (corner === 'se') {
      minLat = dragCoord.lat;
      maxLng = dragCoord.lng;
    } else if (corner === 'sw') {
      minLat = dragCoord.lat;
      minLng = dragCoord.lng;
    }

    //get new position array
    let newPosition = [];
    newPosition.push({lat: minLat, lng: minLng});//sw
    newPosition.push({lat: maxLat, lng: minLng});//nw
    newPosition.push({lat: maxLat, lng: maxLng});//ne
    newPosition.push({lat: minLat, lng: maxLng});//se
    newPosition.push({lat: minLat, lng: minLng});//repeat sw

    return newPosition;
}

export function ReverseToLngLat(position, route) {
  let formattedArray = [];
  for (const coordinate of position[0]) {
    if (route) {
      formattedArray.push([coordinate[1], coordinate[0]]);
    } else {
      formattedArray.push([coordinate.lng, coordinate.lat]);
    }
  }

  formattedArray = [formattedArray];

  return formattedArray;
}

export function ReverseToLatLng(position, line, route) {
  let outerArray = [];
  let formattedArray = [];
  for (const poly of position) {
    if (line) {
      outerArray.push({lat: poly[1], lng: poly[0]});
    } else {
      for (const coordinate of poly) {
        if (route) {
          formattedArray.push([coordinate[1], coordinate[0]]);
        } else {
          formattedArray.push({lat: coordinate[1], lng: coordinate[0]});
        }
      }
      outerArray.push(formattedArray);
    }
  }

  return outerArray;
}

export function FormatShieldData(shieldData) {
  if (!shieldData || shieldData.length === 0)
    return [];
  
  let formattedArray = [];
  //format route label array - first each route label item; then semicolons
  for (const shieldItem of shieldData) {
    const splitArray = shieldItem.routelabelnew.split(';');
    for (const splitItem of splitArray) {
      formattedArray.push(splitItem);
    }
  }
  
  //make formatted array unique
  let uniqueArray = formattedArray.filter((item, i, ar) => ar.indexOf(item) === i);
  return uniqueArray;
}

export function GetShieldType(shieldInfo) {
  const shieldNameArray = shieldInfo.split(" ");
  if (shieldNameArray[0] && shieldNameArray[0].toLowerCase() === "i") {
    return "interstate";
  } else {
    if (shieldNameArray[0]) {
      return shieldNameArray[0].toLowerCase() + "hwy";
    } else {
      return "interstate";
    }
  }
}

export function GetShieldTypeManual(type) {
  if (type === "interstate") {
    return "i";
  } else {
    let newType = type.replace('hwy', '');
    return newType;
  }
}

export function GetShieldText(shieldInfo) {
  const shieldNameArray = shieldInfo.split(" ");
  return shieldNameArray[1] || '';
}

export function GetFallbackCoords(country) {
  switch (country) {
    case 'CA':
      return {
        x: -100,
        y: 54.667
      };
    case 'AUS':
      return {
        x: 151.2036,
        y: -33.8794
      };
    case 'JPN':
      return {
        x: 139.65,
        y: 35.66
      };
    default:
      return {
        x: 0,
        y: 0
      };
  }
}

export const InitialMapZoom = (country, x) => {
  if (x === 0) {
    return 2.5;
  }
  else if (x === -100) {
    return 4;
  }
  switch (country) {
    case 'US':
      return 14;
    case 'CA':
      return 14;
    case 'AUS':
      return 10;
    case 'JPN':
      return 8;
    default:
      return 4;
  }
};