import React from 'react';

import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';
import Tooltip from '@material-ui/core/Tooltip';

class LabelPosition extends React.Component {
  constructor(props) {
    super(props);
  }
  
  setIconOffset = (e) => {
    this.props.setPosition(e);
  }

  render() {
    return (
        <div className="legendPositionOptsDiv">
          <div className="padding-6b paddingT8">Label Position</div>
          <div className="groupNameCompDiv">
            <Tooltip title="Top left" placement="bottom">
              <span onClick={this.setIconOffset.bind(this, 'upper-left')}
                className={this.props.labelPosition === 'upper-left' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                <SVGIconComponent name="upperLeftIcon" />
              </span>
            </Tooltip>
            <Tooltip title="Top Center" placement="bottom">
              <span onClick={this.setIconOffset.bind(this, 'upper-center')}
                className={this.props.labelPosition === 'upper-center' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                <SVGIconComponent name="upperCenterIcon" />
              </span>
            </Tooltip>
            <Tooltip title="Top right" placement="bottom">
              <span onClick={this.setIconOffset.bind(this, 'upper-right')} 
                className={this.props.labelPosition === 'upper-right' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                <SVGIconComponent name="upperRightIcon" />
              </span>
            </Tooltip>
          </div>
          <div className="groupNameCompDiv paddingT8">
            <Tooltip title="Center left" placement="bottom">
              <span onClick={this.setIconOffset.bind(this, 'center-left')}
                className={this.props.labelPosition === 'center-left' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                <SVGIconComponent name="centerLeftIcon" />
              </span>
            </Tooltip>
            <Tooltip title="Center" placement="bottom">
              <span onClick={this.setIconOffset.bind(this, 'center')}
                className={this.props.labelPosition === 'center' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                <SVGIconComponent name="centerIcon" />
              </span>
            </Tooltip>
            <Tooltip title="Center right" placement="bottom">
              <span onClick={this.setIconOffset.bind(this, 'center-right')} 
                className={this.props.labelPosition === 'center-right' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                <SVGIconComponent name="centerRightIcon" />
              </span>
            </Tooltip>
          </div>
          <div className="groupNameCompDiv paddingT8">
            <Tooltip title="Bottom left" placement="bottom">
              <span onClick={this.setIconOffset.bind(this, 'lower-left')} 
                className={this.props.labelPosition === 'lower-left' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                <SVGIconComponent name="lowerLeftIcon" />
              </span>
            </Tooltip>
            <Tooltip title="Bottom Center" placement="bottom">
              <span onClick={this.setIconOffset.bind(this, 'lower-center')}
                className={this.props.labelPosition === 'lower-center' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>
                <SVGIconComponent name="lowerCenterIcon" />
              </span>
            </Tooltip>
            <Tooltip title="Bottom right" placement="bottom">
              <span onClick={this.setIconOffset.bind(this, 'lower-right')}
                 className={this.props.labelPosition === 'lower-right' ? 'legendPositionBtn legendPositionBtnActive' : 'legendPositionBtn legendPositionBtnInactive'}>                
                <SVGIconComponent name="lowerRightIcon" />
              </span>
            </Tooltip>
          </div>
          </div> 
              );
            }
          }
          
export default LabelPosition;
          