import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import translateObj from '../../../../utils/translate';

class DeleteGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevPoints: this.props.points
    }

    this.closePanel = this.closePanel.bind(this);
    this.deleteCurrentGroupPoints = this.deleteCurrentGroupPoints.bind(this);
    this.deleteCurrentGroup = this.deleteCurrentGroup.bind(this);
  }

  componentDidUpdate() {
    if (this.state.prevPoints.length > this.props.points.length) {
      this.deleteCurrentGroup();
    }
  }

  closePanel() {
    this.props.toggleDeleteGroup(false, this.props.currentGroup);
  }

  deleteCurrentGroupPoints() {
    const pointsArrayClone = Array.from(this.props.points);
    const newPointsClone = pointsArrayClone.filter(point => point.content.group !== this.props.currentGroup);
    const groupPoints = pointsArrayClone.filter(point => point.content.group === this.props.currentGroup);

    //delete any rings and routes associated with deleted points
    let ringsArrayClone = Array.from(this.props.rings);
    ringsArrayClone = ringsArrayClone.filter(item => !groupPoints.find(point => (point.content.id === item.pointId)));
    let routesArrayClone = Array.from(this.props.driveTimes);
    routesArrayClone = routesArrayClone.filter(item => !groupPoints.find(point => (point.content.id === item.pointId)));

    const legendClone = Array.from(this.props.legend);
    if (newPointsClone.length === 0 && this.props.customLayerCount === 0) {
      legendClone[0].visible = false;
    }

    if (groupPoints.length === 0) {
      this.deleteCurrentGroup();
    } else {
      this.props.updateMapObj({ mapPoints: newPointsClone, rings: ringsArrayClone, driveTimes: routesArrayClone });
    }
  }

  deleteCurrentGroup() {
    const groupsArrayClone = Array.from(this.props.groups);
    let newGroupsClone = groupsArrayClone;
    if (groupsArrayClone.length !== 1) { //don't filter and remove group if the map only has one group
      newGroupsClone = groupsArrayClone.filter(group => group.id !== this.props.currentGroup);
    }

    this.props.updateMapObj({ mapGroups: newGroupsClone });

    const newCurrentGroup = this.getNewCurrentGroup(newGroupsClone);
    this.props.updateMapObj({ currentGroup: newCurrentGroup }); //consolidate with update above?

    this.props.toggleDeleteGroup(false, 'none');
  }

  getNewCurrentGroup(newGroupsClone) {
    const newCurrentGroup = newGroupsClone[0].id;
    return newCurrentGroup;
  }

  render() {
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="squaresIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
              <path id="Path_4187" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M24.29-4.79v-31.3h-60v60h31.3" transform="translate(36.96 37.34)" />
              <path id="Path_4188" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M-4.65,23.67V55.91H56.29V-5H24.05" transform="translate(36.96 37.34)" />
              <path id="Path_4189" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M3.5,23.76h-8v-8" transform="translate(36.96 37.34)" />
              <path id="Path_4191" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M-4.5,3.12v-8h8" transform="translate(36.96 37.34)" />
              <path id="Path_4193" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M16.14-4.88h8v8" transform="translate(36.96 37.34)" />
              <path id="Path_4195" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M24.14,15.76v8h-8" transform="translate(36.96 37.34)" />
            </svg>
          </span>
          <span>{translateObj.deleteLayerTitle[this.props.translate]}</span>
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          {translateObj.areYouSure[this.props.translate]}
          <div className="geocodeDiv flex">
            <button
              className="geocodeBtn"
              onClick={this.closePanel}
            >
              {translateObj.cancelBtn[this.props.translate]}
            </button>
            <button
              className="geocodeBtn"
              onClick={this.deleteCurrentGroupPoints}
            >
              {translateObj.deleteBtn[this.props.translate]}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteGroup;