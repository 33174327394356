import React from 'react';
import ThematicColorPickerCell from './ThematicColorPickerCell';

const colorPickerDiv = {
  width: '120px',
}

const colorPickerRow = {
  display: 'flex',
  flex: 3,
  alignItems: 'center'
}

class ThematicColorPicker extends React.Component {
  

  render() {
    return (
      <div style={colorPickerDiv}>
       <div style={colorPickerRow}>
         <ThematicColorPickerCell 
             fillColors='d3d9e9,a8b3d2,7c8cbc,5166a5,25408f'
             handleCellClick={this.props.handleCellClick}
           />
         </div>

        <div style={colorPickerRow}>
        {/* pri blues */}
        <ThematicColorPickerCell 
            fillColors='c3e6ff,4d93ff,1c54f4,25408f,000759'
            handleCellClick={this.props.handleCellClick}
          />
        </div>

        <div style={colorPickerRow}>
        {/* blue sampler */}
        <ThematicColorPickerCell 
             fillColors='d4eff8,b7e4f4,6dc5e8,0c9ed9,25408f'
             handleCellClick={this.props.handleCellClick}
        />
        </div>

        <div style={colorPickerRow}>
        {/* secy blues */}
        <ThematicColorPickerCell 
            fillColors='dbe5ff,acbbe8,7b8bbd,56648f,353e59'
            handleCellClick={this.props.handleCellClick}
          />
        </div>

        <div style={colorPickerRow}>
        {/* teal */}
        <ThematicColorPickerCell   
             fillColors='d4f0ee,aae2dd,7fd3cb,55c5ba,2ab6a9'
             handleCellClick={this.props.handleCellClick}
        />
        </div>

        <div style={colorPickerRow}>
        {/* purple */}
        <ThematicColorPickerCell   
             fillColors='ebdaef,d7b5df,c48fce,b27fb2,b06abe'
             handleCellClick={this.props.handleCellClick}
        />
        </div>

        <div style={colorPickerRow}>
        {/* reds */}
        <ThematicColorPickerCell   
             fillColors='fbd1d6,f8a4ae,f47685,f1495d,ed1b34'
             handleCellClick={this.props.handleCellClick}
        />
        </div>
        <div style={colorPickerRow}>
        {/* yellow to red */}
        <ThematicColorPickerCell  
             fillColors='fff6cc,ffd400,fa6609,f1495d,ed1b34'
             handleCellClick={this.props.handleCellClick}
           />
        </div>
        <div style={colorPickerRow}>
        {/* red to greeen */}          
         <ThematicColorPickerCell 
             fillColors='ed1b34,f47685,fff6cc,7fd3cb,2ab6a9'
             handleCellClick={this.props.handleCellClick}
           />
        </div>
        <div style={colorPickerRow}>
        {/* 5 color */}
          <ThematicColorPickerCell 
            fillColors='c3e6ff,2ab6a9,ffd400,fa6609,ed1b34'
            handleCellClick={this.props.handleCellClick}
          />
        </div>
      </div>
    );
  }
}

export default ThematicColorPicker;