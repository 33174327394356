import React, { Component } from 'react';
import SVGIconComponent from '../../MainPage/SVGIconComponent/SVGIconComponent';

export default class ReleaseNotes2Pt1 extends Component {
  render() {
    return (
      <div>
        <div className="updateMenuBody">
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Shields tool</div>
          </div>
          <div className="updateMenuFeatureDesc">Ability to manually add highway shields.
                  Select the highway icon from the upper right toolbar.  Click on a highway to automatically add a shield, or select the <span className="updateHighlightSpan">Manual Mode</span> checkbox to add a custom shield.  Click on an existing shield to update the style.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Label tool</div>
          </div>
          <div className="updateMenuFeatureDesc">Add custom labels to a map by clicking the <span className="updateHighlightSpan">Tt</span> button in the upper right toolbar.  Choose fonts, size, and colors.  Click on an existing label to update the style.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Revised basemap picker</div>
          </div>
          <div className="updateMenuFeatureDesc">Compressed list of available basemaps, with checkboxes for No Labels, No Shields, and Roads Only.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">NAIP replaces USGS Imagery</div>
          </div>
          <div className="updateMenuFeatureDesc">Instead of USGS Imagery as a basemap option, BOOM now offers NAIP imagery.  This will improve printing performance and elimnate some printing timeouts.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Enhanced geocoding</div>
          </div>
          <div className="updateMenuFeatureDesc">More accurate geocoding, including better fallbacks to state or city centroids for unrecognized addresses.
                </div>
        </div>
      </div>
    );
  }
}