import React from 'react';

const colorPickerCell = {
  flex: 1,
  cursor: 'pointer'
}

const indCell = {
    paddingTop: 5,
    paddingLeft: 2,
    float: 'left',
}



class ThematicColorPickerCell extends React.Component {

  render() {
    const cellFills = this.props.fillColors || 'transparent';
    const cellFill = cellFills.split(",");

    return (
      <div style={colorPickerCell} onClick={this.props.handleCellClick.bind(this, cellFills)}>
          <div style={indCell}>
          <svg height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <rect fill={'#' + cellFill[0]} width="90" height="90" rx="15" x="5" y="5" stroke="#959595" strokeWidth="10" />
          </svg>
          </div>
          <div style={indCell}>
          <svg height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <rect fill={'#' +cellFill[1]} width="90" height="90" rx="15" x="5" y="5" stroke="#959595" strokeWidth="10" />
          </svg>
          </div>
          <div style={indCell}>
          <svg height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <rect fill={'#' +cellFill[2]} width="90" height="90" rx="15" x="5" y="5" stroke="#959595" strokeWidth="10" />
          </svg>
          </div>
          <div style={indCell}>
          <svg height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <rect fill={'#' +cellFill[3]} width="90" height="90" rx="15" x="5" y="5" stroke="#959595" strokeWidth="10" />
          </svg>
          </div>
          <div style={indCell}>
          <svg height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <rect fill={'#' +cellFill[4]} width="90" height="90" rx="15" x="5" y="5" stroke="#959595" strokeWidth="10" />
          </svg>
          </div>
      </div>
    );
  }
}

export default ThematicColorPickerCell;