import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Modal from '@material-ui/core/Modal';
import FAQComponent from '../FAQComponent/FAQComponent';
import VideoComponent from '../VideoComponent/VideoComponent';
import ReportListComponent from '../ReportListComponent/ReportListComponent';
import MethodologyComponent from '../MethodologyComponent/MethodologyComponent';

const mainTextDiv = {
  color: '#4a4a4d'
}

class SupportMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalClosed: true,
      modalSrc: ''
    }

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalOpen(reportName) {
    const src = reportName;
    this.setState({
      modalOpen: true,
      modalClosed: false,
      modalSrc: src
    });
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
      modalClosed: true
    });
  }

  openBoomTutorial() {
    window.open('https://portal.atlas.colliers.com/portal/apps/Cascade/index.html?appid=1aae28a145b3438b9c57b28e58bf4ab9', '_blank');
  }

  render() {
    return (
      <div style={mainTextDiv}>
        <div className="reportsAccordionDiv">
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.modalOpen}
            onClose={this.handleModalClose}
          >
            <div className="modalStyle">
              <div className="modalCloseDiv">
                <button onClick={this.handleModalClose} className="modalCloseButton">X</button>
              </div>
              <iframe className="modalIframe" title="sample report" src={this.state.modalSrc} frameBorder="0"></iframe>
            </div>
          </Modal>

          <div className="panel-divider"></div>

          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1b-content" className="sectionHeader" id="panel1b-header">
              Training materials
            </AccordionSummary>
            <AccordionDetails>
              <div className="method-container">
                <div className="method-grid-row">                  
                  <div className="method-grid-item">
                    <div className="method-grid-item-container">
                      <span className="blueTitle">Boom Tutorial</span>
                      <div className="summaryTxt">
                        <div className="video-style pointer" onClick={this.openBoomTutorial}>
                          <img style={{ width: '390px' }}
                            src="https://colliers-boom-docs.s3.us-east-2.amazonaws.com/BoomTutorialCover.PNG"
                            alt="BOOM tutorial page"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <VideoComponent
                    title={'BOOM Overview Demo'}
                    src="https://colliers-boom-docs.s3.us-east-2.amazonaws.com/Boom+Demo+Video+1.mov"
                  />
                </div>
                <div className="method-grid-row">
                  <VideoComponent
                    title={'BOOM Legend Demo'}
                    src="https://colliers-boom-docs.s3.us-east-2.amazonaws.com/BOOM+Legend+Video.mov"
                  />
                  <VideoComponent
                    title={'BOOM Training 4/1/21'}
                    src="https://colliers-boom-docs.s3.us-east-2.amazonaws.com/BOOM+2+Training+1.mp4"
                  />
                </div>
                <div className="method-grid-row">
                  <VideoComponent
                    title={'BOOM Logo Demo'}
                    src="https://colliers-boom-docs.s3.us-east-2.amazonaws.com/BOOM+Logo+Video.mov"
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

            <div className="panel-divider"></div>

            <Accordion square>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1f-content" className="sectionHeader" id="panel1f-header">
                FAQs
            </AccordionSummary>
              <AccordionDetails>
                <div className="method-container">
                  <div className="method-grid-row">
                    <FAQComponent
                      id="a"
                      question="How do I get access to BOOM?"
                      answer="All Colliers professionals have access to BOOM.  You can go directly to BOOM using boom.colliers.com, or you can also find it on my.colliers, the USA Colliers Hub, and soon on Colliers Atlas."
                    ></FAQComponent>
                    <FAQComponent
                      id="b"
                      question="Is BOOM supported on all internet browsers?"
                      answer="Chrome is preferred, however currently BOOM is supported on Chrome and Firefox browsers.  We are working on implementing support on Safari."
                    ></FAQComponent>
                  </div>
                  <div className="method-grid-row">
                    <FAQComponent
                      id="c"
                      question="Can you type an intersection instead of an address, and what is the correct format for entering intersections?"
                      answer="Yes you can enter an intersection while using the ‘address’ method to enter a location.  Separate the two streets with either ‘&amp;’ or ‘and’.  It’s always best if the street names are entered complete with directional and suffix."
                    ></FAQComponent>
                    <FAQComponent
                      id="d"
                      question="What is the correct format when I’m entering an address?"
                      answer="Ideally you want to enter as complete a street address as possible including directional prefix and street type suffix.  BOOM will often be able to figure out or give you choices based on an incomplete entry, but for best results be as complete as possible."
                    ></FAQComponent>
                  </div>
                  <div className="method-grid-row">
                    <FAQComponent
                      id="e"
                      question="How can I share a BOOM map?"
                      answer="Once the map or project is saved, every BOOM map has an ID.  The ID can be shared with any Colliers professional, and it can be entered into the text box at the bottom of the History panel in BOOM."
                    ></FAQComponent>
                    <FAQComponent
                      id="f"
                      question="The labels and shields in my printed map do not always match what I see in BOOM.  Why?"
                      answer="The map image you see on your screen in BOOM is 92 dpi resolution, while the printed map is around 300 dpi.  This conversion allows for a high quality printed product, but occasionally it will affect the label placement and quantity due to the larger size of the printed map."
                    ></FAQComponent>
                  </div>
                  <div className="method-grid-row">
                    <FAQComponent
                      id="g"
                      question="Some of the basemap tiles are missing when I change a basemap or type an address.  How can I fix this issue?"
                      answer="To force tiles to render that are missing, grab the basemap and pan slightly in any direction.  That will refresh the basemap."
                    ></FAQComponent>
                    <FAQComponent
                      id="h"
                      question="Why don't drive time routes move when I move a point?"
                      answer="When you move a point in BOOM, any rings associated with that point will move with the point.  However, any drive time routes will not move.  BOOM cannot re-run the route on the fly, so route polygons will maintain their original location."
                    ></FAQComponent>
                  </div>
                  <div className="method-grid-row">
                    <FAQComponent
                      id="i"
                      question="What's the difference between saving a copy and updating a map?"
                      answer="If your map project has already been saved, you have the option to copy or update.  If you select Copy, that will create a new map id for your current project, and your existing map will be unchanged.  Any new changes that happened after your last save will not affect the old map id.  If you select Update, any new changes that happened after your last save will be applied to the current map id."
                    ></FAQComponent>
                    <FAQComponent
                      id="j"
                      question="What's the difference between map title and project name when I save a map?"
                      answer="The map title is what will appear on the pdf layout of your BOOM map.  The project name may or may not be the same as your map title, and it simply identifies the project to which the map belongs."
                    ></FAQComponent>
                  </div>
                  <div className="method-grid-row">
                    <FAQComponent
                      id="k"
                      question="What retailers have logos in BOOM?"
                      answer="The logos in BOOM are based on ChainXY data.  There are thousands of retail chains in ChainXY, and BOOM has over 800 of the largest retailers.  Logos are added regularly, and you can also request missing logos in the Add Logo panel in BOOM."
                    ></FAQComponent>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="panel-divider"></div>

            <Accordion square>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" className="sectionHeader" id="panel1c-header">
                Sample maps
            </AccordionSummary>
              <AccordionDetails>
                <div className="panel-divider"></div>
                <div>
                  <div className="method-grid-row">
                    <ReportListComponent
                      reportTitle={'Basic Aerial'}
                      reportDescription={' - A basic aerial in a Colliers branded layout using the Aerial basemap option.'}
                      handleClick={this.handleModalOpen.bind(this, 'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/BasicAerial1.pdf')}
                    />
                  </div>
                  <div className="method-grid-row">
                    <ReportListComponent
                      reportTitle={'Basic Hybrid'}
                      reportDescription={' - A hybrid aerial map in a Colliers branded layout using the Hybrid basemap option.'}
                      handleClick={this.handleModalOpen.bind(this, 'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/BasicHybrid1.pdf')}
                    />
                  </div>
                  <div className="method-grid-row">
                    <ReportListComponent
                      reportTitle={'Basic Street 1'}
                      reportDescription={' - A street map in a Colliers branded layout using the Colliers Grey basemap option.'}
                      handleClick={this.handleModalOpen.bind(this, 'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/BasicStreet1.pdf')}
                    />
                  </div>
                  <div className="method-grid-row">
                    <ReportListComponent
                      reportTitle={'Basic Street 2'}
                      reportDescription={' - A street map using the Colliers Grey - No Labels basemap option and no layout.'}
                      handleClick={this.handleModalOpen.bind(this, 'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/BasicStreet2.png')}
                    />
                  </div>
                  <div className="method-grid-row">
                    <ReportListComponent
                      reportTitle={'Basic Topo'}
                      reportDescription={' - A topographic map using the Colliers Topographic basemap option and no layout.'}
                      handleClick={this.handleModalOpen.bind(this, 'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/BasicTopo1.png')}
                    />
                  </div>
                  <div className="method-grid-row">
                    <ReportListComponent
                      reportTitle={'Employee Map'}
                      reportDescription={' - An employee map with a 20 minute drive time around a subject location.'}
                      handleClick={this.handleModalOpen.bind(this, 'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/EmployeeMap.pdf')}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="panel-divider"></div>

            <Accordion square defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1f-content" className="sectionHeader" id="panel1f-header">
                Release notes
              </AccordionSummary>
              <AccordionDetails>
              <div className="panel-divider"></div>
                  <div className="method-container">
                    <div className="padding5">
                      <MethodologyComponent
                        methodTitle="BOOM version 3.1 updates"
                        id="b"
                        buttonText="release3_1"
                        expanded={true}
                      />
                    </div>
                  </div>

                  <div className="panel-divider"></div>
                  <div className="method-container">
                    <div className="padding5">
                      <MethodologyComponent
                        methodTitle="BOOM version 3.0 updates"
                        id="b"
                        buttonText="release3_0"
                        expanded={false}
                      />
                    </div>
                  </div>

              <div className="panel-divider"></div>

                  <div className="method-container">
                    <div className="padding5">
                      <MethodologyComponent
                        methodTitle="BOOM version 2.2 updates"
                        id="b"
                        buttonText="release2_2"
                        expanded={false}
                      />
                    </div>
                  </div>
                <div className="panel-divider"></div>
                  <div className="method-container">
                    <div className="padding5">
                      <MethodologyComponent
                        methodTitle="BOOM version 2.1 updates"
                        id="b"
                        buttonText="release2_1"
                        expanded={false}
                      />
                    </div>
                  </div>
                <div className="panel-divider"></div>
                  <div className="method-container">
                    <div className="padding5">
                      <MethodologyComponent
                        methodTitle="BOOM version 2.0 updates"
                        id="b"
                        buttonText="release2_0"
                        expanded={false}
                      />
                    </div>
                  </div>
                <div className="panel-divider"></div>
                  <div className="method-container">
                    <div className="padding5">
                      <MethodologyComponent
                        methodTitle="BOOM version 1.2 updates"
                        id="a"
                        buttonText="release1_2"
                        expanded={false}
                      />
                    </div>
                  </div>
                <div className="panel-divider"></div>
              </AccordionDetails>
            </Accordion>
        </div>
        </div >
    );
  }
}

export default SupportMain;