import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import translateObj from '../../../utils/translate';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';

const EarthquakeLayerPanel = (props) => {
  const {
    mapObj,
    toggleEarthquakePanel,
    togglePanel,
    translate,
    updateMapObj,
    legend,
  } = props;

  const closePanel = () => {
    togglePanel("addData");
  };

  const backToAddData = () => {
    toggleEarthquakePanel(true, false);
  }

  const handleToggleLayer = (action) => {
    if (action === 'add') {
      const legendClone = Array.from(legend);
      legendClone[0].visible = true;
      updateMapObj({ earthquakeLayer: true, legend: legendClone });
    } else {
      let updateObj = {
        earthquakeLayer: false
      };
      if(mapObj.mapPoints.length===0){
        const legendClone = Array.from(legend);
        legendClone[0].visible = false;
        updateObj.legend = legendClone;
      }
      updateMapObj(updateObj);
    }
  };

  return (
    <div>
      <h2 className="panel-title">
        <span className="panel-icon-span">
          <SVGIconComponent name={'addDataBtnIcon'} color={'#0c9ed9'} size={'16px'} />
        </span>
        {translateObj.addEarthquakeTitle[translate]}
        <span className="panel-close closeStyle"><CloseIcon onClick={closePanel} fontSize="small" /></span>
      </h2>
      <div className="panel-body">
        <div className="panel-body-small-text">
          {translateObj.earthquakeLayerDesc[translate]}
        </div>
        <div className="geocodeDiv flex">
          <button
            className="geocodeBtn"
            onClick={backToAddData}
          >
             {translateObj.backBtn[translate]}
          </button>
          {mapObj.earthquakeLayer ?
            <button
              className="geocodeBtn"
              onClick={() => handleToggleLayer('remove')}
            >
              {translateObj.removeLayerBtn[translate]}
            </button>
            :
            <button
              className="geocodeBtn"
              onClick={() => handleToggleLayer('add')}
            >
              {translateObj.addLayerBtn[translate]}
            </button>
          }
        </div>
      </div>
    </div>
  );
}

export default EarthquakeLayerPanel;