import React from 'react';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import translateObj from '../../../../utils/translate';

class AddGrpPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTitle: ''
    }

    this.setTitle = this.setTitle.bind(this);
    this.saveGroupName = this.saveGroupName.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  setTitle(e) {
    this.setState({
      currentTitle: e.target.value
    });
  }

  saveGroupName() {
    let updateObject = {};
    if (this.state.currentTitle === '') {
      updateObject.currentTitle = "Layer " + (this.props.groups.length + 1);
    }

    this.setState(updateObject);

    this.props.saveAddGroup(this.state.currentTitle);
  }

  closePanel() {
    this.props.togglePanel('addPts');
  }

  render() {
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="squaresIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
              <path id="Path_4187" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M24.29-4.79v-31.3h-60v60h31.3" transform="translate(36.96 37.34)" />
              <path id="Path_4188" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M-4.65,23.67V55.91H56.29V-5H24.05" transform="translate(36.96 37.34)" />
              <path id="Path_4189" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M3.5,23.76h-8v-8" transform="translate(36.96 37.34)" />
              <path id="Path_4191" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M-4.5,3.12v-8h8" transform="translate(36.96 37.34)" />
              <path id="Path_4193" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M16.14-4.88h8v8" transform="translate(36.96 37.34)" />
              <path id="Path_4195" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M24.14,15.76v8h-8" transform="translate(36.96 37.34)" />
            </svg>
          </span>
          {translateObj.addGroupTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <div id="addGrpsDiv">
            <div>
              <div className="padding-6b">
                <TextField
                  id="groupNameInput"
                  label={translateObj.enterGroupName[this.props.translate]}
                  defaultValue={"Layer " + (this.props.groups.length + 1)}
                  variant="outlined"
                  onChange={e => this.setTitle(e)}
                  size="small"
                  fullWidth
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.saveGroupName()
                    }
                  }}
                />
              </div>
              <div className="geocodeDiv flex">
                <button
                  className="geocodeBtn"
                  onClick={this.props.hideGroupPanel}
                >
                  {translateObj.backBtn[this.props.translate]}
                </button>
                <button
                  className="geocodeBtn"
                  onClick={this.saveGroupName}
                >
                  {translateObj.saveBtnLowerCase[this.props.translate]}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddGrpPanel;