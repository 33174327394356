import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import LabelStyleOnly from '../../MapComponent/LabelStyleOnly';
import LabelPosition from '../../MapComponent/LabelPosition';
import {getPointTextLabelOffset } from '../../../utils/tools';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class StylePointLabels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labelColor: '#ffffff',
      labelStroke: '#000000',
      labelFontSize: 14,
      //opacity={this.props.pointAddLabelOpacity}
      labelFont: 'Open Sans',
      labelWeight: true,
      labelText: false,
      labelPosition: 'upper-right',
      labelField: 'name',
      timer: null,
      redrawHack: null
    }
    this.closePanel = this.closePanel.bind(this);
    this.updateLabelStyle = this.updateLabelStyle.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.selectLabelField = this.selectLabelField.bind(this);
  }


  // value={this.state.labelField}
  // onChange={this.selectLabelField}

  componentDidMount() {
    if(this.props.currentPoint){
      this.setState({
        labelColor: this.props.currentPoint.pointTextLabelColor,
        labelStroke: this.props.currentPoint.pointTextLabelStroke,
        labelFontSize: this.props.currentPoint.pointTextLabelFontSize,
        labelFont: this.props.currentPoint.pointTextLabelFont,
        labelWeight: this.props.currentPoint.pointTextLabelWeight,
        labelOpacity: this.props.currentPoint.pointTextLabelOpacity,
        labelPosition: this.props.currentPoint.pointTextLabelPosition,
        labelField: this.props.currentPoint.pointTextLabelField
      });
    }
    else{
      const myGroup = this.props.groups.filter(group => group.id === this.props.currentGroup)[0];
      this.setState({
        labelColor: myGroup.groupTextLabelColor,
        labelStroke: myGroup.groupTextLabelStroke,
        labelFontSize: myGroup.groupTextLabelFontSize,
        labelFont: myGroup.groupTextLabelFont,
        labelWeight: myGroup.groupTextLabelWeight,
        labelOpacity: myGroup.groupTextLabelOpacity,
        labelPosition: myGroup.groupTextLabelPosition,
        labelField: myGroup.groupTextLabelField
      });
    }
  }

  closePanel() {
    this.props.toggleGroupLabelPanel(false, this.props.currentGroup);
  }

  selectLabelField(e) {

    const checkAtStart = this.getCurrentCustomTextLabel();
    if(checkAtStart){
      this.toggleCustomTextLabel(this.props.currentGroup, {
        target:{
          checked: false
        }
      });
    }

    this.setState({labelField: e.target.value});

    if(this.props.currentPoint){
      const pointsClone = this.props.points;
      const pointIx = pointsClone.findIndex((point => point.content.id === this.props.currentPoint.id));
      let pt = pointsClone[pointIx];
      pt.content.pointTextLabelField = e.target.value;
      //pt.content.labelOffset = getPointTextLabelOffset(pt);
      
      this.props.updateMapObj({
        mapPoints: pointsClone
      });
    }
    else {
      const groupsClone = this.props.groups;
      const groupIx = groupsClone.findIndex((group => group.id === this.props.currentGroup));
      let grp = groupsClone[groupIx];
      grp.groupTextLabelField = e.target.value;


      const sourceArray = this.props.points.filter(point => point.content.group === this.props.currentGroup);
      const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== this.props.currentGroup);
  
      const newPoints = [];
  
      sourceArray.forEach(pt => {
        pt.content.pointTextLabelField = e.target.value;
          newPoints.push(pt);
      });
      const combinedArray = newPoints.concat(leftOverArray);
  
      this.props.updateMapObj({
        mapPoints: combinedArray,
        mapGroups: groupsClone
      });
    }

    if(checkAtStart){
      setTimeout(() => {
        this.toggleCustomTextLabel(this.props.currentGroup, {
          target:{
            checked: true
          }
        });
        }, 100);
      }

  }

  updateLabelStyle(obj) {
    const strobj = JSON.stringify(obj).replace("mapAddL","l");
    const newobj = JSON.parse(strobj);

    this.setState(newobj);

    const strobj2 = JSON.stringify(obj).replace('mapAdd','pointText').replaceAll('"','').replace('{','').replace('}','');
    const results = strobj2.split(':');

    if(results[0] === 'pointTextLabelFontSize')
      results[1] = parseInt(results[1]);

    if(results[0] === 'pointTextLabelOpacity')
      results[1] = parseFloat(results[1]);

    if(results[0] === 'pointTextLabelFontSize' || results[0] === 'pointTextLabelOpacity' || results[0] || 'pointTextLabelFont'){
      const checkAtStart = this.getCurrentCustomTextLabel();
      if(checkAtStart){
        this.redrawHack = true;
        this.toggleCustomTextLabel(this.props.currentGroup, {
          target:{
            checked: false
          }
        });
      }

      if(this.redrawHack){
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.toggleCustomTextLabel(this.props.currentGroup, {
            target:{
              checked: true
            }
          });
          this.redrawHack = null;
          }, 100);
      }
    }

    if(this.props.currentPoint){
      const pointsClone = this.props.points;
      const pointIx = pointsClone.findIndex((point => point.content.id === this.props.currentPoint.id));
      let pt = pointsClone[pointIx];
      pt.content[results[0]] = results[1];
      pointsClone[pointIx] = pt;
      this.props.updateMapObj({
        mapPoints: pointsClone,
      });
    }
    else {
      const groupsClone = this.props.groups;
      const groupIx = groupsClone.findIndex((group => group.id === this.props.currentGroup));
      const groupAttr = results[0].replace('pointText','groupText');
  
      groupsClone[groupIx][groupAttr] = results[1];
  
      const sourceArray = this.props.points.filter(point => point.content.group === this.props.currentGroup);
      const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== this.props.currentGroup);
  
      const newPoints = [];
  
      sourceArray.forEach(pt => {
          pt.content[results[0]] = results[1];
          pt.content.labelPosition = this.state.labelPosition;
          pt.content.labelOffset = getPointTextLabelOffset(pt);
          newPoints.push(pt);
      });
      const combinedArray = newPoints.concat(leftOverArray);
  
      this.props.updateMapObj({
        mapPoints: combinedArray,
        mapGroups: groupsClone
      });
    }
  }

  toggleCustomTextLabel(currentGroup, e) {
    if(this.props.currentPoint){
      const pointsClone = this.props.points;
      const pointIx = pointsClone.findIndex((point => point.content.id === this.props.currentPoint.id));
      let pt = pointsClone[pointIx];

      pt.content.pointTextLabel = e.target.checked;
      pt.content.pointTextLabelPosition = this.state.labelPosition;
      pt.content.labelOffset = getPointTextLabelOffset(pt);
      pointsClone[pointIx] = pt;
      this.props.updateMapObj({
        mapPoints: pointsClone,
      });
    }
    else {
      const groupsClone = this.props.groups;
      const groupIx = groupsClone.findIndex((group => group.id === currentGroup));
      groupsClone[groupIx].groupTextLabel = e.target.checked;
  
      const sourceArray = this.props.points.filter(point => point.content.group === currentGroup);
      const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== currentGroup);
  
      const newPoints = [];
      sourceArray.forEach(pt => {
        pt.content.pointTextLabel = e.target.checked;
        pt.content.pointTextLabelPosition = this.state.labelPosition;
        pt.content.labelOffset = getPointTextLabelOffset(pt);
        newPoints.push(pt);
      });
  //pointTextLabelPosition
      const combinedArray = newPoints.concat(leftOverArray);
      this.props.updateMapObj({
        mapPoints: combinedArray,
        mapGroups: groupsClone
      });
    }
  }

  getCurrentCustomTextLabel() {
    if(this.props.currentPoint){
      return this.props.currentPoint.pointTextLabel;
    }
    else{
      const myGroup = this.props.groups.filter(group => group.id === this.props.currentGroup)[0];
      return myGroup.groupTextLabel;
    }

  }

  setPosition(e) {
    const checkAtStart = this.getCurrentCustomTextLabel();
    if(checkAtStart){
      this.toggleCustomTextLabel(this.props.currentGroup, {
        target:{
          checked: false
        }
      });
    }

    this.updateLabelStyle({pointTextLabelPosition: e});
    this.setState({labelPosition: e});

    if(checkAtStart){
    setTimeout(() => {
      this.toggleCustomTextLabel(this.props.currentGroup, {
        target:{
          checked: true
        }
      });
      }, 100);
    }
  }

  render() {
    return (
      <div>
        <h2 className="panel-title">
        <span className="panel-icon-span">
            <svg id="styleIconGroups" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 88.62 94.1">
              <line id="Line_20" stroke="#0c9ed9" fill="none" strokeWidth="4" y1="92.85" x2="88.62" y2="92.85" />
              <path id="Path_4362" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M24.45-22.9,7.23-35A4.68,4.68,0,0,0,.72-33.89a4.48,4.48,0,0,0-.58,1.13L-3.51-22.3-30.89,5.46a4.65,4.65,0,0,0-1.34,3.23V56.11" transform="translate(34.31 37.14)" />
              <path id="Path_4363" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M-28.37,55.58,13.52,40.67a4.6,4.6,0,0,0,2.57-2.37L32.84,3l8.58-7a4.68,4.68,0,0,0,.66-6.59,4.62,4.62,0,0,0-.92-.85" transform="translate(34.31 37.14)" />
              <circle id="Ellipse_2" fill="none" stroke="#0c9ed9" strokeWidth="4" cx="33.22" cy="52.86" r="5.67" />
              <line id="Line_21" fill="none" stroke="#0c9ed9" strokeWidth="4" x1="3.37" y1="92.08" x2="30.5" y2="56.12" />
            </svg>
          </span>
          <span>{this.props.currentPoint ? "Label Point" : "Label Group"} </span>
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <div>
            {this.props.currentPoint ? "Add Label" : "Label Layer"} 
            <Checkbox
              color="default"
              value="default"
              inputProps={{ 'aria-label': 'use custom label' }}
              onChange={(event) => this.toggleCustomTextLabel(this.props.currentGroup, event)}
              checked={this.getCurrentCustomTextLabel()}
              disableRipple
            />
          </div>

          <div className="paddingL15">Label Field</div>
            <div style={{ paddingLeft: '5px' }}>
              <Select
                labelId="mapAddLabelFont-select"
                id="mapAddLabelFont-select"
                value={this.state.labelField}
                onChange={this.selectLabelField}
                variant="outlined"
                autoWidth={true}
              >
                <MenuItem value={'name'}>Name</MenuItem>
                <MenuItem value={'address'}>Address</MenuItem>
                {/* <MenuItem value={'label'}>Label</MenuItem> */}
                <MenuItem value={'legenddata'}>Legend Data</MenuItem>
              </Select>
            </div>

          <div>
          <LabelStyleOnly
              updateMapState={this.updateLabelStyle}
              color={this.state.labelColor}
              stroke={this.state.labelStroke}
              size={this.state.labelFontSize}
              text={''}
              opacity={this.state.labelOpacity}
              font={this.state.labelFont}
              weight={this.state.labelWeight}
              readOnlyText={true}
              translate={this.props.translate}
            />
          </div>
          <LabelPosition
            labelPosition={this.state.labelPosition}
            setPosition={this.setPosition}
            translate={this.props.translate}
          />
        </div>
        <div className="geocodeDiv flexRight">
          <button className="geocodeBtn" onClick={this.closePanel}>Done</button>
        </div>
      </div>
      );
    }
  }
  
export default StylePointLabels;
