import React from 'react';
import PanelButton from './PanelButton/PanelButton';
import LayoutPanel from './LayoutPanel/LayoutPanel';
import AddPtsPanel from './AddPtsPanel/AddPtsPanel';
import AddDataPanel from './AddDataPanel/AddDataPanel';
import BasemapPanel from './BasemapPanel/BasemapPanel';
import LegendPanel from './LegendPanel/LegendPanel';
import SavePanel from './SavePanel/SavePanel';
import StylePoints from './StylePoints/StylePoints';
import HistoryPanel from './HistoryPanel/HistoryPanel';
import PrintPanel from './PrintPanel/PrintPanel';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';
import StartOverPanel from './StartOverPanel/StartOverPanel';
import ImportPtsPanel from './ImportPtsPanel/ImportPtsPanel';
import TypeAddress from './AddPtsPanel/TypeAddress/TypeAddress';
import { GetNewGroupColor, GenerateRandomId } from '../../utils/tools.js';
import translateObj from '../../utils/translate';

const buttonHolderDiv = {
  paddingTop: '10px',
}

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addPtsShow: false,
      addDataShow: false,
      basemapShow: false,
      layoutShow: false,
      legendShow: false,
      saveShow: false,
      historyShow: false,
      startOverShow: false,
      basemapBtnColor: '#25408f',
      layoutBtnColor: '#25408f',
      addPtsBtnColor: '#25408f',
      addDataBtnColor: '#25408f',
      legendBtnColor: '#25408f',
      printBtnColor: '#25408f',
      saveBtnColor: '#25408f',
      historyBtnColor: '#25408f',
      startOverBtnColor: '#25408f',
      mapHistoryData: [],
      historyProjectSort: 'ascending',
      historyIdSort: 'ascending',
      historySaveSort: 'ascending',
      isPrinting: false,
      isSaving: false,
      isLoadingHistory: false,
      importPoints: false,
      headers: null
    };

    this.togglePanel = this.togglePanel.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleHoverOff = this.handleHoverOff.bind(this);
    this.addNewGroup = this.addNewGroup.bind(this);
    this.updateSidebarObj = this.updateSidebarObj.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.toggleImportPoints = this.toggleImportPoints.bind(this);
    this.toggleTypeAddress = this.toggleTypeAddress.bind(this);
  }

  componentDidUpdate(prevProps) {

    if(this.state.headers!==this.props.headers){
      const uploadObj = window.sessionStorage.getItem("upload_points");
      if(uploadObj!==null){
        this.setState({headers:this.props.headers,importPointsShow: true});
      }
    }

    if (prevProps.isStyling !== this.props.isStyling) {
      if (this.props.isStyling === true) {
        this.togglePanel('stylePoints');
      }
    }

    if (prevProps.isEditingRing !== this.props.isEditingRing) {
      if (this.props.isEditingRing === true) {
        this.togglePanel('stylePoints');
      }
    }

    if (prevProps.popupOpen !== this.props.mapObj.popupOpen) {
      if (this.props.mapObj.popupOpen === true) {
        this.togglePanel();
      }
    }
  }

  toggleImportPoints(expr){
    this.setState({
      importPointsShow: false
    });
  }


  toggleTypeAddress() {
    this.props.updateMapObj({
      isReGeocoding: false
    });
  }

  togglePanel(expr) {
    //!this.state.isPrinting && 
    if (!this.state.isSaving && !this.state.isLoadingHistory) {
      this.props.updateMapObj({ mapAddPt: false });
      switch (expr) {
        case 'addPts':
          this.setState({
            addPtsShow: !this.state.addPtsShow,
            addDataShow: false,
            basemapShow: false,
            layoutShow: false,
            legendShow: false,
            saveShow: false,
            historyShow: false,
            printShow: false,
            startOverShow: false,
            importPoints: false
          });
          this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: !this.state.addPtsShow });
          break;
          case 'addData':
            this.setState({
              addPtsShow: false,
              addDataShow: !this.state.addDataShow,
              basemapShow: false,
              layoutShow: false,
              legendShow: false,
              saveShow: false,
              historyShow: false,
              printShow: false,
              startOverShow: false
            });
            this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: !this.state.addDataShow });
            break;
        case 'basemap':
          this.setState({
            addPtsShow: false,
            addDataShow: false,
            basemapShow: !this.state.basemapShow,
            layoutShow: false,
            legendShow: false,
            saveShow: false,
            historyShow: false,
            printShow: false,
            startOverShow: false
          });
          this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: !this.state.basemapShow });
          break;
        case 'layout':
          this.setState({
            addPtsShow: false,
            addDataShow: false,
            basemapShow: false,
            layoutShow: !this.state.layoutShow,
            legendShow: false,
            saveShow: false,
            historyShow: false,
            printShow: false,
            startOverShow: false
          });
          this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: !this.state.layoutShow });
          break;
        case 'legend':
          this.setState({
            addPtsShow: false,
            addDataShow: false,
            basemapShow: false,
            layoutShow: false,
            legendShow: !this.state.legendShow,
            saveShow: false,
            historyShow: false,
            printShow: false,
            startOverShow: false
          });
          this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: !this.state.legendShow });
          break;
        case 'save':
          this.setState({
            addPtsShow: false,
            addDataShow: false,
            basemapShow: false,
            layoutShow: false,
            legendShow: false,
            saveShow: !this.state.saveShow,
            historyShow: false,
            printShow: false,
            startOverShow: false
          });
          this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: !this.state.saveShow });
          break;
        case 'stylePoints':
          this.setState({
            addPtsShow: false,
            addDataShow: false,
            basemapShow: false,
            layoutShow: false,
            legendShow: false,
            saveShow: false,
            historyShow: false,
            printShow: false,
            startOverShow: false
          });
          break;
        case 'history':
          this.setState({
            addPtsShow: false,
            addDataShow: false,
            basemapShow: false,
            layoutShow: false,
            legendShow: false,
            saveShow: false,
            historyShow: !this.state.historyShow,
            printShow: false,
            startOverShow: false
          });
          this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: !this.state.historyShow });
          break;
        case 'print':
          this.setState({
            addPtsShow: false,
            addDataShow: false,
            basemapShow: false,
            layoutShow: false,
            legendShow: false,
            saveShow: false,
            historyShow: false,
            printShow: !this.state.printShow,
            startOverShow: false
          });
          this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: !this.state.printShow });
          break;
        case 'startOver':
          this.setState({
            addPtsShow: false,
            addDataShow: false,
            basemapShow: false,
            layoutShow: false,
            legendShow: false,
            saveShow: false,
            historyShow: false,
            printShow: false,
            startOverShow: !this.state.startOverShow
          });
          this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: !this.state.startOverShow });
          break;
        default:
          this.setState({
            addPtsShow: false,
            addDataShow: false,
            basemapShow: false,
            layoutShow: false,
            legendShow: false,
            saveShow: false,
            historyShow: false,
            printShow: false,
            startOverShow: false
          });
          this.props.updateMapObj({ isStyling: false, isEditingRing: false, sidebarOpen: false });
      }
    }
  }

  handleHover(button) {
    this.setState({
      [button + 'BtnColor']: '#0c9ed9'
    });
  }

  handleHoverOff(button) {
    this.setState({
      [button + 'BtnColor']: '#25408f'
    });
  }

  addNewGroup(groupName) {
    const groups = this.props.groups;
    const newName = groupName.toString() || 'Layer ' + (this.props.groups.length + 1);

    const newGroup = {
      id: GenerateRandomId(),
      order: this.props.groups.length + 1,
      name: newName,
      useGroupStyle: false,
      useCustomLabel: false,
      groupTextColor: '#ffffff',
      groupIconName: 'circleIcon',
      groupIconColor: GetNewGroupColor(this.props.groups.length + 1),
      groupIconSize: 'default',
      startNumber: 1,
      visible: true,
      nameInLegend: true,
      pointsInLegend: true,
      groupInLegend: true,
      icon: '',
      showLegendData: false,
      groupLeaderLine: false,
      groupLeaderColor: '#ffffff',
      groupLeaderWidth: 3,
      groupLeaderOpacity: 1,
      groupLeaderAnchor: false,
      groupTextLabel: false,
      groupTextLabelStroke: '#4a4a4d',
      groupTextLabelColor: '#ffffff',
      groupTextLabelFontSize: 14,
      groupTextLabelOpacity: 1,
      groupTextLabelFont: 'Open Sans',
      groupTextLabelWeight: true,
      groupTextLabelPosition: 'upper-right',
      groupTextLabelField: 'name'
    }

    const newGroups = Array.from(groups);
    newGroups.push(newGroup);

    this.props.updateMapObj({
      mapGroups: newGroups,
      currentGroup: newGroup.id
    });
  }

  updateSidebarObj(updateObj) {
    this.setState(updateObj);
  }

  updateStatus(key, bool) {
    this.setState({
      [key]: bool
    });
  }

  render() {
    return (
      <div className="sideBarDiv">
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'history')}
            handleHoverOff={this.handleHoverOff.bind(this, 'history')}
            handleHover={this.handleHover.bind(this, 'history')}
          >
            <SVGIconComponent size={'22'} name={'historyBtnIcon'} color={this.state.historyBtnColor} />
            <div className="sideBarBtnTxt">{translateObj.historyBtn[this.props.translate]}</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'basemap')}
            handleHoverOff={this.handleHoverOff.bind(this, 'basemap')}
            handleHover={this.handleHover.bind(this, 'basemap')}
          >
            <SVGIconComponent size={'22'} name={'basemapBtnIcon'} color={this.state.basemapBtnColor} />
            <div className="sideBarBtnTxt">{translateObj.basemapBtn[this.props.translate]}</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'layout')}
            handleHoverOff={this.handleHoverOff.bind(this, 'layout')}
            handleHover={this.handleHover.bind(this, 'layout')}
          >
            <SVGIconComponent size={'22'} name={'layoutBtnIcon'} color={this.state.layoutBtnColor} />
            <div className="sideBarBtnTxt">{translateObj.layoutBtn[this.props.translate]}</div>
          </PanelButton>
        </div>
          <div>
            <div style={buttonHolderDiv}>
              <PanelButton
                handleClick={this.togglePanel.bind(this, 'addPts')}
                handleHoverOff={this.handleHoverOff.bind(this, 'addPts')}
                handleHover={this.handleHover.bind(this, 'addPts')}
              >
                <SVGIconComponent size={'22'} name={'addPtsBtnIcon'} color={this.state.addPtsBtnColor} />
                <div className="sideBarBtnTxt">{translateObj.addPointsBtn[this.props.translate]}</div>
              </PanelButton>
            </div>
            <div style={buttonHolderDiv}>
              <PanelButton
                handleClick={this.togglePanel.bind(this, 'addData')}
                handleHoverOff={this.handleHoverOff.bind(this, 'addData')}
                handleHover={this.handleHover.bind(this, 'addData')}
              >
                <SVGIconComponent size={'22'} name={'addDataBtnIcon'} color={this.state.addDataBtnColor} />
                <div className="sideBarBtnTxt">{translateObj.addDataBtn[this.props.translate]}</div>
              </PanelButton>
            </div>
          </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'legend')}
            handleHoverOff={this.handleHoverOff.bind(this, 'legend')}
            handleHover={this.handleHover.bind(this, 'legend')}
          >
            <SVGIconComponent size={'22'} name={'legendBtnIcon'} color={this.state.legendBtnColor} />
            <div className="sideBarBtnTxt">{translateObj.legendBtn[this.props.translate]}</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'save')}
            handleHoverOff={this.handleHoverOff.bind(this, 'save')}
            handleHover={this.handleHover.bind(this, 'save')}
          >
            <SVGIconComponent size={'22'} name={'saveBtnIcon'} color={this.state.saveBtnColor} />
            <div className="sideBarBtnTxt">{translateObj.saveBtn[this.props.translate]}</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'print')}
            handleHoverOff={this.handleHoverOff.bind(this, 'print')}
            handleHover={this.handleHover.bind(this, 'print')}
          >
            <SVGIconComponent size={'22'} name={'printBtnIcon'} color={this.state.printBtnColor} />
            <div className="sideBarBtnTxt">{translateObj.printBtn[this.props.translate]}</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'startOver')}
            handleHoverOff={this.handleHoverOff.bind(this, 'startOver')}
            handleHover={this.handleHover.bind(this, 'startOver')}
          >
            <SVGIconComponent size={'22'} name={'startOverBtnIcon'} color={this.state.startOverBtnColor} />
            <div className="sideBarBtnTxt">{translateObj.startOverBtn[this.props.translate]}</div>
          </PanelButton>
        </div>
        <div className="sidebarMapIdDiv">
          {this.props.mapObj.mapId !== null ?
            <div className="panel-body-small-text mapId-padding">
              Map ID: <span className="blueText">{this.props.mapObj.mapId}</span>
            </div>
            :
            <div className="panel-body-small-text mapId-padding">
              Map ID: <span className="blueText">None</span>
            </div>
          }
        </div>
        <div>
          {this.state.historyShow
            ? <HistoryPanel
              updateMapObj={this.props.updateMapObj}
              togglePanel={this.togglePanel}
              headers={this.props.headers}
              setPointIcons={this.props.setPointIcons}
              mapHistoryData={this.state.mapHistoryData}
              updateSidebarObj={this.updateSidebarObj}
              mapObj={this.props.mapObj}
              updateIsLoadingHistory={this.updateStatus}
              historyProjectSort={this.state.historyProjectSort}
              historyIdSort={this.state.historyIdSort}
              historySaveSort={this.state.historySaveSort}
              getLabelIcon={this.props.getLabelIcon}
              getShieldIcon={this.props.getShieldIcon}
              translate={this.props.translate}
            ></HistoryPanel>
            : null
          }
        </div>
        <div>
          {this.state.layoutShow
            ? <LayoutPanel
              layout={this.props.layout}
              updateMapObj={this.props.updateMapObj}
              togglePanel={this.togglePanel}
              mapObj={this.props.mapObj}
              translate={this.props.translate}
            ></LayoutPanel>
            : null
          }
        </div>
        <div>
          {this.state.addPtsShow
            ? <AddPtsPanel
              updateMapObj={this.props.updateMapObj}
              mapAddPt={this.props.mapAddPt}
              groups={this.props.groups}
              currentGroup={this.props.currentGroup}
              togglePanel={this.togglePanel}
              points={this.props.points}
              createNewPoint={this.props.createNewPoint}
              addNewGroup={this.addNewGroup}
              updateStatus={this.updateStatus}
              legend={this.props.legend}
              headers={this.props.headers}
              country={this.props.country}
              mapCenter={this.props.mapObj.mapCenter}
              supergeo={this.props.supergeo}
              badGeocodes={this.props.badGeocodes}
              undoMoveLocation={this.props.undoMoveLocation}
              secyGeoName={this.props.secyGeoName}
              translate={this.props.translate}
            ></AddPtsPanel>
            : null
          }
        </div>
        <div>
          {this.state.addDataShow
            ? <AddDataPanel
              togglePanel={this.togglePanel}
              mapObj={this.props.mapObj}
              updateMapObj={this.props.updateMapObj}
              updateIsSaving={this.updateStatus}
              headers={this.props.headers}
              country={this.props.country}
              mapAddPt={this.props.mapAddPt}
              groups={this.props.groups}
              currentGroup={this.props.currentGroup}
              points={this.props.points}
              createNewPoint={this.props.createNewPoint}
              addNewGroup={this.addNewGroup}
              updateStatus={this.updateStatus}
              legend={this.props.legend}
              atlasToken={this.props.mapObj.atlasToken}
              mapCenter={this.props.mapObj.mapCenter}
              getGAPIPolygons={this.props.getGAPIPolygons}
              gapiPolygonSettings={this.props.gapiPolygonSettings}
              updateGAPIPolygonLabels={this.props.updateGAPIPolygonLabels}
              zoomToGAPIPolygon={this.props.zoomToGAPIPolygon}
              setGapiIcon={this.props.setGapiIcon}
              setGAPILabelStyle={this.props.setGAPILabelStyle}
              setGapiPointFilter={this.props.setGapiPointFilter}
              translate={this.props.translate}
            ></AddDataPanel>
            : null
          }
        </div>
        <div>
          {this.state.basemapShow
            ? <BasemapPanel
              updateMapObj={this.props.updateMapObj}
              basemap={this.props.basemap}
              togglePanel={this.togglePanel}
              country={this.props.country}
              translate={this.props.translate}
            ></BasemapPanel>
            : null
          }
        </div>
        <div>
          {this.state.legendShow
            ? <LegendPanel
              rings={this.props.rings}
              driveTimes={this.props.driveTimes}
              points={this.props.points}
              groups={this.props.groups}
              currentPoint={this.props.currentPoint}
              currentGroup={this.props.currentGroup}
              updateMapObj={this.props.updateMapObj}
              togglePanel={this.togglePanel}
              deletePoint={this.props.deletePoint}
              updateOrderAndIcon={this.props.updateOrderAndIcon}
              popupOpen={this.props.popupOpen}
              legend={this.props.legend}
              isSaving={this.props.mapObj.isSaving}
              headers={this.props.headers}
              updateStatus={this.updateStatus}
              isLoadingHistory={this.state.isLoadingHistory}
              isEditingRing={this.props.isEditingRing}
              country={this.props.country}
              demographicLegend={this.props.mapObj.demographicLegend}
              translate={this.props.translate}
              pointLeaderColor={this.props.pointLeaderColor}
              pointLeaderWidth={this.props.pointLeaderWidth}
              pointLeaderOpacity={this.props.pointLeaderOpacity}
              pointLeaderAnchro={this.props.pointLeaderAnchor}
              customLayers={this.props.customLayers}
              scaleLocation={this.props.scaleLocation}
              undoMoveLocation={this.props.mapObj.undoMoveLocation}
              badGeocodes={this.props.badGeocodes}
              secyGeoName={this.props.secyGeoName}
              atlasToken={this.props.mapObj.atlasToken}
              getGAPIPolygons={this.props.getGAPIPolygons}
              gapiPolygonSettings={this.props.gapiPolygonSettings}
              updateGAPIPolygonLabels={this.props.updateGAPIPolygonLabels}
              zoomToGAPIPolygon={this.props.zoomToGAPIPolygon}
              mapObj={this.props.mapObj}
              setGapiIcon={this.props.setGapiIcon}
              setGAPILabelStyle={this.props.setGAPILabelStyle}
              setGapiPointFilter={this.props.setGapiPointFilter}
              retriveGCAtlasToken={this.props.retriveGCAtlasToken}
            ></LegendPanel>
            : null
          }
        </div>
        <div>
          {this.state.saveShow
            ? <SavePanel
              togglePanel={this.togglePanel}
              mapObj={this.props.mapObj}
              updateMapObj={this.props.updateMapObj}
              headers={this.props.headers}
              updateSidebarObj={this.updateSidebarObj}
              updateIsSaving={this.updateStatus}
              translate={this.props.translate}
            ></SavePanel>
            : null
          }
        </div>
        <div>
          {this.state.printShow
            ? <PrintPanel
              togglePanel={this.togglePanel}
              mapObj={this.props.mapObj}
              updateMapObj={this.props.updateMapObj}
              headers={this.props.headers}
              updateIsPrinting={this.updateStatus}
              updateSidebarObj={this.updateSidebarObj}
              translate={this.props.translate}
            ></PrintPanel>
            : null
          }
        </div>
        <div>
          {this.state.startOverShow
            ? <StartOverPanel
              togglePanel={this.togglePanel}
              updateMapObj={this.props.updateMapObj}
              updateSidebarObj={this.updateSidebarObj}
              mapObj={this.props.mapObj}
              headers={this.props.headers}
              updateIsSaving={this.updateStatus}
              translate={this.props.translate}
            />
            : null
          }
        </div>
        <div>
        {this.props.isReGeocoding ?
          <TypeAddress 
            createNewPoint={this.props.createNewPoint}
            updateMapObj={this.props.updateMapObj}
            toggleTypeAddress={this.toggleTypeAddress}
            points={this.props.points}
            legend={this.props.legend}
            headers={this.props.headers}
            currentPoint={this.props.currentPoint}
            currentGroup={this.props.currentGroup}
            country={this.props.country}
            mapCenter={this.props.mapObj.mapCenter}
            undoMoveLocation={this.props.mapObj.undoMoveLocation}
            translate={this.props.translate}
          />
          : null
        }
        </div>
        <div>
          {this.props.isStyling || this.props.isEditingRing || this.props.isLabeling
            ? <StylePoints
              points={this.props.points}
              groups={this.props.groups}
              currentGroup={this.props.currentGroup}
              updateMapObj={this.props.updateMapObj}
              togglePanel={this.togglePanel}
              currentPoint={this.props.currentPoint}
              isEditingRing={this.props.isEditingRing}
              rings={this.props.rings}
              driveTimes={this.props.driveTimes}
              updateOrderAndIcon={this.props.updateOrderAndIcon}
              headers={this.props.headers}
              updateStatus={this.updateStatus}
              isLoadingHistory={this.state.isLoadingHistory}
              country={this.props.country}
              pointLeaderColor={this.props.pointLeaderColor}
              pointLeaderWidth={this.props.pointLeaderWidth}
              pointLeaderOpacity={this.props.pointLeaderOpacity}
              pointLeaderAnchor={this.props.pointLeaderAnchor}
              isLabeling={this.props.isLabeling}
              isStyling={this.props.isStyling}
              translate={this.props.translate}
              gcAtlasToken={this.props.mapObj.gcAtlasToken}
              retriveGCAtlasToken={this.props.retriveGCAtlasToken}
            ></StylePoints>
            : null
          }
        </div>
        <div>
          {this.state.importPointsShow && this.props.headers !== null ?
            <ImportPtsPanel
              toggleImportPoints={this.toggleImportPoints}
              uploadPtObj={this.props.uploadPtObj}
              updateMapObj={this.props.updateMapObj}
              legend={this.props.legend}
              headers={this.props.headers}
            />
            : null
          }
        </div>
      </div>
    )
  }
}

export default SideBar;