import React from 'react';
import ExposureNeg2Icon from '@material-ui/icons/ExposureNeg2';
import ExposurePlus2Icon from '@material-ui/icons/ExposurePlus2';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import UndoIcon from '@material-ui/icons/Undo';
import Tooltip from '@material-ui/core/Tooltip';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';

class CustomMapBtns extends React.Component {
  constructor(props) {
    super(props);

    this.zoomToAll = this.zoomToAll.bind(this);
    this.quickZoom = this.quickZoom.bind(this);
    this.undoLast = this.undoLast.bind(this);
    this.addCustomLabel = this.addCustomLabel.bind(this);
    this.addCustomShield = this.addCustomShield.bind(this);
  }

  zoomToAll() {
    this.props.updateMapState({
      zoomToAll: true
    });
  }

  quickZoom(type) {
    const currentZoom = this.props.currentZoom;
    let newZoom = 0;

    if (type === 'zoomIn') {
      if (currentZoom < 18) {
        newZoom = currentZoom + 2;
      } else {
        newZoom = 20;
      }
    } else {
      if (currentZoom > 2) {
        newZoom = currentZoom - 2;
      } else {
        newZoom = 0;
      }
    }

    this.props.updateMapObj({ mapZoom: newZoom });
  }

  undoLast() {
    console.log('undoLast');
    let lastPoint = this.props.mapObj.undoMoveLocation;

    if (!lastPoint || lastPoint === null || (lastPoint.lat && lastPoint.lat === null) || (lastPoint.lng && lastPoint.lng === null)) {
      return;
    }

    const pointsArrayClone = Array.from(this.props.mapObj.mapPoints);
    const ringsArrayClone = Array.from(this.props.mapObj.rings);
    let oldPointLocation = {
      id: null,
      lat: null,
      lng: null
    };
    pointsArrayClone.forEach(point => {
      if (point.content.id === lastPoint.id) {
        oldPointLocation.id = point.content.id;
        oldPointLocation.lat = point.position.lat;
        oldPointLocation.lng = point.position.lng;
        point.position = { lat: lastPoint.lat, lng: lastPoint.lng };
        point.content.lat = lastPoint.lat;
        point.content.lng = lastPoint.lng;

        //move any rings associated with point
        ringsArrayClone.forEach(ring => {
          if (ring.pointId === lastPoint.id) {
            ring.center = [lastPoint.lat, lastPoint.lng];
          }
        });
      }
    });

    this.props.updateMapObj({ mapPoints: pointsArrayClone, rings: ringsArrayClone, undoMoveLocation: oldPointLocation });
  }

  addCustomLabel() {
    this.props.updateMapState({ customLabelPanel: !this.props.customLabelPanelBool, selectedLabel: null, customShieldPanel: false, selectedShield: null });
  }

  addCustomShield() {
    this.props.updateMapState({ customShieldPanel: !this.props.customShieldPanelBool, customLabelPanel: false, selectedLabel: null, selectedShield: null });
  }

  render() {
    return (
      <div>
        <div className="mapSideButtonsDiv">
          <div
            className="mapCustomBtn mapTopBtn"
            onClick={this.quickZoom.bind(this, 'zoomIn')}
          >
            <Tooltip title="Quick zoom in" placement="left">
              <ExposurePlus2Icon style={{ fontSize: '20px' }} />
            </Tooltip>
          </div>
          <div
            className="mapCustomBtn"
            onClick={this.zoomToAll}
          >
            <Tooltip title="Zoom to all points" placement="left">
              <ScatterPlotIcon style={{ fontSize: '20px' }} />
            </Tooltip>
          </div>
          <div
            className="mapCustomBtn"
            onClick={this.undoLast}
          >
            <Tooltip title="Undo last move" placement="left">
              <UndoIcon style={{ fontSize: '20px' }} />
            </Tooltip>
          </div>
          <div
            className="mapCustomBtn"
            onClick={this.addCustomLabel}
          >
            <Tooltip title="Add custom label" placement="left">
              <TextFieldsIcon style={{ fontSize: '20px' }} />
            </Tooltip>
          </div>
          <div
            className="mapCustomBtn"
            onClick={this.addCustomShield}
          >
            <Tooltip title="Add custom highway shield" placement="left">
              <div><SVGIconComponent name={'boom_interstate_icon'} size={16} /></div>
            </Tooltip>
          </div>
          <div
            className="mapCustomBtn mapBottomBtn"
            onClick={this.quickZoom.bind(this, 'zoomOut')}
          >
            <Tooltip title="Quick zoom out" placement="left">
              <ExposureNeg2Icon style={{ fontSize: '20px' }} />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomMapBtns;