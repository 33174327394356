import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import { TextField } from '@material-ui/core';
import ColorPickerMenu from '../ColorPicker/ColorPickerMenu';
import { GenerateRandomId, isLatitude, isLongitude } from '../../../utils/tools';
import MapColumns from '../AddPtsPanel/MapColumns/MapColumns';
import translateObj from '../../../utils/translate';

const buttonRow = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
    marginBottom: '5px'
  }

class ImportFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileUploadShow: true,
            mapColumnsShow: false,
            polyStyleShow: false,
            selectedColumns: {
                name: 'None',
                layer: 'None',
                legend: 'None',
                label: 'None'
              },
            fill: '#0c9ed9',
            stroke: '#0c9ed9',
            opacity: 0.5,
            strokeOpacity: 1,
            columns: []
        }
        this.closePanel = this.closePanel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.createUploadedShapes = this.createUploadedShapes.bind(this);
        this.backToImportFile = this.backToImportFile.bind(this);
        this.setFillColor = this.setFillColor.bind(this);
        this.setStrokeColor = this.setStrokeColor.bind(this);
        this.setFillOpacity = this.setFillOpacity.bind(this);
        this.setStrokeOpacity = this.setStrokeOpacity.bind(this);
        this.toggleUploadPanel = this.toggleUploadPanel.bind(this);
        this.mapColumnNames = this.mapColumnNames.bind(this);
    }

    setFillColor(fill) {
        this.setState({fill:fill});
    }

    setStrokeColor(stroke){
        this.setState({stroke:stroke});
    }

    setFillOpacity(opacity) {
        this.setState({opacity:opacity});
    }

    setStrokeOpacity(strokeOpacity){
        this.setState({strokeOpacity:strokeOpacity});
    }

    selectChange(colName, event) {
        let colObj = this.state.selectedColumns;
        colObj[colName] = event.target.value;
        this.setState(colObj);
    }

    createUploadedShapes() {
        const shapes = this.state.poly_data;
        const newExtent = this.state.newExtent;

        const shapesArrayClone = Array.from(this.props.mapObj.shapes);
        for(const s in shapes){
            shapesArrayClone.push(
                {
                  id: GenerateRandomId(),
                  order: s,
                  type: shapes[s].type,
                  position: [shapes[s].position],
                  fill: this.state.fill,
                  stroke: this.state.stroke,
                  opacity: this.state.opacity,
                  strokeOpacity: this.state.strokeOpacity,
                  color: this.state.stroke,
                  width: 3
                });
        }
        this.props.updateMapObj({
            shapes: shapesArrayClone,
            newExtent: newExtent
        });

        this.closePanel();

    }

    _makeGeoJson(file) {
        const gj = JSON.parse(file);
        const newpoints = [];
        const points2 = []; 
        const newpolypositions = [];
        const columns = [];
        let first = true;
        const esriType = gj.geometryType || null;
    
        if(!gj.features){
            this.setState({
                error: true
              });
            return;
        }

        let newExtent = null;
        let north = -90;
        let south = 90;
        let west = 180;
        let east = -180;
        for (const feat of gj.features){

          if(feat.geometry?.type==='Point' || esriType === 'esriGeometryPoint')
          {
            let newProps = feat.properties || feat.attributes;
            if(first){
              for (const key in newProps) {
                columns.push(key);
              }
              columns.push('calculated_latitude');
              columns.push('calculated_longitude');
              first = false;
            }
            const lat = feat.geometry.y || feat.geometry.coordinates[1];
            const lng = feat.geometry.x || feat.geometry.coordinates[0];

            newProps.calculated_latitude = lat;
            newProps.calculated_longitude = lng;
            points2.push(newProps);

            newpoints.push({
                properties: newProps,
                geometry: {
                    coordinates: [lng, lat]
                }
            });
          }
          else if(feat.geometry?.type==='Polygon' || esriType === 'esriGeometryPolygon')
          {
            let oldcoords = [];
            const newcords = [];
            if( esriType === 'esriGeometryPolygon'){
                oldcoords = feat.geometry.rings[0];
            }
            else {
                oldcoords = feat.geometry.coordinates[0];
            }

            for(let i=0;i<oldcoords.length;i++){
              const coord = oldcoords[i];
              if(!isLatitude(coord[1])){
                this.setState({
                    error: true
                  });
                return;
              }
              if(!isLongitude(coord[0])){
                this.setState({
                    error: true
                  });
                return;
              }

              if(coord[1] > north)
                north = coord[1];
              if(coord[1] < south)
                south = coord[1];
              if(coord[0] > east)
                east = coord[0];
              if(coord[0] < west)
                west = coord[0];

              newcords.push({lat: coord[1],lng: coord[0]});
            }
            newpolypositions.push({position: newcords,type: 'polygon'});
          }
          else if(feat.geometry?.type==='MultiPolygon' || esriType === 'esriGeometryMultiPolygon')
          {
            let rings = []
            if( esriType === 'esriGeometryMultiPolygon'){
              rings = feat.geometry.rings;
            }
            else {
              rings = feat.geometry.coordinates;
            }
            for(let i=0;i<rings.length;i++){
              const ring=rings[i];
              let oldcoords = [];
              const newcords = [];
              oldcoords = ring[0];

              for(let i=0;i<oldcoords.length;i++){
                const coord = oldcoords[i];
                if(!isLatitude(coord[1])){
                  this.setState({
                      error: true
                    });
                  return;
                }
                if(!isLongitude(coord[0])){
                  this.setState({
                      error: true
                    });
                  return;
                }
  
                if(coord[1] > north)
                  north = coord[1];
                if(coord[1] < south)
                  south = coord[1];
                if(coord[0] > east)
                  east = coord[0];
                if(coord[0] < west)
                  west = coord[0];
  
                newcords.push({lat: coord[1],lng: coord[0]});
              }
              newpolypositions.push({position: newcords,type: 'polygon'});
            }
          }
          else if(feat.geometry?.type==='LineString' || esriType === 'esriGeometryPolyline')
          {
            let oldcoords = [];
            const newcords = [];
            if( esriType === 'esriGeometryPolyline'){
                oldcoords = feat.geometry.paths[0];
            }
            else {
                oldcoords = feat.geometry.coordinates;
            }

            for(let i=0;i<oldcoords.length;i++){
              const coord = oldcoords[i];
              if(coord[1] > north)
                north = coord[1];
              if(coord[1] < south)
                south = coord[1];
              if(coord[0] > east)
                east = coord[0];
              if(coord[0] < west)
                west = coord[0];

              newcords.push({lat: coord[1],lng: coord[0]});
            }
            newpolypositions.push({position: newcords,type: 'polyline'});
          }
          else if(feat.geometry?.type==='MultiLineString')
          {
            let oldcoords = [];

            let allcoords = feat.geometry.coordinates;
            for(let i=0;i<allcoords.length;i++){
              let oldcoords = allcoords[i];
              const newcords = [];
              for(let j=0;j<oldcoords.length;j++){
                const coord = oldcoords[j];
                if(coord[1] > north)
                  north = coord[1];
                if(coord[1] < south)
                  south = coord[1];
                if(coord[0] > east)
                  east = coord[0];
                if(coord[0] < west)
                  west = coord[0];

                newcords.push({lat: coord[1],lng: coord[0]});
              }
              newpolypositions.push({position: newcords,type: 'polyline'});
            }
          }

          if(north > -90){
            newExtent = {
              north: north,
              south: south,
              west: west,
              east: east
            }
          }
        }
        
        this.setState({
          point_data: {features: newpoints},
          data: points2,
          poly_data: newpolypositions,
          uploadColumns: columns,
          columns: columns,
          newExtent: newExtent
        });
    
        if(newpoints.length > 0){
            this.togglePanels(false, true, false);
        }
        else if(newpolypositions.length > 0){
            this.togglePanels(false, false, true);
        }
        else {
            if(!gj.features){
                this.setState({
                    error: true
                  });
            return;
            }
        }  
      }
    
    handleChange(e) {
        this.setState({
          error: false
        });
        const files = e.target.files;
        if (files && files[0]) {
          const file = files[0];
    
          /* Boilerplate to set up FileReader */
          const reader = new FileReader();
          const rABS = !!reader.readAsBinaryString;
          reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            if(file.name.endsWith('json')){
              this._makeGeoJson(bstr);
              return;
            }
            
        };
          if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
        } else {
          return;
        }
    }

    closePanel() {
        this.props.togglePanel("addData");
    }

    backButton() {
        console.log('backButton');
    }

    backToImportFile() {
        this.setState({
            fileUploadShow: true,
            mapColumnsShow: false,
            polyStyleShow: false
          });
    }

    togglePanels(panel1, panel2, panel3) {
        this.setState({
          fileUploadShow: panel1,
          mapColumnsShow: panel2,
          polyStyleShow: panel3
        });
    }

    mapColumnNames(colName, custom) {
        this.setState({
            [colName]: custom
        });
    }

    toggleUploadPanel(){
        this.props.togglePanel("addData");
    }

    render() {
        return (
        <div>
            <h2 className="panel-title">
            <span className="panel-icon-span">
                <SVGIconComponent name={'retailFilterIcon'} color={'#0c9ed9'} size={'16px'} />
            </span>
            {translateObj.importFileTitle[this.props.translate]}
            <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
            </h2>
            <div className="panel-body">

            {this.state.fileUploadShow
            ? <div>
              <div className="panel-body-small-text">
                {translateObj.uploadGeojsonMsg[this.props.translate]}
            </div>
              <div className="geocodeDiv flex">
                <input className="fileUploadInput" type="file" accept={["json","geojson"]} id="uploadFileBtn" onChange={this.handleChange} />
                <label htmlFor="uploadFileBtn">
                  <span className="geocodeBtn button-appearance">
                    {translateObj.selectFileBtn[this.props.translate]}
                  </span>
                </label>
              </div>
            </div>
            : null
          }
          {this.state.mapColumnsShow
          ?
          <MapColumns
           columns={this.state.columns}
           data={this.state.data}
           togglePanel={ this.props.togglePanel}
           togglePanels={this.togglePanels}
           toggleUploadPanel={this.toggleUploadPanel}
           mapColumnNames={this.mapColumnNames}
           updateStatus={this.props.updateStatus}
           groups={this.props.groups}
           points={this.props.points}
           currentGroup={this.props.currentGroup}
           updateMapObj={this.props.updateMapObj}
           addGroup={this.saveAddGroup}
           createNewPoint={this.props.createNewPoint}
           legend={this.props.legend}
           headers={this.props.headers}
           country={this.props.country}
        //    latColName={"calculated_latitide"}
        //    lngColName={"calculated_longitude"}
        //    addressColName={""}
           allColumns={false}
           backButton={this.backToImportFile}
           translate={this.props.translate}
          />
          : null
          }
          {
          this.state.polyStyleShow
          ?
          <div className="margin-t15 margin-b5">
            <div className="panel-body-small-text">
                {translateObj.selectPolyPointStyle[this.props.translate]}
            </div>
              <div className="add-ring-row-div">
                <div className="add-ring-row">
                <div>
                    <span className="paddingL15">
                      {translateObj.outline[this.props.translate]}
                    </span>
                </div>

                <div style={{ paddingLeft: '5px' }}>
                  <ColorPickerMenu
                    squareColor={this.state.stroke}
                    addTransparent={true}
                    setFillColor={this.setStrokeColor}
                  />
                </div>
              </div>
              <div className="add-ring-row paddingL15">
                <div style={{ width: '80px', paddingLeft: '5px' }}>
                  <TextField
                    label="Opacity"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={this.state.strokeOpacity}
                    InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                    onChange={(e) => { this.setStrokeOpacity(e.target.value) }}
                  />
                </div>
              </div>
              </div>


              <div className="add-ring-row-div">
                <div className="add-ring-row">
                  <div>
                    <span className="paddingL15">
                      {translateObj.fill[this.props.translate]}
                    </span>
                  </div>
                  <div style={{ paddingLeft: '5px' }}>
                    <ColorPickerMenu
                      squareColor={this.state.fill}
                      setFillColor={this.setFillColor}
                      addTransparent={true}
                    />
                  </div>
                </div>
                <div className="add-ring-row paddingL15">
                  <div style={{ width: '80px', paddingLeft: '5px' }}>
                    <TextField
                      label="Opacity"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={this.state.opacity}
                      InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                      onChange={(e) => { this.setFillOpacity(e.target.value) }}
                    />
                  </div>
                </div>
              </div>

              <div style={buttonRow}>
              <div className="geocodeDiv flex">
                  <button className="geocodeBtn" onClick={this.backToImportFile}>
                    {translateObj.backBtn[this.props.translate]}
                  </button>
              </div>

              <div className="geocodeDiv flex">
                  <button className="geocodeBtn" onClick={this.createUploadedShapes}>
                    {translateObj.goBtn[this.props.translate]}
                  </button>
              </div>
              </div>


              </div>
            : null
          }
          {this.state.error ?
            <div className="errorMsgDiv">
              {translateObj.oopsWrongCheckDataTryAgain[this.props.translate]}
            </div>
            : null
          }

            </div>
            </div>
        );
    }
}

export default ImportFile