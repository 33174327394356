import React from 'react';
import Menu from '@material-ui/core/Menu';
import ThematicColorPicker from '../ColorPicker/ThematicColorPicker';
import ThematicColorPickerCell from '../ColorPicker/ThematicColorPickerCell';

class ThematicColorPickerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      anchorEl: null,
    };

    this.toggleColorPicker = this.toggleColorPicker.bind(this);
    //this.selectThematic = this.selectThematic.bind(this);
  }

  toggleColorPicker(e, bool, fillColor) {
    this.setState({
      anchorEl: e.currentTarget,
      showMenu: bool
    });

    if(!bool && (typeof fillColor === 'string' || fillColor instanceof String)) {
      this.props.selectThematic(fillColor);
    }
  }

  render() {
    return (
      <div>
        <div
          onClick={(e) => this.toggleColorPicker(e, true)}
          style={{ cursor: 'pointer', height: '20px' }}
        >
        <ThematicColorPickerCell 
            fillColors={this.props.thematicColors}
            handleCellClick={this.toggleColorPicker.bind(this, { currentTarget: null }, false)}
          />
        </div>
        {this.state.showMenu ?
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.showMenu}
            onClose={this.toggleColorPicker.bind(this, { currentTarget: null }, false)}
          >
            <div className="legendOptsMenuDiv">
              <ThematicColorPicker
                //handleCellClick={this.props.selectThematic} //.bind(this, { currentTarget: null }, false)}
                handleCellClick={this.toggleColorPicker.bind(this, { currentTarget: null }, false)}
                addTransparent={this.props.addTransparent}
              />
            </div>
          </Menu>
          :
          null
        }
      </div>
    );
  }
}

export default ThematicColorPickerMenu;