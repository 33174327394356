import React from 'react';

class SortTableHeader extends React.Component {
  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="pointer">
        <svg id="sortIcon" xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 98.7 95.31">
          <path d="M-15,18.05V-7.36H-35.57L-2.21-36.11,31.16-7.36h-21V25.87" transform="translate(38.93 37.76)" fill="none" stroke="#25408f" strokeWidth="6" />
          <path d="M11.36,26.81H-10.3L23.06,55.89,56.43,26.81h-21V1" transform="translate(38.93 37.76)" fill="none" stroke="#25408f" strokeWidth="6" />
        </svg>
        </div>
        <span style={{ paddingLeft: '4px' }}>{this.props.text}</span>
      </div>
    );
  }
}

export default SortTableHeader;