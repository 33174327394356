import React from 'react';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
class DemographicLegend extends React.Component {

    _getKey (idx) {
        return 'demolegendclor' + idx
    }

    render() {
        const textStyle = {
            float: 'right',
            padding: 4
        };
        const iconStyle = {
            float: 'left'
        }
        const boldStyle = {
            fontWeight: 'bold',
            padding: 8
        }
        const twoStyle = {
            paddingBottom: 8,
            textAlign: 'center'
        }
        const legendRangeStyle = {
            fontSize: 14
        }

        const ranges = this.props.demographicLegend.ranges;
        const colors = this.props.demographicColors ||  'd3d9e9,a8b3d2,7c8cbc,5166a5,25408f'; //'ed1b34,f68b1f,ffd400,c59fc5,85bcb8';//.colors;
        const _clrs = colors.split(',');
        const variable = this.props.demographicLegend.variable;
        const outline = this.props.outlineColor;
        const linetwo = this.props.demographicLegend.linetwo;
        const demoFillOpa = this.props.demographicFillOpacity;
        const demoStrokeOpa = this.props.demographicStrokeOpacity;
        if(ranges===null)
            ranges = [];
        return (
            <div>
            <div style={boldStyle}>{variable}</div>
            {linetwo ? <div style={twoStyle}>{linetwo}</div> : null }
            {this.props.demographicLegend.ranges.map((range,idx) =>
                 <div key={'demokey_' + (4 - idx)} className="legendView-point">
                    <SVGIconComponent
                        key={this._getKey(4 - idx)}
                        color={'#' + _clrs[4 - idx]}
                        name='squareIcon'
                        outline={outline}
                        fillOpacity={demoFillOpa}
                        strokeOpacity={demoStrokeOpa}
                    ></SVGIconComponent>
                <span style={legendRangeStyle}>{ranges[4 - idx]}</span>
                </div>
            )
            }
            </div>
        );
    }
}
export default DemographicLegend
