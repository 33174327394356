import React from 'react';

class DeleteMapComponent extends React.Component {
  render() {
    return (
      <div>
        <div>Are you sure you want to delete map {this.props.deleteMapId}?</div>
        {this.props.isDeleteError ?
          <div className="errorText">Error deleting map.</div>
          : null
        }
        <div className="geocodeDiv flex">
          <button className="geocodeBtn" onClick={this.props.showDeletePanel.bind(this, false, null)}>Back</button>
          <button className="geocodeBtn" onClick={this.props.deleteMap.bind(this, this.props.deleteMapId)}>Delete</button>
        </div>
      </div>
    );
  }
}

export default DeleteMapComponent;