import React from 'react';
import TextField from '@material-ui/core/TextField';
import translateObj from '../../../../utils/translate';

class GroupNameComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false
    };

    this.saveLayerName = this.saveLayerName.bind(this);
    this.editName = this.editName.bind(this);
  }

  saveLayerName(groupId, value) {
    const groupsClone = Array.from(this.props.groups);

    groupsClone.forEach(group => {
      if (group.id === groupId) {
        group.name = value.toString();
      }
    })
    this.props.updateMapObj({ mapGroups: groupsClone });

    this.setState({
      editing: false
    });
  }

  editName() {
    this.setState({
      editing: true
    });
  }

  render() {
    const g = this.props.g;
    return (
      <div>
        {this.state.editing ?
          <TextField
            id={"layerNameInput" + g.id}
            defaultValue={g.name}
            size="small"
            variant="outlined"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.saveLayerName(g.id, e.target.value)
              }
            }}
            onBlur={(e) => {
              this.saveLayerName(g.id, e.target.value)
            }}
          />
          :
          <div className="groupNameCompDiv">
            <div className="groupNameTextDiv">{g.name}</div>
            <button className="geocodeBtnSmall margin-l5" onClick={this.editName}>{translateObj.editBtn[this.props.translate]}</button>
            <button className="geocodeBtnSmall margin-l5" onClick={this.props.sortPoints}>
              {translateObj.sortBtn[this.props.translate]}
            </button>
            <button className="geocodeBtnSmall margin-l5" onClick={this.props.arrangePoints}>
              {translateObj.arrangeBtn[this.props.translate]}
            </button>
            {this.props.originalPoints ?
            <button className="geocodeBtnSmall margin-l5" onClick={this.props.undoPoints}>
              {translateObj.undoBtn[this.props.translate]}
            </button>
            : null
            }
            </div>
        }
      </div>
    );
  }
}

export default GroupNameComponent;