import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import LabelStyleOnly from './LabelStyleOnly';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import translateObj from '../../utils/translate';

class CustomLabelPanel extends React.Component {
  constructor(props) {
    super(props);

    this.deleteAllLabels = this.deleteAllLabels.bind(this);
    this.applyStyleToAllLabels = this.applyStyleToAllLabels.bind(this);
    this.setLabelLayer = this.setLabelLayer.bind(this);
    this.toggleUseLabel = this.toggleUseLabel.bind(this);
    this.state = {
      useLayer: false,
      selLayerVal: {url:'',label:'Select layer'}
    }
  }

  componentDidMount() {
    this.props.updateMapState({ mapAddLabel: true, mapAddShield: false });
    document.getElementsByClassName('leaflet-container')[0].classList.add('mapClickCursor');
  }

  deleteAllLabels() {
    this.props.updateMapObj({ labels: [] });
  }

  applyStyleToAllLabels() {
    const labelsArrayClone = Array.from(this.props.labels);
    for (const label of labelsArrayClone) {
      label.content.color = this.props.mapAddLabelColor;
      label.content.stroke = this.props.mapAddLabelStroke;
      label.content.text = this.props.mapAddLabelText;
      label.content.fontSize = this.props.mapAddLabelFontSize;
      label.content.opacity = this.props.mapAddLabelOpacity;
      label.content.font = this.props.mapAddLabelFont;
      label.content.weight = this.props.mapAddLabelWeight;
      label.content.icon = this.props.getLabelIcon(label);
    }

    this.props.updateMapObj({ labels: labelsArrayClone });
  }

  toggleUseLabel(val){
    this.setState({useLayer:val});

    ////Add Road Label if we add more layers here, change if we add more layers
    this.props.updateMapObj({labelRoad:val});


    // if(val)
    //   this.props.updateMapObj({labelLayer:this.props.layers[0].url});
    // else
    //   this.props.updateMapObj({labelLayer:null});

  }

  setLabelLayer(e) {
    const val = e.target.value;
    this.setState({selLayerVal:val});
    this.props.updateMapObj({labelLayer:val});
  }

  componentWillUnmount() {
    this.props.updateMapState({ mapAddLabel: false });
    document.getElementsByClassName('leaflet-container')[0].classList.remove('mapClickCursor');
  }

  render() {
    const clPolygons = this.props.layers.filter(cl => cl.type === 'polygon');
    return (
      <div>
        <div className="customLabelPanel">
          <h2 className="panel-title">
            <span className="panel-icon-span">
            <svg id="starIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.63 94.63">
              <path id="Path_4164" d="M16.51,3.68c-7.1-20.22-5.4-19.73-12.33,0-21.68.43-20.6-1-3.82,11.63-6.27,20.5-7.32,19.1,10,7.18,17.77,12.23,16.1,12.8,10-7.18C37.61,2.35,37.56,4.1,16.51,3.68Z" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4165" d="M9.83-36A46.07,46.07,0,1,1-25-19.93" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
            </svg>
            </span>
            {translateObj.customLabelTitle[this.props.translate]}
            <span className="panel-close closeStyle"><CloseIcon onClick={this.props.toggleMapAddLabel} fontSize="small" /></span>
          </h2>
          <div className="panel-body">
            <div className="textAreaDiv paddingB20">
              {translateObj.clickOnMapToAddCustomLabel[this.props.translate]}
            </div>
            <LabelStyleOnly
              updateMapState={this.props.updateMapState}
              text={this.props.mapAddLabelText}
              color={this.props.mapAddLabelColor}
              stroke={this.props.mapAddLabelStroke}
              size={this.props.mapAddLabelFontSize}
              opacity={this.props.mapAddLabelOpacity}
              font={this.props.mapAddLabelFont}
              weight={this.props.mapAddLabelWeight}
              translate={this.props.translate}
              angle={this.props.mapAddLabelAngle}
              roadLabel={this.props.labelRoad}
              getLabelBoxPostions={this.props.getLabelBoxPostions}
            />
            {this.props.country==='US' ?
            <div>
            Use Road Label:
            <Checkbox
                        color="default"
                        //value={this.state.useLayer}
                        value={this.props.labelRoad}
                        inputProps={{ 'aria-label': 'use layer style' }}
                        onChange={(event) => this.toggleUseLabel(event.target.checked)}
                        //checked={this.state.useLayer}
                        checked={this.props.labelRoad}
                        //disableRipple
                    />
            </div>
            : null }
            <div className="geocodeDiv flex">
              <button
                className="geocodeBtn"
                onClick={this.deleteAllLabels}
              >
                {translateObj.deleteAllLabels[this.props.translate]}
              </button>
              <button
                className="geocodeBtn"
                onClick={this.applyStyleToAllLabels}
              >
                {translateObj.applyStyleToAll[this.props.translate]}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomLabelPanel;