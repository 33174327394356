import React, { Component } from 'react';
import SVGIconComponent from '../../MainPage/SVGIconComponent/SVGIconComponent';

export default class ReleaseNotes1Pt2 extends Component {
  render() {
    return (
      <div>
        <div className="updateMenuBody">
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Custom labels</div>
          </div>
          <div className="updateMenuFeatureDesc">Ability to have a three character custom symbol instead of a numbered dot.
                  Select <span className="updateHighlightSpan">Use Custom Label</span> in the style points panel.
                  Fill in the <span className="updateHighlightSpan">Label</span> field of any point, or select a column from your data for the <span className="updateHighlightSpan">Label</span> field during upload.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Custom layout size</div>
          </div>
          <div className="updateMenuFeatureDesc">You can now export a custom size PNG of your BOOM map.
                  Click <span className="updateHighlightSpan">Layout</span> and select <span className="updateHighlightSpan">Custom</span> from the dropdown menu.
                  Select any size from 1" x 1" up to 8" x 8".
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Add polygons/lines/circles</div>
          </div>
          <div className="updateMenuFeatureDesc">Select any of the drawing tools from the menu on the upper right side of the map.
          Draw lines, polygons, circles, or rectangles, then click a shape to edit color and opacity.  Ability to delete shapes that are not needed.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">Undo last move of a point</div>
          </div>
          <div className="updateMenuFeatureDesc">You can now undo the last move of a point by clicking the back arrow in the menu on the right side of the map.
                </div>
          <div className="updateMenuFeatureName">
            <div className="updateMenuFeatureSVG">
              <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
            </div>
            <div className="updateMenuFeatureLabel">North arrow</div>
          </div>
          <div className="updateMenuFeatureDesc">There is now a north arrow on the map.
                  To customize the location of the north arrow, or to toggle the north arrow on or off, click <span className="updateHighlightSpan">Legend</span>, then click <span className="updateHighlightSpan">Legend Options</span>.
                  There are options for <span className="updateHighlightSpan">Show North Arrow on Map</span> and <span className="updateHighlightSpan">North Arrow Position</span>.
                </div>
        </div>
      </div>
    );
  }
}