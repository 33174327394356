import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import translateObj from '../../../utils/translate';

class BasemapPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noLabels: false,
      roadsOnly: false,
      noShields: false
    }

    this.getSelectDefault = this.getSelectDefault.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNoLabels = this.handleNoLabels.bind(this);
    this.handleRoadsOnly = this.handleRoadsOnly.bind(this);
    this.handleNoShields = this.handleNoShields.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  componentDidMount() {
    let updateObj = {};
    if (this.props.basemap.includes('clean')) {
      updateObj.noLabels = true;
    }

    if (this.props.basemap.includes('_roads')) {
      updateObj.roadsOnly = true;
    }
    
    if (this.props.basemap.includes('-no-shields')) {
      updateObj.noShields = true;
    }

    this.setState(updateObj);
  }

  getSelectDefault() {
  	if (this.props.basemap.startsWith('colliers-grey-japanese')) {
      return 'colliers-grey-japanese';
    } else if (this.props.basemap.startsWith('colliers-grey')) {
      return 'colliers-grey';
    } else if (this.props.basemap.startsWith('colliers-topo-japanese')) {
      return 'colliers-topo-japanese';
    } else if (this.props.basemap.startsWith('colliers-topo')) {
      return 'colliers-topo';
    } else if (this.props.basemap.startsWith('colliers-bayview-japanese')) {
      return 'colliers-bayview-japanese';
    } else if (this.props.basemap.startsWith('colliers-bayview')) {
      return 'colliers-bayview';
    } else if (this.props.basemap.startsWith('colliers-northstar')) {
      return 'colliers-northstar';
    } else if (this.props.basemap.startsWith('colliers-northstar-japanese')) {
      return 'colliers-northstar-japanese';
    } else if (this.props.basemap.startsWith('hybrid-japanese')) {
      return 'hybrid-japanese';
	  }
    else if (this.props.basemap.startsWith('colliers-grey')) {
      return 'colliers-grey';
    } else if (this.props.basemap.startsWith('colliers-topo')) {
      return 'colliers-topo';
    } else if (this.props.basemap.startsWith('colliers-bayview')) {
      return 'colliers-bayview';
    } else if (this.props.basemap.startsWith('colliers-northstar')) {
      return 'colliers-northstar';
    } else if (this.props.basemap.startsWith('colliers-lakeside')) {
      return 'colliers-lakeside';
    } else if (this.props.basemap.startsWith('hybrid')) {
      return 'hybrid';
    } else if (this.props.basemap.startsWith('aerial')) {
      return 'aerial';
    } else if (this.props.basemap.startsWith('naip-aerial')) {
      let basemapFamily = this.props.country === 'US' ? 'naip-aerial' : 'aerial';
      return basemapFamily;
    } else if (this.props.basemap.startsWith('naip-hybrid')) {
      let basemapFamily = this.props.country === 'US' ? 'naip-hybrid' : 'hybrid';
      return basemapFamily;
          } else if (this.props.basemap.startsWith('esri-topo-jpn')) {
      return 'esri-topo-jpn';
    } else if (this.props.basemap.startsWith('esri-nav-jpn')) {
      return 'esri-nav-jpn';
    } else if (this.props.basemap.startsWith('esri-nav-eng')) {
      return 'esri-nav-eng';
    }
  }

  handleChange(event) {
    const basemapFamily = event.target.value;
    const noLabelString = basemapFamily === 'aerial' || basemapFamily === 'naip-aerial' ? '' : (this.state.noLabels ? '-clean' : '');
    const roadsOnlyString = basemapFamily === 'aerial' || basemapFamily === 'naip-aerial' ? '' : (this.state.roadsOnly ? '_roads' : '');
    const noShieldsString = basemapFamily === 'aerial' || basemapFamily === 'naip-aerial' ? '' : (this.state.noShields ? '-no-shields' : '');
    const newBasemapName = basemapFamily + noLabelString + roadsOnlyString + noShieldsString;

    this.props.updateMapObj({mapOptionsBasemap: newBasemapName});
  };

  handleNoLabels(event) {
    let noLabelString = '';
    if (event.target.checked) { //if no labels is true, roads only and no shields must be false
      this.setState({
        noLabels: true,
        roadsOnly: false,
        noShields: false
      });
      noLabelString = '-clean';
    } else {
      this.setState({
        noLabels: false
      });
    }

    const basemapFamily = this.getSelectDefault();
    this.props.updateMapObj({ mapOptionsBasemap: basemapFamily + noLabelString });
  }

  handleRoadsOnly(event) {
    let roadsOnlyString = '';
    if (event.target.checked) { //if roads only is true, no labels must be false
      this.setState({
        noLabels: false,
        roadsOnly: true
      });
      roadsOnlyString = '_roads';
    } else {
      this.setState({
        roadsOnly: false
      });
    }

    const basemapFamily = this.getSelectDefault();
    const noShieldsString = this.state.noShields ? '-no-shields' : '';
    this.props.updateMapObj({ mapOptionsBasemap: basemapFamily + roadsOnlyString + noShieldsString });
  }

  handleNoShields(event) {
    let noShieldsString = '';
    if (event.target.checked) { //if no shields is true, no labels must be false
      this.setState({
        noLabels: false,
        noShields: true
      });
      noShieldsString = '-no-shields';
    } else {
      this.setState({
        noShields: false
      });
    }

    const basemapFamily = this.getSelectDefault();
    const roadsOnlyString = this.state.roadsOnly ? '_roads' : '';
    this.props.updateMapObj({ mapOptionsBasemap: basemapFamily + roadsOnlyString + noShieldsString });
  }

  closePanel() {
    this.props.togglePanel('basemap');
  }

  render() {
    return (
      <div className="sidePanelStyle">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="basemapIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 90.78 87.07">
              <path id="Path_4389" d="M-25.48-11l.05-2,4-4,4.15.05L-5-18.94-5.07-17-7-14.93l3.85,4.07-8,8.23,0,3.87,8,2L1,7.29l-2,4.42L-3,13.34-5,23.51l-4.29-2-1.74-10-4.19-4.17,2-4-6-6.1-2.2-8.08Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="2" />
              <path id="Path_4390" d="M-.08-19l5,0v3.15S0-12.79,0-12.9s-1.8-3-1.8-3Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="2" />
              <path id="Path_4391" d="M8.85-10.9,6.61-5.25l8.28-3.62v2.18L6.61-.93,4.81,5.41l6,4.07,1.8,9.81,4.24,4.17,6.18-12L23,7.1,19,1.64,14.5-.69l4.34-.2L23,2.53l4-2.28,3,4,.72-2a34.27,34.27,0,0,1,2.23-3c.2.2,4.19,3.92,4.19,3.92l1.42-4.62.92.35L39.24-3.3l.6-1.95,3.35-3.57L41.34-10.9l2.15-1.27.79,1.22,1-2.25L48.32-15l-6-.8L29.26-17,14.69-14.87Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="2" />
              <path id="Path_4392" d="M38.51,7.12s1.41-1.86,1.38-1.92Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="2" />
              <path id="Path_4393" d="M41.9,2.06a3.74,3.74,0,0,0,1-.32Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="2" />
              <path id="Path_4394" d="M44.5,6.9" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeMiterlimit="10" strokeWidth="2" />
              <path id="Path_4395" d="M35.57,8.14,35,9.54s-1.39-1.7-1.42-1.84Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="2" />
              <path id="Path_4396" d="M35.2,13.38l8.13-4.14,2.2,6.24-2.25,6.08-3.79-4.14-4.59,0Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="2" />
              <path id="Path_4398" d="M46.37,22.69l1.17-1.61Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="2" />
              <path id="Path_4399" d="M4.19-7.43l.9-3.14Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="2" />
              <path id="Path_4400" d="M11.15-17.15,9.9-15.9Z" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeLinejoin="round" strokeWidth="4" />
              <path id="Path_4109" d="M50.82,32.89l3.73.74V-28.08L31-32.24,8.27-23.35-10.9-30.51l-22.83,9.08V40.28l22.83-9.09L2.87,35.61" transform="translate(34.98 33.53)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4114" d="M29.5,35.3A2.53,2.53,0,1,0,27,32.78,2.52,2.52,0,0,0,29.5,35.3Z" transform="translate(34.98 33.53)" stroke="#0c9ed9" fill="none" strokeWidth="2" />
              <path id="Path_4115" d="M29.56,52.85s-9.67-14.63-9.67-19.94a9.65,9.65,0,0,1,9.67-9.63h0c5.06,0,10,4.5,9.68,9.63-.14,2.5-1.7,6-3.22,9C34.6,44.73,33.21,47,33.21,47" transform="translate(34.98 33.53)" stroke="#0c9ed9" fill="none" strokeWidth="2" />
            </svg>
          </span>
          {translateObj.selectBasemapTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small"/></span>
          </h2>
          <div className="panel-body">
            <Select
              labelId="basemap-select"
              id="basemap-select"
              defaultValue={this.getSelectDefault}
              onChange={this.handleChange}
              variant="outlined"
              autoWidth={true}
            >
              <MenuItem value={'aerial'}>Aerial</MenuItem>
              {this.props.country === 'JPN' ?
                <MenuItem value={'esri-topo-jpn'}>ESRI Topo - Japanese</MenuItem>
                : null
              }
              {this.props.country === 'JPN' ?
                <MenuItem value={'esri-nav-jpn'}>ESRI Navigation - Japanese</MenuItem>
                : null
              }
              {this.props.country === 'JPN' ?
                <MenuItem value={'esri-nav-eng'}>ESRI Navigation - English</MenuItem>
                : null
              }
              {this.props.country === 'US' ?
                <MenuItem value={'naip-aerial'}>NAIP Aerial</MenuItem>
                : null
              }
			        {this.props.country !== "JPN" &&
                <MenuItem value={'colliers-grey'}>Colliers Grey</MenuItem>
              }
              {this.props.country !== "JPN" &&
                <MenuItem value={'colliers-topo'}>Colliers Topographic</MenuItem>
              }
              {this.props.country !== "JPN" &&
                <MenuItem value={'colliers-bayview'}>Colliers Bayview</MenuItem>
              }
              {this.props.country !== "JPN" &&
                <MenuItem value={'colliers-northstar'}>Colliers Northstar</MenuItem>
              }
              {this.props.country !== "JPN" && 
                <MenuItem value={'colliers-lakeside'}>Colliers Lakeside</MenuItem>
              }
              {this.props.country !== "JPN" &&
                <MenuItem value={'hybrid'}>Hybrid</MenuItem>
              }
              {this.props.country === 'US' ?
                <MenuItem value={'naip-hybrid'}>NAIP Hybrid</MenuItem>
                : null
              }
            </Select>
            {
             this.props.basemap === 'aerial' || this.props.basemap === 'naip-aerial' ?
             null
             :
              <div style={{ display: 'flex' }}>
                <div className="logoListCheckbox">
                  <Checkbox
                    color="default"
                    defaultValue="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onChange={(event) => this.handleNoLabels(event)}
                    checked={this.state.noLabels}
                    disableRipple
                  />
                  <span>{translateObj.noLabelsCheckbox[this.props.translate]}</span>
                </div>
                <div className="logoListCheckbox">
                  <Checkbox
                    color="default"
                    value="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onChange={(event) => this.handleRoadsOnly(event)}
                    checked={this.state.roadsOnly}
                    disableRipple
                  />
                  <span>{translateObj.roadsOnlyCheckbox[this.props.translate]}</span>
                </div>
                <div className="logoListCheckbox">
                  <Checkbox
                    color="default"
                    value="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onChange={(event) => this.handleNoShields(event)}
                    checked={this.state.noShields}
                    disableRipple
                  />
                   <span>{translateObj.noShieldsCheckbox[this.props.translate]}</span>
                </div>
              </div>
            }
            <div className="basemapNote">
				{translateObj.basemapNote[this.props.translate]}
            </div>
          </div>
      </div>
    );
  }
}

export default BasemapPanel;