import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

class LogoCheckListPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoCount: 0
    }
    this.checkForSelectedRetailer = this.checkForSelectedRetailer.bind(this);
  }

  componentDidMount() {
    this.getLogoCount();
  }

  toggleLogoCheckbox(e, retailer) {
    if (e.target.checked) {
      const suggestion = {
        categories: "",
        chainid: retailer.chainid,
        chainname: retailer.chainname,
        naics: "000000",
        primarycategory: "",
        sic: "0000"
      };
      this.props.setSelectedRetailer({}, suggestion);
      
      //update count
      let newCount = this.state.logoCount;
      newCount = newCount + parseInt(retailer.count);
      this.setState({ logoCount: newCount });
    } else {
      this.props.removeRetailer(retailer.chainid, retailer.chainname, 'retailer');
      
      //update count
      let newCount = this.state.logoCount;
      newCount = newCount - parseInt(retailer.count);
      this.setState({ logoCount: newCount });
    }
  }

  checkForSelectedRetailer(retailer) {
    const tempSelectedClone = Array.from(this.props.selectedRetailer);
    const filteredClone = tempSelectedClone.filter(ret => ret.chainid === retailer.chainid);
    if (filteredClone.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getLogoCount() {
    const tempSelectedClone = Array.from(this.props.selectedRetailer);
    const searchAreaListClone = Array.from(this.props.searchAreaList);
    let newCount = this.state.logoCount;

    for (const selected of tempSelectedClone) {
      for (const searchAreaItem of searchAreaListClone) {
        if (searchAreaItem.chainid === selected.chainid) {
          newCount = newCount + parseInt(searchAreaItem.count);
        }
      }
    }

    this.setState({ logoCount: newCount });
  }

  getTotalCount() {
    const searchAreaListClone = Array.from(this.props.searchAreaList);
    let totalCount = 0;
    totalCount = searchAreaListClone.reduce((accum,item) => accum + parseInt(item.count), 0);
    return totalCount || 0;
  }

  selectAll(event) {
    let add = true;
    if (event.target.checked) {
      const newCount = this.getTotalCount();
      this.setState({
        logoCount: newCount
      });
    } else {
      add = false;
      this.setState({
        logoCount: 0
      });
    }
    this.props.setOrRemoveAllRetailersFromList(add);
  }

  render() {
    return (
      <div>
        <div className="logoListContainerDiv">
          <div>Select retailers:</div>
          <div className="logoCountDiv">Logo count: {this.state.logoCount}</div>
        </div>
        {this.state.logoCount > process.env.REACT_APP_POINTLIMIT ?
           <div className="logoListMaxError">
            Too many logos.  The maximum is {process.env.REACT_APP_POINTLIMIT} logos.
          </div>
          : null
        }
        <div className="logoListDiv">
          <div key={"retailer_selectAll"} className="logoListCheckbox">
            <Checkbox
              color="default"
              value="default"
              inputProps={{ 'aria-label': 'checkbox with default color' }}
              onChange={(event) => this.selectAll(event)}
              disableRipple
            />
            <span>Select all</span><span className="paddingL5">({this.getTotalCount()})</span>
          </div>
          {this.props.searchAreaList.map((retailer, index) =>
            <div key={"retailer_" + index} className="logoListCheckbox">
              <Checkbox
                key={"retailerChkbx_" + index + "_" + this.checkForSelectedRetailer(retailer)}
                color="default"
                value="default"
                inputProps={{ 'aria-label': 'checkbox with default color' }}
                onChange={(event) => this.toggleLogoCheckbox(event, retailer)}
                disableRipple
                checked={this.checkForSelectedRetailer(retailer)}
              />
              <span>{retailer.chainname}</span><span className="paddingL5">({retailer.count})</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LogoCheckListPanel;