const translateObj = {
    "mainConfirmMessage": {
        eng: "You may have unsaved work. Please check before leaving.",
        jpn: "未保存の作業がある可能性があります。出発前にご確認ください"
    },
    "beforeUnloadMessage": {
        eng: "You may have unsaved work. Please check before leaving.",
        jpn: "未保存の作業がある可能性があります。出発前にご確認ください。"
    },
    "supportCenter": {
        eng: "BOOM Support Center",
        jpn: "BOOM ヘルプ"
    },
    "uploadSuccess": {
        eng: "Upload successful!",
        jpn: "アップロードが成功しました!"
    },
    "feedbackAndSupport": {
        eng: "Feedback and support",
        jpn: "フィードバックとサポート"
    },
    "help": {
        eng: "Help",
        jpn: "ヘルプ"
    },
    "signOut": {
        eng: "Sign out",
        jpn: "サインアウト"
    },
    "welcome": {
        eng: "Welcome",
        jpn: "ようこそ"
    },
    "historyBtn": {
        eng: "HISTORY",
        jpn: "履歴"
    },
    "basemapBtn": {
        eng: "BASEMAP",
        jpn: "ベースマップ"
    },
    "layoutBtn": {
        eng: "LAYOUT",
        jpn: "レイアウト"
    },
    "addPointsBtn": {
        eng: "ADD POINTS",
        jpn: "ポイントを追加する"
    },
    "addDataBtn": {
        eng: "ADD DATA",
        jpn: "データの追加"
    },
    "legendBtn": {
        eng: "LEGEND",
        jpn: "凡例"
    },
    "saveBtn": {
        eng: "SAVE",
        jpn: "保存"
    },
    "printBtn": {
        eng: "PRINT",
        jpn: "出力"
    },
    "startOverBtn": {
        eng: "START OVER",
        jpn: "やり直す"
    },
    "errorLoadingMapHistory": {
        eng: "Error loading map history.",
        jpn: "マップ履歴のロード中にエラーが発生しました。"
    },
    "mapHistoryTitle": {
        eng: "Map History",
        jpn: "作成履歴"
    },
    "enterMapId": {
        eng: "Enter map id",
        jpn: "マップIDを入力してください"
    },
    "enterSearchTerm": {
        eng: "Enter search term",
        jpn: "検索語を入力してください"
    },
    "errorLoadingMap": {
        eng: "Error loading map",
        jpn: "マップの読み込みエラー"
    },
    "backBtn": {
        eng: "Back",
        jpn: "戻る"
    },
    "loadMapBtn": {
        eng: "Load Map",
        jpn: "マップのロード"
    },
    "selectBasemapTitle": {
        eng: "Select Basemap",
        jpn: "ベースマップの選択"
    },
    "basemapNote": {
        eng: "*Please note: slow network connections may result in some basemap tiles loading incorrectly/incompletely.  To force missing tiles to load, or to center map on the correct location, click and grab (pan) the map slightly in any direction.",
        jpn: "*注意: ネットワーク接続が遅いと、一部のベースマップ タイルが正しく読み込まれない、または不完全になる可能性があります。欠落しているタイルを強制的に読み込むか、マップを正しい位置に中央に配置するには、マップをクリックして任意の方向に少しグラブ (パン) します。"
    },
    "selectLayoutTitle": {
        eng: "Select Layout",
        jpn: "レイアウトの選択"
    },
    "startOverTitle": {
        eng: "Start Over",
        jpn: "最初からやり直す"
    },
    "doYouNeedToSave": {
        eng: "Do you need to save your map?",
        jpn: "マップを保存する必要がありますか?"
    },
    "yesBtn": {
        eng: "Yes",
        jpn: "はい"
    },
    "noStartOverBtn": {
        eng: "No, start over",
        jpn: "いいえ、最初からやり直します"
    },
    "printMapTitle": {
        eng: "Print Map",
        jpn: "地図を出力する"
    },
    "outputType": {
        eng: "Output type:",
        jpn: "出力タイプ:"
    },
    "noPrintMapBtn": {
        eng: "No, print map",
        jpn: "地図を出力"
    },
    "genericPrintError": {
        eng: "There was an error.  Please try again shortly.  If the problem persists please contact support at colliers.gis@colliers.com",
        jpn: "エラーが発生しました。しばらくしてからもう一度お試しください。問題が解決しない場合は、サポート (colliers.gis@colliers.com) までご連絡ください。"
    },
    "serverBusyError": {
        eng: "There was a problem and the server may be busy, please try again shortly",
        jpn: "問題が発生したため、サーバーがビジー状態になっている可能性があります。しばらくしてからもう一度お試しください。"
    },
    "startMapError": {
        eng: "Error in startMap. Result succeeded, but status not OK.",
        jpn: "startMap でエラーが発生しました。結果は成功しましたが、ステータスは OK ではありませんでした"
    },
    "startMapNoResultError": {
        eng: "Error in startMap. No result.",
        jpn: "startMap でエラーが発生しました。検索結果はありません。"
    },
    "retrieveMapError": {
        eng: "Error in retrieveMap. Result succeeded, but status not OK.",
        jpn: "retrieveMap でエラーが発生しました。結果は成功しましたが、ステータスは OK ではありませんでした。"
    },
    "retrieveMapNoResultError": {
        eng: "Error in retrieveMap. No result.",
        jpn: "retrieveMap でエラーが発生しました。検索結果はありません。"
    },
    "saveMapTitle": {
        eng: "Save Map",
        jpn: "マップを保存"
    },
    "mapStatus": {
        eng: "Map status",
        jpn: "マップのステータス"
    },
    "enterMapTitle": {
        eng: "Enter map title",
        jpn: "マップのタイトルを入力してください"
    },
    "enterProjectName": {
        eng: "Enter project name",
        jpn: "プロジェクト名を入力してください"
    },
    "cancelBtn": {
        eng: "Cancel",
        jpn: "キャンセル"
    },
    "saved": {
        eng: "Saved",
        jpn: "保存されました"
    },
    "unsaved": {
        eng: "Unsaved",
        jpn: "未保存"
    },
    "mapId": {
        eng: "Map ID",
        jpn: "マップID"
    },
    "errorSavingMap": {
        eng: "Error saving map.",
        jpn: "マップの保存中にエラーが発生しました"
    },
    "copyBtn": {
        eng: "Copy",
        jpn: "コピー"
    },
    "updateBtn": {
        eng: "Update",
        jpn: "アップデート"
    },
    "addPointsTitle": {
        eng: "Add Points",
        jpn: "ポイントを追加する"
    },
    "targetLayer": {
        eng: "Target Layer",
        jpn: "対象層"
    },
    "addLayerBtn": {
        eng: "Add Layer",
        jpn: "レイヤーを追加"
        //jpn: "レイヤーを追加する"
    },
    "clickOnMapBtn": {
        eng: "Click on Map",
        jpn: "地図をクリック"
        //jpn: "地図をクリックしてください"
    },
    "uploadLocationsBtn": {
        eng: "Upload Locations",
        jpn: "場所をアップロード"
        //jpn: "場所をアップロードする"
    },
    "typeAddressBtn": {
        eng: "Type Address",
        jpn: "住所を入力"
        //jpn: "アドレスを入力してください"
    },
    "saveBtnLowerCase": {
        eng: "Save",
        jpn: "保存"
    },
    "addGroupTitle": {
        eng: "Add Group",
        jpn: "グループの追加"
    },
    "enterGroupName": {
        eng: "Enter group name",
        jpn: "グループ名を入力してください"
    },
    "clickOnMapTitle": {
        eng: "Click on Map",
        jpn: "地図をクリックします"
    },
    "clickOnMapDesc": {
        eng: "Click on the map to add a point or change point.",
        jpn: "地図上をクリックしてポイントを追加またはポイントを変更します。"
    },
    "doneBtn": {
        eng: "Done",
        jpn: "終わり"
    },
    "uploadLocationsTitle": {
        eng: "Upload Locations",
        jpn: "場所をアップロードする"
    },
    "uploadLocationsDesc": {
        eng: "Upload csv or xlsx of addresses, latitude/longitude coordinates, or intersections.",
        jpn: "住所、緯度経度座標、交差点などのcsvまたはxlsxをアップロードします。"
    },
    "selectFileBtn": {
        eng: "Select File",
        jpn: "ファイルを選ぶ"
    },
    "mapColumnsDesc": {
        eng: "Select the correct column headers from your data in each of the dropdowns below.  If the fields in the dropdown are already correct, click Geocode to continue.",
        jpn: "以下の各ドロップダウンでデータから正しい列ヘッダーを選択します。ドロップダウンのフィールドがすでに正しい場合は、「地理コード」をクリックして続行します。"
    },
    "geocodeBtn": {
        eng: "Geocode",
        jpn: "ジオコード"
    },
    "oopsWrongTryAgain": {
        eng: "Oops! Something went wrong. Please try again.",
        jpn: "おっとっと！何か問題が発生しました。もう一度試してください。"
    },
    "oopsWrongCheckDataTryAgain": {
        eng: "Oops! Something went wrong. Please check data and try again.",
        jpn: "おっとっと！何か問題が発生しました。データを確認して再試行してください"
    },
    "typeAddressTitle": {
        eng: "Type Address",
        jpn: "タイプアドレス"
    },
    "findAddressOrPlace": {
        eng: "Find address or place",
        jpn: "住所や場所を探す"
    },
    "addtoMapBtn": {
        eng: "Add to Map",
        jpn: "地図に追加"
    },
    "zoomToAddressOrPlace": {
        eng: "Zoom to address or place",
        jpn: "住所または場所にズームする"
    },
    "formatLegendTitle": {
        eng: "Format Legend",
        jpn: "凡例の書式設定"
    },
    "changeToPrintViewBtn": {
        eng: "Change to Print View",
        jpn: "印刷ビューに変更"
    },
    "legendOptionsBtn": {
        eng: "Legend Options",
        jpn: "凡例のオプション"
    },
    "editBtn": {
        eng: "Edit",
        jpn: "編集"
    },
    "editPointDataTitle": {
        eng: "Edit Point Data",
        jpn: "ポイントデータの編集"
    },
    "addEditPointTooltip": {
        eng: "Add/Edit Point Rings/Routes",
        jpn: "ポイント リング/ルートの追加/編集"
    },
    "editPointDataTooltip": {
        eng: "Edit point data",
        jpn: "ポイントデータの編集"
    },
    "editPointStyleTooltip": {
        eng: "Edit point style",
        jpn: "ポイントのスタイルを編集する"
    },
    "deletePointTooltip": {
        eng: "Delete point",
        jpn: "ポイントの削除"
    },
    "changeToButton": {
        eng: "Change to ",
        jpn: ""
    },
    "changeToPrintViewButton2": {
        eng: "Print View",
        jpn: "印刷ビューに変更"
    },
    "changeToFormatViewButton": {
        eng: "Format View",
        jpn: "フォーマットビューに変更"
    },
    "showLegendOnMap": {
        eng: "Show Legend on Map",
        jpn: "地図上に凡例を表示"
    },
    "twoColumnLegend": {
        eng: "Two Column Legend",
        jpn: "2 列の凡例"
    },
    "legendSize": {
        eng: "Legend Size",
        jpn: "凡例のサイズ"
    },
    "legendPosition": {
        eng: "Legend Position",
        jpn: "凡例の位置"
    },
    "showNorthArrowOnMap": {
        eng: "Show North Arrow on Map",
        jpn: "地図上に北向き矢印を表示"
    },
    "northArrowPosition": {
        eng: "North Arrow Position",
        jpn: "北向き矢印の位置"
    },
    "addEditGroupRingsTitle": {
        eng: "Add/Edit Group Rings & Routes",
        jpn: "グループリングとルートの追加/編集"
    },
    "addEditRingBtn": {
        eng: "Add/Edit Ring",
        jpn: "リングの追加/編集"
    },
    "noRingsAppliedToGroup": {
        eng: "No rings applied to whole group.",
        jpn: "グループ全体にリングは適用されません。"
    },
    "ringSpan": {
        eng: "ring",
        jpn: "指輪"
    },
    "addEditRouteBtn": {
        eng: "Add/Edit Route",
        jpn: "ルートの追加/編集"
    },
    "noRoutesAppliedToGroup": {
        eng: "No routes applied to whole group.",
        jpn: "グループ全体に適用されるルートはありません。"
    },
    "minuteRingSpan": {
        eng: "minute ring",
        jpn: "分間の呼び出し音"
    },
    "addEditLayerRingsTooltip": {
        eng: "Add/Edit Layer Rings/Routes",
        jpn: "レイヤーリング/ルートの追加/編集"
    },
    "editLayerStyleTooltip": {
        eng: "Edit layer style",
        jpn: "レイヤースタイルを編集する"
    },
    "deleteLayerTooltip": {
        eng: "Delete layer",
        jpn: "レイヤーの削除"
    },
    "moreLayerOptionsTooltip": {
        eng: "More layer options",
        jpn: "その他のレイヤーオプション"
    },
    "useLayerStyle": {
        eng: "Use Layer Style",
        jpn: "レイヤースタイルを使用する"
    },
    "layerNameInLegend": {
        eng: "Layer Name in Legend",
        jpn: "凡例のレイヤー名"
    },
    "pointsInLegend": {
        eng: "Points in Legend",
        jpn: "凡例のポイント"
    },
    "layerInLegend": {
        eng: "Layer in Legend",
        jpn: "凡例のレイヤー"
    },
    "showOnMap": {
        eng: "Show on Map",
        jpn: "地図上に表示"
    },
    "showLegendDataColumn": {
        eng: "Show legend data column",
        jpn: "凡例データ列を表示"
    },
    "startNumberingAt": {
        eng: "Start numbering at:",
        jpn: "番号付けを開始する場所:"
    },
    "collapseLayerTooltip": {
        eng: "Collapse layer",
        jpn: "レイヤーを折りたたむ"
    },
    "expandLayerTooltip": {
        eng: "Expand layer",
        jpn: "レイヤーを展開する"
    },
    "deleteLayerTitle": {
        eng: "Delete Layer",
        jpn: "レイヤーの削除"
    },
    "areYouSure": {
        eng: "Are you sure?",
        jpn: "本気ですか？"
    },
    "deleteBtn": {
        eng: "Delete",
        jpn: "消去"
    },
    "styleLayersTitle": {
        eng: "Style Layers",
        jpn: "スタイルレイヤー"
    },
    "stylePointsTitle": {
        eng: "Style Points",
        jpn: "スタイルポイント"
    },
    "currentIcon": {
        eng: "Current Icon:",
        jpn: "現在のアイコン:"
    },
    "useCustomLabel": {
        eng: "Use Custom Label",
        jpn: "カスタムラベルを使用する"
    },
    "chooseIconColor": {
        eng: "Choose Icon Color:",
        jpn: "アイコンの色を選択してください:"
    },
    "chooseIcon": {
        eng: "Choose Icon:",
        jpn: "アイコンを選択:"
    },
    "chooseTextColor": {
        eng: "Choose Text Color:",
        jpn: "テキストの色を選択します:"
    },
    "chooseSize": {
        eng: "Choose Size:",
        jpn: "サイズを選択してください:"
    },
    "deleteRingBtn": {
        eng: "Delete Ring",
        jpn: "リングの削除"
    },
    "ringType": {
        eng: "Type:",
        jpn: "タイプ："
    },
    "drivingTimeOpt": {
        eng: "Driving Time",
        jpn: "運転時間"
    },
    "drivingDistanceOpt": {
        eng: "Driving Distance",
        jpn: "走行距離"
    },
    "truckingTimeOpt": {
        eng: "Trucking Time",
        jpn: "トラック輸送時間"
    },
    "truckingDistanceOpt": {
        eng: "Trucking Distance",
        jpn: "トラック輸送距離"
    },
    "walkingTimeOpt": {
        eng: "Walking Time",
        jpn: "歩行時間"
    },
    "walkingDistanceOpt": {
        eng: "Walking Distance",
        jpn: "歩行距離"
    },
    "autoLabel": {
        eng: "Auto label",
        jpn: "自動ラベル"
    },
    "fill": {
        eng: "fill",
        jpn: "埋める"
    },
    "fillOpacity": {
        eng: "fill opacity:",
        jpn: "塗りつぶしの不透明度:"
    },
    "outline": {
        eng: "outline",
        jpn: "枠線"
        //jpn: "概要"
    },
    "outlineOpacity": {
        eng: "outline opacity:",
        jpn: "輪郭の不透明度:"
    },
    "label": {
        eng: "Label",
        jpn: "ラベル"
    },
    "placement": {
        eng: "placement:",
        jpn: "配置:"
    },
    "bottom": {
        eng: "Bottom",
        jpn: "底"
    },
    "top": {
        eng: "Top",
        jpn: "上"
    },
    "left": {
        eng: "Left",
        jpn: "左"
    },
    "right": {
        eng: "Right",
        jpn: "右"
    },
    "addEditRingsRoutesTitle": {
        eng: "Add/Edit Rings & Routes",
        jpn: "リングとルートの追加/編集"
    },
    "noRingsAddedToThisPoint": {
        eng: "No rings added to this point.",
        jpn: "このポイントにはリングは追加されていません."
    },
    "noRoutesAddedToThisPoint": {
        eng: "No routes added to this point.",
        jpn: "このポイントに追加されたルートはありません."
    },
    "customLabelTitle": {
        eng: "Add Custom Label",
        jpn: "カスタムラベルの追加"
    },
    "clickOnMapToAddCustomLabel": {
        eng: "Click on the map to add a custom label.",
        jpn: "マップをクリックしてカスタム ラベルを追加します。"
    },
    "useLayerForLabels": {
        eng: "Use Layer for Labels",
        jpn: "ラベルにレイヤーを使用する"
    },
    "deleteAllLabels": {
        eng: "Delete All Labels",
        jpn: "すべてのラベルを削除"
    },
    "applyStyleToAll": {
        eng: "Apply Style to All",
        jpn: "すべてにスタイルを適用"
    },
    "styleLabelsTitle": {
        eng: "Style Labels",
        jpn: "スタイルラベル"
    },
    "addCustomShieldTitle": {
        eng: "Add Custom Shield",
        jpn: "カスタムシールドを追加"
    },
    "clickOnHighway": {
        eng: "Click on a highway or interstate to add a custom shield.",
        jpn: "高速道路または州間高速道路をクリックして、カスタム シールドを追加します。"
    },
    "noteCustomShields": {
        eng: "Note: Custom shields are not intended to match the styles of shields associated with a particular basemap.  For best results, use a basemap style with no shields.",
        jpn: "注: カスタム シールドは、特定のベースマップに関連付けられたシールドのスタイルと一致することを目的としたものではありません。最良の結果を得るには、シールドのないベースマップ スタイルを使用してください。"
    },
    "manualMode": {
        eng: "Manual Mode",
        jpn: "マニュアルモード"
    },
    "deleteAllShields": {
        eng: "Delete All Shields",
        jpn: "すべてのシールドを削除"
    },
    "styleShieldsTitle": {
        eng: "Style Shields",
        jpn: "スタイルシールド"
    },
    "font": {
        eng: "font",
        jpn: "フォント"
    },
    "bold": {
        eng: "bold",
        jpn: "太字"
        //jpn: "大胆な"
    },
    "color": {
        eng: "color",
        jpn: "色"
    },
    "fontSize": {
        eng: "font size",
        jpn: "フォントサイズ"
    },
    "opacity": {
        eng: "opacity",
        jpn: "不透明度"
    },
    "resizeRectanglesTitle": {
        eng: "Resize Rectangles",
        jpn: "長方形のサイズを変更する"
    },
    "clickAndDragCorners": {
        eng: "Click and drag corners to resize rectangle.",
        jpn: "角をクリックしてドラッグし、長方形のサイズを変更します。"
    },
    "noteBold": {
        eng: "Note",
        jpn: "注記"
    },
    "featureOnlyWorksOnRect": {
        eng: "this feature only works on rectangles.  Other four-sided polygons will be converted to rectangles with this tool.",
        jpn: "この機能は長方形に対してのみ機能します。他の四辺多角形はこのツールで長方形に変換されます。"
    },
    "styleShapesTitle": {
        eng: "Style Shapes",
        jpn: "スタイルシェイプ"
    },
    "size": {
        eng: "size",
        jpn: "サイズ"
    },
    "type": {
        eng: "type",
        jpn: "タイプ"
    },
    "distance": {
        eng: "Distance",
        jpn: "距離"
    },
    "addDataTitle": {
        eng: "Add Data",
        jpn: "データの追加"
    },
    "addEarthquakeBtn": {
        eng: "Add Earthquake Layer",
        jpn: "地震レイヤーを追加"
    },
    "addEarthquakeTitle": {
        eng: "Add Earthquake Layer",
        jpn: "地震レイヤーを追加"
    },
    "earthquakeLayerDesc": {
        eng: "This layer predicts the intensity of shaking in the event of great megathrust earthquakes along the Nankai Trough off the Pacific coast of Japan, representing the maximum expected intensity of shaking for each area that is based upon analysis of five potential earthquake scenarios assessed and researched by the Disaster Management Bureau, Cabinet Office, Government of Japan. The shaking intensity displayed is categorized by ten intensity classes (0, 1, 2, 3, 4, 5-lower, 5-upper, 6-lower, 6-upper and 7) of the JMA Seismic Intensity Scale defined by the Japan Meteorological Agency.",
        jpn: "内閣府 南海トラフの巨大地震モデル検討会が公開した、南海トラフ巨大地震で想定される震度についての報告内容を使用した Web マップです。想定される地震（ケース 1 ～ 5）のうち、最大震度（最大クラス）となる場合の震度を可視化しています。震度の定義には気象庁震度階級（0、1、2、3、4、5 弱、5 強、6 弱、6 強、7）が使用されています。"
    },
    "removeLayerBtn": {
        eng: "Remove Layer",
        jpn: "レイヤーの削除"
    },
    "noLabelsCheckbox": {
        eng: "No Labels",
        jpn: "ラベルなし"
    },
    "roadsOnlyCheckbox": {
        eng: "Roads Only",
        jpn: "道路のみ"
    },
    "noShieldsCheckbox": {
        eng: "No Shields",
        jpn: "道路番号なし"
    },
    "typeCoordsBtn": {
        eng: "Type Coordinates",
        jpn: "座標を入力"
        //jpn: "タイプ座標"
    },
    "latitudePlaceholder": {
        eng: "Latitude",
        jpn: "緯度"
    },
    "longitudePlaceholder": {
        eng: "Longitude",
        jpn: "経度"
    },
    "importGeojsonBtn": {
        eng: "Import GeoJson",
        jpn: "GeoJSONをインポートする"
    },
    "importFileTitle": {
        eng: "Import File",
        jpn: "インポートファイル"
    },
    "uploadGeojsonMsg": {
        eng: "Upload geojson of json file of points and polygons",
        jpn: "ポイントとポリゴンのjsonファイルのgeojsonをアップロードします"
    },
    "selectPolyPointStyle": {
        eng: "Select style for polygons and polylines",
        jpn: "ポリゴンとポリラインのスタイルを選択します"
    },
    "goBtn": {
        eng: "Go",
        jpn: "行く"
    },
    "excelPrintOption": {
        eng: "Excel (Point Data Only)",
        jpn: "Excel（ポイントデータのみ"
    },
    "geojsonPrintOption": {
        eng: "GeoJson",
        jpn: "ジオジェソン"
    },
    "sortBtn": {
        eng: "Sort",
        jpn: "並べ替え"
        //jpn: "選別"
    },
    "arrangeBtn": {
        eng: "Arrange",
        jpn: "アレンジ"
        //jpn: "整える"
    },
    "undoBtn": {
        eng: "Undo",
        jpn: "元に戻す"
    }
};

export default translateObj;