import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

class SelectedRetailerRow extends React.Component {
  render() {
    return (
      <div key={this.props.retailer.chainid} className="selectedRetailerRowDiv">
        <span>
          {this.props.retailer.chainname}
        </span>
        <span className="selectedRetailerCancelSpan">
          <CancelIcon
            style={{ fontSize: '16px' }}
            onClick={this.props.removeRetailer.bind(this, this.props.retailer.chainid, this.props.retailer.chainname, this.props.type)}
          ></CancelIcon>
        </span>
      </div>
    );
  }
}

export default SelectedRetailerRow;