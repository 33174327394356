import React from 'react';
import axios from 'axios';

class SearchLogoAreaMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevMapBounds: this.props.mapObj.mapBounds
    }
  }

  componentDidUpdate() {
    if (this.state.prevMapBounds !== this.props.mapObj.mapBounds) {
      if (this.props.gettingSearchAreaLogos) {
        this.getLogosFromSearchArea();
      }
      this.setState({
        prevMapBounds: this.props.mapObj.mapBounds
      });
    }
  }

  async getLogosFromSearchArea() {
    if (this.props.mapObj.mapZoom < 7) {
      this.props.updateLogoState({ isError: true, errorType: 'logoList', gettingSearchAreaLogos: false });
      this.props.updateMapObj({ isSaving: false });
      return;
    }

    this.props.updateIsSaving('isSaving', true);

    const sendObj = {
      mapbounds: this.props.mapObj.mapBounds,
      logotype: "fullcolor",
      mapid: this.props.mapObj.mapId
    };
    this.props.updateLogoState({
      retailerLoading: true,
      isError: false,
      gettingSearchAreaLogos: false
    });

    axios.post(process.env.REACT_APP_APIURL + "/retailPointsFromBounds", sendObj, { headers: this.props.headers })
      .then((result) => {
        this.props.updateLogoState({ retailerLoading: false, showSearchAreaLogoDiv: true, searchAreaList: result.data });
        this.props.updateIsSaving('isSaving', false);
        this.props.updateMapObj({ isSaving: false });
      }).catch(error => {
        console.log(error);
        this.props.updateLogoState({
          retailerLoading: false,
          isError: true,
          errorType: 'generic'
        });
        this.props.updateIsSaving('isSaving', false);
        this.props.updateMapObj({ isSaving: false });
      });
  }

  toggleOptionsPanel(e, bool) {
    this.props.updateLogoState({ gettingSearchAreaLogos: true });
    this.props.updateMapObj({ isSaving: true });
  }

  render() {
    return (
      <div className="retailerOuterDiv padding-t20">
        <div className="retailerLabel">Search Area: </div>
        <div>
          <button className="geocodeBtnSmall" onClick={(e) => this.toggleOptionsPanel(e)}>Get Logo List</button>
        </div>
      </div>
    );
  }
}

export default SearchLogoAreaMenu;