import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class FAQComponent extends Component {
  render() {
    const id = 'panel4' + this.props.id + '-header' || "id";
    const aria = 'panel4' + this.props.id + '-content' || "content";
    const question = this.props.question || "question";
    const answer = this.props.answer || "answer";

    return (
      <div className="method-grid-item">
        <Accordion className="innerExpansionPanel" square>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={aria} id={id}>
            <span className="blueTitle">Q:</span><span className="faqQ">{question}</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faqAText">
              <span className="blueTitle faqApadding">A:</span><span className="faqA">{answer}</span>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}