import React from 'react';
import { TextField } from '@material-ui/core';
import ColorPickerMenu from '../SideBar/ColorPicker/ColorPickerMenu';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import translateObj from '../../utils/translate';
import LabelPosition from './LabelPosition';
import { Label } from '@material-ui/icons';


class LabelStyleOnly extends React.Component {
  constructor(props) {
    super(props);

    this.setFillColor = this.setFillColor.bind(this);
    this.setStrokeColor = this.setStrokeColor.bind(this);
    this.setOpacity = this.setOpacity.bind(this);
    this.setMapAddLabelFontSize = this.setMapAddLabelFontSize.bind(this);
    this.selectFontFamily = this.selectFontFamily.bind(this);
    this.setIconOffset = this.setIconOffset.bind(this);
    this.state = {
      labelPosition: this.props.currentLabel?.content?.offsetPosition || this.props.offsetPosition || null
    }
  }

  

  setMapAddLabelText(e) {
    const newTextString = e.target.value;
    if (this.props.individualStyle) {
      if (this.props.currentLabel) {
        this._updateLabelsArray('text', newTextString);
      }
    } else {
      this.props.updateMapState({ mapAddLabelText: newTextString });
    }
  }

  setStrokeColor(stroke) {
    if (this.props.individualStyle) {
      if (this.props.currentLabel) {
        this._updateLabelsArray('stroke', stroke);
      }
    } else {
      this.props.updateMapState({ mapAddLabelStroke: stroke });
    }
  }

  setFillColor(fill) {
    if (this.props.individualStyle) {
      if (this.props.currentLabel) {
        this._updateLabelsArray('color', fill);
      }
    } else {
      this.props.updateMapState({ mapAddLabelColor: fill });
    }
  }

  setIconOffset(val) {
    this.state.labelPosition = val;
    if (this.props.individualStyle) {
      if (this.props.currentLabel) {
        this._updateLabelsArray('offsetPosition', val);
      }
    }
    else {
      this.props.updateMapState({mapAddLabelPosition: val});
    }
  }

  setAngle(value) {
    if (this.props.individualStyle) {
      if (this.props.currentLabel) {
        this._updateLabelsArray('angle', value);
      }
    }
    else {
      this.props.updateMapState({mapAddLabelAngle: value});
    }
  }

  setOpacity(value) {
    let userVal = value || 0;
    if (value > 1) {
      userVal = 1;
    } else if (value < 0) {
      userVal = 0;
    }

    const num = Math.round((userVal) * 100) / 100;

    if (this.props.individualStyle) {
      if (this.props.currentLabel) {
        this._updateLabelsArray('opacity', num);
      }
    } else {
      this.props.updateMapState({ mapAddLabelOpacity: num });
    }
  }

  setMapAddLabelFontSize(e) {
    const newFontSize = e.target.value;
    if (this.props.individualStyle) {
      if (this.props.currentLabel) {
        this._updateLabelsArray('fontSize', newFontSize);
      }
    } else {
      this.props.updateMapState({ mapAddLabelFontSize: newFontSize });
    }
  }

  selectFontFamily(e) {
    if (this.props.individualStyle) {
      if (this.props.currentLabel) {
        this._updateLabelsArray('font', e.target.value);
      }
    } else {
      this.props.updateMapState({ mapAddLabelFont: e.target.value });
    }
  }

  toggleFontWeight(e) {
    const isBold = e.target.checked;
    if (this.props.individualStyle) {
      if (this.props.currentLabel) {
        this._updateLabelsArray('weight', isBold);
      }
    } else {
      this.props.updateMapState({ mapAddLabelWeight: isBold });
    }
  }

  _updateLabelsArray(field, value) {

    let labelsArrayClone = Array.from(this.props.labels);

    for (const label of labelsArrayClone) {
      if (label.content.id === this.props.currentLabel.content.id) {
        label.content[field] = value;
        label.content.icon = this.props.getLabelIcon(label)
        if(field==='angle'){
          const lbox = this.props.getLabelBoxPostions(label);
          label.box = lbox;
        }
      }
    }

    let updateObject = {
      labels: labelsArrayClone
    };

    if(this.props.currentLabel.content.customlayer){
      updateObject = {
        layerlabels: labelsArrayClone
      };
    }
    this.props.updateMapObj(updateObject);
  }

  render() {
    const _className = this.props.slimVersion ? "panel-body-slim" : "panel-body"
    const _className2 = this.props.slimVersion ? "add-ring-row-div-t5" : "add-ring-row-div-t10"
    return (
      <div className={_className}>
        <div>
          {this.props.readOnlyText ?
          <div>
            {this.props.text}
          <div/>
        </div>
          :
          <div>
            {this.props.roadLabel ? null :
            <TextField
              id="mapAddLabel-input"
              label="Custom label text"
              variant="outlined"
              defaultValue={this.props.text}
              size="small"
              fullWidth
              onChange={(e) => { this.setMapAddLabelText(e) }}
            />
            }
          </div>
          }
        </div>
        <div className={_className2}>
          <div className="add-ring-row">
           <div className="paddingL15">{translateObj.font[this.props.translate||'eng']}</div>
            <div style={{ paddingLeft: '5px' }}>
              <Select
                labelId="mapAddLabelFont-select"
                id="mapAddLabelFont-select"
                value={this.props.font}
                onChange={this.selectFontFamily}
                variant="outlined"
                autoWidth={true}
              >
                <MenuItem value={'Merriweather'}>Merriweather</MenuItem>
                <MenuItem value={'Open Sans'}>Open Sans</MenuItem>
              </Select>
            </div>
          </div>
          <div className="add-ring-row paddingL15">
            <div className="paddingL15">{translateObj.bold[this.props.translate||'eng']}</div>
            <div style={{ paddingLeft: '5px' }}>
              <Checkbox
                color="default"
                value="default"
                inputProps={{ 'aria-label': 'checkbox for bold label' }}
                onChange={(event) => this.toggleFontWeight(event)}
                checked={this.props.weight}
                disableRipple
              />
            </div>
          </div>
        </div>
        <div className={_className2}>
          <div className="add-ring-row">
            <div className="paddingL15">{translateObj.color[this.props.translate||'eng']}</div>
            <div style={{ paddingLeft: '5px' }}>
              <ColorPickerMenu
                squareColor={this.props.color}
                addTransparent={true}
                setFillColor={this.setFillColor}
              />
            </div>
          </div>
          <div className="add-ring-row paddingL15">
            {translateObj.fontSize[this.props.translate||'eng']}:
                <div style={{ width: '80px', paddingLeft: '5px' }}>
              <TextField
                id="mapAddLabelFontSize-input"
                variant="outlined"
                value={this.props.size}
                size="small"
                type="number"
                InputProps={{ inputProps: { min: 1, max: 30, step: 1 } }}
                onChange={(e) => { this.setMapAddLabelFontSize(e) }}
              />
            </div>
          </div>
        </div>
        <div className={_className2}>
          <div className="add-ring-row">
            <div className="paddingL15">{translateObj.outline[this.props.translate||'eng']}</div>
            <div style={{ paddingLeft: '5px' }}>
              <ColorPickerMenu
                squareColor={this.props.stroke}
                addTransparent={true}
                setFillColor={this.setStrokeColor}
              />
            </div>
          </div>
          <div className="add-ring-row paddingL15">
            {translateObj.opacity[this.props.translate||'eng']}:
                <div style={{ width: '80px', paddingLeft: '5px' }}>
              <TextField
                id="mapAddLabelOpacity-input"
                variant="outlined"
                size="small"
                type="number"
                value={this.props.opacity}
                InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                onChange={(e) => { this.setOpacity(e.target.value) }}
              />
            </div>
          </div>
        </div>
        {this.props.angle !== undefined && !this.props.roadLabel ? 
        <div className="add-ring-row paddingL15">
            angle:
                <div style={{ width: '80px', paddingLeft: '5px' }}>
              <TextField
                id="mapAddLabelOpacity-input"
                variant="outlined"
                size="small"
                type="number"
                value={this.props.angle}
                InputProps={{ inputProps: { min: 0, max: 90, step: 1 } }}
                onChange={(e) => { this.setAngle(e.target.value) }}
              />
            </div>
          </div>
          : null }
        <div>
        {(this.props.currentLabel?.content?.offsetPosition || this.props.offsetPosition || this.props.offsetOpen) ?
          <LabelPosition
            labelPosition={this.props.currentLabel?.content?.offsetPosition || this.props.offsetPosition}
            setPosition={this.setIconOffset}
          />
        : null
        }
        </div>
      </div>
    );
  }
}

export default LabelStyleOnly;


 //this.state.top_leftStatus ? 'legendPositionBtn legendPositionBtnActive' : 