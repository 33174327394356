import React from 'react';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { GetIconOffset } from '../../../utils/tools';
import EditRings from './EditRings';
import MainStylePanel from '../StyleGroups/MainStylePanel';
import StylePointLabels from './StylePointLabels';

function updateIcon(color, order, iconName, textFill, size, label) {
  let newIcon = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(<SVGIconComponent outline={color === 'transparent' ? 'transparent' : null} color={color} label={label} name={iconName} textFill={textFill} size={size} />)
  });

  newIcon.options.iconAnchor = GetIconOffset(newIcon.options.html, iconName);

  return newIcon;
}

class StylePoints extends React.Component {
  constructor(props) {
    super(props);
    this.closePanel = this.closePanel.bind(this);
    this.changeColorOrIcon = this.changeColorOrIcon.bind(this);
    this.toggleGroupLabelPanel = this.toggleGroupLabelPanel.bind(this);
  }

  closePanel() {
    this.props.updateMapObj({ isStyling: false, isEditingRing: false });
    if (this.props.isFromLegend) {
      this.props.updateLegendObj({ isStylingPoints: false });
    }
  }

  changeColorOrIcon(type, newStyle) {
    const group = this.props.groups.filter(group => group.id === this.props.currentPoint.group);

    const newPoints = Array.from(this.props.points);
    //get index of current point
    const indexOfCurrent = newPoints.findIndex(point => point.content.id === this.props.currentPoint.id);
    const label = this.props.currentPoint.useCustomLabel ? this.props.currentPoint.label : this.props.currentPoint.order;
    if (type === 'color') {
      if (group[0].useGroupStyle === false) {
        newPoints[indexOfCurrent].content.icon = updateIcon(newStyle, this.props.currentPoint.order, this.props.currentPoint.pointIconName, this.props.currentPoint.pointTextColor, this.props.currentPoint.pointIconSize, label);
      }

      newPoints[indexOfCurrent].content.pointColor = newStyle;
    }

    if (type === 'icon') {
      if (group[0].useGroupStyle === false) {
        newPoints[indexOfCurrent].content.icon = updateIcon(this.props.currentPoint.pointColor, this.props.currentPoint.order, newStyle, this.props.currentPoint.pointTextColor, this.props.currentPoint.pointIconSize, label);
      }

      newPoints[indexOfCurrent].content.pointIconName = newStyle;
    }

    if (type === 'text') {
      if (group[0].useGroupStyle === false) {
        newPoints[indexOfCurrent].content.icon = updateIcon(this.props.currentPoint.pointColor, this.props.currentPoint.order, this.props.currentPoint.pointIconName, newStyle, this.props.currentPoint.pointIconSize, label);
      }

      newPoints[indexOfCurrent].content.pointTextColor = newStyle;
    }

    if (type === 'size') {
      if (group[0].useGroupStyle === false) {
        newPoints[indexOfCurrent].content.icon = updateIcon(this.props.currentPoint.pointColor, this.props.currentPoint.order, this.props.currentPoint.pointIconName, this.props.currentPoint.pointTextColor, newStyle, label);
      }

      newPoints[indexOfCurrent].content.pointIconSize = newStyle;
    }
    if (type === 'leaderColor') {
      this.props.updateMapObj({pointLeaderColor: newStyle});

      // if (group[0].useGroupStyle === false) {
      //   newPoints[indexOfCurrent].content.icon = updateIcon(this.props.currentPoint.pointColor, this.props.currentPoint.order, this.props.currentPoint.pointIconName, this.props.currentPoint.pointTextColor, newStyle, label);
      // }

      if(newPoints[indexOfCurrent].content.leaderline){
        newPoints[indexOfCurrent].content.leaderline.color = newStyle;
      }
    }
    if (type === 'leaderWidth'){
      const val = parseInt(newStyle.target.value);
      this.props.updateMapObj({pointLeaderWidth: val});
      if(newPoints[indexOfCurrent].content.leaderline){
        newPoints[indexOfCurrent].content.leaderline.width = val;
      }
    }
    if (type === 'leaderOpacity'){
      const val = parseFloat(newStyle.target.value);
      this.props.updateMapObj({pointLeaderOpacity: val});
      if(newPoints[indexOfCurrent].content.leaderline){
        newPoints[indexOfCurrent].content.leaderline.opacity = val;
      }
    }

    if (type === 'hasLeader'){
      const chk = newStyle.target.checked;
      newPoints[indexOfCurrent].content.useLeaderline = chk;
      if(chk && newPoints[indexOfCurrent].content.lastLeaderLine){
        newPoints[indexOfCurrent].content.leaderline = newPoints[indexOfCurrent].content.lastLeaderLine;
        newPoints[indexOfCurrent].content.orglat = newPoints[indexOfCurrent].content.lastLeaderLine.positions[0][0].lat;
        newPoints[indexOfCurrent].content.orglng = newPoints[indexOfCurrent].content.lastLeaderLine.positions[0][0].lng;
      }
      else if(!chk){
        newPoints[indexOfCurrent].content.lastLeaderLine = newPoints[indexOfCurrent].content.leaderline;
        newPoints[indexOfCurrent].content.leaderline = null;
        newPoints[indexOfCurrent].content.orglat = null;
        newPoints[indexOfCurrent].content.orglng = null;
      }
      //}
    }

    if (type === 'hasLeaderAnchor'){
      const chk = newStyle.target.checked;
      this.props.updateMapObj({pointLeaderAnchor: chk});
      if(newPoints[indexOfCurrent].content.leaderline){
        newPoints[indexOfCurrent].content.leaderline.anchor = chk;
      }
    }
    this.props.updateMapObj({ mapPoints: newPoints });
  }

  toggleGroupLabelPanel(){
    this.props.updateMapObj({ isLabeling: false });
  }

  render() {
    const sidePanelStyle = this.props.isFromLegend ? "" : "sidePanelStyle";
    return (
      <div className={sidePanelStyle}>
        {this.props.isLabeling ?
          <StylePointLabels
            toggleGroupLabelPanel={this.toggleGroupLabelPanel}
            currentGroup={this.props.currentGroup}
            points={this.props.points}
            groups={this.props.groups}
            updateMapObj={this.props.updateMapObj}
            currentPoint={this.props.currentPoint}
            translate={this.props.translate}
          />
          : null
        }
        {this.props.isEditingRing ?
          <EditRings
          updateMapObj={this.props.updateMapObj}
          currentPoint={this.props.currentPoint}
          rings={this.props.rings}
          driveTimes={this.props.driveTimes}
          headers={this.props.headers}
          updateStatus={this.props.updateStatus}
          isLoadingHistory={this.props.isLoadingHistory}
          country={this.props.country}
          gcAtlasToken={this.props.gcAtlasToken}
          retriveGCAtlasToken={this.props.retriveGCAtlasToken}
          translate={this.props.translate}
        />
        : null
        }
        {
        this.props.isStyling ?
          <MainStylePanel
          isGroup={false}
          groups={this.props.groups}
          currentGroup={this.props.currentGroup}
          points={this.props.points}
          currentPoint={this.props.currentPoint}
          updateOrderAndIcon={this.props.updateOrderAndIcon}
          updateMapObj={this.props.updateMapObj}
          changeColorOrIcon={this.changeColorOrIcon}
          closePanel={this.closePanel}
          pointLeaderColor={this.props.pointLeaderColor}
          pointLeaderWidth={this.props.pointLeaderWidth}
          pointLeaderOpacity={this.props.pointLeaderOpacity}
          pointLeaderAnchor={this.props.pointLeaderAnchor}
          translate={this.props.translate}
        />
        : null }
      </div>
    );
  }
}

export default StylePoints;