import React, { Component } from 'react';
import SVGIconComponent from '../../MainPage/SVGIconComponent/SVGIconComponent';

export default class ReleaseNotes2Pt2 extends Component {
  render() {
    return (
    <div>
        <div className="updateMenuBody">

            <div className="updateMenuFeatureName">
                  <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                  </div>
                  <div className="updateMenuFeatureLabel">Demographics tool</div>
                </div>
                <div className="updateMenuFeatureDesc">Ability to add thematic demographic data.  
                  Select Add Data... Add Demographics... Fill out the following information  
                    <br/><span className="updateHighlightSpan">Range:</span>&nbsp;Allows you to set how the ranges are calculated.
                    <br/><span className="updateHighlightSpan">Geography:</span>&nbsp;The area you want to display
                    <br/><span className="updateHighlightSpan">Variable:</span>&nbsp;The variable you want to display
                    <br/>Select <span className="updateHighlightSpan">Get Demographics</span>
                    <br/>Select color choices
            </div>


            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Zoom to address or place</div>
                </div>
                <div className="updateMenuFeatureDesc">
                  Textbox in the upper left will zoom to address without adding point to the map.
                </div>



            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Search by term</div>
                </div>
                <div className="updateMenuFeatureDesc">
                  In map history you can enter a search term in addition to using an existing Map ID
                </div>

        </div>
    </div>
    );
  }
}
