import React from 'react';
import Menu from '@material-ui/core/Menu';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';
import ReleaseNotes2Pt2 from '../../Support/MethodologyComponent/ReleaseNotes2Pt2';
import ReleaseNotes3Pt0 from '../../Support/MethodologyComponent/ReleaseNotes3Pt0';
import ReleaseNotes3Pt1 from '../../Support/MethodologyComponent/ReleaseNotes3Pt1';

class UpdateBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
      anchorEl: null
    }

    this.toggleUpdatePanel = this.toggleUpdatePanel.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  toggleUpdatePanel(e, bool) {
    this.setState({
      anchorEl: e.currentTarget,
      showOptions: bool
    });
  }

  closePanel() {
    //this.props.togglePanel('layout');
  }

  render() {
    const text = this.props.text;
    return (
      <div className="updateBannerDiv">
        <div className="fontBold paddingR10">
          <div className="padding-R5"><SVGIconComponent name="boomFavIcon" size={16} fill={'#ffffff'} fill2={'#25408f'} /></div>
          <div>New updates now in BOOM! - March 2024</div>
        </div>
        <div className="paddingR10"><button className="geocodeBtnSmall" onClick={(e) => this.toggleUpdatePanel(e, true)}>Details</button></div>
        <div className="flexSelfStart"><CloseIcon style={{ fontSize: '12px' }} onClick={this.props.closeBanner} /></div>
        {this.state.showOptions ?
          <Menu
            id="legendOpts-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.showOptions}
            onClose={this.toggleUpdatePanel.bind(this, { currentTarget: null }, false)}
          >
            <div className="updateMenuDiv">
              <div className="updateMenuTitle">BOOM version 3.1 updates</div>
              <div className="updateMenuBody">
              <ReleaseNotes3Pt1/>
            </div>
          </div>
      
          </Menu>
          : null
        }
      </div>
    );
  }
}

export default UpdateBanner;