import React, { useState } from 'react';
import Logo from '../Logo/Logo';
import UserProfile from './UserProfile/UserProfile';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth0 } from '../../react-auth0-spa';
import translateObj from '../../utils/translate';

const titleStyleBold = {
  fontWeight: 'bold'
}

const whiteTitleArea = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#ffffff'
}

const titleArea = {
  height: '65px',
  display: 'flex',
  justifyContent: 'space-between',
  width: 'calc(100% - 100px)',
  alignItems: 'center'
}

const stackedTitleArea = {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '0px',
  justifyContent: 'center',
  fontSize: '14px'
}

function NavBar(props) {
  const [display, setDisplay] = useState('block');
  const { isAuthenticated, logout } = useAuth0();
  const subTitle = props.subTitle;
  const link = props.link;
  const linkTitle = props.linkTitle;
  const isMain = props.isMain;
  const translate = props.translate;

  const openHamburgerMenu = () => {
    if (display === 'none') {
      setDisplay('block');
    } else {
      setDisplay('none');
    }
    document.getElementById('headerHamburgerMenuItemsDiv').style.display = display;
  }

  return (
    <div className="navBarDiv">
      <div className="topBlueBar"></div>
      <div style={whiteTitleArea}>
        <div className="blueIconArea">
          <Logo></Logo>
        </div>
        <div style={titleArea}>
          <div style={stackedTitleArea}>
            <div>
              <div>
                <span style={titleStyleBold}>BOOM |</span>
              </div>
              <div>
                <span className="rtSubtitle"> {subTitle}</span>
              </div>
            </div>
          </div>

          {props.country === 'JPN' &&
            <div className="languageDiv">
              <div>Language:</div>
              <div className="languageChoiceDiv">
                <div
                  className={`languageChoiceItem ${translate === 'eng' ? 'active' : ''}`}
                  onClick={() => props.setLanguage('eng')}
                >
                  eng
                </div>
                <div
                  className={`languageChoiceItem ${translate === 'jpn' ? 'active' : ''}`}
                  onClick={() => props.setLanguage('jpn')}
                >
                  jpn
                </div>
              </div>
            </div>
          }

          {isMain ?
            <div className="helpPanels">
              <span title={linkTitle} className="helpPanelItem">
                <a className="helpLink" target="_blank" rel="noopener noreferrer" href={link}>
                  <svg height="28px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="#25408f" cx="50" cy="50" r="50" />
                    <text x="50%" y="50%" textAnchor="middle" fill="#ffffff" fontFamily="Open Sans" fontSize="75px" dy=".3em">?</text>
                  </svg>
                </a>
              </span>
            </div>
            :
            <div className="helpPanelsSupport">
              <span className="hydraLinkButton">
                <a className="hydraLink" href={link} target="_blank" rel="noopener noreferrer">
                  BOOM
              </a>
              </span>
            </div>
          }

          <UserProfile
            translate={translate}
          />
          <div className="headerHamburgerMenu">
            <div id="clickHeaderHamburger"><MenuIcon onClick={openHamburgerMenu}></MenuIcon></div>
          </div>
        </div>
      </div>
      <div id="headerHamburgerMenuItemsDiv">
        <div id="hamburgerFlex">
          <div
            className="headerHamItem"
          >
              <a
                className="helpLinkMobile"
                target="_blank"
                rel="noopener noreferrer"
                href={link}
              >
                {isMain ?
                  <span className="headerHamItemSpan">{translateObj.help[translate]}</span>
                  :
                  <span className="headerHamItemSpan">BOOM</span>}
              </a>
            </div>
          <div className="headerHamItem borderNone"><a className="helpLinkMobile" href="mailto:colliers.gis@colliers.com">
            <span className="headerHamItemSpan">{translateObj.feedbackAndSupport[translate]}</span></a></div>
          {isAuthenticated ?
            <div
              className="headerHamItem helpLinkMobile"
              id="sign-out-mobile"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              {translateObj.signOut[translate]}
            </div>
            : null
          }
        </div>
      </div>
    </div>
  );
}

export default NavBar;