import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import ShieldStyleOnly from './ShieldStyleOnly';
import translateObj from '../../utils/translate';

class CustomShieldPanel extends React.Component {
  constructor(props) {
    super(props);

    this.deleteAllShields = this.deleteAllShields.bind(this);
    this.applyStyleToAllShields = this.applyStyleToAllShields.bind(this);
  }

  componentDidMount() {
    this.props.updateMapState({ mapAddShield: true, mapAddLabel: false });
    document.getElementsByClassName('leaflet-container')[0].classList.add('mapClickCursor');
  }

  deleteAllShields() {
    this.props.updateMapObj({ shields: [] });
  }

  applyStyleToAllShields() {
    const shieldsArrayClone = Array.from(this.props.shields);
    for (const shield of shieldsArrayClone) {
      shield.content.color = this.props.mapAddShieldColor;
      shield.content.size = this.props.mapAddShieldSize;
      shield.content.text = this.props.shieldManualMode ? this.props.mapAddShieldText : shield.content.text;
      shield.content.type = this.props.shieldManualMode ? this.props.mapAddShieldType : shield.content.type;
      shield.content.icon = this.props.getShieldIcon(shield);
    }

    this.props.updateMapObj({ shields: shieldsArrayClone });
  }

  componentWillUnmount() {
    this.props.updateMapState({ mapAddShield: false });
    document.getElementsByClassName('leaflet-container')[0].classList.remove('mapClickCursor');
  }

  render() {
    return (
      <div>
        <div className="customShieldPanel">
          <h2 className="panel-title">
            <span className="panel-icon-span">
              <svg id="starIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.63 94.63">
                <path id="Path_4164" d="M16.51,3.68c-7.1-20.22-5.4-19.73-12.33,0-21.68.43-20.6-1-3.82,11.63-6.27,20.5-7.32,19.1,10,7.18,17.77,12.23,16.1,12.8,10-7.18C37.61,2.35,37.56,4.1,16.51,3.68Z" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                <path id="Path_4165" d="M9.83-36A46.07,46.07,0,1,1-25-19.93" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              </svg>
            </span>
            {translateObj.addCustomShieldTitle[this.props.translate]}
            <span className="panel-close closeStyle"><CloseIcon onClick={this.props.toggleMapAddShield} fontSize="small" /></span>
          </h2>
          <div className="panel-body">
            <div className="textAreaDiv paddingB10">
              {translateObj.clickOnHighway[this.props.translate]}
            </div>
            <div className="panel-body-small-text">
              *{translateObj.noteCustomShields[this.props.translate]}
            </div>
            {this.props.country !== 'OT' ? 
            <div className="shieldManualModeDiv">
              <div className="logoListCheckbox">
                <Checkbox
                  color="default"
                  defaultValue="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={(event) => this.props.toggleShieldManualMode(event.target.checked)}
                  checked={this.props.shieldManualMode}
                  disableRipple
                  disabled={this.props.country === 'JPN'}
                />
                <span>{translateObj.manualMode[this.props.translate]}</span>
              </div>
            </div> :
            null }
            <ShieldStyleOnly
              updateMapState={this.props.updateMapState}
              text={this.props.mapAddShieldText}
              color={this.props.mapAddShieldColor}
              size={this.props.mapAddShieldSize}
              type={this.props.mapAddShieldType}
              shieldManualMode={this.props.shieldManualMode}
              country={this.props.country}
            />
            <div className="geocodeDiv flex">
              <button
                className="geocodeBtn"
                onClick={this.deleteAllShields}
              >
                {translateObj.deleteAllShields[this.props.translate]}
              </button>
              <button 
                className="geocodeBtn"
                onClick={this.applyStyleToAllShields}
              >
                {translateObj.applyStyleToAll[this.props.translate]}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomShieldPanel;