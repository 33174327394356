import React from 'react';
import { TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import translateObj from '../../utils/translate';

const usShieldOptions = [
  { value: 'interstate', label: 'Interstate' },
  { value: 'ushwy', label: 'US Hwy' },
  { value: 'akhwy', label: 'Alaska' },
  { value: 'alhwy', label: 'Alabama' },
  { value: 'azhwy', label:  'Arizona' },
  { value: 'arhwy', label:  'Arkansas' },
  { value: 'cahwy', label:  'California' },
  { value: 'cohwy', label:  'Colorado' },
  { value: 'cthwy', label:  'Connecticut' },
  { value: 'dchwy', label:  'DC' },
  { value: 'dehwy', label:  'Delaware' },
  { value: 'flhwy', label:  'Florida' },
  { value: 'gahwy', label:  'Georgia' },
  { value: 'hihwy', label:  'Hawaii' },
  { value: 'idhwy', label:  'Idaho' },
  { value: 'ilhwy', label:  'Illinios' },
  { value: 'inhwy', label:  'Indiana' },
  { value: 'iahwy', label:  'Iowa' },
  { value: 'kshwy', label:  'Kansas' },
  { value: 'kyhwy', label:  'Kentucky' },
  { value: 'lahwy', label:  'Louisiana' },
  { value: 'mehwy', label:  'Maine' },
  { value: 'mdhwy', label:  'Maryland' },
  { value: 'mahwy', label:  'Massachusetts' },
  { value: 'mihwy', label:  'Michigan' },
  { value: 'mnhwy', label:  'Minnesota' },
  { value: 'mshwy', label:  'Mississippi' },
  { value: 'mohwy', label:  'Missouri' },
  { value: 'mthwy', label:  'Montana' },
  { value: 'nehwy', label:  'Nebraska' },
  { value: 'nvhwy', label:  'Nevada' },
  { value: 'nhhwy', label:  'New Hampshire' },
  { value: 'njhwy', label:  'New Jersey' },
  { value: 'nmhwy', label:  'New Mexico' },
  { value: 'nyhwy', label:  'New York' },
  { value: 'nchwy', label:  'North Carolina' },
  { value: 'ndhwy', label:  'North Dakota' },
  { value: 'okhwy', label:  'Oklahoma' },
  { value: 'ohhwy', label:  'Ohio' },
  { value: 'orhwy', label:  'Oregon' },
  { value: 'pahwy', label:  'Pennsylvania' },
  { value: 'rihwy', label:  'Rhode Island' },
  { value: 'schwy', label:  'South Carolina' },
  { value: 'sdhwy', label:  'South Dakota' },
  { value: 'tnhwy', label:  'Tennessee' },
  { value: 'txhwy', label:  'Texas' },
  { value: 'uthwy', label:  'Utah' },
  { value: 'vthwy', label:  'Vermont' },
  { value: 'vahwy', label:  'Virginia' },
  { value: 'wahwy', label:  'Washington' },
  { value: 'wvhwy', label:  'West Virginia' },
  { value: 'wihwy', label:  'Wisconsin' },
  { value: 'wyhwy', label:  'Wyoming' },
  { value: 'acehwy', label:  'ACE' },
  { value: 'gsphwy', label:  'GSP' },
  { value: 'srthwy', label:  'Sam Rayburn' },
  { value: 'dnthwy', label:  'Dallas North' },
  { value: 'pgbthwy', label:  'Pres Bush Tpke' },
  { value: 'bwbhwy', label:  'Bruce Woodbury' },
  { value: 'audhwy', label:  'Audubon Pkwy' },
  { value: 'flatpkehwy', label: 'Florida`s Tpke'}
];

const canShieldOptions = [
  {  value: 'transcanhwy', label: 'TransCanada' },
  {  value: 'onhwy', label: 'Ontario' },
  {  value: 'qewhwy', label: 'QEW' },
  {  value: 'onsechwy', label: 'Ontario Sec' },
  {  value: 'abhwy', label: 'Alberta' },
  {  value: 'absechwy', label: 'Alberta Sec' },
  {  value: 'bchwy', label: 'British Col' },
  {  value: 'mbhwy', label: 'Manitoba' },
  {  value: 'nlhwy', label: 'Nfld &amp; Lab' },
  {  value: 'qchwy', label: 'Quebec' },
  {  value: 'nshwy', label: 'N. Scotia' },
  {  value: 'nstrunkhwy', label: 'NS Trunk' },
  {  value: 'nbhwy', label: 'New Bruns.' },
  {  value: 'nbcollhwy', label: 'NB Collector' },
  {  value: 'peihwy', label: 'Pr. Edward Is.' },
  {  value: 'skhwy', label: 'Saskatchewan' },
  {  value: 'nwthwy', label: 'NW Territory' },
  {  value: 'ythwy', label: 'Yukon' }
];

const ausShieldOptions = [
  {  value: 'ausalphanumhwy', label: 'Alphanumeric' },
  {  value: 'ausntlhwy', label: 'National Hwy' },
  {  value: 'ausntlalphahwy', label: 'National Hwy AN'},
  {  value: 'ausntlrtehwy', label: 'National Route' },
  {  value: 'ausstatehwy', label: 'State Hwy' },
];

const jpnShieldOptions = [
  {  value: 'jpnntlhwy', label: 'National Hwy' },
  {  value: 'jpnprefhwy', label: 'Prefectural Rd' },
  {  value: 'jpnexphwy', label: 'Express Way' },
];

class ShieldStyleOnly extends React.Component {
  constructor(props) {
    super(props);

    this.selectColor = this.selectColor.bind(this);
    this.selectType = this.selectType.bind(this);
    this.setMapAddShieldSize = this.setMapAddShieldSize.bind(this);
  }

  setMapAddShieldText(e) {
    const newTextString = e.target.value;
    if (this.props.individualStyle) {
      if (this.props.currentShield) {
        this._updateShieldsArray('text', newTextString);
      }
    } else {
      this.props.updateMapState({ mapAddShieldText: newTextString });
    }
  }

  setMapAddShieldSize(e) {
    const newShieldSize = e.target.value;
    if (this.props.individualStyle) {
      if (this.props.currentShield) {
        this._updateShieldsArray('size', newShieldSize);
      }
    } else {
      this.props.updateMapState({ mapAddShieldSize: newShieldSize });
    }
  }

  selectColor(e) {
    if (this.props.individualStyle) {
      if (this.props.currentShield) {
        this._updateShieldsArray('color', e.target.value);
      }
    } else {
      this.props.updateMapState({ mapAddShieldColor: e.target.value });
    }
  }

  selectType(e) {
    if (this.props.individualStyle) {
      if (this.props.currentShield) {
        this._updateShieldsArray('type', e.target.value);
      }
    } else {
      this.props.updateMapState({ mapAddShieldType: e.target.value });
    }
  }

  _updateShieldsArray(field, value) {
    let shieldsArrayClone = Array.from(this.props.shields);
    for (const shield of shieldsArrayClone) {
      if (shield.content.id === this.props.currentShield.content.id) {
        shield.content[field] = value;
        shield.content.icon = this.props.getShieldIcon(shield)
      }
    }

    this.props.updateMapObj({
      shields: shieldsArrayClone
    });
  }

  render() {
    return (
      <div className="panel-body">
        <div className="add-ring-row-div paddingTB10">
          <div className="add-ring-row">
            {this.props.individualStyle || this.props.shieldManualMode ?
              <div style={{ width: '120px' }}>
                <TextField
                  id="mapAddShield-input"
                  label="Shield text"
                  variant="outlined"
                  defaultValue={this.props.text}
                  size="small"
                  onChange={(e) => { this.setMapAddShieldText(e) }}
                />
              </div>
              : null
            }
            <div className="add-ring-row">
              <div className="paddingL15">{translateObj.color[this.props.translate]}</div>
              <div style={{ paddingLeft: '5px' }}>
                <Select
                  labelId="mapAddShieldColor-select"
                  id="mapAddShieldColor-select"
                  value={this.props.color}
                  onChange={this.selectColor}
                  variant="outlined"
                  autoWidth={true}
                >
                  <MenuItem value={'monotone'}>Monotone</MenuItem>
                  <MenuItem value={'color'}>Color</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="add-ring-row-div paddingTB10">
          <div className="add-ring-row">
            <div className="paddingL15">{translateObj.size[this.props.translate]}</div>
            <div style={{ width: '80px', paddingLeft: '5px' }}>
              <TextField
                id="mapAddShieldSize-input"
                variant="outlined"
                value={this.props.size}
                size="small"
                type="number"
                InputProps={{ inputProps: { min: 1, max: 30, step: 1 } }}
                onChange={(e) => { this.setMapAddShieldSize(e) }}
              />
            </div>
          </div>
          {this.props.individualStyle || this.props.shieldManualMode ?
          <div className="add-ring-row">
              <div className="paddingL15">{translateObj.type[this.props.translate]}</div>
              <div style={{ paddingLeft: '5px' }}>
                <Select
                  labelId="mapAddShieldType-select"
                  id="mapAddShieldType-select"
                  value={this.props.type}
                  onChange={this.selectType}
                  variant="outlined"
                  autoWidth={true}
                >
                  {this.props.country === 'US' ?
                      usShieldOptions.map((option, i) => (
                        <MenuItem
                          value={option.value}
                          key={option + "_" + i}
                        >{option.label}</MenuItem>
                      ))
                    : null
                  }
                  {this.props.country === 'CA' ?
                    canShieldOptions.map((option, i) => (
                      <MenuItem
                        value={option.value}
                        key={option + "_" + i}
                      >{option.label}</MenuItem>
                    ))
                    : null
                  }
                  {this.props.country === 'AUS' ?
                    ausShieldOptions.map((option, i) => (
                      <MenuItem
                        value={option.value}
                        key={option + "_" + i}
                      >{option.label}</MenuItem>
                    ))
                    : null
                  }
                  {this.props.country === 'JPN' ?
                    jpnShieldOptions.map((option, i) => (
                      <MenuItem
                        value={option.value}
                        key={option + "_" + i}
                      >{option.label}</MenuItem>
                    ))
                    : null
                  }
                  <MenuItem value={'spurhwy' && 'loophwy'}>Rectangle</MenuItem>
                </Select>
              </div>
            </div>
            : null
          }
        </div>
      </div>
    );
  }
}

export default ShieldStyleOnly;