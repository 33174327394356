import React from 'react';
import * as XLSX from 'xlsx';
import MapColumns from '../MapColumns/MapColumns';
import CloseIcon from '@material-ui/icons/Close';
import { IsObjectEmpty, ReplaceKeys } from '../../../../utils/tools';
import translateObj from '../../../../utils/translate';

class UploadPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      targetGroup: 0,
      fileUploadShow: true,
      mapColumnsShow: false,
      data: null,
      columns: [],
      nameColName: '',
      addressColName: '',
      cityColName: '',
      stateColName: '',
      postalColName: '',
      latColName: '',
      lngColName: '',
      groupColName: '',
      legenddataColName: '',
      labelColName: '',
      countryColName: '',
      error: false,
      maxRows: 300
    }
    this.closePanel = this.closePanel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.togglePanels = this.togglePanels.bind(this);
    this.mapColumnNames = this.mapColumnNames.bind(this);
    this.backToAddPts = this.backToAddPts.bind(this);
  }

  componentDidMount() {
    const maxRows = this.props.supergeo ?  process.env.REACT_APP_SUPER_UPLOAD_LIMIT : process.env.REACT_APP_UPLOAD_LIMIT;
    this.setState({maxRows: maxRows});
  }

  closePanel() {
    this.props.togglePanel('addPt');
  }

  componentDidMount() {
    const maxRows = process.env.REACT_APP_UPLOAD_LIMIT;
    this.setState({maxRows: maxRows});
  }

  togglePanels(panel1, panel2) {
    this.setState({
      fileUploadShow: panel1,
      mapColumnsShow: panel2,
    });
  }


  handleChange(e) {
    this.setState({
      error: false
    });
    const files = e.target.files;
    if (files && files[0]) {
      const file = files[0];

      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        if(file.name.endsWith('json')){
          this._makeGeoJson(bstr);
          return;
        }
        
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        const isWBEmpty = IsObjectEmpty(wb);
        if (isWBEmpty || !wb.SheetNames || !wb.Sheets) {
          this.setState({
            error: true
          });
          return;
        }

        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        //make sure worksheet is not empty
        const isWSEmpty = IsObjectEmpty(ws);
        if (isWSEmpty) {
          this.setState({
            error: true
          });
          return;
        }
        /* Convert array of arrays */
        const columns = this.make_cols(ws);
        if (columns.length === 0) { //if no columns
          this.setState({
            error: true
          });
          return;
        }

        const data = XLSX.utils.sheet_to_json(ws, {raw: false}, { header: columns });
        //const maxRows = process.env.REACT_APP_UPLOAD_LIMIT || 300;
        if (data.length > this.state.maxRows){
          this.setState({
            tooManyPoints: true
          });
          return;
        }

        if (data.length < 2) { //if data has nothing, or only a header
          this.setState({
            error: true
          });
          return;
        }

        ReplaceKeys(data);
        //show uploaded columns in map columns panel
        this.setState({
          data: data,
          columns: columns,
          error: false,
          tooManyPoints: false
        });

        this.mapColumnNames('nameColName');
        this.mapColumnNames('addressColName');
        this.mapColumnNames('cityColName');
        this.mapColumnNames('stateColName');
        this.mapColumnNames('postalColName');
        this.mapColumnNames('latColName');
        this.mapColumnNames('lngColName');
        this.mapColumnNames('groupColName');
        this.mapColumnNames('legenddataColName');
        this.mapColumnNames('labelColName');
        if(this.props.supergeo){
          this.mapColumnNames('countryColName');
        }

        this.togglePanels(false, true);
      };
      if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
    } else {
      return;
    }
  };

  make_cols(ws) {
    const header = [];
    let columnCount = 0;
    if (ws['!ref']) {
      columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
    } else {
      return [];
    }

    for (let i = 0; i < columnCount; ++i) {
      if (ws[`${XLSX.utils.encode_col(i)}1`]) {
        header[i] = ws[`${XLSX.utils.encode_col(i)}1`].v;
        header[i] = header[i].toString().trim();
      } else {
        header[i] = 'no header';
      }
    }
    
    return header;
  };

  mapColumnNames(colName, custom) {
    if (colName === 'nameColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'name') || (columnLowerCase === '_name') || (columnLowerCase === 'location') || (columnLowerCase === '_location')
        || (columnLowerCase === 'location name') || (columnLowerCase === 'site') || (columnLowerCase === 'property name') || (columnLowerCase === 'propertyname')) {
          this.setState({
            nameColName: column
          });
          return;
        }
      }
      this.setState({
        nameColName: 'None'
      });
    } else if (colName === 'addressColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'address') || (columnLowerCase === 'addr') || (columnLowerCase === '_addr') || (columnLowerCase === 'street')
        || (columnLowerCase === '_street') || (columnLowerCase === '_address') || (columnLowerCase === 'property address') || (columnLowerCase === 'propertyaddress')) {
          this.setState({
            addressColName: column
          });
          return;
        }
      }
      this.setState({
        addressColName: 'None'
      });
    } else if (colName === 'cityColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'city') || (columnLowerCase === '_city') || (columnLowerCase === 'municipality') || (columnLowerCase === '_municipality')
          || (columnLowerCase === 'town')) {
          this.setState({
            cityColName: column
          });
          return;
        }
      }
      this.setState({
        cityColName: 'None'
      });
    } else if (colName === 'stateColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'state') || (columnLowerCase === '_state') || (columnLowerCase === 'province') || (columnLowerCase === '_province')) {
          this.setState({
            stateColName: column
          });
          return;
        }
      }
      this.setState({
        stateColName: 'None'
      });
    } else if (colName === 'postalColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'zip') || (columnLowerCase === '_zip') || (columnLowerCase === 'postalcode') || (columnLowerCase === '_postalcode')
          || (columnLowerCase === 'postal') || (columnLowerCase === '_postal') || (columnLowerCase === 'zipcode') || (columnLowerCase === '_zipcode')
          || (columnLowerCase === 'zip code') || (columnLowerCase === 'postal code')) {
          this.setState({
            postalColName: column
          });
          return;
        }
      }
      this.setState({
        postalColName: 'None'
      });
    } else if (colName === 'countryColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if (columnLowerCase === 'country'){
          this.setState({
            countryColName: column
          });
          return;
        }
      }
      this.setState({
        countryColName: 'None'
      });
    } else if (colName === 'latColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'lat') || (columnLowerCase === 'latitude') || (columnLowerCase === 'y') || (columnLowerCase === '_lat')
          || (columnLowerCase === '_latitude') || (columnLowerCase === '_y')) {
          this.setState({
            latColName: column
          });
          return;
        }
      }
      this.setState({
        latColName: 'None'
      });
    } else if (colName === 'lngColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'lng') || (columnLowerCase === 'longitude') || (columnLowerCase === 'x') || (columnLowerCase === '_lng')
          || (columnLowerCase === '_longitude') || (columnLowerCase === '_x') || (columnLowerCase === 'long') || (columnLowerCase === '_long')
          || (columnLowerCase === 'lon') || (columnLowerCase === '_lon')) {
          this.setState({
            lngColName: column
          });
          return;
        }
      }
      this.setState({
        lngColName: 'None'
      });
    } else if (colName === 'groupColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'group') || (columnLowerCase === '_group') || (columnLowerCase === 'layer') || (columnLowerCase === '_layer')
          || (columnLowerCase === 'grp') || (columnLowerCase === '_grp') || (columnLowerCase === 'lyr') || (columnLowerCase === '_lyr')
          || (columnLowerCase === 'layername') || (columnLowerCase === 'groupname')) {
          this.setState({
            groupColName: column
          });
          return;
        }
      }
      this.setState({
        groupColName: 'None'
      });
    } else if (colName === 'legenddataColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'legenddata') || (columnLowerCase === 'data') || (columnLowerCase === 'legend data') || (columnLowerCase === 'lgd data')
          || (columnLowerCase === 'lgnd data') || (columnLowerCase === 'legend')) {
          this.setState({
            legenddataColName: column
          });
          return;
        }
      }
      this.setState({
        legenddataColName: 'None'
      });
    } else if (colName === 'labelColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'label') || (columnLowerCase === '_label') || (columnLowerCase === 'label name') || (columnLowerCase === 'icon label')
          || (columnLowerCase === 'customlabel') || (columnLowerCase === 'custom label')) {
          this.setState({
            labelColName: column
          });
          return;
        }
      }
      this.setState({
        labelColName: 'None'
      });
    } else if (custom) {
      this.setState({
        [colName]: custom
      })
    }
  }

  backToAddPts() {
    this.props.toggleUploadPanel(false, true);
  }

  // list of supported file types
  SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
  ].map(function (x) { return "." + x; }).join(",");

  render() {
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="uploadIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 108.09 53.77">
              <polygon stroke="#0c9ed9" fill="none" strokeWidth="4" strokeMiterlimit="10" points="104.67 15.11 3.44 15.11 20.01 1.25 88.28 1.26 104.67 15.11" />
              <polyline stroke="#0c9ed9" fill="none" strokeWidth="4" strokeMiterlimit="10" points="14.5 24.56 3.44 33.81 104.67 33.81 93.71 24.56" />
              <polyline stroke="#0c9ed9" fill="none" strokeWidth="4" strokeMiterlimit="10" points="14.5 43.26 3.44 52.52 104.67 52.52 93.71 43.26" />
            </svg>
          </span>
          {translateObj.uploadLocationsTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          {this.state.fileUploadShow
            ? <div>
              <div className="panel-body-small-text">
                {translateObj.uploadLocationsDesc[this.props.translate]}
            </div>
              <div className="geocodeDiv flex">
                <input className="fileUploadInput" type="file" accept={this.SheetJSFT} id="uploadFileBtn" onChange={this.handleChange} />
                <label htmlFor="uploadFileBtn">
                  <span className="geocodeBtn button-appearance">
                    {translateObj.selectFileBtn[this.props.translate]}
                  </span>
                </label>
                <button
                  className="geocodeBtn"
                  onClick={this.backToAddPts}
                >
                  {translateObj.backBtn[this.props.translate]}
                </button>
              </div>
            </div>
            : null
          }
          {this.state.mapColumnsShow ?
            <MapColumns
              columns={this.state.columns}
              mapColumnNames={this.mapColumnNames}
              nameColName={this.state.nameColName}
              addressColName={this.state.addressColName}
              cityColName={this.state.cityColName}
              stateColName={this.state.stateColName}
              postalColName={this.state.postalColName}
              countryColName={this.state.countryColName}
              latColName={this.state.latColName}
              lngColName={this.state.lngColName}
              groupColName={this.state.groupColName}
              legenddataColName={this.state.legenddataColName}
              labelColName={this.state.labelColName}
              togglePanels={this.togglePanels}
              data={this.state.data}
              createNewPoint={this.props.createNewPoint}
              updateMapObj={this.props.updateMapObj}
              toggleUploadPanel={this.props.toggleUploadPanel}
              groups={this.props.groups}
              points={this.props.points}
              currentGroup={this.props.currentGroup}
              updateStatus={this.props.updateStatus}
              legend={this.props.legend}
              headers={this.props.headers}
              country={this.props.country}
              allColumns={true}
              backButton={this.backToAddPts}
              secyGeoName={this.props.secyGeoName}
              supergeo={this.props.supergeo}
              translate={this.props.translate}
            />
            : null
          }
          {this.state.error ?
            <div className="errorMsgDiv">{translateObj.oopsWrongCheckDataTryAgain[this.props.translate]}</div>
            : null
          }
          {
            this.state.tooManyPoints ?
            <div className="errorMsgDiv">Oops! This file is over the limit of {this.state.maxRows} records.  Please select a smaller file and try again</div>
            : null
          }
        </div>
      </div>
    );
  }
}

export default UploadPanel;