import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import TextField from '@material-ui/core/TextField';
import CustomPointStyle from '../../MapComponent/CustomPointStyle';
import Checkbox from '@material-ui/core/Checkbox';
import LabelStyleOnly from '../../MapComponent/LabelStyleOnly';

class TrafficCountsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
            ,refreshLayer: false
            ,showInLegend: true
            ,minValue : 10000
            ,minDisplayValue: 10000
            ,displayLabels: false
            ,labelStyle: {
                labelStroke: '#4a4a4d',
                labelColor: '#ffffff',
                labelFontSize: 12,
                labelOpacity: 1,
                labelFont: 'Open Sans',
                labelWeight: true,
                labelOpacity: 1
            },
            pointStyle:{
                pointColor: "#f68b1f",
                pointIconName: "circleIcon",
                pointIconSize: "default",
                pointTextColor: "#ffffff"
            }
        }
        //this.closePanel = this.closePanel.bind(this);
        this.backToAddData = this.backToAddData.bind(this);
        this.getLayer = this.getLayer.bind(this);
        this.removeLayer = this.removeLayer.bind(this);
        this.selectLayer = this.selectLayer.bind(this);
        this.changeColorOrIcon = this.changeColorOrIcon.bind(this);
        this.toggleDefaultStyling = this.toggleDefaultStyling.bind(this);
        this.changeFill = this.changeFill.bind(this);
        this.clearLayer = this.clearLayer.bind(this);
        this.updateLabelStyle = this.updateLabelStyle.bind(this);
    }

    componentDidMount() {
        const settings = this.props.gapiPointSettings[0];
        this.setState({
            labelStyle:settings.labelStyle,
            pointStyle:settings.pointStyle,
            minValue:settings.minValue,
            minDisplayValue:settings.minDisplayValue,
            displayLabels:settings.displayLabels
        });
        this.props.updateMapObj({gapiPointsErrorMessage:null})
    }

    updateLabelStyle(obj) {
        //console.log(obj,'updateLabelStyle');
        const strobj = JSON.stringify(obj).replace("mapAddL","l");
        const newobj = JSON.parse(strobj);

        let curLabel = this.state.labelStyle;
        const name = Object.keys(obj);
        curLabel[name] = obj[name];
        this.setState({labelStyle:curLabel});
        //curInfo.labelStyle = curLabel;


        this.props.setGAPILabelStyle(obj);
    }

    selectLayer(event) {
        const val = event.target.value;
        if(val>-1){
            this.setState({selectedLayer: this.state.otherLayerList[val] });
        }
    }

    clearLayer() {
        let selLayer = this.state.selectedLayer;
        this.props.updateMapObj(
            {removeLayer: selLayer,
            gettingLayers: true
        });
    }

    setMinValue(e) {
        //this.setState({ isError: false });
        const searchVal = parseInt(e.target.value);
        const currentMinValue = parseInt(this.state.minValue);
        if(searchVal < currentMinValue){
            this.setState({refreshLayer:true})
        }
        else {
            this.props.setGapiPointFilter('minDisplayValue',searchVal);
        }

        this.setState({
            minDisplayValue: searchVal
        });
       

        //this.onChangeDebounced(searchVal);
    }

    selectFilter(e) {
        const searchVal = e.target.value;
        this.setState({
            filterSelectVal: searchVal
        }); 
    }

    removeLayer() {
        // let selLayer = this.state.selectedLayer;
        // this.props.updateMapObj(
        //     {removeLayer: selLayer,
        //     gettingLayers: true
        // });
        this.props.updateMapObj({
            gapiPoints: [],
            gapiPointLabels: [],
            gapiPointCustomLabels: [],
            gapiPointHideLabels: [],
            gapiPointBounds: null
        })
        this.props.closePanel();
    }

    getLayer() {
        const retObj = {
            pointStyle: this.state.pointStyle,
            minValue: this.state.minDisplayValue
        }
        this.setState({minValue:this.state.minDisplayValue,refreshLayer:false});
        this.props.updateMapObj({addGapiPoints: retObj});
        //this.props.setGapiPointFilter('minDisplayValue',this.state.minDisplayValue);
        
        //addGapiPoints
    }

    backToAddData() {
        this.props.toggleOtherLayersFilter(true, false);
    }

    changeFill(type,newStyle) {
        console.log('changeFill');
        let newFill = this.state.currentFill;
        if(type==='fill')
            newFill.fill = newStyle;
        if(type==='opacity')
            newFill.opacity = newStyle;
        if(type==='stroke')
            newFill.stroke = newStyle;
        if(type==='strokeOpacity')
            newFill.strokeOpacity = newStyle;
        this.setState({ currentFill: newFill });    
        // this.props.setGapiIcon({
            
        // });
    }

    changeColorOrIcon(type, newStyle) {
        let newPoint = this.state.pointStyle || null;
        if(newPoint === null)
            newPoint = {
                pointColor: '#0c9ed9',
                pointIconName: 'circleIcon',
                pointIconSize: 'default',
                pointTextColor: '#ffffff'
            };
        
        if (type === 'color') {
            newPoint.pointColor = newStyle;
        }
        if (type === 'size'){
            newPoint.pointIconSize = newStyle;
        }
        if (type === 'icon'){
            newPoint.pointIconName = newStyle;
        }
        this.setState({ pointStyle: newPoint });
        this.props.setGapiIcon(newPoint);
    }

    toggleDefaultStyling (chk) {
        this.setState({defaultLayerStyle: chk});
    }
    toggleLabelFeats (chk) {
        this.setState({displayLabels: chk});
        this.props.setGapiPointFilter('displayLabels', chk);
    }

    render() {
        return (
        <div>
        <h2 className="panel-title">
        <span className="panel-icon-span">
            <SVGIconComponent name={'retailFilterIcon'} color={'#0c9ed9'} size={'16px'} />
        </span>
        Add Traffic Counts
        <span className="panel-close closeStyle"><CloseIcon onClick={this.props.closePanel} fontSize="small" /></span>
        </h2>


        {this.props.gapiPointsLoading ? <div className="panel-loader"></div>
        :
        <div className="panel-body-slim">
            <div className="retailerOuterDiv padding-t10">
            <div> Count &gt; </div>
            <TextField
                id="other-filter-minval"
                label="Type Minimum Value"
                variant="outlined"
                value={this.state.minDisplayValue}
                size="small"
                autowidth="true"
                onChange={(e) => { this.setMinValue(e) }}
                /> 
            </div> 
            <div className='small-text'>Counts are 'Average Daily Traffic Volume'</div>
            {this.state.refreshLayer ?
             <div className='small-text'>You must 'Refresh Layer' to reflect new Filter Value</div>
             : null }
            <CustomPointStyle
                defaultLayerStyle={this.state.defaultLayerStyle}
                currentPoint={this.state.pointStyle}
                changeColorOrIcon={this.changeColorOrIcon}
                toggleDefaultStyling={this.toggleDefaultStyling}
                slimVersion={true}
            />
            Label Layer Features:
            <Checkbox
                color="default"
                value={this.state.displayLabels}
                inputProps={{ 'aria-label': 'use layer style' }}
                onChange={(event) => this.toggleLabelFeats(event.target.checked)}
                checked={this.state.displayLabels}
            />
            {this.state.displayLabels ?
                <div>
                <LabelStyleOnly
                    updateMapState={this.updateLabelStyle}
                    text={this.state.labelStyle.labelText}
                    color={this.state.labelStyle.labelColor}
                    stroke={this.state.labelStyle.labelStroke}
                    size={this.state.labelStyle.labelFontSize}
                    opacity={this.state.labelStyle.labelOpacity}
                    font={this.state.labelStyle.labelFont}
                    weight={this.state.labelStyle.labelWeight}
                    readOnlyText={true}
                    slimVersion={true}
                />
                </div>
            : null }

            <div className="geocodeDiv flex">
                    <button className="geocodeBtn" onClick={this.removeLayer}>Remove Layer</button>
                    {this.props.hasGapiPoints ? 
                        this.state.refreshLayer ?
                        <button className="geocodeBtn" onClick={this.getLayer}>Refresh Layer</button>
                        :
                        null
                    : <button className="geocodeBtn" onClick={this.getLayer}>Get Layer</button>
                    }
                    
            </div>           
        </div>
        }
        {this.props.gapiPointsErrorMessage ?
        <div className='errorText'>{this.props.gapiPointsErrorMessage}</div>
        : null }
        </div>
        );
    }
}

export default TrafficCountsFilter