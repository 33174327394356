import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import CustomPolygonStyle from '../../MapComponent/CustomPolygonStyle';
import Checkbox from '@material-ui/core/Checkbox';
import LabelStyleOnly from '../../MapComponent/LabelStyleOnly';

class SubmarketPanel extends React.Component {
    constructor(props) {
      super(props);
  
        this.state = {
            type: '',
            defaultLayerStyle: false,
            currentStyle: {
                fill: '#0c9ed9',
                opacity: 0.5,
                stroke: '#ffffff',
                strokeOpacity: 0.5,
                width: 3
            },
            labelFeats: false,
            selectResultName: '',
            displaySinglePoly: false,
            resultNames: [],
            gapiTypes: [
                {featureId: 5, label: "Office Submarkets"},
                {featureId: 7, label: "Industrial Submarkets"},
                {featureId: 8, label: "Retail Submarkets"},
            ]  
        };
        this.getSubs = this.getSubs.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
        this.changeFill = this.changeFill.bind(this);
        this.clearSubs = this.clearSubs.bind(this);
        this.updateLabelStyle = this.updateLabelStyle.bind(this);
        this.selectResultName = this.selectResultName.bind(this);
    }

    componentDidMount () {
        this.setState({type: this.props.gapiPolygonSettings.selectedGapiPolygon});
        if(this.props.gapiPolygonSettings.displayNames.length===0){
            this.setState({displaySinglePoly: false})
        }
        else{
            this.setState({displaySinglePoly: true})
        }                        
    }

    clearSubs(){
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.selectedGapiPolygon = false;
        curInfo.selectedGapiPolygonLabel = '';
        curInfo.resultNames = [];
        curInfo.displayNames = [];
        this.setState({type:'',displaySinglePoly: false, resultNames:[]});
        this.props.updateMapObj({gapiPolygons: [],gapiPolygonLabels: [],gapiPolygonSettings: curInfo});
    }

    getSubs () {
        let curInfo = this.props.gapiPolygonSettings;
        let ix = this.state.gapiTypes.findIndex(t=>t.featureId===this.state.type);
        curInfo.selectedGapiPolygon = this.state.type;
        curInfo.selectedGapiPolygonLabel = this.state.gapiTypes[ix].label;
        curInfo.resultNames = [];
        curInfo.displayNames = [];
        this.setState({displaySinglePoly: false, gettingSubs: true});
        this.props.updateMapObj({gapiPolygonSettings: curInfo});
        this.props.getGAPIPolygons(this.state.type);
    }

    selectFilter(e) {
        this.setState({type:e.target.value});
    }

    toggleDefaultStyling (chk) {
        this.setState({defaultLayerStyle: chk});
    }

    changeFill(type,newStyle) {
        let newFill = this.state.currentStyle;
        if(type==='fill')
            newFill.fill = newStyle;
        if(type==='opacity')
            newFill.opacity = newStyle;
        if(type==='stroke')
            newFill.stroke = newStyle;
        if(type==='strokeOpacity')
            newFill.strokeOpacity = newStyle;
        if(type==='width')
            newFill.width = newStyle;
        this.setState({currentStyle: newFill});
        
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.polygonStyle = newFill;
        this.props.updateMapObj({gapiPolygonSettings: curInfo});
    }

    toggleDisplaySinglePoly(chk){
        this.setState({displaySinglePoly:chk});
        this._updateDisplaySinglePoly(chk,this.state.selectResultName);
    }

    toggleLabelFeats (chk) {
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.displayLabels = chk;
        this.props.updateMapObj({gapiPolygonSettings: curInfo});
    }

    updateLabelStyle(obj) {
        let curInfo = this.props.gapiPolygonSettings;
        let curLabel = curInfo.labelStyle;
        const name = Object.keys(obj);
        curLabel[name] = obj[name];
        curInfo.labelStyle = curLabel;
        this.props.updateMapObj({gapiPolygonSettings: curInfo});
        this.props.updateGAPIPolygonLabels();
    }

    _updateDisplaySinglePoly(bool,mkt){
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.displayNames = bool ? [mkt] : [];
        this.props.updateMapObj({gapiPolygonSettings: curInfo});
    }

    selectResultName(e) {
        this.setState({selectResultName:e.target.value});
        if(e.target.value!=='--Select Submarket--'){
            this.props.zoomToGAPIPolygon(e.target.value);
            this._updateDisplaySinglePoly(this.state.displaySinglePoly,e.target.value);
        }
    }

    render() {
        return (
        <div>
        <h2 className="panel-title">
        <span className="panel-icon-span">
            <SVGIconComponent name={'retailFilterIcon'} color={'#0c9ed9'} size={'16px'} />
        </span>
        Add Submarkets
        <span className="panel-close closeStyle"><CloseIcon onClick={this.props.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
        {this.props.gapiPolygonSettings.gettingGapiPolygons ? 
            <div className="panel-loader"></div>
            :
            <div>
                <div className="retailerOuterDiv padding-t20">
            <Select
                labelId="filter-select"
                id="filter-select"
                onChange={(e) => this.selectFilter(e)}
                value={this.state.type}
                variant="outlined"
                autoWidth={true}
            >
            <MenuItem value={5}>Office</MenuItem>
            <MenuItem value={7}>Industrial</MenuItem>
            <MenuItem value={8}>Retail</MenuItem>
            </Select>
            </div>
            {this.props.gapiPolygonSettings.resultNames.length > 0 ?
            <div>
                <div className="retailerOuterDiv padding-t20">
                Display Single Submarket:
                <Checkbox
                    color="default"
                    value={this.state.displaySinglePoly}
                    inputProps={{ 'aria-label': 'use layer style' }}
                    onChange={(event) => this.toggleDisplaySinglePoly(event.target.checked)}
                    checked={this.state.displaySinglePoly}
                    disableRipple
                />

                </div>
                <div className="retailerOuterDiv padding-t10">
                <Select
                    native
                    labelId="filter-select"
                    id="filter-select"
                    onChange={(e) => this.selectResultName(e)}
                    value={this.state.selectResultName}
                    variant="outlined"
                    autoWidth={true}
                >
                    <option key={'otherField_equal_none'} value={'--Select Submarket--'}>{'--Select Submarket--'}</option>
                    {this.props.gapiPolygonSettings.resultNames.map((fSelValue, idx) =>
                        <option key={'otherField_equal_' + idx} value={fSelValue}>{fSelValue}</option>
                    )}
                </Select>
                </div>
            </div>
            : null}
            <CustomPolygonStyle
                toggleDefaultStyling={this.toggleDefaultStyling}
                defaultLayerStyle={this.state.defaultLayerStyle}
                currentFill={this.props.gapiPolygonSettings.polygonStyle}
                changeFill={this.changeFill}
            />
            Label Layer Features:
            <Checkbox
                color="default"
                value={this.props.gapiPolygonSettings.displayLabels}
                inputProps={{ 'aria-label': 'use layer style' }}
                onChange={(event) => this.toggleLabelFeats(event.target.checked)}
                checked={this.props.gapiPolygonSettings.displayLabels}
                disableRipple
            />
            {this.props.gapiPolygonSettings.displayLabels ?
            <LabelStyleOnly
                updateMapState={this.updateLabelStyle}
                text={''}
                readOnlyText={true}
                color={this.props.gapiPolygonSettings.labelStyle.mapAddLabelColor}
                stroke={this.props.gapiPolygonSettings.labelStyle.mapAddLabelStroke}
                size={this.props.gapiPolygonSettings.labelStyle.mapAddLabelFontSize}
                opacity={this.props.gapiPolygonSettings.labelStyle.mapAddLabelOpacity}
                font={this.props.gapiPolygonSettings.labelStyle.mapAddLabelFont}
                weight={this.props.gapiPolygonSettings.labelStyle.mapAddLabelWeight}
            />
            : null
            }


            <div className="geocodeDiv flex">
            <button className="geocodeBtn" onClick={this.clearSubs}>Clear Layer</button>
            { this.state.type !== this.props.gapiPolygonSettings.selectedGapiPolygon ?
            <button className="geocodeBtn" onClick={this.getSubs}>Get Layer</button>
            :
            null
            }
            </div>
            </div>
        }
        </div>
        </div>
        )
    }
}

export default SubmarketPanel