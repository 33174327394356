import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import LabelStyleOnly from './LabelStyleOnly';

class AddGapiLabelPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.selectedGapiLabel?.content?.text || this.props.potentialGapiLabel?.content?.text,
      mapAddLabelColor: this.props.selectedGapiLabel?.content?.color || this.props.labelStyle.labelColor,
      mapAddLabelStroke: this.props.selectedGapiLabel?.content?.stroke || this.props.labelStyle.labelStroke,
      mapAddLabelFontSize: this.props.selectedGapiLabel?.content?.fontSize || this.props.labelStyle.labelFontSize,
      mapAddLabelOpacity: this.props.selectedGapiLabel?.content?.opacity || this.props.labelStyle.labelOpacity ||  1,
      mapAddLabelFont: this.props.selectedGapiLabel?.content?.font || this.props.labelStyle.labelFont || 'Open Sans',
      mapAddLabelWeight:  this.props.selectedGapiLabel?.content?.weight || this.props.labelStyle.labelWeight,
      mapAddLabelPosition: this.props.selectedGapiLabel?.content?.postion || this.props.currentLabel?.content?.offsetPosition ||  'upper-right',
      position:  this.props.selectedGapiLabel?.position || this.props.potentialGapiLabel?.position,
      removedGapiLabelId: this.props.removedGapiLabelId
    }

    //this.deleteLabel = this.deleteLabel.bind(this);
    this.updateState = this.updateState.bind(this);
    this.addLabel = this.addLabel.bind(this);
    this.removeLabel = this.removeLabel.bind(this);
    this.reAddLabel = this.reAddLabel.bind(this);
    //this.closePanel = this.closePanel.bind(this);
  }


  updateState(updateObj) {
    this.setState(updateObj);
    if(this.props.selectedGapiLabel!==null){
        const keys = Object.keys(updateObj);
        let key = keys[0].replace("mapAddLabel","").toLowerCase();
        if(key==='fontsize')
          key = 'fontSize';

        const value = updateObj[keys[0]];

        if(key==='position')
            key = 'offsetPosition';
    
        let newLabel = this.props.selectedGapiLabel;
        newLabel.content[key] = value;
        newLabel.content.customLabel = true;
        this.props.updateGapiLabel(newLabel);
    }
  }

  removeLabel() {
    this.props.removeGapiLabel(this.props.selectedGapiLabel);
    this.props.closePanel();
  }

  reAddLabel(){
    this.props.readdGapiLabel(this.props.removedGapiLabelId);
    this.setState({removedGapiLabelId:null});
  }

  addLabel() {
    const count = this.props.potentialGapiLabel.content.Traffic1;
    const text = count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const newLabel = {
      position: this.props.potentialGapiLabel.position, //e.latlng,
      content: {
        id: this.props.potentialGapiLabel.content.OBJECTID,
        count: count,
        text: text,
        color: this.state.mapAddLabelColor,
        stroke: this.state.mapAddLabelStroke,
        fontSize: this.state.mapAddLabelFontSize || this.props.currentLabel.content.fontSize,
        opacity: this.state.mapAddLabelOpacity,
        font: this.state.mapAddLabelFont,
        weight: this.state.mapAddLabelWeight,
        customlayer: this.props.potentialGapiLabel.content.customlayer,
        offsetPosition: this.state.mapAddLabelPosition,
        offsetAnchor: this.props.potentialGapiLabel.content.offsetAnchor,
        draggable: this.props.potentialGapiLabel.content.draggable,
        customLabel: true
      }
    }

    this.props.addGapiLabel(newLabel);
    this.props.closePanel();
  }

  render() {
    return (
      <div>
        <div className="customLabelPanel">
          <h2 className="panel-title">
            <span className="panel-icon-span">
              <svg id="styleIconGroups" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 88.62 94.1">
                  <line id="Line_20" stroke="#0c9ed9" fill="none" strokeWidth="4" y1="92.85" x2="88.62" y2="92.85" />
                  <path id="Path_4362" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M24.45-22.9,7.23-35A4.68,4.68,0,0,0,.72-33.89a4.48,4.48,0,0,0-.58,1.13L-3.51-22.3-30.89,5.46a4.65,4.65,0,0,0-1.34,3.23V56.11" transform="translate(34.31 37.14)" />
                  <path id="Path_4363" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M-28.37,55.58,13.52,40.67a4.6,4.6,0,0,0,2.57-2.37L32.84,3l8.58-7a4.68,4.68,0,0,0,.66-6.59,4.62,4.62,0,0,0-.92-.85" transform="translate(34.31 37.14)" />
                  <circle id="Ellipse_2" fill="none" stroke="#0c9ed9" strokeWidth="4" cx="33.22" cy="52.86" r="5.67" />
                  <line id="Line_21" fill="none" stroke="#0c9ed9" strokeWidth="4" x1="3.37" y1="92.08" x2="30.5" y2="56.12" />
                </svg>
            </span>
            Style Labels
            <span className="panel-close closeStyle"><CloseIcon onClick={this.props.closePanel.bind()} fontSize="small" /></span>         </h2>
          <div className="panel-body">
            <LabelStyleOnly
                          updateMapState={this.updateState}
                          updateMapObj={this.props.updateMapObj}
                          text={this.state.text} //props.currentLabel?.content?.text}
                          color={this.state.mapAddLabelColor}
                          stroke={this.state.mapAddLabelStroke}
                          size={this.state.mapAddLabelFontSize}
                          opacity={this.state.mapAddLabelOpacity}
                          font={this.state.mapAddLabelFont} //this.state.font}
                          weight={this.state.mapAddLabelWeight}
                          offsetPosition={this.state.mapAddLabelPosition}
                          individualStyle={false}
                          readOnlyText={true}
                          offsetOpen={true}
            />
            <div className="geocodeDiv flex">
                {this.props.potentialGapiLabel ?
                    <button className="geocodeBtn" onClick={this.addLabel}>Add Label</button>
                : null}
                {this.props.selectedGapiLabel ?
                    this.state.removedGapiLabelId ? 
                    <button className="geocodeBtn" onClick={this.reAddLabel}>Re-Add Label</button>
                    :
                    <button className="geocodeBtn" onClick={this.removeLabel}>Remove Label</button>
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddGapiLabelPanel;