
import { GenerateRandomId, GetNewGroupColor, GetIconOffset } from '../utils/tools';

import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import SVGIconComponent from '../../src/MainPage/SVGIconComponent/SVGIconComponent';
import React from 'react';

export function geoJsonToNewPoints(geojson,selectedColumns) {
    const allfeats = geojson.features;


    let _zi = 5000;
    let _newGroups = [];

    if (selectedColumns.layer !== 'None'){
        const unique = [...new Set(allfeats.map(item => item.properties[selectedColumns.layer]))];
        for (let i=0;i<unique.length;i++)
            _newGroups.push(newGroup(i+1,unique[i]));
    }
    else {
        _newGroups.push(newGroup(1,'Layer 1'));
    }

    let pointsArray = [];
    for (let i=0;i<_newGroups.length;i++){
        const _groupid = _newGroups[i].id;
        const groupIconColor = _newGroups[i].groupIconColor;
        const groupIconName = _newGroups[i].groupIconName;
        const groupTextFill = _newGroups[i].groupTextColor;
        const groupIconSize = _newGroups[i].groupIconSize;
        let _order = 0;

        //map feats
        let feats = allfeats;
        if(_newGroups.length>1){
            feats = allfeats.filter(item=>item.properties[selectedColumns.layer]===_newGroups[i].name);
        }
            
        for (let j=0;j<feats.length;j++) {
            const f = feats[j];
            _order += 1;
            _zi += 50;

            let _icon = L.divIcon({
                className: 'custom-icon',
                html: ReactDOMServer.renderToString(<SVGIconComponent outline={groupIconColor === 'transparent' ? 'transparent' : null} color={groupIconColor} label={_order} name={groupIconName} textFill={groupTextFill} size={groupIconSize} />)
            });

            _icon.options.iconAnchor = GetIconOffset(_icon.options.html, groupIconName);

            let featName = "None";
            if (selectedColumns.name !== "None") 
                featName = f.properties[selectedColumns.name] || "None";
            

            let featLabel = "";
            if (selectedColumns.label !== "None") 
                featLabel = f.properties[selectedColumns.label] || "None";
            

            let featLegend = "";
            if (selectedColumns.name !== "None") 
                featLegend = f.properties[selectedColumns.legend] || "None";
            

            const _thisPoint = {
                "position" : {
                    "lat": f.geometry.coordinates[1],
                    "lng": f.geometry.coordinates[0]
                },
                "content": {
                    "id": GenerateRandomId(),
                    "order": _order,
                    "group": _groupid,
                    "icon": _icon,
                    "pointColor": groupIconColor,
                    "pointIconName": groupIconName,
                    "pointTextColor" : groupTextFill,
                    "pointIconSize": groupIconSize,
                    "lat": f.geometry.coordinates[1],
                    "lng": f.geometry.coordinates[0],
                    "name": featName,
                    "label": featLabel,
                    "address": f.properties.Address || "",
                    "city": f.properties.City || "",
                    "state": f.properties.State || "",
                    "zip": f.properties.Zip || "",
                    "legenddata": featLegend,
                    "useGroupStyle": false,
                    "useCustomLabel": false,
                    "zIndex": _zi
                }
            }
            pointsArray.push(_thisPoint)
        }
    }

    return {
        newPoints: pointsArray,
        newGroups: _newGroups
    }

}

function newGroup(order,name) {
    return {
        id: GenerateRandomId(),
        order: order,
        name: name,
        useGroupStyle: false,
        groupTextColor: '#ffffff',
        groupIconName: 'circleIcon',
        groupIconColor: GetNewGroupColor(order),
        groupIconSize: 'default',
        startNumber: 1,
        visible: true,
        nameInLegend: true,
        pointsInLegend: true,
        groupInLegend: true,
        icon: '',
        showLegendData: false,
        useCustomLabel: false
    }
}

