import React from 'react';
import Menu from '@material-ui/core/Menu';
import CloseIcon from '@material-ui/icons/Close';

class Message extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    }

    this.toggleUpdatePanel = this.toggleUpdatePanel.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  componentDidUpdate() {
    this.props.updateMapZoom();
    setTimeout(() => {
        this.props.closeMapMessage(true);
      }, 5000);
  }

  toggleUpdatePanel(e, bool) {
    this.setState({
      anchorEl: e.currentTarget,
      showOptions: bool
    });
  }

  closePanel() {
    //this.props.togglePanel('layout');
  }

  render() {
    const text = this.props.text;
    return (
      <div className="messageDiv">
        <div className="fontBold paddingR10">
          <div>{text}</div>
        </div>
      </div>
    );
  }
}

export default Message;