import React from 'react';

const colorPickerCell = {
  flex: 1,
  cursor: 'pointer'
}

class ColorPickerCell extends React.Component {

  render() {
    const cellFill = this.props.fillColor || 'transparent';
    const isTransparent = this.props.isTransparent || false;
    const isWhite = this.props.isWhite || false;

    return (
      <div style={colorPickerCell} onClick={this.props.handleCellClick.bind(this, this.props.fillColor)}>
        {isTransparent ?
          <svg height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <line x1="1" y1="1" x2="19" y2="19" stroke="#ff0000" strokeWidth="2" />
            <rect x="1" y="1" fill="transparent" stroke="#959595" strokeWidth="2" width="18" height="18" rx="2" />
          </svg>
          :
          <svg height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            {isWhite ?
              <rect fill={cellFill} width="90" height="90" rx="15" x="5" y="5" stroke="#959595" strokeWidth="10" />
              :
              <rect fill={cellFill} width="100" height="100" rx="15" />
            }
          </svg>
        }
      </div>
    );
  }
}

export default ColorPickerCell;