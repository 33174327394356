import React from 'react';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { GetIconOffset } from '../../../utils/tools';
import MainStylePanel from './MainStylePanel';

function updateIcon(color, order, iconName, textFill, size) {
  const newIcon = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(<SVGIconComponent outline={color === 'transparent' ? 'transparent' : null} color={color} label={order} name={iconName} textFill={textFill} size={size} />)
  });

  newIcon.options.iconAnchor = GetIconOffset(newIcon.options.html, iconName);

  return newIcon;
}

class StyleGroups extends React.Component {
  constructor(props) {
    super(props);
    this.closePanel = this.closePanel.bind(this);
    this.changeColorOrIcon = this.changeColorOrIcon.bind(this);
  }

  closePanel() {
    this.props.toggleGroupPanel(false, this.props.currentGroup);
  }

  changeColorOrIcon(type, newStyle) {
    console.log(type,newStyle);
    //update current group's groupIconColor property
    const groupsArrayClone = Array.from(this.props.groups);
    let groupColor;
    let groupTextColor;
    let groupIcon;
    let groupIconSize;
    let currentGroup = this.props.currentGroup;

    for (let group of groupsArrayClone) {
      if (group.id === this.props.currentGroup) {
        if (type === 'color') {
          group.groupIconColor = newStyle;
          groupIcon = group.groupIconName;
          groupTextColor = group.groupTextColor;
          groupIconSize = group.groupIconSize;
        }
        if (type === 'icon') {
          group.groupIconName = newStyle;
          groupColor = group.groupIconColor;
          groupTextColor = group.groupTextColor;
          groupIconSize = group.groupIconSize;
        }
        if (type === 'text') {
          group.groupTextColor = newStyle;
          groupColor = group.groupIconColor;
          groupIcon = group.groupIconName;
          groupIconSize = group.groupIconSize;
        }
        if (type === 'size') {
          groupTextColor = group.groupTextColor;
          groupColor = group.groupIconColor;
          groupIcon = group.groupIconName;
          group.groupIconSize = newStyle;
        }
        if (type === 'hasLeader') {
          const chk = newStyle.target.checked;
          group.groupLeaderLine = chk;
        }
        if (type === 'hasLeaderAnchor') {
          const chk = newStyle.target.checked;
          console.log(chk);
          group.groupLeaderAnchor = chk;
        }
        if (type === 'leaderColor') {
          group.groupLeaderColor = newStyle;
        }
        if(type==='leaderWidth') {
          const val = parseInt(newStyle.target.value);
          group.groupLeaderWidth = val;
        }
        if(type==='leaderOpacity') {
          const val = parseFloat(newStyle.target.value);
          group.groupLeaderOpacity = val;
        }
      }
    }

    //this.props.updateGroupsArray(groupsArrayClone);

    //update icon color of points in group if necessary
    const pointsArrayClone = Array.from(this.props.points);
    pointsArrayClone.forEach(point => {
      if (point.content.group === this.props.currentGroup) {
        if (point.content.useGroupStyle === true) {
          if (type === 'color') {
            point.content.icon = updateIcon(newStyle, point.content.order, groupIcon, groupTextColor, groupIconSize);
          }
          if (type === 'icon') {
            point.content.icon = updateIcon(groupColor, point.content.order, newStyle, groupTextColor, groupIconSize);
          }
          if (type === 'text') {
            point.content.icon = updateIcon(groupColor, point.content.order, groupIcon, newStyle, groupIconSize);
          }
          if (type === 'size') {
            point.content.icon = updateIcon(groupColor, point.content.order, groupIcon, groupTextColor, newStyle);
          }
          if(point.content.leaderline){
            if (type === 'hasLeaderAnchor'){
              point.content.leaderline.anchor =  newStyle.target.checked;
            }
            if (type === 'leaderColor') {
              point.content.leaderline.color = newStyle;
            }
            if(type==='leaderWidth') {
              const val = parseInt(newStyle.target.value);
              point.content.leaderline.width = val;
            }
            if(type==='leaderOpacity') {
              const val = parseFloat(newStyle.target.value);
              point.content.leaderline.opacity = val;
            }
            if (type === 'hasLeader') {
              point.content.leaderline.show = newStyle.target.checked;
            }
          }
        }
      }
    });
    this.props.updateMapObj({
      mapGroups: groupsArrayClone,
      mapPoints: pointsArrayClone,
      currentGroup: currentGroup
    });
  }

  render() {
    return (
      <MainStylePanel
        isGroup={true}
        groups={this.props.groups}
        currentGroup={this.props.currentGroup}
        points={this.props.points}
        updateOrderAndIcon={this.props.updateOrderAndIcon}
        updateMapObj={this.props.updateMapObj}
        changeColorOrIcon={this.changeColorOrIcon}
        closePanel={this.closePanel}
        translate={this.props.translate}
      />
    );
  }
}

export default StyleGroups;