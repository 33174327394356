import React from 'react';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
class EarthquakeLegend extends React.Component {

    render() {
        const textStyle = {
            float: 'right',
            padding: 4
        };
        const iconStyle = {
            float: 'left'
        }
        const boldStyle = {
            fontWeight: 'bold',
            padding: 8
        }
        const twoStyle = {
            paddingBottom: 8,
            textAlign: 'center'
        }
        const legendRangeStyle = {
            fontSize: 14
        }

        return (
            <div>
                <div style={boldStyle}>予測震度_最大クラス</div>
                <div key="eqkey_0a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_0b"
                        color="#3058ff"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 0</span>
                </div>
                <div key="eqkey_1a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_1b"
                        color="#3a9dff"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 1</span>
                </div>
                <div key="eqkey_2a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_2b"
                        color="#1fe5ff"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 2</span>
                </div>
                <div key="eqkey_3a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_3b"
                        color="#6fffd2"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 3</span>
                </div>
                <div key="eqkey_4a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_4b"
                        color="#b6ff8e"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 4</span>
                </div>
                <div key="eqkey_5a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_5b"
                        color="#e7ff4b"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 5 弱</span>
                </div>
                <div key="eqkey_55a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_55b"
                        color="#ffea00"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 5 強</span>
                </div>
                <div key="eqkey_6a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_6b"
                        color="#ffa600"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 6 弱</span>
                </div>
                <div key="eqkey_66a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_66b"
                        color="#ff6600"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 6 強</span>
                </div>
                <div key="eqkey_7a" className="legendView-point">
                    <SVGIconComponent
                        key="eqkey_7b"
                        color="#ff0000"
                        name='squareIcon'
                        outline="#cccccc"
                    ></SVGIconComponent>
                    <span style={legendRangeStyle}>震度 7</span>
                </div>
            </div>
        );
    }
}
export default EarthquakeLegend;
