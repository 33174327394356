import React, { Component } from 'react';
import SVGIconComponent from '../../MainPage/SVGIconComponent/SVGIconComponent';

export default class ReleaseNotes3Pt1 extends Component {
  render() {
    const maxRows = process.env.REACT_APP_UPLOAD_LIMIT;
    return (
    <div>
        <div className="updateMenuBody">


            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Label Improvements</div>
            </div>
            <div className="updateMenuFeatureDesc">
                Automatic labeling of major roads using label tool
                &nbsp;
                <svg height="18" viewBox="0 0 24 24">
                <path d="M2.5 4v3h5v12h3V7h5V4h-13zm19 5h-9v3h3v7h3v-7h3V9z"></path>
                </svg>
                <br/>Angles added to custom labels.  You can manually change angle in label panel
                <br/>
                <div className="update-text-with-icon">Or on screen using rotate tool
                <div className="rotate-tool"></div>
                </div>
            </div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Interactive Geocoding</div>
            </div>
            <div className="updateMenuFeatureDesc">
            Accuracy information included with results.
            <br/>Option to check and improve results on import
            <br/>Also available using "Add Points...Verify Points"
            <br/>Correction tool also available for individual point in the point click menu
            <br/>
            <div className="text-and-icons">
                <svg fill="#339088" height="24" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path>
                </svg>
                <span className="gText">Good &nbsp;</span>

                <svg fill="#ffd400" height="24" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path>
                </svg>
                <span className="qText">Questionable &nbsp;</span>

                <svg fill="#ed1b34" height="24" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path>
                </svg>
                <span className="dText">Doubtful &nbsp;</span>

                <svg fill="#cccdd5" height="24" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path>
                </svg>
                <span className="uText">Manually Placed &nbsp;</span>
            </div>
            </div>
            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Demographic Range improvements</div>
            </div>
            <div className="updateMenuFeatureDesc">
                Some variables have "High" and "Low" default options
                <br/>Option to round any range type
            </div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Traffic Count Improvements</div>
            </div>
            <div className="updateMenuFeatureDesc">
                Traffic counts now more responsive
            </div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Display Rings in Legend</div>
            </div>
            <div className="updateMenuFeatureDesc">Option to display ring or drive time distance in legend</div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">More Point Sizes</div>
            </div>
            <div className="updateMenuFeatureDesc">Two new options for smaller point sizes</div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Change Imagery Quality</div>
            </div>
            <div className="updateMenuFeatureDesc">
            For the sharpest image use "Best"
            <br/>If the output doesn't match what you see on screen use "Screen"
            </div>


        </div>
    </div>
    );
  }
}
