import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import translateObj from '../../utils/translate';

class ResizeShapePanel extends React.Component {
  render() {
    return (
      <div>
        <div className="styleShapePanel">
          <h2 className="panel-title">
            <span className="panel-icon-span">
            <svg id="resizeShapeIcon" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 100 100">
              <polyline points="5.64 48.67 60.47 75.44 90.56 38.04 44.28 22.9 12.46 44.12" fill="none" stroke="#0c9ed9" strokeWidth="4"/>
              <line x1="53.53" y1="45.72" x2="71.74" y2="54.12" fill="none" stroke="#0c9ed9" strokeWidth="4"/>
              <polygon points="66.7 55.53 71.2 53.88 69.55 49.38 72.16 50.58 73.82 55.08 69.32 56.74 66.7 55.53" fill="#0c9ed9"/>
              <line x1="32.82" y1="36.16" x2="47" y2="42.7" fill="none" stroke="#0c9ed9" strokeWidth="4"/>
              <polygon points="34.97 40.81 33.35 36.4 37.76 34.78 35.2 33.6 30.79 35.22 32.41 39.63 34.97 40.81" fill="#0c9ed9"/>
              <line x1="61.9" y1="33.01" x2="35.98" y2="58.4" fill="none" stroke="#0c9ed9" strokeWidth="4"/>
              <polygon points="56.78 33.36 61.48 33.41 61.44 38.11 63.45 36.14 63.5 31.44 58.8 31.39 56.78 33.36" fill="#0c9ed9"/>
              <polygon points="36.45 53.19 36.4 57.98 41.2 58.03 39.14 60.05 34.35 59.99 34.4 55.2 36.45 53.19" fill="#0c9ed9"/>
            </svg>
            </span>
          {translateObj.resizeRectanglesTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.props.toggleShapeStyle.bind(this, false)} fontSize="small" /></span>
          </h2>
          <div className="panel-body maxWidthResize">
            <div>{translateObj.clickAndDragCorners[this.props.translate]}</div>
            <div className="panel-body-small-text">
              <b>{translateObj.noteBold[this.props.translate]}:</b> {translateObj.featureOnlyWorksOnRect[this.props.translate]}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResizeShapePanel;