import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import PaletteIcon from '@material-ui/icons/Palette';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import PublicIcon from '@material-ui/icons/Public';
import {getGeoAccColor} from '../../../../src/utils/tools';


const popupStyle = {
  fontFamily: 'Open Sans, Arial, sans-serif',
  minWidth: '150px'
}

const popupFooter = {
  display: 'flex',
  paddingTop: '15px',
}

const popupFooterActions = {
  marginLeft: 'auto',
  color: '#0c9ed9',
  display: 'flex',
  flexDirection: 'row',
}

const footerIcons = {
  cursor: 'pointer',
  marginLeft: '5px'
}

const nameDiv = {
  display: 'inline-flex',
  alignItems: 'center',
  padding: '3px 0px'
}

const labelSpan = {
  paddingRight: '8px'
}

class PopupContent extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      editing: false,
      labelHack: false,
      accuracy: false
    }

    this.deletePoint = this.deletePoint.bind(this);
    this.setStylePoint = this.setStylePoint.bind(this);
    this.savePointName = this.savePointInfo.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.setEditRing = this.setEditRing.bind(this);
    this.setLabelPoint = this.setLabelPoint.bind(this);
    this.accuracyPointInfo = this.accuracyPointInfo.bind(this);
  }

  deletePoint() {
    this.props.deletePoint(this.props.content);
  }

  accuracyPointInfo() {
    this.setState({accuracy: !this.state.accuracy});
    this.props.updateMapObj({
      currentPoint: this.props.content,
      currentGroup: this.props.content.group,
      isReGeocoding: true,
      isStyling: false,
      isEditingRing: false,
      isLabeling: false,
      closePopup: true
    });
  }

  setStylePoint() {
    this.props.updateMapObj({
      currentPoint: this.props.content,
      currentGroup: this.props.content.group,
      isStyling: true,
      isEditingRing: false,
      isLabeling: false
    });
  }

  setLabelPoint() {
    this.props.updateMapObj({
    currentPoint: this.props.content,
    currentGroup: this.props.content.group,
    isStyling: false,
    isEditingRing: false,
    isLabeling: true
    });
  }

  savePointInfo(key, value) {
    let timeoutHack = false;
    if(this.props.content.pointTextLabel===true && this.props.content.pointTextLabelField === key){
      timeoutHack = true;
    }

    const newPointContent = this.props.content;
    newPointContent[key] = value;

    const pointsArrayClone = Array.from(this.props.points);
    let thisPoint = null;

    pointsArrayClone.forEach(point => {
      if (point.content.id === newPointContent.id) {
        point.content[key] = newPointContent[key];
        if(timeoutHack){
          point.content.pointTextLabel = false;
        }
        thisPoint = point;
      }
    });

    let newPointsClone = key === 'label' ? this._updateIconForLabelChange(thisPoint) : pointsArrayClone;
    this.props.updateMapObj({ mapPoints: newPointsClone });
    if(timeoutHack){
      setTimeout(() => {
        const points2 = []; //Array.from(this.props.points);
        pointsArrayClone.forEach(point => {
          if (point.content.id === newPointContent.id) {
            thisPoint = point;
            thisPoint.content[key] = newPointContent[key];
            thisPoint.content.pointTextLabel = true;
            points2.push(thisPoint);
          }
          else {
            points2.push(point);
          }
        });
        this.props.updateMapObj({ mapPoints: points2 });
      }, 1000);
    }
  }

  _updateIconForLabelChange(point) {
    const groupsArrayClone = Array.from(this.props.groups);
    const pointArray = [point];
    const leftOverArray = Array.from(this.props.points).filter(point => point.content.id !== this.props.content.id);

    const newPtArray = this.props.updateOrderAndIcon(groupsArrayClone, this.props.content.group, pointArray, true);
    let combinedArray = newPtArray.concat(leftOverArray);
    combinedArray.sort((a, b) => (a.content.order > b.content.order) ? 1 : ((b.content.order > a.content.order) ? -1 : 0));
    return combinedArray;
  }

  toggleEditing() {

    this.setState({
      editing: !this.state.editing
    });
  }

  setEditRing() {
    this.props.updateMapObj({
      currentPoint: this.props.content,
      isEditingRing: true
    });
  }

  render() {
    const color = getGeoAccColor(this.props.content);
    const accColor = {
      cursor: 'pointer',
      marginLeft: '5px',
      color: color
    }
    
    return (
      <div style={popupStyle}>
        <div>
          {this.props.content.order}
        </div>
        <Divider />
        {}
        {!this.state.editing ?
          <div>
            <div>
              <span style={labelSpan}>Name:</span>{this.props.content.name}
            </div>
            <div>
              <span style={labelSpan}>Layer:</span>{this.props.groupName}
            </div>
            <div>
              <span style={labelSpan}>Address:</span>{this.props.content.address}
            </div>
            <div>
              <span style={labelSpan}>City:</span>{this.props.content.city}
            </div>
            <div>
              <span style={labelSpan}>{this.props.secyGeoName + ':'}</span>{this.props.content.state}
            </div>
            <div>
              <span style={labelSpan}>ZIP:</span>{this.props.content.zip}
            </div>
            <div>
              <span style={labelSpan}>Label:</span>{this.props.content.label}
            </div>
            <div>
              <span style={labelSpan}>Legend Data:</span>{this.props.content.legenddata}
            </div>
          </div>
          :
          <div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>Name:</span>
              </div>
              <TextField
                id="pointNameInput"
                defaultValue={this.props.content.name}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.savePointInfo('name', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.savePointInfo('name', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>Address:</span>
              </div>
              <TextField
                id="pointAddressInput"
                defaultValue={this.props.content.address}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.savePointInfo('address', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.savePointInfo('address', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>City:</span>
              </div>
              <TextField
                id="pointCityInput"
                defaultValue={this.props.content.city}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.savePointInfo('city', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.savePointInfo('city', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>State:</span>
              </div>
              <TextField
                id="pointStateInput"
                defaultValue={this.props.content.state}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.savePointInfo('state', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.savePointInfo('state', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>ZIP:</span>
              </div>
              <TextField
                id="pointZIPInput"
                defaultValue={this.props.content.zip}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.savePointInfo('zip', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.savePointInfo('zip', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>Label:</span>
              </div>
              <TextField
                id="pointLabelInput"
                defaultValue={this.props.content.label}
                variant="outlined"
                size="small"
                inputProps={ {maxLength: 3} }
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.savePointInfo('label', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.savePointInfo('label', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>Legend Data:</span>
              </div>
              <TextField
                id="pointLegendDataInput"
                defaultValue={this.props.content.legenddata}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.savePointInfo('legenddata', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.savePointInfo('legenddata', e.target.value)
                }}
              />
            </div>
          </div>
        }
        <div style={popupFooter}>
          <div style={popupFooterActions}>
            <div style={accColor}><PublicIcon onClick={this.accuracyPointInfo} fontSize="small"/></div>
            <div style={footerIcons}><RadioButtonCheckedIcon onClick={this.setEditRing} fontSize="small" /></div>
            <div style={footerIcons}><EditIcon onClick={this.toggleEditing} fontSize="small" /></div>
            <div style={footerIcons}><PaletteIcon onClick={this.setStylePoint} fontSize="small" /></div>
            <div style={footerIcons}><TextFieldsIcon onClick={this.setLabelPoint} fontSize="small"/></div>
            <div style={footerIcons}><DeleteIcon onClick={this.deletePoint} fontSize="small" /></div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopupContent;