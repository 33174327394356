import React, { Component } from 'react';
import SVGIconComponent from '../../MainPage/SVGIconComponent/SVGIconComponent';

export default class ReleaseNotes3Pt0 extends Component {
  render() {
    const maxRows = process.env.REACT_APP_UPLOAD_LIMIT;
    return (
    <div>
        <div className="updateMenuBody">

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Layer and data enhancements</div>
            </div>
            <div className="updateMenuFeatureDesc">Traffic counts and Colliers submarkets added.  They can be filtered, labeled and styled.  They can be found in the "Add Data" menu.
            <br/>Geojson format can be imported.  Geojson is a common format for mapping software and compatible with Google Earth Pro.  This is also found in the "Add Data" menu.
            <br/>All data will populate when the map is panned.
            <br/>Demographics more responsive when changing range or variable.</div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Additional export options</div>
            </div>
            <div className="updateMenuFeatureDesc">All point data including traffic counts and retailers on the map can be exported to .XLSX
            <br/>Shapes routes and points can be exported to geojson format.
            <br/>These options are found in the "Output" menu</div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Logo improvements</div>
            </div>
            <div className="updateMenuFeatureDesc">
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22">
            <path fill={'#ffffff'} stroke={'#cccccc'} d="M0 0L0 22L6 14L1 14L1 2L18 2L18 14L5 21L5 22L20 22L20 0L0 0z"/>
            <path fill={'#010101'} stroke={'none'} d="M1 2L1 14L6 14L3 22L18 14L18 2L1 2z"/>
            <path fill={'#ffffff'} stroke={'none'} d="M3 4L3 12L8 12L5 20L16 12L16 4L3 4z"/>
            </svg> 
            &nbsp;&nbsp;New callout box drawing tool
            <br/>Optional customizable lead lines for all or individual logos.  "Add Leader Line on Move" is now in the main "Add Retail Logos" menu for all points.  Click on the point and the style icon for options for individual points.
            <br/>Improved process for requesting missing logo.  The application will check all retailers in the database and all locations in the map window.</div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Legend, your data improvements</div>
            </div>
            <div className="updateMenuFeatureDesc">Optional lead lines.  This is accessible in the style menu when clicking the point, or in the legend.
            <br/>"Sort" alphabetically or "Arrange" geographically.  Use the legend menu to access.  You can undo if the results are not wanted.
            <br/>New option to label by name or other field.  This will be a longer text offset from the point.  Access in the legend menu using the <span className="updateHighlightSpan">Tt</span> icon or click the point and then the <span className="updateHighlightSpan">Tt</span> icon for an individual point.  
            <br/>Use coordinates to add points.  "Type Coordinates" is now an option in the "Add Points" Menu.  Coordinates should be in decimal degrees. (33.1234, -111.5678)</div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Map Scale</div>
            </div>
            <div className="updateMenuFeatureDesc">Display is optional and you can select which corner of the map is best for display.  This is accessed in the Legend Menu under Legend Options</div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Right Click Menu</div>
            </div>
            <div className="updateMenuFeatureDesc">Coordinates are displayed and there is a link to Hydra that will auto-populate Hydra with the point clicked</div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Change outline weights</div>
            </div>
            <div className="updateMenuFeatureDesc">All shapes, rings, lines will have weight displayed in style window.  3 is the weight of previous versions and is the default</div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Clearer limits for uploading</div>
            </div>
            <div className="updateMenuFeatureDesc">{maxRows} row limit for importing points</div>

        </div>
    </div>
    );
  }
}
