import React from 'react';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';

class LoadingPanel extends React.Component {
    constructor(props) {
        super(props);
	}

    render() {
        return (
            <div className="loadingPanelStyle">
                <h2 className="panel-title">
                    <span className="panel-icon-span">
                        <SVGIconComponent name={this.props.icon || 'retailFilterIcon'} color={'#0c9ed9'} size={'16px'} />
                    </span>
                    {this.props.text || "Loading"}
                </h2>
                <div className="loader-body">
                    <div className="loader-loader"></div>
                </div>
            </div>
		)
	}
}

export default LoadingPanel