import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import translateObj from '../../../utils/translate';

class LayoutPanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.setCustomLayoutSize = this.setCustomLayoutSize.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  handleChange(event) {
    let updateObj = {
      mapOptionsLayout: event.target.value
    };

    if (event.target.value === 'none' || event.target.value === 'custom') {
      updateObj.outputType = 'png';
    }

    this.props.updateMapObj(updateObj);
  };

  setCustomLayoutSize(value, type) {
    let customLayoutClone = Array.from(this.props.mapObj.customLayoutDimensions);
    let currentWidth = customLayoutClone[0];
    let currentHeight = customLayoutClone[1];
    const convert = this.props.mapObj.country === 'US' ? 1 : 2.54;

    let userVal = value || 1;
    if(this.props.mapObj.country === 'US')
    {
      if (value > 8) {
        userVal = 8;
      } else if (value < 1) {
        userVal = 1;
      }
    }
    else {
      if (value > 20) {
        userVal = 20;
      } else if (value < 3) {
        userVal = 3;
      }
    }

    const num = Math.round((userVal) * 100) / 100 / convert;

    let newCustomLayoutArray = [];
    if (type === 'width') {
      newCustomLayoutArray.push(num, currentHeight);
    } else {
      newCustomLayoutArray.push(currentWidth, num);
    }
    
    this.props.updateMapObj({ customLayoutDimensions: newCustomLayoutArray });
  }

  closePanel() {
    this.props.togglePanel('layout');
  }

  render() {
    //const cmToIn = 2.54;
    const convert = this.props.mapObj.country === 'US' ? 1 : 2.54;

    const units = this.props.mapObj.country === 'US' ? 'inches' : 'cm';
    const widthLabel = "Width (" + units + ")";
    const heightLabel = "Height (" + units + ")";
    
    const minValue = this.props.mapObj.country === 'US' ?  1 : 3;
    const maxValue = this.props.mapObj.country === 'US' ?  8 : 20;


    const currentWidth = Math.round((this.props.mapObj.customLayoutDimensions[0]) * 100 * convert) / 100;
    const currentHeight = Math.round((this.props.mapObj.customLayoutDimensions[1]) * 100 * convert) / 100;
    // const currentWidth = this.props.mapObj.country === 'US' ? this.props.mapObj.customLayoutDimensions[0] : this.props.mapObj.customLayoutDimensions[0] * cmToIn;
    // const currentHeight = this.props.mapObj.country === 'US' ? this.props.mapObj.customLayoutDimensions[1] : this.props.mapObj.customLayoutDimensions[1] * cmToIn;

    return (
      <div className="sidePanelStyle">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="layoutIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 84.58 73.33">
              <polygon points="72.53 61.61 67.38 61.61 67.38 59.27 69.56 59.27 69.01 57.11 71.23 56.52 72.53 61.61" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <path d="M-20.22,35.36h6.92V33h-6.92Zm13.84,0H.53V33H-6.38Zm13.82,0h6.92V33H7.44Zm13.83,0h6.92V33H21.27Z" transform="translate(32.28 26.25)" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <polygon points="5.15 61.61 0 61.61 1.31 56.52 3.52 57.11 2.97 59.27 5.15 59.27 5.15 61.61" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <path d="M-19.25-6.29l-2.21-.6,1.9-7.43,2.22.6ZM-23,8.57-25.27,8l1.9-7.43,2.22.59Zm-3.81,14.86-2.21-.59,1.9-7.43,2.22.59Z" transform="translate(32.28 26.25)" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <polygon points="16.84 5.09 14.62 4.5 15.78 0 20.32 0 20.32 2.34 17.54 2.34 16.84 5.09" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <path d="M-5.58-23.91H.8v-2.34H-5.58Zm12.76,0h6.37v-2.34H7.18Z" transform="translate(32.28 26.25)" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <polygon points="55.7 5.09 54.99 2.34 52.21 2.34 52.21 0 56.76 0 57.91 4.5 55.7 5.09" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <path d="M27.22-6.29l-1.9-7.43,2.21-.6,1.91,7.43ZM31,8.57,29.12,1.14,31.34.55,33.24,8Zm3.8,14.86L32.93,16l2.21-.59,1.91,7.43Z" transform="translate(32.28 26.25)" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <polygon points="79.44 60.73 64.27 1.47 66.48 0.88 81.66 60.14 79.44 60.73" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <rect x="1.48" y="68.03" width="69.56" height="2.35" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <rect x="0.34" y="65.08" width="2.29" height="8.25" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <rect x="69.9" y="65.08" width="2.29" height="8.25" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <rect x="61.34" width="8.07" height="2.34" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
              <rect x="76.51" y="59.26" width="8.07" height="2.35" fill="#0c9ed9" stroke="#0c9ed9" strokeWidth="1" />
            </svg>
          </span>
          {translateObj.selectLayoutTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <Select
            labelId="layout-select"
            id="layout-select"
            value={this.props.layout}
            onChange={this.handleChange}
            variant="outlined"
          >
            {/* <MenuItem value={'none'}>No Layout</MenuItem> */}
            {/* <MenuItem value={'4x4'}>4 x 4</MenuItem> */}
            <MenuItem value={'landscape'}>Landscape</MenuItem>
            <MenuItem value={'portrait'}>Portrait</MenuItem>
            <MenuItem value={'landscape_tabloid'}>Landscape Tabloid</MenuItem>
            <MenuItem value={'portrait_tabloid'}>Portrait Tabloid</MenuItem>
            <MenuItem value={'custom'}>Custom</MenuItem>
          </Select>
          {this.props.mapObj.mapOptionsLayout === 'custom' ?
            <div className="customLayoutInputs">
              <div className="customLayoutText">
                <TextField
                  id="groupNameInput"
                  label={widthLabel}
                  defaultValue={currentWidth}
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: minValue, max: maxValue } }}
                  onChange={(e) => { this.setCustomLayoutSize(e.target.value, 'width') }}
                />
              </div>
              <div className="customLayoutText">
                <TextField
                  id="groupNameInput"
                  label={heightLabel}
                  defaultValue={currentHeight}
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: minValue, max: maxValue } }}
                  onChange={(e) => { this.setCustomLayoutSize(e.target.value, 'height') }}
                />
              </div>
            </div>
            : null
          }
        </div>
      </div>
    );
  }
}

export default LayoutPanel;